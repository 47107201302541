/* eslint camelcase: off */

import { GET_ADMINS, GET_ADMIN, GET_ADMIN_ASSESSMENTS, UPDATE_AUTH } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Return a list of admins
export const getAdmins = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      // const { offset = 0, sortCount = 10, direction = 'desc', sortBy = 'lastName', search = '' } = filter

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin?${queryParams}`, options), loaderArea.ADMIN_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_ADMINS,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get admins.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return details for a single admin
export const getAdmin = (adminID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin/${adminID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const adminData = await response.json()

        dispatch({
          type: GET_ADMIN,
          payload: adminData
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get admin.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new admin
export const createAdmin = (adminInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // if the user has confirmed they wish to plus-address the original user's email, add in the query param that bypasses the duplicate email check
      const query = adminInfo?.duplicateUser ? '?confirm_duplicate_update=true' : ''

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adminInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin${query}`, options), loaderArea.ADMIN_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const res = await response.json()
        const fullRes = { ...res }

        // if we get a 409, check for a userID returned in the res
        if (parsedResponse.type === responseTypes.DUPLICATE_ENTRY) {
          const { data } = res
          if (data) {
            if (!isNaN(parseInt(data))) {
              fullRes.duplicateUser = data
            }
          }
        }
        fireFailure(fullRes)

        log.info('Failed to create admin.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing admin
export const updateAdmin = (adminInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID: authUser = '' } = getState().auth

      const { userID = '' } = adminInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adminInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin/${userID}`, options), loaderArea.ADMIN_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        if (userID === authUser) {
          dispatch({
            type: UPDATE_AUTH,
            payload: { ...adminInfo }
          })
        }

        return true
      } else {
        const data = await response.json()
        fireFailure(data)
        log.info('Failed to update admin.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an existing admin
export const deleteAdmin = (adminID = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin/${adminID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to delete admin.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return a list of assessments viewable to an admin
export const getAdminAssessments = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction
      tempFilter.sortBy = !tempFilter.sortBy ? 'createdAt' : tempFilter.sortBy

      const body = JSON.stringify({ ...tempFilter })

      // Example Filter:
      /*
        {
          "offset": 0,
          "sortCount": 10,
          "direction": "desc",
          "sortBy": "createdAt",
          "subFilter": {
              "company": "",
              "search": "Jeff",
              "partnerID": "",
              "partnerUserID": "",
              "discountCode": "",
              "industryID": "",
              "sectorID": "",
              "status": "IN_PROGRESS",
              "userID": ""
          }
      */

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin/${userID}/assessments`, options), loaderArea.ADMIN_ASSESSMENTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_ADMIN_ASSESSMENTS,
          payload: data
        })

        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get admins.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const generateAssessmentLink = (assessmentLinkInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID: id = '' } = getState().auth
      const { assessmentID = '' } = assessmentLinkInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(assessmentLinkInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/admin/${id}/assessments/${assessmentID}/link`, options), loaderArea.GET_ASSESSMENT_LINK)
      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        fireSuccess(data)
        return true
      } else {
        fireFailure(parsedResponse)
        log.info('Failed to send assessment link.')
        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
