import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { usePromiseTracker } from 'react-promise-tracker'
import { validateEmail } from '../../../utils'
import { preResetPassword } from '../../../redux/actions'

import {
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert
} from 'reactstrap'

import Loader from '../../../components/Loader'

import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const formFeedbackMsgs = {
  INVALID_EMAIL: {
    alertType: 'warning',
    msg: 'Invalid Email Format'
  },
  NOT_FOUND: {
    alertType: 'warning',
    msg: 'No record was found for the provided email. Please try again with a new email, or contact FoundersIB for assistance.'
  },
  GEN_ERROR: {
    alertType: 'danger',
    msg: 'Oh no! Something went wrong with your request. Please try again later or contact FoundersIB for assistance.'
  },
  SUCCESS: {
    alertType: 'success',
    msg: 'We have received your request! You should receive an email shortly with a link to reset your password. If you do not receive the email, please be sure to check you spam folder, or contact FoundersIB for assistance.'
  }
}

export const ResetPasswordForm = () => {
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker()

  const [email, setEmail] = useState('')
  const [feedbackMsg, setFeedbackMsg] = useState(null)

  const fireFailure = (res) => {
    const { code } = res
    const errType = code === 404 ? formFeedbackMsgs.NOT_FOUND : formFeedbackMsgs.GEN_ERROR
    setFeedbackMsg(errType)
  }

  const fireSuccess = () => {
    setFeedbackMsg(formFeedbackMsgs.SUCCESS)
    setEmail('')
  }

  const handlePreResetPassword = () => {
    const isValidEmail = validateEmail(email)
    if (!isValidEmail) {
      setFeedbackMsg(formFeedbackMsgs.INVALID_EMAIL)
    } else {
      dispatch(preResetPassword({ email }, fireSuccess, fireFailure))
    }
  }

  return (
    <Col className='col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 px-xl-5 flex-grow-1 d-flex flex-column justify-content-center'>
      <div className='text-center'>
        <h3>Investment Grade Assessment</h3>
      </div>
      <Card>
        <CardBody className='px-0'>
          <Form>
            <FormGroup>
              <Label className='font-600'>Email</Label>
              <Input
                maxLength='50'
                required
                bsSize='lg'
                value={email}
                name='email'
                className='mb-1'
                placeholder='Enter your email'
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className='d-flex justify-content-end'>
                <small>
                  <Link to='/auth/sign-in'>
                    Remember your password? Sign In!
                  </Link>
                </small>
              </div>
            </FormGroup>
            <div className='text-center mt-3'>
              <UncontrolledAlert
                isOpen={Boolean(feedbackMsg)}
                toggle={() => { setFeedbackMsg(null) }}
                color={feedbackMsg?.alertType || 'light'}
              >
                <div className='alert-icon'>
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className='alert-message text-left'>
                  <span className=''>{feedbackMsg?.msg || ''}</span>
                </div>
              </UncontrolledAlert>
              {promiseInProgress
                ? (
                  <Loader size='sm' />
                  )
                : (
                  <Button color='primary' type='button' onClick={handlePreResetPassword} size='lg'>
                    Reset
                  </Button>
                  )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  )
}
