import React from 'react'
import { LinearProgress, CircularProgress, useTheme } from '@mui/material'
import { progressComponentTypes } from '../variables'

export const StyledLoadingIndicator = (props) => {
  const { loaderType, sx, style, color, area, size } = props
  const theme = useTheme()

  const defaultSX = { '.MuiLinearProgress-bar1Indeterminate': { backgroundColor: theme.palette.blue.main }, '.MuiLinearProgress-bar2Indeterminate': { backgroundColor: theme.palette.blue.main } }

  const defaultStyles = {
    circularProgressStyle: { color: 'white', margin: '.4em' },
    linearProgressStyle: { height: 2 }
  }

  switch (loaderType) {
    case progressComponentTypes.LINEAR_PROGRESS: {
      return (<LinearProgress area={area} color={color || 'primary'} style={style || defaultStyles.linearProgressStyle} sx={sx || defaultSX} />)
    }
    case progressComponentTypes.CIRCULAR_PROGRESS: {
      return (<CircularProgress area={area} size={size || 15} style={style || defaultStyles.circularProgressStyle} />)
    }
    default: return false
  }
}
