const api_beta = 'https://api.beta.foundersib.com/'
const api_local = 'http://localhost:3085/'
let apiURL

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  apiURL = process.env.REACT_APP_API_URL
} else if (process.env.REACT_APP_ENV === 'beta') {
  apiURL = api_beta
} else {
  apiURL = api_local
}

const config = { apiURL }

export default config
