import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken, logout } from '../redux/actions/auth'
import Swal from 'sweetalert2'
import moment from 'moment'

// Session Toast config information (timer set to one minute)
const sessionToastConfig = Swal.mixin({
  position: 'top',
  showConfirmButton: true,
  timer: 60000,
  timerProgressBar: true,
  customClass: 'swal-margin-top',
  confirmButtonColor: '#5280AA'
})

// Sweet alert triggered when the auth token is one minute from expiring.
// Allows the user that one minute to confirm they do not wish to be logged out
// refreshes the token if the alert is dismissed
const SessionResponse = () => {
  // the current moment the token is set to expire
  const { exp } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const intervalRef = useRef()
  const expirationRef = useRef()

  useEffect(() => {
    if (!exp || isNaN(parseInt(exp)) || exp === 0) { return false }
    expirationRef.current = exp

    // Get the current moment, and the moment when the alert should trigger
    const now = moment().valueOf()
    const triggerAt = moment(exp).subtract(1, 'm').valueOf()

    // listens for when to set the counter timeout
    const setActiveTimeout = () => {
      // if we have a valid number for when to trigger the alert, and the current moment is still less than the trigger moment
      if (triggerAt && !isNaN(parseInt(triggerAt)) && now < triggerAt) {
        // grab the differnce in time to set as the interval
        const ms = triggerAt - now
        const interval = setTimeout(() => { checkActive() }, ms)
        intervalRef.current = interval
      } else {
        // otherwise something was wrong with the timing, so log out the user
        dispatch(logout(true))
      }
    }

    setActiveTimeout()

    // the toast to fire at the end of the timout
    const checkActive = () => {
      sessionToastConfig.fire({
        title: 'Are you there?',
        html: 'You will be logged out in one minute. You may resume by dismissing this message.',
        timer: 60000
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          // if the timer runs out, logout the user
          return dispatch(logout(true))
        } else {
          // otherwise if the user dismissed the alert, refresh the token
          return dispatch(refreshToken())
        }
      })
    }

    return () => {
      // clear out everything on unmount
      clearTimeout(intervalRef.current)
      expirationRef.current = null
    }
  }, [dispatch, exp])

  return (
    <></>
  )
}

export default SessionResponse
