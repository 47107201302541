import {
  GET_ASSESSMENT_FLOW_QUESTIONS, RESET_ASSESSMENT, GET_ASSESSMENT_LIST,
  GET_ASSESSMENT_SECTION_QUESTIONS, LOGOUT_USER
} from '../types'

const initialState = {
  selectedAssessment: {
    assessmentID: '',
    assessmentTitle: '',
    createdAt: '',
    editedAt: '',
    deletedAt: null,
    sectorID: null,
    sectorName: '',
    industryID: null,
    industryName: '',
    versionHistory: []
  },
  assessmentFlowQuestions: [],
  assessmentSectionQuestions: [],
  assessmentList: {
    assessments: [],
    totalAssessments: 0
  }
}

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENT_FLOW_QUESTIONS: return {
      ...state,
      assessmentFlowQuestions: action.payload.assessmentQuestions
    }
    case GET_ASSESSMENT_LIST: return {
      ...state,
      assessmentList: {
        assessments: action.payload.assessments,
        totalAssessments: action.payload.totalAssessments
      }
    }
    case GET_ASSESSMENT_SECTION_QUESTIONS: return {
      ...state,
      selectedAssessment: action.payload.selectedAssessment,
      assessmentSectionQuestions: action.payload.sections
    }
    case RESET_ASSESSMENT: return {
      ...state,
      selectedAssessment: {
        ...initialState.selectedAssessment
      },
      assessmentFlowQuestions: [],
      assessmentSectionQuestions: []
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default assessmentReducer
