import React from 'react'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col } from 'reactstrap'

const SCORE_LIMIT = 71

const FarLeftBox = ({ reportMode, assessmentScore = 0, onTalkToAnExpert = () => {} }) => {
  const getFarLeftBoxHeader = () => {
    if (assessmentScore < SCORE_LIMIT) {
      return 'Feeling Overwhelmed?'
    }
    return 'Great Score'
  }

  const getFarLeftBoxText = () => {
    if (assessmentScore < SCORE_LIMIT) {
      return 'There might be a variety of areas for improvement, but we’re here to help! Our team can provide insight into the best places to focus your energy to improve your Investment Grade.'
    }
    return 'Your Company appears to be doing really well. Our team of experts is happy to discuss your results and potential opportunities to harvest the reward of your investment.'
  }

  return (
    <Col className='d-flex flex-column p-3 border rounded bg-white mt-2'>
      <span className='font-600'>{getFarLeftBoxHeader()}</span>
      <p className='small mt-2'>{getFarLeftBoxText()}</p>
      {!reportMode &&
        <div className='d-flex justify-content-start'>
          <Button onClick={() => onTalkToAnExpert()} color='primary' className='rounded d-flex align-items-center'>
            <FontAwesomeIcon icon={faShareSquare} className='text-white' size='lg' />
            <small className='ml-2'>Discuss Results with an Expert</small>
          </Button>
        </div>}
    </Col>
  )
}

export default FarLeftBox
