export const partnerUserSideMenuTabs = {
  USERS: {
    tab: 'users',
    label: 'Users'
  },
  ASSESSMENTS: {
    tab: 'assessments',
    label: 'Assesments'
  },
  CODES: {
    tab: 'codes',
    label: 'Discount Codes'
  }
}

export const adminSideMenuTabs = {
  USERS: {
    tab: 'users',
    label: 'Users'
  },
  ADMINS: {
    tab: 'admins',
    label: 'Admins'
  },
  PARTNERS: {
    tab: 'partners',
    label: 'Partners'
  },
  ASSESSMENTS: {
    tab: 'assessments',
    label: 'Assessments'
  },
  AGREEMENTS: {
    tab: 'agreements',
    label: 'Agreements'
  },
  CODES: {
    tab: 'codes',
    label: 'Discount Codes'
  }
}

export const userTypes = {
  COMPANY_USER: 'user',
  PARTNER_USER: 'partnerUser',
  ADMIN_USER: 'admin'
}

export const discountCodeTabTypes = {
  DISCOUNT_CODES: 'discountCodes',
  PARTNER_DISCOUNT_CODES: 'partnerDiscountCodes',
  PARTNER_USER_DISCOUNT_CODES: 'partnerUserDiscountCodes'
}

export const assessmentStatus = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS'
}

export const assessmentStatusLabel = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress'
}

export const validInputCheck = {
  containsSpecialChars: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
  onlySpecialChars: /^[!@#$%^&*()_-\s]+$/,
  onlyBlankSpaces: /^\s*$/
}

export const builderModalTypes = {
  QUESTION: 'QUESTION',
  OPTION: 'OPTION',
  NEW_OPTION: 'NEW_OPTION'
}

export const loaderArea = {
  BUILDER_ASSESSMENT_LIST: 'builder-assessment-list',
  ASSESSMENT_SECTOR_QUESTIONS: 'assessment-sector-questions',
  ALL_QUESTIONS_FETCH: 'all-question-fetch',
  FETCH_QUESTION_OPTIONS: 'fetch-question-options',
  EDIT_QUESTION_OPTIONS: 'edit-question-options',
  DUPLICATE_ASSESSMENTS: 'duplicate-assessments',
  CREATE_ASSESSMENT: 'create-assessment',
  ADMIN_ASSESSMENTS: 'admin-assessments',
  ADMIN_ADMINISTRATION: 'admin-administration',
  USER_ADMINISTRATION: 'user-administration',
  PARTNER_ADMINISTRATION: 'partner-administration',
  PARTNER_USER_ADMINISTRATION: 'partner-user-administration',
  PARTNER_USERS: 'partner-users',
  ADMIN_AGREEMENTS: 'admin-agreements',
  ADMIN_DISCOUNTS: 'admin-discounts',
  ASSESSMENT_EXPORT: 'assessment-export',
  ASSESSMENT_EXPORT_DOWNLOAD: 'assessment-export-download',
  MODIFY_ASSESSMENT_DETAILS: 'modify-assessment-details',
  ADD_QUESTION: 'add-question',
  SELECT_ASSESSMENT: 'SELECT_ASSESSMENT',
  GET_ASSESSMENT_LINK: 'GET_ASSESSMENT_LINK'
}

export const progressComponentTypes = {
  CIRCULAR_PROGRESS: 'Circular Progress',
  LINEAR_PROGRESS: 'Linear Progress'
}

export const newAssessmentOptions = {
  CREATE_NEW_ASSESSMENT: 'create',
  DUPLICATE_ASSESSMENT: 'duplicate'
}

export const historyActionTypes = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
  PUBLISHED: 'PUBLISHED'
}

export const assessmentTypes = {
  PRIMARY: 'P',
  ALTERNATE: 'A'
}

export const assessmentTypeLabels = {
  P: 'Primary',
  A: 'Alternate'
}
