import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { findDefaultRoute } from '../../utils'

import { Button } from 'reactstrap'

const Page500 = ({ history }) => {
  const { isValidated = false, userType = '', userID = '' } = useSelector(state => state.auth)

  return (
    <div className='text-center'>
      <h1 className='display-1 font-weight-bold'>500</h1>
      <p className='h1'>Internal server error.</p>
      <p className='h2 font-weight-normal mt-3 mb-4'>
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </p>
      <Button
        onClick={() => findDefaultRoute(isValidated, userType, userID, history)}
        color='primary'
        size='lg'
      >
        Return to website
      </Button>
    </div>
  )
}

export default withRouter(Page500)
