import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button } from 'reactstrap'
import { findDefaultRoute } from '../../utils'

const Page404 = (props) => {
  const { isValidated = false, userType = '', userID = '' } = useSelector(state => state.auth)

  return (
    <div className='text-center'>
      <h1 className='display-1 font-weight-bold'>404</h1>
      <p className='h1'>Page not found.</p>
      <p className='h2 font-weight-normal mt-3 mb-4'>
        The page you are looking for might have been removed.
      </p>
      <Button
        onClick={() => { findDefaultRoute(isValidated, userType, userID, props.history) }}
        color='primary'
        size='lg'
      >
        Return to website
      </Button>
    </div>
  )
}

export default withRouter(Page404)
