import React from "react";
import { Button, Card, CardHeader, CardTitle, CardSubtitle, CardBody } from "reactstrap";
import { Link } from 'react-scroll';

const ImproveActionThree = ({ reportMode, onJumpToSection }) => {
  return (
        <div className="col-4 d-flex flex-column">
            <Card className="border bg-light flex-grow-1">
                <CardHeader style={{ minHeight: "120px" }}>
                    <CardTitle>
                        <small className="font-600">Growth, Profitability & Financial Health</small>
                    </CardTitle>
                    <CardSubtitle className="font-600 d-flex flex-column">
                        <span>Company's largest customer % of total revenue</span>
                    </CardSubtitle>
                </CardHeader>
                <CardBody className="m-3 bg-white border rounded d-flex flex-column justify-content-between">
                    <div className="flex-grow-1">
                        <span className="font-600">Action Step</span>
                        <p className="small mt-2 font-600">Seek to diversify customer base to reduce risk of revenue loss.</p>
                    </div>
                    {reportMode ? null :
                        <div className="d-flex justify-content-between flex-grow-0">
                            <Link 
                                className="text-primary border border-primary btn btn-white btn-sm rounded col-6" 
                                to="sections-container" 
                                spy={true} 
                                smooth={true} 
                                offset={50} 
                                duration={500}
                                onClick={onJumpToSection}
                            >
                                Jump To Section
                            </Link>
                            <Button color="primary" size="sm" className="col-6 ml-2 rounded d-flex align-items-center justify-content-center small">
                                Talk to an Expert
                            </Button>
                        </div>
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default ImproveActionThree;
