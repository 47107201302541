import {
  GET_PARTNERS, GET_PARTNER, GET_PARTNER_DISCOUNT_CODES,
  GET_PARTNER_USERS, GET_PARTNER_USER,
  GET_PARTNER_USER_ASSESSMENTS,
  GET_PARTNER_USER_DISCOUNT_CODES,
  GET_PARTNER_USER_COMPANIES,
  LOGOUT_USER
} from '../types'

const initialState = {
  partnerList: {
    partners: [],
    discountCodes: [],
    totalPartners: 0
  },
  partnerDiscountCodes: [],
  partnerData: {},
  partnerUserList: {
    partnerUsers: [],
    totalPartnerUsers: 0
  },
  partnerUserData: {},
  partnerUserAssessments: {
    assessments: [],
    totalAssessments: 0
  },
  partnerUserDiscountCodes: [],
  partnerUserCompanies: {
    companyUsers: [],
    totalCompanyUsers: 0
  }
}

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS: return {
      ...state,
      partnerList: action.payload
    }
    case GET_PARTNER: return {
      ...state,
      partnerData: action.payload
    }
    case GET_PARTNER_DISCOUNT_CODES: return {
      ...state,
      partnerDiscountCodes: action.payload
    }
    case GET_PARTNER_USERS: return {
      ...state,
      partnerUserList: action.payload
    }
    case GET_PARTNER_USER: return {
      ...state,
      partnerUserData: action.payload
    }
    case GET_PARTNER_USER_ASSESSMENTS: return {
      ...state,
      partnerUserAssessments: action.payload
    }
    case GET_PARTNER_USER_DISCOUNT_CODES: return {
      ...state,
      partnerUserDiscountCodes: action.payload
    }
    case GET_PARTNER_USER_COMPANIES: return {
      ...state,
      partnerUserCompanies: {
        companyUsers: action.payload.users,
        totalCompanyUsers: action.payload.totalUsers
      }
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default partnerReducer
