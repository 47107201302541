import { GET_ASSESSMENT_FLOW_QUESTIONS, GET_ASSESSMENT_LIST, RESET_ASSESSMENT, GET_ASSESSMENT_SECTION_QUESTIONS } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Get a list of all assessments
export const getAssessmentList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10, noRedux = false } = tempFilter
      tempFilter.offset = ((page || 1) - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction
      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment?${queryParams}`, options), loaderArea.BUILDER_ASSESSMENT_LIST)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        const rtrnData = noRedux ? data?.assessments : null

        if (!noRedux) {
          dispatch({
            type: GET_ASSESSMENT_LIST,
            payload: data
          })
        }

        fireSuccess(rtrnData)
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get assessment list.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Get a list of all active assessments
export const getActiveAssessmentList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }

      /*
        optional filters:
        'groupType' -> currently either 'grouped' or 'list' -- will default to 'list' if no type is provided
        'assessmentType' -> currently either 'P' (primary) or 'A' (alternate) -- will default to all if none is provided
        'industryID' -> expects a number -- will default to all if none is provided
        'sectorID' -> expects a number -- will default to all if none is provided

        all filters are optional and interchangeable. However if an ID is provided for industry and/or sector and they do not exist (or do not exist as a connected pair), it will return a 404.
      */

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/selection?${queryParams}`, options), loaderArea.SELECT_ASSESSMENT)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse || parsedResponse?.type !== responseTypes.SUCCESS) { fireFailure(); return false }

      const { data } = await response.json()
      fireSuccess(data)
      return data
    } catch (err) {
      log.error(err)
      fireFailure()
      return false
    }
  }
}

// Get a list of assessment questions sorted by sector
export const getSelectedAssessment = (assessmentID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions`, options), loaderArea.ASSESSMENT_SECTOR_QUESTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_ASSESSMENT_SECTION_QUESTIONS,
          payload: data
        })

        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get assessment list.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Get the options related to a sector's question
export const getSectorQuestionOptions = (assessmentID, questionID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions/${questionID}/options`, options), loaderArea.FETCH_QUESTION_OPTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        fireSuccess(data)
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get assessment list.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Get a list of assessment questions sorted by sector
export const getAllAssessmentQuestions = (filter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = ((page || 1) - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/questions?${queryParams}`, options), loaderArea.ALL_QUESTIONS_FETCH)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        fireSuccess(data)
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get assessment list.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Duplicate an existing Assessment
export const duplicateAssessment = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '', sector = '', industry, originID, discardDraft, sectorID = '', assessmentType } = info

      const body = { sector, industry, originID, discardDraft, sectorID, assessmentType }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/duplicate`, options), loaderArea.DUPLICATE_ASSESSMENTS)

      const parsedResponse = parseResponse(response, dispatch)

      const res = await response.json()
      const fullRes = { ...res }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { newAssessmentID } = fullRes?.data
        fireSuccess(newAssessmentID)
        return true
      } else {
        fireFailure(fullRes)
        log.info('Failed to duplicate assessment.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

export const createNewAssessment = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { sector = '', industry, sectorID = '', assessmentType } = info

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ sector, industry, sectorID, assessmentType })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment`, options), loaderArea.CREATE_ASSESSMENT)

      const parsedResponse = parseResponse(response, dispatch)
      const res = await response.json()

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { newAssessmentID } = res?.data
        fireSuccess(newAssessmentID)
        return true
      } else {
        const duplicateSector = parsedResponse.code === 409

        fireFailure({ ...res, duplicateErr: duplicateSector })

        log.info('Failed to create new assessment.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// modify an assessment's industry or sector
export const modifyAssessmentDetails = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '' } = info

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...info })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}`, options), loaderArea.MODIFY_ASSESSMENT_DETAILS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { fireFailure(); return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess(assessmentID)
        return true
      } else {
        const { code } = parsedResponse
        fireFailure(code)

        log.info('Failed to modify assessment details.')

        return false
      }
    } catch (err) {
      log.error(err)
      fireFailure()
      return false
    }
  }
}

// Duplicate an existing Assessment
export const postAssessmentQuestion = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '' } = info

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...info })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions`, options), loaderArea.ADD_QUESTION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to add new assessment question.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// post a new question option
export const postQuestionOption = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID, questionID, text: optionText, optionOrder, scoreImpact, conditionalLanguageFB = '', conditionalLanguageAT = '' } = info

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ optionText, optionOrder, scoreImpact, conditionalLanguageFB, conditionalLanguageAT })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions/${questionID}/options`, options), loaderArea.EDIT_QUESTION_OPTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        log.error('Failed to post new question option.')
        fireFailure()
        return false
      }
    } catch (err) {
      log.error(err)
      fireFailure()
      return false
    }
  }
}

// Update the activated status of an assessment
export const updateActiveAssessmentStatus = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '', publishDraft, isActive = 0 } = info

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ publishDraft, isActive })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/active`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update active status of assessment.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Update an assessment question's information
export const updateAssessmentQuestion = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '', questionID = '' } = info

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...info })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions/${questionID}`, options), loaderArea.EDIT_QUESTION_OPTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update assessment question.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Update an assessment question option's information
export const updateAssessmentQuestionOption = (info, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { assessmentID = '', questionOptionID = '' } = info

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...info })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/options/${questionOptionID}`, options), loaderArea.EDIT_QUESTION_OPTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update assessment question option.')

        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}

// Return a list of questions in a user assessment
export const getAssessmentFlowQuestions = (assessmentID, userAssessmentID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const queryParam = userAssessmentID ? `?userAssessmentID=${userAssessmentID}` : ''

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}${queryParam}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_ASSESSMENT_FLOW_QUESTIONS,
          payload: data[0]
        })
        fireSuccess(userAssessmentID)
        return true
      } else {
        // left this in on purpose until it is determined we do not need it
        // const [data] = await response.json()
        fireFailure('Unable to fetch assessment questions.')

        log.info('Failed to get user assessments.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an assessment question
export const deleteAssessmentQuestion = (assessmentID, questionID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/questions/${questionID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to delete assessment question.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an assessment question option
export const deleteAssessmentQuestionOption = (assessmentID, questionOptionID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/${assessmentID}/options/${questionOptionID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to delete assessment question option.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const resetAssessment = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_ASSESSMENT })
  }
}
