import React from "react";
import { Container, Spinner } from "reactstrap";

const Loader = ({ size = 'md', align = 'center', color = 'primary' }) => (
  <Container fluid className={`${size !== 'sm' ? 'vh-75' : ''} d-flex`}>
    <div className={`justify-content-${align} align-self-center w-100 text-center d-flex`}>
      <Spinner size={size} color={color} />
    </div>
  </Container>
);

export default Loader;
