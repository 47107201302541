import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'

import { downloadExport } from '../../redux/actions'
import { NotificationToast } from '../../components/tools'
import { findDefaultRoute, loaderArea, progressComponentTypes } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'

import { usePromiseTracker } from 'react-promise-tracker'

import queryString from 'query-string'

const ExportDownload = ({ history, location }) => {
  const { isValidated = false, userType = '', userID = '' } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ASSESSMENT_EXPORT_DOWNLOAD })

  const parsedProps = queryString.parse(location?.search)

  const fireDownloadSuccess = useCallback(async (fileURL, assessmentExportID) => {
    const a = document.createElement('a')

    if (a) {
      a.href = fileURL
      a.download = `${assessmentExportID}.zip`
      a.onclick = () => { return true }
      a.click()

      a.remove()
    }
  }, [])

  useEffect(() => {
    if (parsedProps?.exportID) {
      dispatch(downloadExport(parsedProps.exportID, fireDownloadSuccess, fireDownloadFailure))
    }
  }, [parsedProps.exportID, dispatch, fireDownloadSuccess])

  const fireDownloadFailure = () => {
    NotificationToast(true, 'Unable to download file. Please try the link again or request a new export.')
  }

  return (
    <div className='text-center'>
      <h2 className='font-weight-bold'>{promiseInProgress ? 'Downloading' : 'File downloaded successfully!'}</h2>

      {promiseInProgress
        ? (<StyledLoadingIndicator area={loaderArea.ASSESSMENT_EXPORT_DOWNLOAD} loaderType={progressComponentTypes.LINEAR_PROGRESS} />)
        : (
          <>
            <div style={{ marginBottom: '0.7em' }}>
              <CheckCircleOutline fontSize='large' color='success' />
            </div>
            <Button
              onClick={() => findDefaultRoute(isValidated, userType, userID, history)}
              color='success'
              size='lg'
              variant='contained'
            >
              {isValidated ? 'Return to Previous Page' : 'Return to Sign In'}
            </Button>
          </>)}
    </div>
  )
}

export default withRouter(ExportDownload)
