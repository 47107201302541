import React from 'react'
import { useSelector } from 'react-redux'
import brand from '../assets/img/logo.png'
import ProfileDropdown from './ProfileDropdown'
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import { findDefaultRoute } from '../utils'

const MainHeader = () => {
  const { isValidated, userType, userID } = useSelector(state => state.auth)
  const history = useHistory()

  return (
    <div className='d-flex justify-content-between pt-4 px-4'>
      <img
        onClick={() => findDefaultRoute(isValidated, userType, userID, history)}
        src={brand}
        className='logo-width cursor-pointer'
        alt='Investment Grade Advisory'
      />
      {isValidated && <ProfileDropdown />}
    </div>
  )
}

export default withRouter(MainHeader)
