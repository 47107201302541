import {
  GET_USER_ASSESSMENTS, GET_USER_ASSESSMENT_RESULTS, LOGOUT_USER
} from '../types'

const initialState = {
  userAssessments: [],
  userAssessmentResults: {
    assessmentID: '',
    assessmentScore: 0,
    createdAt: '',
    firstName: '',
    lastName: '',
    sectionScores: [],
    sections: [],
    sectorID: 0,
    sectorName: '',
    updatedAt: '',
    userCompany: '',
    userID: 0
  }
}

const userAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ASSESSMENTS: return {
      ...state,
      userAssessments: action.payload
    }
    case GET_USER_ASSESSMENT_RESULTS: return {
      ...state,
      userAssessmentResults: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default userAssessmentReducer
