import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Paper, useTheme } from '@mui/material'
import { isEqual } from 'lodash'
import {
  UserAdministration, AdminAdministration, PartnerAdministration,
  AssessmentAdministration, AgreementAdministration, DiscountCodeAdministration
} from './sections'
import { adminSideMenuTabs } from '../../utils'

import {
  getUsers, getPartnerUserCompanies, getAdmins, getAgreements, getPartners,
  getAdminAssessments, getPartnerUserAssessments,
  getDiscountCodes, getPartnerUserDiscountCodes
} from '../../redux/actions'

const useStyles = (theme) => ({
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme?.palette.grey.darkGrey
  },
  pagination: {
    '& .MuiPaginationItem-textPrimary': {
      '&.Mui-selected': {
        backgroundColor: theme?.palette.blue.main
      }
    }
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  }
})

const AdministrationMainContent = (props) => {
  const { currentTab, adminUser, filter, setFilter, defaultFilter } = props
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const { usersList: { users: usersData = [], totalUsers: usersCount = 0 } } = useSelector(state => state.user)
  const {
    partnerUserCompanies: { companyUsers: compUsersData = [], totalCompanyUsers: compUsersCount = 0 },
    partnerList: { partners: partnerData = [], totalPartners: partnerCount = 0 },
    partnerUserDiscountCodes: { discountCodes: partnerUserDiscountData = [], totalDiscounts: partnerUserDiscountCount = 0 },
    partnerUserAssessments: { assessments: puAssessments = [], totalAssessments: totalPuAssessments = 0 }
  } = useSelector(state => state.partner)
  const { adminList: { admins: adminsData = [], totalAdmins: adminCount = 0 }, adminAssessments: { assessments: assessmentData = [], totalAssessments: assessmentCount = 0 } } = useSelector(state => state.admin)

  const { agreementsList: { agreements: agreementData = [], totalAgreements: agreementCount = 0 } } = useSelector(state => state.agreement)
  const { discountList: { discountCodes: discountData = [], totalDiscounts: discountCount = 0 } } = useSelector(state => state.discount)
  const { partnerID = '', userID = '' } = useSelector(state => state.auth)

  // ********************** User Tab Logic: ************************ //
  const [users, setUsers] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the Users tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.USERS.tab) {
      if (adminUser) {
        dispatch(getUsers(filter))
      } else {
        dispatch(getPartnerUserCompanies(partnerID, userID, filter))
      }
    }
  }, [dispatch, currentTab, filter, defaultFilter, adminUser, userID, partnerID])

  // Checks the returned array from the dispatch against the local storage and resets users if they do not match
  useEffect(() => {
    if (
      (adminUser && users && users !== usersData) || (!adminUser && compUsersData && users !== compUsersData)
    ) {
      const dataArr = adminUser ? usersData : compUsersData
      const dataCount = adminUser ? usersCount : compUsersCount
      setUsers(dataArr)
      setTotalUsers(dataCount)
    }
  }, [users, usersData, usersCount, compUsersCount, compUsersData, adminUser])

  // ********************** Admin Tab Logic: ************************ //
  const [admins, setAdmins] = useState([])
  const [totalAdmins, setTotalAdmins] = useState(0)

  // // Will fire initially before the filter has been changed and when the user is viewing the Admin tab.
  // // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.ADMINS.tab) {
      dispatch(getAdmins(filter))
    }
  }, [dispatch, currentTab, filter, defaultFilter])

  // // Checks the returned array from the dispatch against the local storage and resets admins if they do not match
  useMemo(() => {
    if (admins && adminsData && !isEqual(admins, adminsData)) {
      setAdmins(adminsData)
      setTotalAdmins(adminCount)
    }
  }, [admins, adminsData, adminCount])

  // ********************** Agreement Tab Logic: ************************ //
  const [agreements, setAgreements] = useState([])
  const [totalAgreements, setTotalAgreements] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the Agreement tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.AGREEMENTS.tab) {
      dispatch(getAgreements(filter))
    }
  }, [dispatch, currentTab, filter, defaultFilter])

  // Checks the returned array from the dispatch against the local storage and resets agreements if they do not match
  useMemo(() => {
    if (agreements && agreementData && !isEqual(agreements, agreementData)) {
      setAgreements(agreementData)
      setTotalAgreements(agreementCount)
    }
  }, [agreements, agreementData, agreementCount])

  // ********************** Partner Tab Logic: ************************ //
  const [partners, setPartners] = useState([])
  const [totalPartners, setTotalPartners] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the Partners tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.PARTNERS.tab) {
      dispatch(getPartners(filter))
    }
  }, [dispatch, currentTab, filter, defaultFilter])

  // Checks the returned array from the dispatch against the local storage and resets agreements if they do not match
  useMemo(() => {
    if (partners && partnerData && !isEqual(partners, partnerData)) {
      setPartners(partnerData)
      setTotalPartners(partnerCount)
    }
  }, [partners, partnerData, partnerCount])

  // ********************** Discount Tab Logic: ************************ //
  const [discountCodes, setDiscountCodes] = useState([])
  const [totalDiscountCodes, setTotalDiscountCodes] = useState(0)

  // // // Will fire initially before the filter has been changed and when the user is viewing the Discount Codes tab.
  // // // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.CODES.tab) {
      if (adminUser) {
        dispatch(getDiscountCodes(filter))
      } else {
        dispatch(getPartnerUserDiscountCodes(filter))
      }
    }
  }, [dispatch, currentTab, filter, defaultFilter, adminUser])

  // // // Checks the returned array from the dispatch against the local storage and resets discount codes if they do not match
  useMemo(() => {
    if (discountCodes && discountData && !isEqual(discountCodes, discountData) && adminUser) {
      setDiscountCodes(discountData)
      setTotalDiscountCodes(discountCount)
    } else if (discountCodes && partnerUserDiscountData && !isEqual(discountCodes, partnerUserDiscountData) && !adminUser) {
      setDiscountCodes(partnerUserDiscountData)
      setTotalDiscountCodes(partnerUserDiscountCount)
    }
  }, [discountCodes, discountData, discountCount, partnerUserDiscountData, partnerUserDiscountCount, adminUser])
  // ********************** Assessments Tab Logic: ************************ //
  const [assessments, setAssessments] = useState([])
  const [totalAssessments, setTotalAssessments] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the Assessments tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.ASSESSMENTS.tab) {
      if (adminUser) {
        dispatch(getAdminAssessments(filter))
      } else {
        dispatch(getPartnerUserAssessments(filter))
      }
    }
  }, [dispatch, currentTab, filter, defaultFilter, adminUser])

  // Checks the returned array from the dispatch against the local storage and resets assessments if they do not match
  useMemo(() => {
    if (
      assessments &&
      ((adminUser && assessmentData && !isEqual(assessments, assessmentData)) ||
        (!adminUser && puAssessments && !isEqual(assessments, puAssessments)))
    ) {
      const dataArr = adminUser ? assessmentData : puAssessments
      const dataCount = adminUser ? assessmentCount : totalPuAssessments
      setAssessments(dataArr)
      setTotalAssessments(dataCount)
    }
  }, [adminUser, puAssessments, totalPuAssessments, assessments, assessmentData, assessmentCount])

  useEffect(() => {
    return () => {
      setUsers([])
      setTotalUsers(0)
      setAdmins([])
      setTotalAdmins(0)
      setAgreements([])
      setTotalAgreements(0)
      setPartners([])
      setTotalPartners(0)
      setDiscountCodes([])
      setTotalDiscountCodes(0)
      setAssessments([])
      setTotalAssessments(0)
    }
  }, [])

  const renderCurrentSection = (currentTab) => {
    const defaultProps = {
      filter,
      setFilter,
      defaultFilter,
      currentTab,
      adminUser,
      classes
    }

    switch (currentTab) {
      case 'users':
        return (
          <UserAdministration
            users={users}
            totalUsers={totalUsers}
            {...defaultProps}
          />
        )
      case 'admins':
        return (
          <AdminAdministration
            totalAdmins={totalAdmins}
            admins={admins}
            {...defaultProps}
          />
        )
      case 'partners':
        return (
          <PartnerAdministration
            partners={partners}
            totalPartners={totalPartners}
            {...defaultProps}
          />
        )
      case 'assessments':
        return (
          <AssessmentAdministration
            assessments={assessments}
            totalAssessments={totalAssessments}
            {...defaultProps}
          />
        )
      case 'agreements':
        return (
          <AgreementAdministration
            agreements={agreements}
            totalAgreements={totalAgreements}
            {...defaultProps}
          />
        )
      case 'codes':
        return (
          <DiscountCodeAdministration
            discountCodes={discountCodes}
            totalDiscountCodes={totalDiscountCodes}
            {...defaultProps}
          />
        )
      default:
        return console.log('no current tab')
    }
  }

  return (
    <Paper style={{ width: '100%', padding: '1em' }}>
      {renderCurrentSection(currentTab)}
    </Paper>
  )
}

export default AdministrationMainContent
