import {
  GET_USERS, GET_USER, LOGOUT_USER
} from '../types'

const initialState = {
  usersList: {
    users: [],
    totalUsers: 0
  },
  userData: {}
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS: return {
      ...state,
      usersList: action.payload
    }
    case GET_USER: return {
      ...state,
      userData: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default userReducer
