import React from "react";

const Content = ({ children, width = 8, paddingx, marginTop = 4 }) => (
    <div 
        className={`col-sm-12 col-lg-${width} offset-lg-${(12 - width) / 2} mt-${marginTop} content flex-grow-1 d-flex flex-column ${paddingx !== undefined ? `px-${paddingx}` : ''}`}
        style={{marginBottom: '1.5rem'}}
    >
        {children}
    </div>
);

export default Content;
