/* eslint camelcase: off */

import { LOGIN, LOGOUT_USER, REMOVE_ERROR, REFRESH_TOKEN } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import LogRocket from 'logrocket'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Login action
export const login = (info, fireFailure = () => { }) => {
  return async (dispatch) => {
    try {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/auth/sign-in`, options))

      const parsedResponse = parseResponse(response, dispatch, true)
      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const userData = await response.json()

        const { userID, lastName, email: userEmail } = userData

        dispatch({
          type: LOGIN,
          payload: userData
        })

        // Start a new session in log rocket when a user logs in and identify them as the current sesion user
        LogRocket.startNewSession()
        LogRocket.identify(userID, {
          name: lastName,
          email: userEmail
        })
      } else {
        console.log(parsedResponse)
        fireFailure(parsedResponse)
        log.info('Failed to login.')
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new user
export const userSignup = (userInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(userInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/auth/sign-up`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const data = await response.json()
        const duplicateEmail = parsedResponse.code === 409

        fireFailure({ ...data, duplicateErr: duplicateEmail })

        log.info('Failed to create user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const logout = (error = false) => {
  return async (dispatch, getState) => {
    try {
      // Reset the log rocket session on logout (this will set the identified user to 'anonymous')
      LogRocket.startNewSession()

      dispatch({
        type: LOGOUT_USER,
        payload: { error }
      })
    } catch (err) {
      log.error(err)
    }
  }
}

export const removeErrors = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_ERROR })
  }
}

// Sends current token in for a fresh one
export const refreshToken = () => {
  return async function (dispatch, getState) {
    const { token, userID } = getState().auth

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await trackPromise(window.fetch(`${apiPath}/api/auth/token/refresh`, options))
    const parsedResponse = parseResponse(response, dispatch, true)
    if (!parsedResponse) {
      log.info('logging out')
      dispatch({
        type: LOGOUT_USER,
        payload: { error: true }
      })
    } else {
      const { data } = await response.json()
      const { token, exp, userID: resID } = data

      if (resID && userID && resID === userID) {
        dispatch({
          type: REFRESH_TOKEN,
          payload: { token, exp }
        })
      } else {
        dispatch({
          type: LOGOUT_USER,
          payload: { error: true }
        })
      }
    }
  }
}

export const preResetPassword = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { email = '' } = info

      if (!email) { console.log('no email provided'); return false }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/auth/reset-password`, options))

      const parsedResponse = parseResponse(response, dispatch, true)
      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
      } else {
        fireFailure(parsedResponse)
        log.info('Failed to register user with reset password token.')
        return false
      }
    } catch (err) {
      console.log(err)
      log.error(err)
    }
  }
}

export const resetPassword = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', newPassword = '' } = info

      if (!token || !newPassword) { console.log('required information not provided'); return false }

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/auth/reset-password`, options))

      const parsedResponse = parseResponse(response, dispatch, true)
      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()
      } else {
        const res = await response.json()
        fireFailure(res)
        log.info('Failed to reset password.')
        return false
      }
    } catch (err) {
      log.error(err)
    }
  }
}
