import {
  GET_ADMINS, GET_ADMIN, GET_ADMIN_ASSESSMENTS, LOGOUT_USER
} from '../types'

const initialState = {
  adminList: {
    admins: [],
    totalAdmins: 0
  },
  adminData: {},
  adminAssessments: {
    assessments: [],
    totalAssesments: 0
  }
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINS: return {
      ...state,
      adminList: action.payload
    }
    case GET_ADMIN: return {
      ...state,
      adminData: action.payload
    }
    case GET_ADMIN_ASSESSMENTS: return {
      ...state,
      adminAssessments: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default adminReducer
