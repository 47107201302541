/* eslint camelcase: off */

import { GET_DISCOUNT_CODES, GET_DISCOUNT_CODE } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Return a list of discount codes (admin)
export const getDiscountCodes = (filter, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount?${queryParams}`, options), loaderArea.ADMIN_DISCOUNTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_DISCOUNT_CODES,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get discount codes.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return details for a specific discount code by ID (admin)
export const getDiscountCode = (discountCodeID, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount/${discountCodeID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_DISCOUNT_CODE,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get discount code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Generate a new discount code
export const generateDiscountCode = (discountCodeInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { code = '', discount = 0, description = '', expiresAt = '' } = discountCodeInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(discountCodeInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount`, options), loaderArea.ADMIN_DISCOUNTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to generate discount code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing discount code based on ID
export const updateDiscountCode = (discountCodeID = '', discountCodeInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { discount = 0, description = '', expiresAt = '' } = discountCodeInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(discountCodeInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount/${discountCodeID}`, options), loaderArea.ADMIN_DISCOUNTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to update discount code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Generate a new discount code
export const emailDiscountCode = (id = '', discountCodeInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { email = '', code = '' } = discountCodeInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(discountCodeInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount/${id}/email`, options), loaderArea.ADMIN_DISCOUNTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to generate email code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Generate a new discount code
export const generateDiscountCodeLink = (discountCodeInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(discountCodeInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount/link`, options), loaderArea.ADMIN_DISCOUNTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        fireSuccess(data)

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to generate email code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an existing discount code based on ID
export const deleteDiscountCode = (discountCodeID = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/discount/${discountCodeID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to delete discount code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
