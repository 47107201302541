// Auth
import SignIn from '../pages/auth/signIn/SignIn'
import SignUp from '../pages/auth/signup/SignUp'
import ResetPassword from '../pages/auth/resetPassword/ResetPassword'

// Customer
import AssessmentFlow from '../pages/customer/AssessmentFlow'
import Assessments from '../pages/customer/Assessments'

// Routes
export const customerRoutes = [
  {
    path: '/assessments',
    name: 'Assessments',
    width: 8,
    component: Assessments
  },
  {
    path: '/assessments/flow',
    name: 'Assessment',
    width: 8,
    component: AssessmentFlow
  }
]

// Routes
export const authRoutes = [
  {
    path: '/auth/sign-in',
    name: 'Login',
    marginTop: '0',
    component: SignIn
  },
  {
    path: '/auth/sign-up',
    name: 'Sign Up',
    marginTop: 'lg-n5',
    component: SignUp
  },
  {
    path: '/auth/reset-password',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/auth/reset-password/:token',
    name: 'Reset Password',
    component: ResetPassword
  }
]
