import React from 'react'
import { Button, Col } from 'reactstrap'

const QuestionFeedbackBox = ({ selectedObj, questionObj, onTalkToAnExpert = () => {}, informationModal = {}, setInformationModal = () => {} }) => {
  const answer = selectedObj
  const { referralPartner = '', education = '' } = questionObj

  return (
    <Col className='d-flex flex-column col-12 p-3 border rounded bg-white'>
      <div className='d-flex justify-content-between align-items-center'>
        {Boolean(answer?.feedbackText) && <span className='font-600'>Feedback</span>}
        <div>
          {referralPartner &&
            <Button
              size='sm'
              color='primary'
              className='rounded ml-2'
              style={{ width: '85px' }}
              onClick={() => onTalkToAnExpert({ answerID: answer.questionOptionID })}
            >
              Talk to an Expert
            </Button>}
          {education &&
            <Button
              size='sm'
              color='primary'
              className='rounded ml-2'
              style={{ width: '85px' }}
              onClick={() => setInformationModal({
                isOpen: true,
                size: 'xl',
                title: 'Why this matters?',
                body: <iframe style={{ height: window.innerHeight / 2 }} className='col-12 px-0 border-0' title='Why this matters?' src={education} />,
                onClose: null
              })}
            >
              Read Why This Matters
            </Button>}
        </div>
      </div>
      {Boolean(answer?.feedbackText) &&
        <p className='mt-4'>
          {answer?.feedbackText}
        </p>}
    </Col>
  )
}

export default QuestionFeedbackBox
