import React, { useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import {
  Dialog, DialogActions, DialogTitle, DialogContent, Popover,
  Grid, Typography, IconButton, Button, Tabs, Tab, Tooltip
} from '@mui/material'

import { CloseRounded } from '@mui/icons-material'
import { NewQuestionContent, CopyQuestionContent, QuestionOptionsContent } from '../builderTools'
import { loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

// Handles rendering the content of either creating or copying a new question and its options
// rendered in BuilderQuestionTable.js
const NewQuestionModal = (props) => {
  const {
    // generated in parent component AssessmentSections.js
    newQuestionOpen = false, handleCloseNewQuestion = () => { },
    viewContentIndex = 0, setViewContentIndex = () => { }, contentIndex = {},
    newQuestionForm = {}, setNewQuestionForm = () => { },
    errorCheck = false, errors = {},
    handleCopyQuestion = () => { }, handleAddQuestion = () => { },
    editOptionView, setEditOptionView = () => { },
    // generated in top most parent Builder.js
    classes
  } = props

  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ADD_QUESTION })

  const handleTabChange = (e, newValue) => {
    setViewContentIndex(newValue)
  }

  const getContent = (contentTab) => {
    const commonProps = { classes, newQuestionForm, setNewQuestionForm }
    switch (contentTab) {
      case contentIndex?.NEW: {
        return (
          <NewQuestionContent
            {...commonProps}
          />
        )
      }
      case contentIndex?.COPY: {
        return (
          <CopyQuestionContent
            {...commonProps}
            handleCopyQuestion={handleCopyQuestion}
          />
        )
      }
      case contentIndex?.OPTIONS: {
        return (
          <QuestionOptionsContent
            {...commonProps}
            editOptionView={editOptionView}
            setEditOptionView={setEditOptionView}
          />
        )
      }
      default: return 'default'
    }
  }

  // Confirm popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  // open the delete confirm popover
  const handleConfirmPopover = (e, value) => {
    setConfirmAnchorEl(e.currentTarget)
  }

  // close the delete popover
  const handleConfirmClose = () => {
    setConfirmAnchorEl(null)
  }

  const openConfirmNoOptions = Boolean(confirmAnchorEl)

  const handleSaveOrOptionsButton = (e) => {
    if (viewContentIndex === contentIndex?.NEW) {
      setViewContentIndex(2)
    } else {
      if (!newQuestionForm?.questionOptions?.length) {
        handleConfirmPopover(e)
      } else {
        handleAddQuestion()
      }
    }
  }

  return (
    <Dialog
      open={newQuestionOpen}
      onClose={() => handleCloseNewQuestion()}
      fullWidth
      maxWidth={viewContentIndex !== 1 ? 'sm' : 'md'}
    >
      <DialogTitle style={{ padding: '12px 18px' }}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={11} container direction='row'>
            {viewContentIndex !== 2
              ? (
                <Tabs value={viewContentIndex !== contentIndex?.OPTIONS ? viewContentIndex : 0} onChange={handleTabChange}>
                  <Tab value={contentIndex?.NEW} label='New Question' sx={{ '&:focus': { outline: 'none' }, textTransform: 'none' }} />
                  <Tab value={contentIndex?.COPY} label='Copy Question' sx={{ '&:focus': { outline: 'none' }, textTransform: 'none' }} />
                </Tabs>)
              : (<Typography variant='h6'>Question Options</Typography>)}
          </Grid>

          <Grid item container justifyContent='flex-end' xs={1}>
            <IconButton style={{ padding: '0px' }} onClick={() => handleCloseNewQuestion()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' spacing={2}>
          {getContent(viewContentIndex)}
        </Grid>
      </DialogContent>

      {/* Action Buttons */}
      {viewContentIndex !== contentIndex?.COPY &&
        <DialogActions>
          <Grid container direction='column'>
            {errors?.requiredInformation &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter all required information.</Typography>}
            {errors?.invalidLink &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid resource link.</Typography>}
            {errors?.invalidInput &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Invalid input provided. Cannot contian only special characters or only blank spaces.</Typography>}
            {errors?.failedAction &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Unable to perform action.</Typography>}
            <Grid container item direction='row' justifyContent='space-between'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '400'
                }}
                onClick={() => {
                  if (viewContentIndex === contentIndex?.OPTIONS) {
                    setViewContentIndex(0)
                    setEditOptionView(false)
                  } else {
                    handleCloseNewQuestion()
                  }
                }}
              >
                {viewContentIndex === contentIndex?.OPTIONS ? 'Back' : 'Cancel'}
              </Button>

              <Tooltip
                arrow
                enterDelay={500}
                placement='top'
                disableHoverListener={!errorCheck}
                title={errorCheck ? 'Be sure you have completed the question form and have selected more than one answer option.' : ''}
              >
                <span>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      margin: '.5em 1em',
                      fontWeight: '600'
                    }}
                    disabled={errorCheck || editOptionView}
                    onClick={(e) => handleSaveOrOptionsButton(e)}
                  >
                    {viewContentIndex === contentIndex?.NEW ? 'Add Options' : 'Save'}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogActions>}
      <Popover
        id={openConfirmNoOptions ? 'confirm-popover' : undefined}
        open={openConfirmNoOptions}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h6'
            gutterBottom
          >
            Are you sure you want to create this question with no answer options?
          </Typography>

          <Typography variant='body1' gutterBottom>It will not appear to users taking the assessment without answer options.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmClose} variant='outlined' color='primary' style={{ marginRight: '.5em' }}>Cancel</Button>
            <Button variant='contained' onClick={() => { handleConfirmClose(); handleAddQuestion() }}>
              {promiseInProgress ? <StyledLoadingIndicator area={loaderArea.ADD_QUESTION} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} /> : 'Confirm'}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Dialog>
  )
}

export default NewQuestionModal
