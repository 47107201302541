/* eslint camelcase: off */

import { GET_USERS, GET_USER, UPDATE_AUTH, UPDATE_USER_AGREEMENT } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Return a list of users
export const getUsers = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/user?${queryParams}`, options), loaderArea.USER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_USERS,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get users.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return details for a single user
export const getUser = (userID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const userData = await response.json()

        dispatch({
          type: GET_USER,
          payload: userData
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new user as an admin
export const adminCreatedUser = (userInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { paymentMethodId, discountCode } = userInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(userInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user`, options), loaderArea.USER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing user
export const updateUser = (userInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID: authUser = '' } = getState().auth

      const { userID = '' } = userInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(userInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        if (userID === authUser) {
          dispatch({
            type: UPDATE_AUTH,
            payload: { ...userInfo }
          })
        }

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an existing user
export const deleteUser = (userID = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to delete user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing user
export const updateUserPassword = (info = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const { password } = info

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ password })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/password`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const updateUserAgreement = (agreementID, fireSuccess, fireFailure) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID } = getState().auth

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/agreement/${agreementID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        dispatch({
          type: UPDATE_USER_AGREEMENT,
          payload: agreementID
        })

        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update user agreement.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
