import { createTheme } from '@mui/material'
const theme = createTheme({
  palette: {
    grey: {
      lightestGrey: '#fbfafa',
      lighterGrey: '#eeeeee',
      lightGrey: '#e6e6e6',
      darkGrey: '#777777',
      darkerGrey: '#404040',
      darkestGrey: '#222131'
    },
    red: {
      main: '#CD5B5B'
    },
    blue: {
      main: '#5280AA'
    },
    logo: {
      primary: '#3D5065',
      secondary: '#B8B9BB'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro !important',
          fontWeight: 400
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          padding: '3px 14px',
          '&:focus': {
            outline: 'none'
          }
        },
        contained: {
          color: 'white',
          textTransform: 'none',
          '&:disabled': {
            color: '#A3A2A2',
            backgroundColor: '#F4F4F4'
          }
        },
        containedPrimary: {
          backgroundColor: '#5280AA',
          color: '#ffff',
          '&:hover': {
            backgroundColor: '#5280AACF',
            color: '#ffff'
          }
        },
        containedSecondary: {},
        containedError: {
          backgroundColor: '#cd2d2d',
          color: '#ffff',
          '&:hover': {
            backgroundColor: '#cd2d2dcf !important',
            color: '#ffff'
          },
          '&:focus': {
            outlineColor: '#ad211b'
          }
        },
        outlined: {
          textTransform: 'none',
          '&:disabled': {
            color: '#A3A2A2',
            backgroundColor: '#F4F4F4'
          }
        },
        outlinedPrimary: {
          color: '#5280AA',
          borderColor: '#5280AA',
          '&:hover': {
            color: '#5280AACF',
            borderColor: '#5280AA'
          }
        },
        outlinedSecondary: {},
        outlinedError: {},
        text: {
          textTransform: 'none'
        },
        textPrimary: {},
        textSecondary: {},
        textError: {}
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: '400',
          textTransform: 'none',
          backgroundColor: '#F4F4F4',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #C4C4C4',
            '& legend': {
              width: 'auto'
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #A3A2A2'
            }
          }
        },
        input: {
          padding: '6px'
        }
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          '&$underline:after': {
            borderBottom: '2px solid #C4C4C4'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          backgroundColor: '#5280AA'
        },
        outlinedPrimary: {
          color: '#5280AA',
          borderColor: '#5280AA',
          backgroundColor: 'white'
        }
      }
    }
  }
})

export default theme
