import React from 'react'
import { useSelector } from 'react-redux'
import {
  Popover, Typography, Grid, Button, List, ListItemButton, ListItemText, ListItemIcon, useTheme,
  Divider
} from '@mui/material'

import { Publish, VisibilityOff, Visibility } from '@mui/icons-material'

import { historyActionTypes } from '../../../utils'

import { DateTime } from 'luxon'

// Popover to view version history
// rendered in Builder.js
const VersionHistoryPopover = (props) => {
  const {
    openVersionHistoryPopover, versionHistoryAnchorEl, handleVersionHistoryPopClose = () => { }
  } = props

  const theme = useTheme()
  const { selectedAssessment = {} } = useSelector(state => state.assessment)
  const { versionHistory = [] } = selectedAssessment

  // Icons to display in version history list based on the action that was taken
  const handleActionTypeIcon = (action) => {
    switch (action) {
      case historyActionTypes.PUBLISHED: {
        return (
          <Publish />
        )
      }
      case historyActionTypes.ACTIVATED: {
        return (
          <Visibility />
        )
      }
      case historyActionTypes.DEACTIVATED: {
        return (
          <VisibilityOff />
        )
      }
      default: return ''
    }
  }

  // List of version history for versions that were either published, activated, or deactivated
  const renderVersionHistoryList = () => {
    if (versionHistory && Boolean(versionHistory.length)) {
      // Combines all action arrays into one array with the versionNumber added as a key/value pair to each action obj in order to display it in the list
      const combinedVersionHistoryActions = versionHistory.flatMap(version => version?.actions.map(a => ({ ...a, versionNumber: version?.versionNumber })))

      return (
        <>
          {/*
            Filters out any history items with action types that are not PUBLISHED, ACTIVATED, OR DEACTIVATED then sorts the filtered items chronologically by
            date and finally maps through them to create list items
          */}
          {(combinedVersionHistoryActions.filter(a => Object.keys(historyActionTypes).includes(a?.actionType))
            .sort((a, b) => Date.parse(a?.historyCreated) - Date.parse(b?.historyCreated))
            .map((action, index) => {
              const formattedDate = action?.historyCreated ? DateTime.fromISO(action?.historyCreated).toLocaleString(DateTime.DATE_SHORT) : 'N/A'
              const formattedType = action?.actionType ? action?.actionType[0].toUpperCase() + action?.actionType.toLowerCase().slice(1) : 'N/A'
              return (
                <div key={`action-type-${index}`}>
                  {/* NOTE: disabling ripple and removing cursor temporarily until button actions are implemented */}
                  <ListItemButton style={{ cursor: 'default' }} disableRipple key={`vh-action-${index}`}>
                    <ListItemIcon style={{ color: theme.palette.blue.main }}>{handleActionTypeIcon(action?.actionType)}</ListItemIcon>
                    <ListItemText
                      primary={action?.versionNumber ? `Version ${action?.versionNumber}` : 'N/A'}
                      secondary={`${formattedType}: ${formattedDate}`}
                    />
                  </ListItemButton>
                  <Divider />
                </div>
              )
            }))}
        </>
      )
    }
    return ''
  }

  return (
    <Popover
      id={openVersionHistoryPopover ? 'version-history-popover' : undefined}
      open={openVersionHistoryPopover}
      anchorEl={versionHistoryAnchorEl}
      onClose={handleVersionHistoryPopClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <Grid container direction='column' style={{ padding: '1em' }}>
        <Typography
          variant='h5'
          style={{ textTransform: 'none', fontWeight: 600, marginBottom: '1em' }}
          gutterBottom
        >
          Assessment Version History
        </Typography>

        <List
          disablePadding
          style={{
            height: '20vh',
            overflowX: 'hidden',
            overflowY: 'scroll'
          }}
        >
          {renderVersionHistoryList()}
        </List>

        <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '1em' }}>
          <Button onClick={handleVersionHistoryPopClose} variant='contained' color='primary' style={{ textTransform: 'none', color: 'white' }}>Close</Button>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default VersionHistoryPopover
