import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, OutlinedInput, Select, MenuItem, useTheme, Divider
} from '@mui/material'

// Form for editing a question
// rendered in EditQuestionOrOptionModal.js
const EditQuestionContent = (props) => {
  const {
    // generated in parent component EditQuestionOrOptionModal.js
    form, setForm, currentSectionID, newSectionDetails,
    // generated in top most parent component Builder.js
    classes
  } = props
  const theme = useTheme()

  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    size: 'small',
    defaultValue: '',
    style: { padding: 0 },
    sx: { ...classes?.searchInput },
    MenuProps: {
      sx: { ...classes?.statusSelect }
    }
  }

  const { assessmentSectionQuestions: sections = [] } = useSelector(state => state.assessment)

  return (
    <>
      {/* Edit the question text */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Question<span style={{ color: 'red' }}>*</span></Typography>
        <OutlinedInput
          fullWidth
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 200
          }}
          value={form?.text || ''}
          placeholder='Type the content of the question here...'
          onChange={(e) => setForm({ ...form, text: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.text?.length || 0}/200`}</Typography>
      </Grid>

      {/* Change question's section or order */}
      <Grid item container direction='column' spacing={1}>
        <Divider sx={{ margin: '0.5em 0' }} />
        <Grid item container direction='row' spacing={1}>
          <Grid item container direction='column' xs={6}>
            <Typography gutterBottom variant='body1'>Section</Typography>
            <Grid item container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={12}>
                <Select
                  {...commonSelectProps}
                  disabled={Boolean(!sections || !sections.length)}
                  fullWidth
                  value={form?.newSectionID || currentSectionID}
                  onChange={(e) => setForm({ ...form, newSectionID: e.target.value, requestedOrder: form?.questionOrder })}
                >
                  <MenuItem value='' disabled>Select a new section...</MenuItem>
                  {sections?.map(section => {
                    return (
                      <MenuItem key={`new-section-${section?.sectionID}`} id={section?.sectionID} value={section?.sectionID}>Section {section?.sectionID} - {section?.sectionShortName}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item container>
                <Typography variant='body1' sx={{ marginTop: '0.2em', fontSize: '0.9em', color: theme.palette.grey.darkGrey }}>
                  Current Section: <span style={{ fontSize: '1em', fontWeight: 600, color: theme.palette.blue.main }}>{currentSectionID}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction='column' xs={6}>
            <Typography gutterBottom variant='body1'>Question Order</Typography>
            <Grid item container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={12}>

                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  type='number'
                  value={form?.requestedOrder}
                  inputProps={{ min: 1, max: newSectionDetails }}
                  onChange={(e) => setForm({ ...form, requestedOrder: e.target.value })}
                />
              </Grid>
              <Grid item container>
                <Typography variant='body1' sx={{ marginTop: '0.2em', fontSize: '0.9em', color: theme.palette.grey.darkGrey }}>
                  Current Question Order: <span style={{ fontSize: '1em', fontWeight: 600, color: theme.palette.blue.main }}>{form?.questionOrder}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '1.5em 0' }} />
      </Grid>

      {/* Edit the explanation for a question */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Explanation</Typography>
        <OutlinedInput
          fullWidth
          multiline
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 1000
          }}
          value={form?.explanationText || ''}
          placeholder='Type an explanation of the question here...'
          onChange={(e) => setForm({ ...form, explanationText: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.explanationText?.length || 0}/1000`}</Typography>
      </Grid>
      {/* Edit the help text for a question */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Help Text</Typography>
        <OutlinedInput
          fullWidth
          multiline
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 1000
          }}
          value={form?.helpText || ''}
          placeholder='Type helper text for the question here...'
          onChange={(e) => setForm({ ...form, helpText: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.helpText?.length || 0}/1000`}</Typography>
      </Grid>
      {/* resource link for a question */}
      <Grid container item direction='row' spacing={2}>
        <Grid item xs={7}>
          <Typography gutterBottom variant='body1'>Resource</Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            inputProps={{
              maxLength: 255
            }}
            value={form?.education || ''}
            placeholder='Type a resource link for the question here...'
            onChange={(e) => setForm({ ...form, education: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.education?.length || 0}/250`}</Typography>
        </Grid>
        {/* resource type (currently only has 'read' as available option) */}
        <Grid item xs={5}>
          <Typography gutterBottom variant='body1'>Resource Type</Typography>
          <Select
            {...commonSelectProps}
            disabled
          >
            <MenuItem value='' disabled>Read</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  )
}

export default EditQuestionContent
