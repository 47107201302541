import React, { useState } from 'react'
import {
  Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton,
  Table, TableRow, TableContainer, TableHead, TableCell, TableSortLabel, TableBody,
  useTheme, Select, MenuItem, Pagination
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { StyledTableRow } from '../../../utils'

const userHeadCells = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'company', label: 'Company' },
  { id: 'email', label: 'Email' }
]

const CompanyUsersModal = (props) => {
  const {
    usersModalOpen = false, handleUsersModalClose = () => {}, smScreenDown,
    users = [], totalUsers = 0, userFilter = {}, setUserFilter = () => {}, classes = {}
  } = props

  const theme = useTheme()

  // ********************* Partner User Pagination Logic: **************** //
  const [usersPage, setUsersPage] = useState(0)

  const handleChangeUserPage = (event, value) => {
    if (value >= 0) {
      setUsersPage(value - 1)
      setUserFilter({
        ...userFilter,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setUserFilter({
      ...userFilter,
      page: 1,
      sortCount: event.target.value
    })
  }

  // ******************** Partner User Sort Logic **************** //

  const handleUsersRequestSort = (property) => {
    const { sortBy, direction } = userFilter
    const isAsc = sortBy === property && direction === 'asc'

    setUserFilter({
      ...userFilter,
      page: 1,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  const emptyUserRows = userFilter?.sortCount - Math.min(userFilter?.sortCount, totalUsers - usersPage * userFilter?.sortCount)

  return (
    <Dialog
      open={usersModalOpen}
      onClose={() => handleUsersModalClose()}
      fullWidth
      keepMounted
      maxWidth={smScreenDown ? 'lg' : 'md'}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h5'>Users</Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => handleUsersModalClose()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column'>
          <TableContainer elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  {userHeadCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align='left'
                        sortDirection={userFilter?.sortBy === header?.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={userFilter?.sortBy === header?.id}
                          direction={userFilter?.sortBy === header?.id ? userFilter?.direction : 'asc'}
                          onClick={() => handleUsersRequestSort(header?.id)}
                          sx={{ '.MuiTableSortLabel-icon': { fontSize: '14px' } }}
                        >
                          <Typography variant='body1' style={{ fontSize: '18px' }}>{header.label}</Typography>
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                  <TableCell align='left' style={{ padding: '16px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Temporary sort users */}
                {users.map((row) => {
                  const { userID = '', firstName = '', lastName = '', company = '', email = '' } = row
                  return (
                    <StyledTableRow key={userID}>
                      <TableCell align='left'>{firstName}</TableCell>
                      <TableCell align='left'>{lastName}</TableCell>
                      <TableCell align='left'>{company}</TableCell>
                      <TableCell colSpan={6} align='left'>{email}</TableCell>
                    </StyledTableRow>
                  )
                })}
                {/* Data Array has reached it's length: */}
                {emptyUserRows > 0 && (
                  <StyledTableRow style={{ height: 8 }}>
                    <TableCell colSpan={6}>
                      <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>{!users?.length ? 'No companies assigned.' : 'End of List'}</Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Pagination */}
        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={userFilter?.sortCount}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalUsers ? Math.ceil(totalUsers / userFilter?.sortCount) : 0}
              page={userFilter?.page || 1}
              onChange={handleChangeUserPage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CompanyUsersModal
