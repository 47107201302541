import React from 'react'
import {
  Dialog, DialogTitle, DialogContent, Button, useTheme,
  Grid, IconButton, Typography, Table, TableContainer, TableRow, TableCell, TableHead, TableBody
} from '@mui/material'
import { CloseRounded, EditRounded } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { StyledTableRow, builderModalTypes, loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

const defaultHeaderPadding = '8px 16px 8px 0'
const headCells = [
  { id: 'optionOrder', label: '', padding: defaultHeaderPadding },
  { id: 'text', label: 'Option', padding: defaultHeaderPadding },
  { id: 'scoreImpact', label: 'Score Impact', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageFB', label: 'Feedback', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageAT', label: 'Action to Take', padding: defaultHeaderPadding }
]

const useStyles = (theme) => ({
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '20em',
    padding: '8px 16px 8px 0'
  }
})

// Displays the question options for the selected question
// rendered in BuilderQuestionTable.js
const QuestionOptionsModal = (props) => {
  const {
    // generated in parent BuilderQuestionTable.js
    questionOptionsModalOpen = false,
    handleQuestionOptionsClose = () => { },
    handleOpenEditModal = () => { },
    optionRows = [],
    // generated in top most parent Builder.js
    classes,
    editMode = false
  } = props
  const theme = useTheme()
  const localClasses = useStyles(theme)
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.FETCH_QUESTION_OPTIONS })

  const noOptionsRow = () => {
    return (
      <>
        <StyledTableRow key='question-row-none'>
          <TableCell align='center' colSpan={6} sx={classes.smallTableCellContent} style={{ padding: '8px 16px 8px 0', color: theme.palette.grey.darkGrey }}>
            <Grid container direction='column'>
              <Typography variant='caption'>No Options.</Typography>
              {/* TODO: change the below to a button to add question options once it has been implemented */}
              {/* <Typography variant='caption'>Close this modal and select 'Edit' on this question to add answer options.</Typography> */}
            </Grid>
          </TableCell>
        </StyledTableRow>
      </>
    )
  }

  return (
    <Dialog
      open={questionOptionsModalOpen}
      onClose={() => handleQuestionOptionsClose()}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item container xs={8}>
            <Typography variant='h5'>Question Options</Typography>
          </Grid>
          {editMode &&
            <Grid item container xs={3} justifyContent='flex-end'>
              <Button variant='contained' color='primary' onClick={() => handleOpenEditModal(builderModalTypes.NEW_OPTION)}>Add Option</Button>
            </Grid>}
          <Grid item container xs={1} justifyContent='flex-end'>
            <IconButton style={{ padding: '0px' }} onClick={() => handleQuestionOptionsClose()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: '.5em' }}>
        <Grid container direction='column'>
          <TableContainer elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align='left'
                        style={{ padding: header?.padding }}
                      >
                        <Typography variant='body2' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                      </TableCell>
                    )
                  })}
                  {editMode && <TableCell align='left' />}
                </TableRow>
              </TableHead>
              <TableBody>
                {promiseInProgress
                  ? (
                    <TableRow style={{ height: 80 }}>
                      <TableCell colSpan={12}>
                        <Grid item container direction='row' justifyContent='center' alignItems='center'>
                          <StyledLoadingIndicator
                            area={loaderArea.FETCH_QUESTION_OPTIONS}
                            loaderType={progressComponentTypes.CIRCULAR_PROGRESS}
                            style={{ color: theme.palette.primary, margin: '.4em' }}
                            size={25}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>)
                  : (
                    <>
                      {!optionRows.length && noOptionsRow()}
                      {Boolean(optionRows.length) && optionRows.map((row) => {
                        const { questionOptionID = 0, optionOrder = 0, text = '', scoreImpact = '', conditionalLanguageFB = '', conditionalLanguageAT = 0 } = row
                        return (
                          <StyledTableRow key={`question-option-row-${questionOptionID}`}>
                            <TableCell align='center' style={{ color: theme.palette.blue.main, fontWeight: 600 }}>{optionOrder}</TableCell>
                            <TableCell sx={localClasses.truncateText} align='left'>{text}</TableCell>
                            <TableCell sx={localClasses.truncateText} align='center'>{scoreImpact}</TableCell>
                            <TableCell sx={localClasses.truncateText} align='left'>{conditionalLanguageFB}</TableCell>
                            <TableCell sx={localClasses.truncateText} align='left'>{conditionalLanguageAT}</TableCell>
                            {editMode &&
                              <TableCell sx={localClasses.truncateText} align='center'>
                                <Button sx={classes.editButtonBasicStyles} style={{ backgroundColor: 'white' }} onClick={() => handleOpenEditModal(builderModalTypes.OPTION, row)}>
                                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '12px' }} />
                                </Button>
                              </TableCell>}
                          </StyledTableRow>
                        )
                      })}
                    </>)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default QuestionOptionsModal
