import React from 'react'
import { Popover, Typography, Grid, Button } from '@mui/material'

// Confirmation popover for activating an assessment
// Rendered in AssessmentList.js
const ActivateAssessmentModal = (props) => {
  // all props generated and passed in from AssessmentList.js
  const { openConfirmModal, isActiveAnchorEl, isActiveID, handlePopoverClose = () => { }, handleAssessmentActiveStatus = () => { } } = props

  return (
    <Popover
      id={openConfirmModal ? 'confirm-popover' : undefined}
      open={openConfirmModal}
      anchorEl={isActiveAnchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Grid container direction='column' style={{ padding: '1em' }}>
        {isActiveID.current &&
          <>
            <Typography
              variant='h6'
              gutterBottom
            >
              {isActiveID.current.isActive === 0
                ? 'Are you sure you want to set this assessment to be active?'
                : 'Are you sure you want to deactivate this assessment?'}
            </Typography>

            <Typography variant='body1' gutterBottom>{isActiveID.current.isActive === 0
              ? 'All users will be able to view and take this assessment.'
              : 'Users currently taking this assessment will be allowed to finish, but no new assessments will be started until assessment is reactivated.'}
            </Typography>
          </>}

        <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
          <Button onClick={handlePopoverClose} variant='outlined' color='primary' style={{ marginRight: '.5em' }}>Cancel</Button>
          <Button variant='contained' onClick={() => handleAssessmentActiveStatus()}>Confirm</Button>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default ActivateAssessmentModal
