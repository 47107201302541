import { useEffect, useState } from 'react'
import { Bar, defaults } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import 'chartjs-plugin-colorschemes'
import { utils } from '../../utils/utils'
import { useSelector } from 'react-redux'

defaults.global.defaultFontFamily = 'Lato'

const data = (section) => (
  {
    labels: section.map((section) => `${section.shortName}`),
    datasets: [
      {
        type: 'bar',
        data: section.map(section => Math.round(Math.max(section.sectionScore, 0) * 100)),
        backgroundColor: section.map(section => utils.getScoreBgColor((section.sectionScore * 100))),
        borderColor: section.map(section => utils.getScoreBgColor((section.sectionScore * 100))),
        borderWidth: 1,
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (data) => `${Math.round(data || 0)}%`,
          display: true,
          font: {
            weight: 'bold'
          }
        }
      }
    ]
  }
)

const options = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 25
      }
    },
    tooltips: {
      enabled: window.innerWidth > 450 || false,
      mode: 'index'
    },
    plugins: {
      legend: false // Hide legend
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true,
            autoSkip: false,
            maxRotation: window.innerWidth > 450 ? 45 : 90,
            minRotation: window.innerWidth > 450 ? 45 : 90
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: label => `${label}%`,
            beginAtZero: true,
            autoSkip: false,
            min: 0,
            max: 100,
            stepSize: 10
          }
        }
      ]
    }
  }
}

const GradingChart = () => {
  const { userAssessmentResults: { sectionScores = [] } } = useSelector(state => state.userAssessment)

  const [sectionsArray, setSectionsArray] = useState([])

  useEffect(() => {
    if (sectionScores?.length) {
      const newArray = []
      sectionScores.forEach((section) => {
        if (section && section.sectionScore !== null) { newArray.push(section) }
      })

      setSectionsArray(newArray)
    }
  }, [sectionScores])

  return (
    <div className='col-12 mt-4'>
      <Bar
        id='barChart'
        height={450}
        data={data(sectionsArray)}
        options={options()}
      />
    </div>
  )
}

export default GradingChart
