import React from 'react'
import { Redirect } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { SignupForm } from './SignupForm'
import { findDefaultRoute } from '../../../utils'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const stripeOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Lato:wght@300400700&display=swap'
    }
  ]
}

const SignUp = ({ location }) => {
  const { isValidated, userType, userID } = useSelector(state => state.auth)

  const redirectTo = (location) => {
    if (location.search) {
      const params = new URLSearchParams(location.search)
      return <Redirect to={params.get('returnURL')} />
    }
    return findDefaultRoute(isValidated, userType, userID)
  }

  if (!isValidated) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        language='en'
      >
        <Elements stripe={stripeTestPromise} options={stripeOptions}>
          <SignupForm location={location} />
        </Elements>
      </GoogleReCaptchaProvider>
    )
  }

  return redirectTo(location)
}

export default SignUp
