/* eslint camelcase: off */

import { GET_INDUSTRIES } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

export const getIndustries = (filter = { }, fireSuccess = () => {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/industries`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()
        const { count: totalIndustries = 0, rows: industries = [] } = data
        const payload = { totalIndustries, industries }

        dispatch({
          type: GET_INDUSTRIES,
          payload
        })

        fireSuccess()

        return true
      } else {
        fireFailure('Unable to fetch industry list.')

        log.info('Failed to get industries.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
