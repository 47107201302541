import React from 'react'

// Checks the range the score falls between to determine feedback verbiage
const scoreRange = (x, min, max) => {
  return x.toFixed() >= min && x.toFixed() <= max
}

const ResultFeedback = ({ assessmentScore }) => {
  if (!assessmentScore) { assessmentScore = 0 }
  const getResultFeedback = () => {
    if (scoreRange(assessmentScore, 0, 39)) {
      return 'Unlikely to Receive an Investment'
    }
    if (scoreRange(assessmentScore, 40, 59)) {
      return 'Challenging to Find Investment'
    }
    if (scoreRange(assessmentScore, 60, 74)) {
      return 'Likely Receive Investment at Industry Average Valuation'
    }
    if (scoreRange(assessmentScore, 75, 89)) {
      return 'Likely Receive Investment at an Above Average Valuation'
    }
    if (scoreRange(assessmentScore, 90, 100)) {
      return 'Likely Receive Investment at a Premium Valuation'
    }
    return 'Unable to determine result feedback.'
  }

  return (
    <div className='px-4 py-3 bg-lighter border-dark rounded-pill border mt-4 mb-3 font-600'>
      {getResultFeedback()}
    </div>
  )
}

export default ResultFeedback
