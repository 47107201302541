import React from 'react'
import {
  DialogActions, Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton,
  Button, Select, MenuItem, useTheme
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { NotificationToast } from '../../tools'

const AssessmentLinkModal = (props) => {
  const {
    assessmentLinkModalOpen = false, handleCloseAssessmentLinkModal = () => { }, assessmentLinkInfo = {}, alternateAssessments, setAssessmentLinkInfo = () => { },
    classes = {}, handleGetAssessmentLink = () => { }, assessmentLinkErrs = {}
  } = props
  const theme = useTheme()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.SELECT_ASSESSMENT })
  const { fetchingAssessmentLink } = usePromiseTracker({ area: loaderArea.GET_ASSESSMENT_LINK })

  const handleAssessmentChange = (id) => {
    // find the id in the list of assessment opts
    const newAssessment = alternateAssessments?.find(x => x?.assessmentID === id)

    // If the assessment was found
    if (newAssessment) {
      setAssessmentLinkInfo({ ...assessmentLinkInfo, assessmentID: newAssessment?.assessmentID })
    } else {
      // If no assessment was found and the id was not intentionally being unset, fire a notification
      if (id !== '') { NotificationToast(true, 'Unable to find selected assessment.') }
      setAssessmentLinkInfo({ ...assessmentLinkInfo, assessmentID: null })
    }
  }

  return (
    <Dialog
      open={assessmentLinkModalOpen}
      onClose={handleCloseAssessmentLinkModal}
      fullWidth
      maxWidth='xs'
      style={{ zIndex: 1060 }}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h5'
            >
              Get Assessment Link
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={handleCloseAssessmentLinkModal}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' spacing={2}>
          {promiseInProgress && (!alternateAssessments?.length || alternateAssessments?.length === 0) &&
            <Grid item container direction='column' justifyContent='center' alignItems='center'>
              <StyledLoadingIndicator area={loaderArea.SELECT_ASSESSMENT} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} size={30} style={{ color: theme.palette.primary }} />
            </Grid>}

          {!promiseInProgress && (!alternateAssessments?.length || alternateAssessments?.length === 0) &&
            (
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>No alternate assessments are currently available.</Typography>
              </Grid>
            )}

          {!promiseInProgress && alternateAssessments?.length > 0 &&
            (
              <>
                {/* NOTE: Commenting email field out for now. We might add it back as an optional thing later on. */}
                {/* <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Email</Typography>
                  <OutlinedInput
                    fullWidth
                    margin='dense'
                    size='small'
                    value={assessmentLinkInfo?.emailAddress || ''}
                  />
                </Grid> */}
                <Grid item container direction='column'>
                  <Grid container direction='column' spacing={2} style={{ marginBottom: '1.2rem' }}>
                    <Grid item container direction='column'>
                      <Typography variant='caption' sx={{ fontSize: '0.9em' }}>Please select an assessment from the dropdown below and click "Save" to copy the link to your clipboard.</Typography>
                    </Grid>
                  </Grid>
                  <Typography gutterBottom variant='body1'>Assessment</Typography>
                  <Select
                    variant='outlined'
                    size='small'
                    defaultValue=''
                    value={assessmentLinkInfo?.assessmentID ? assessmentLinkInfo?.assessmentID : ''}
                    disabled={!alternateAssessments?.length}
                    onChange={e => {
                      handleAssessmentChange(e.target.value)
                    }}
                    sx={{ ...classes?.searchInput }}
                    style={{ padding: 0 }}
                    MenuProps={{
                      sx: { ...classes?.statusSelect }
                    }}
                  >
                    <MenuItem value=''>None</MenuItem>

                    {alternateAssessments && alternateAssessments.length && alternateAssessments.length > 0 && alternateAssessments.map(assessment =>
                      <MenuItem key={`assessment-${assessment.assessmentID}`} value={assessment.assessmentID}>{assessment.title}</MenuItem>
                    )}
                  </Select>
                </Grid>
              </>)}
        </Grid>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Grid container direction='column'>
          {assessmentLinkErrs?.failedAction &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Unable to perform action.</Typography>}
          {/* NOTE: Commenting email error text out for now. We might add it back as an optional thing later on. */}
          {/* {assessmentLinkErrs?.invalidEmail &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>'* Invalid email provided.</Typography>} */}
          {assessmentLinkErrs?.requiredInformation &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>'* Missing required information.</Typography>}
          <Grid container item direction='row' justifyContent='space-between'>
            <Button
              variant='outlined'
              color='primary'
              style={{
                margin: '.5em 1em',
                fontWeight: '600'
              }}
              onClick={handleCloseAssessmentLinkModal}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              color='primary'
              style={{
                margin: '.5em 1em',
                fontWeight: '600'
              }}
              disabled={!alternateAssessments.length || !assessmentLinkInfo?.assessmentID}
              onClick={(e) => { e.preventDefault(); handleGetAssessmentLink() }}
            >
              {fetchingAssessmentLink
                ? <StyledLoadingIndicator area={loaderArea.GET_ASSESSMENT_LINK} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                : 'Copy Link'}
            </Button>

          </Grid>
        </Grid>
      </DialogActions>

    </Dialog>
  )
}

export default AssessmentLinkModal
