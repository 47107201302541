import React from 'react'
import Content from '../components/Content'

import Main from '../components/Main'
import MainHeader from '../components/MainHeader'

const Report = ({ children, width = 12 }) => {
  return (
    <>
      <Main className='d-flex flex-column justify-content-between'>
        <MainHeader />
        <Content width={width} paddingx={0}>{children}</Content>
      </Main>
    </>
  )
}

export default Report
