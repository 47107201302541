import React, { useState, useRef } from 'react'
import {
  Grid, Typography, OutlinedInput, useTheme, Divider, IconButton, Popover,
  Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip
} from '@mui/material'
import { Add, MoreVert, CloseRounded, ExpandLess, ExpandMore, EditRounded } from '@mui/icons-material'
import { StyledTableRow, checkValidInputs } from '../../../utils'

const defaultHeaderPadding = '8px 16px 8px 0'
const headCells = [
  { id: 'optionOrder', label: '', padding: defaultHeaderPadding },
  { id: 'text', label: 'Option', padding: defaultHeaderPadding },
  { id: 'scoreImpact', label: 'Score Impact', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageFB', label: 'Feedback', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageAT', label: 'Action to Take', padding: defaultHeaderPadding },
  { id: 'editButton', label: '', padding: defaultHeaderPadding }
]

const defaultOptionsForm = {
  optionOrder: 0,
  text: '',
  scoreImpact: 0,
  conditionalLanguageFB: '',
  conditionalLanguageAT: ''
}

// Renders the form and creation list for options being added to a new question
// rendered in NewQuestionModal.js
const QuestionOptionsContent = (props) => {
  const {
    // generated in AssessmentSections.js
    newQuestionForm = {}, setNewQuestionForm = () => { }, editOptionView, setEditOptionView = () => { },
    classes = {}
  } = props
  const theme = useTheme()

  const { questionOptions = [] } = newQuestionForm
  const editOpIndex = useRef(0)

  // *** Confirm popper related elements --- //
  const [optionAnchorEl, setOptionAnchorEl] = useState(null)

  // open the delete confirm poover
  const handleOptionPopover = (e, value) => {
    setOptionAnchorEl(e.currentTarget)
    editOpIndex.current = value
  }

  // close the delete popover
  const handleOptionPopClose = () => {
    setOptionAnchorEl(null)
    editOpIndex.current = 0
  }

  const openOptionPop = Boolean(optionAnchorEl)
  // ***

  // local option form
  const [optionForm, setOptionForm] = useState({ ...defaultOptionsForm })

  const [optionError, setOptionError] = useState(false)
  const requiredInfoCondition = Boolean(!optionForm.text || optionForm?.scoreImpact === null || optionForm?.scoreImpact === '')

  // add a new option to the question form and reset the local option form
  const handleAddOption = () => {
    const { text, conditionalLanguageAT, conditionalLanguageFB } = optionForm
    if (!text || !checkValidInputs({ text, conditionalLanguageAT, conditionalLanguageFB })) {
      setOptionError(true)
      return false
    }

    const opForm = { ...optionForm, optionOrder: questionOptions?.length + 1 || 1 }
    const currentArr = [...questionOptions]
    currentArr.push(opForm)
    setOptionForm({ ...defaultOptionsForm })
    setOptionError(false)
    setNewQuestionForm({ ...newQuestionForm, questionOptions: [...currentArr] })
  }

  // Moves or deletes the index of the selected option
  const handleMoveOpIndex = (from, to) => {
    // clone the current set of options
    const currentArr = [...questionOptions]

    // If a 'to' prop was provided, we are rearranging the indices
    if (to || to === 0) {
      // Grab the item being moved
      const [op] = currentArr.splice(from, 1)
      // Add it back into the array at the cosen position (either up or down 1)
      currentArr.splice(to, 0, op)
    } else {
      // Otherwise we are removing the index, so just manipulate th cloned arr
      currentArr.splice(from, 1)
    }

    // Once manipulated, change the 'order' prop to match the new order
    const newOrder = currentArr.map((x, i) => {
      if (x) {
        x = { ...x, optionOrder: i + 1 }
      }
      return x
    })

    // Set the modified array to the question form
    setNewQuestionForm({ ...newQuestionForm, questionOptions: [...newOrder] })
    // close and reset the popover
    handleOptionPopClose()
  }

  const handleOpenEditView = (row) => {
    setEditOptionView(true)

    const tempForm = {
      optionOrder: row?.optionOrder,
      text: row?.text,
      scoreImpact: row?.scoreImpact,
      conditionalLanguageFB: row?.conditionalLanguageFB,
      conditionalLanguageAT: row?.conditionalLanguageAT
    }
    setOptionForm({ ...tempForm })
  }

  // Resets all state after either saving edited option or exiting from edit view
  const handleCloseEditView = () => {
    setEditOptionView(false)
    setOptionForm({ ...defaultOptionsForm })
    setOptionError(false)
    setNewQuestionForm({ ...newQuestionForm, questionOptions: [...questionOptions] })
  }

  // Saves edits made to a question option
  const handleSaveEditChanges = () => {
    const { text, conditionalLanguageAT, conditionalLanguageFB } = optionForm

    const currentArr = [...questionOptions]
    const currentOpt = currentArr.findIndex((opt => opt?.optionOrder === optionForm?.optionOrder))

    if (!text || !checkValidInputs({ text, conditionalLanguageAT, conditionalLanguageFB }) || currentOpt === -1) {
      setOptionError(true)
      return false
    }

    currentArr[currentOpt].optionOrder = optionForm?.optionOrder
    currentArr[currentOpt].text = optionForm?.text
    currentArr[currentOpt].scoreImpact = optionForm?.scoreImpact
    currentArr[currentOpt].conditionalLanguageFB = optionForm?.conditionalLanguageFB
    currentArr[currentOpt].conditionalLanguageAT = optionForm?.conditionalLanguageAT

    handleCloseEditView()
  }

  return (
    <>
      <Grid container item direction='column' style={{ marginBottom: '.5em' }}>
        <Grid item container direction='column' sx={{ marginBottom: '0.7em' }}>
          <Grid item>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>{editOptionView ? 'Edit Option' : 'Add Option'}</Typography>
          </Grid>
        </Grid>

        <Grid container item direction='row' spacing={2}>
          {/*  Question Option Text */}
          <Grid item xs={7}>
            <Typography gutterBottom variant='body1'>Option<span style={{ color: 'red' }}>*</span></Typography>
            <OutlinedInput
              fullWidth
              margin='dense'
              size='small'
              inputProps={{
                maxLength: 100
              }}
              placeholder='Type your option text here...'
              value={optionForm?.text || ''}
              onChange={(e) => setOptionForm({ ...optionForm, text: e.target.value })}
            />
            <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${optionForm?.text?.length || 0}/100`}</Typography>
          </Grid>
          {/* Options's score impact */}
          <Grid item xs={5}>
            <Typography gutterBottom variant='body1'>Score Impact<span style={{ color: 'red' }}>*</span></Typography>
            <OutlinedInput
              fullWidth
              margin='dense'
              size='small'
              type='number'
              name='scoreImpact'
              inputProps={{ min: -100, max: 100, step: 0.01 }}
              value={optionForm?.scoreImpact || 0}
              onChange={(e) => {
                let val = e.target.value
                if (Number(val) > 100) val = 100
                if (Number(val) < -100) val = -100
                setOptionForm({ ...optionForm, scoreImpact: val || 0 })
              }}
            />
          </Grid>
        </Grid>
        {/* Options's conditional feedback text */}
        <Grid container item direction='column'>
          <Typography gutterBottom variant='body1'>Feedback/Commentary</Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            multiline
            rows={2}
            placeholder='Type any feedback for this option here...'
            inputProps={{
              maxLength: 750
            }}
            value={optionForm?.conditionalLanguageFB || ''}
            onChange={(e) => setOptionForm({ ...optionForm, conditionalLanguageFB: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${optionForm?.conditionalLanguageFB?.length || 0}/100`}</Typography>
        </Grid>
        {/* Options conditional action text */}
        <Grid container item direction='column'>
          <Typography gutterBottom variant='body1'>Action to Take</Typography>
          <OutlinedInput
            fullWidth
            multiline
            rows={2}
            margin='dense'
            size='small'
            placeholder='Type any actions to take for this option here...'
            inputProps={{
              maxLength: 750
            }}
            value={optionForm?.conditionalLanguageAT || ''}
            onChange={(e) => setOptionForm({ ...optionForm, conditionalLanguageAT: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${optionForm?.conditionalLanguageAT?.length || 0}/100`}</Typography>
        </Grid>
        {optionError &&
          <Grid>
            <Typography variant='caption' color='error'>* Invalid Input. Cannot contain only special chracters or only white spaces.</Typography>
          </Grid>}
        <Grid item container direction='row' justifyContent='flex-end'>
          <Grid item>
            <Tooltip
              placement='top'
              arrow enterDelay={500}
              disableHoverListener={!requiredInfoCondition}
              title={requiredInfoCondition ? 'Please enter all required information to submit an option.' : ''}
            >
              <span>
                {editOptionView ? (
                  <Button
                    variant='contained'
                    disabled={requiredInfoCondition}
                    onClick={handleSaveEditChanges}
                    startIcon={<EditRounded />}
                    size='small'
                    sx={{ padding: '3px 8px', '.MuiButton-startIcon': { marginRight: '5px' } }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    disabled={requiredInfoCondition || editOptionView}
                    onClick={handleAddOption}
                    startIcon={<Add />}
                    size='small'
                    sx={{ padding: '3px 8px', '.MuiButton-startIcon': { marginRight: '5px' } }}
                  >
                    Add Option
                  </Button>
                )}
              </span>
            </Tooltip>
          </Grid>
          {editOptionView &&
            <Grid item>
              <Button onClick={handleCloseEditView}>Cancel</Button>
            </Grid>
          }
        </Grid>
      </Grid>

      <Divider />

      <Grid item container>
        <Typography gutterBottom variant='body1'>Current Options</Typography>
        <TableContainer elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left' />
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align={header.id === 'editButton' ? 'right' : 'left'}
                      sx={classes.smallTableCellContent}
                    >
                      <Typography style={{ fontSize: '14px' }}>{header?.label}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!questionOptions?.length &&
                <StyledTableRow style={{ height: 10 }}>
                  <TableCell colSpan={6}>
                    <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>No Options Created</Typography>
                  </TableCell>
                </StyledTableRow>}
              {questionOptions?.map((row, i) => {
                const { optionOrder = 0, text = '', scoreImpact = '', conditionalLanguageFB = '', conditionalLanguageAT = 0 } = row
                return (
                  <StyledTableRow key={`new-question-options-optionOrder-${optionOrder}`}>
                    <TableCell align='center' style={{ padding: '4px 8px 4px 0' }}><IconButton size='small' onClick={(e) => { handleOptionPopover(e, i) }}><MoreVert style={{ fontSize: '12px' }} /></IconButton></TableCell>
                    <TableCell align='center' sx={classes.smallTableCellContent} style={{ color: theme.palette.blue.main, fontWeight: 600 }}>{optionOrder}</TableCell>
                    <TableCell sx={classes.smallTableCellContent} align='left'>{text}</TableCell>
                    <TableCell sx={classes.smallTableCellContent} align='center'>{scoreImpact}</TableCell>
                    <TableCell sx={classes.smallTableCellContent} align='left'>{conditionalLanguageFB || 'N/A'}</TableCell>
                    <TableCell sx={classes.smallTableCellContent} align='left'>{conditionalLanguageAT || 'N/A'}</TableCell>
                    <TableCell sx={classes.smallTableCellContent} style={{ paddingLeft: '2em' }} align='right'>
                      <Button
                        style={{
                          minWidth: 0,
                          width: '18px',
                          backgroundColor: theme?.palette?.blue?.main
                        }}
                        onClick={(e) => { e.preventDefault(); handleOpenEditView(row) }}
                      >
                        <EditRounded style={{ color: 'white', fontSize: '16px' }} />
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Popover
        id={openOptionPop ? 'confirm-popover' : undefined}
        open={openOptionPop}
        anchorEl={optionAnchorEl}
        onClose={handleOptionPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Grid container direction='row' style={{ padding: '3px' }}>
          <IconButton size='small' disabled={Boolean(editOpIndex.current === questionOptions.length - 1)} onClick={() => { handleMoveOpIndex(editOpIndex.current, editOpIndex.current + 1) }}><ExpandMore style={{ fontSize: '14px' }} /></IconButton>
          <IconButton size='small' disabled={Boolean(!editOpIndex.current || questionOptions?.length === 1)} onClick={() => { handleMoveOpIndex(editOpIndex.current, editOpIndex.current - 1) }}><ExpandLess style={{ fontSize: '14px' }} /></IconButton>
          <IconButton size='small' onClick={() => handleMoveOpIndex(editOpIndex.current)}><CloseRounded style={{ fontSize: '14px', color: 'red' }} /></IconButton>
        </Grid>
      </Popover>
    </>
  )
}

export default QuestionOptionsContent
