import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert
} from 'reactstrap'

import Loader from '../../../components/Loader'
import { NotificationToast } from '../../../components/tools'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, withRouter } from 'react-router-dom'

import { login } from '../../../redux/actions'

const LoginForm = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onDismiss = () => setError(false)

  const fireFailure = (res) => {
    const message = 'Invalid Login'
    NotificationToast(res.error, message)
  }

  const doLogin = async e => {
    e.preventDefault()
    try {
      setLoading(true)

      dispatch(login({ email, password }, fireFailure))
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(err.response?.data?.message || 'There was an error, please try again.')
    }
  }

  return (
    <Col className='col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 px-xl-5 flex-grow-1 d-flex flex-column justify-content-center'>
      <div className='text-center'>
        <h3>Investment Grade Assessment</h3>
        <div className='my-3 text-primary text-center'>Sign In to Continue</div>
      </div>
      <Card>
        <CardBody className='px-0'>
          <Form onSubmit={doLogin}>
            <FormGroup>
              <Label className='font-600'>Email</Label>
              <Input
                maxLength='50'
                required
                bsSize='lg'
                name='email'
                className='mb-1'
                placeholder='Enter your email'
                onChange={event => setEmail(event.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className='font-600'>Password</Label>
              <Input
                required
                maxLength='50'
                bsSize='lg'
                type='password'
                name='password'
                placeholder='Enter your password'
                onChange={event => setPassword(event.currentTarget.value)}
              />
              <div className='d-flex justify-content-between'>
                <small>
                  <Link to='/auth/reset-password'>Forgot password?</Link>
                </small>
                <small>
                  <Link to='/auth/sign-up'>Don't have an account? Sign Up!</Link>
                </small>
              </div>
            </FormGroup>
            <div className='text-center mt-3'>
              <UncontrolledAlert isOpen={!!error} toggle={onDismiss} color='warning'>
                <div className='alert-icon'>
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className='alert-message text-left'>
                  <span className='ml-2'>{error}</span>
                </div>
              </UncontrolledAlert>
              {loading ? <Loader size='sm' /> : <Button color='primary' type='submit' size='lg'>Sign In</Button>}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  )
}

export default withRouter(LoginForm)
