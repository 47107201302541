import { userTypes, validInputCheck } from './variables'
import { Redirect } from 'react-router-dom'
const Domains = require('./domains')

export const validateEmail = (email) => {
  const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  return isValid
}

export const handlePhonePrepper = (phone) => {
  const parsedPhone = phone.replace(/\+1/g, '').replace(/\s/g, '').replace(/(\(|\))/g, '').replace(/-/g, '').replace(/_/g, '')
  if (!/^[0-9]+$/.test(parsedPhone)) {
    return false
  }
  return parsedPhone
}

export const ValidatePhone = (phone) => {
  const isValid = handlePhonePrepper(phone).length === 10
  return isValid
}

export const findDefaultRoute = (isValidated, userType, userID, history) => {
  let path = '/auth/sign-in'
  if (!isValidated || !userType) {
    return history ? history.push(path) : <Redirect to={{ pathname: path }} />
  }

  switch (userType) {
    case userTypes.COMPANY_USER: {
      path = '/assessments'
      return history ? history.push(path) : <Redirect to={{ pathname: path }} />
    }
    case userTypes.PARTNER_USER: {
      path = { pathname: '/administration', search: `?${userType}=${userID}` }
      return history ? history.push({ ...path }) : <Redirect to={{ ...path }} />
    }
    case userTypes.ADMIN_USER: {
      path = { pathname: '/administration', search: `?${userType}=${userID}` }
      return history ? history.push(path) : <Redirect to={path} />
    }
    default: return history ? history.push('/auth/sign-in') : <Redirect to={{ pathname: '/auth/sign-in' }} />
  }
}

export const ValidateUrl = (url) => {
  const isValid = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/gm.test(url)
  if (isValid) {
    let hasDomain = false
    Domains.forEach((domain) => {
      if (url.includes(`.${domain}`)) {
        hasDomain = true
      }
    })
    if (hasDomain) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// check the provided fields for only white spaces or only special characters
export const checkValidInputs = (obj) => {
  const { onlySpecialChars, onlyBlankSpaces } = validInputCheck
  const check = Object.values(obj).every(val => {
    if (val) {
      if (onlySpecialChars?.test(val) || onlyBlankSpaces?.test(val)) {
        return false
      }
    }
    return true
  })

  return check
}
