import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LoginForm from './LoginForm'
import { findDefaultRoute } from '../../../utils'

const SignIn = ({ location }) => {
  const { isValidated, userType, userID } = useSelector(state => state.auth)

  const redirectTo = (location) => {
    if (location.search) {
      const params = new URLSearchParams(location.search)
      return <Redirect to={params.get('returnURL')} />
    }
    return findDefaultRoute(isValidated, userType, userID)
  }

  if (!isValidated) {
    return <LoginForm />
  }

  return redirectTo(location)
}

export default SignIn
