import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Button, ListGroup, ListGroupItem } from 'reactstrap'
import Header from '../../components/Header'
import AssessmentRow from './AssessmentRow'
import { usePromiseTracker } from 'react-promise-tracker'

import Loader from '../../components/Loader'

import { getUserAssessments, getAssessmentFlowQuestions } from '../../redux/actions'
import { assessmentStatus } from '../../utils'
import { NotificationToast } from '../../components/tools'

const Assessments = (props) => {
  const { width, industry, sector, assessment, handleResetState = () => {} } = props
  const { userID } = useSelector(state => state.auth)
  const { userAssessments = [] } = useSelector(state => state.userAssessment)
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker()

  const indParam = industry ? `?industry=${industry}` : ''
  const sectParam = sector ? `${industry ? '&' : ''}sector=${sector}` : ''
  const assessParam = assessment ? `${industry || sector ? '&' : ''}assessment=${assessment}` : ''
  const flowPath = `/assessments/flow${indParam}${sectParam}${assessParam}`

  // send user to a a current assessment
  const fireQuestionsSuccess = useCallback((userAssessmentID) => {
    return props.history.push(`/assessments/flow?userAssessment=${userAssessmentID}`)
  }, [props.history])

  // check to see if industry, sector, or assessment are present
  const paramsPresent = Boolean((industry && sector) || (industry && sector && assessment))

  const fireFetchAssessmentsSuccess = useCallback(async (assessmentsLength, assessmentID, userAssessmentID) => {
    // if an assessmentID and userAssessmentID are returned, the user has an in progress assessment
    const assessmentInProgress = Boolean(assessmentID && userAssessmentID)

    // if no assessment is currently in progress AND the user has no assessments or params are present, move user to assessment flow
    if (!assessmentInProgress && (!assessmentsLength || paramsPresent)) {
      props.history.push(flowPath)
    }

    if (assessmentInProgress) {
      if (paramsPresent) {
        // if the user has an assessment in progress and params are present, let them know the in progress assessment must be completed
        NotificationToast(false, 'The currently in progress assessment must be completed before starting a new one.', true)
      } else {
        if (assessmentsLength === 1) {
          // if the user has an assessment in progress and it is the only one, take them to continue that assessment
          await dispatch(getAssessmentFlowQuestions(assessmentID, userAssessmentID, fireQuestionsSuccess, fireFailure))
        }
      }
    }

    // reset industry, sector, and assessment from routes
    handleResetState()
  }, [dispatch, fireQuestionsSuccess, props, paramsPresent, handleResetState, flowPath])

  // on load in check if the user has any completed assessments
  useEffect(() => {
    dispatch(getUserAssessments(userID, fireFetchAssessmentsSuccess, fireFailure))
  }, [dispatch, fireFetchAssessmentsSuccess, userID])

  // if we have a list of assessments for the user, find the one (if any) in progress (outside of dev environment, should ONLY be one)
  const assessmentInProgress = userAssessments?.find(x => x.status === assessmentStatus.IN_PROGRESS)

  // handler to determine wether we are stating or continuing an assessment  (currently used as click handler, but probably can be comined as part of useeffect success function)
  const startOrContinueAssessment = () => {
    if (assessmentInProgress) {
      const { userAssessmentID = '', assessmentID = '' } = assessmentInProgress
      dispatch(getAssessmentFlowQuestions(assessmentID, userAssessmentID, fireQuestionsSuccess, fireFailure))
    } else {
      props.history.push(flowPath)
    }
  }

  const fireFailure = (message = 'Something went wrong.') => {
    NotificationToast(true, message, false)
  }

  return (
    <>
      <div
        className={`col-sm-12 col-lg-${width} offset-lg-${(12 - width) / 2} mt-4 content flex-grow-1 d-flex flex-column}`}
        style={{ marginBottom: '1.5rem' }}
      >
        <Container fluid className='flex-grow-1 col-lg-8 d-flex flex-column justify-content-between'>
          <div>
            <Header className='mb-3'>
              <div className='d-flex flex-wrap justify-content-between align-items-center'>
                <h1 className='col-6 mb-0 px-0 col-lg-6 text-dark'>Assessments</h1>
                <div className='d-flex justify-content-end mt-2 mt-lg-0 col-6 col-lg-6 pl-2 pr-0'>
                  {promiseInProgress
                    ? (
                      <div className='min-width-50 d-flex align-items-center'><Loader size='sm' /></div>
                      )
                    : (
                      <Button color='primary' onClick={() => { startOrContinueAssessment() }}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span className='ml-2'>{assessmentInProgress ? 'Continue' : 'Begin'} Assessment</span>
                      </Button>
                      )}
                </div>
              </div>
            </Header>
            {promiseInProgress
              ? (
                <Loader size='sm' />
                )
              : (
                <ListGroup flush className='mt-4'>
                  {!userAssessments?.length
                    ? (
                      <ListGroupItem
                        className='bg-light border rounded py-2 my-1 d-flex justify-content-center align-items-center border-muted text-primary'
                        tag='div'
                      >
                        There are no assessments to show
                      </ListGroupItem>
                      )
                    : (
                        userAssessments.map(assessment =>
                          <AssessmentRow
                            currentAssessment={Boolean(assessment?.status === 'IN_PROGRESS')}
                            key={assessment.userAssessmentID}
                            assessment={assessment}
                            fireQuestionsSuccess={fireQuestionsSuccess}
                          />
                        )
                      )}
                </ListGroup>
                )}
          </div>
          <div className='d-flex justify-content-center align-items-center py-4'>
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className='text-muted mx-2'>Questions?</span>
            <a href='https://foundersib.com/contact-us/'>Contact Customer Support</a>
          </div>
        </Container>
      </div>
    </>
  )
}

export default withRouter(Assessments)
