import React, { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FloatingActionButton = () => {
  const [buttonVisible, setButtonVisible] = useState(false)

  // Used to throttle events
  const debounce = (func, delay) => {
    let timer
    return function() {
      let self = this
      let args= arguments

      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(self, args)
      }, delay)
    }
  }

  const toggleVisibility = () => {
    if (window.pageYOffset >= 400) {
      setButtonVisible(true)
    } else {
      setButtonVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // Debounces the toggleVisibility scrolling event using a callback
  // eslint-disable-next-line
  const debounceScroll = useCallback(debounce(toggleVisibility, 250), [])
    
  useEffect(() => {
    window.addEventListener('scroll', debounceScroll)
    
    return () => {
      window.removeEventListener('scroll', debounceScroll)
    }
  }, [debounceScroll])
  
  return (
    <div 
      onClick={scrollToTop} 
      className={`fab-button d-flex align-items-center justify-content-center d-lg-none d-xl-none bg-primary shadow ${!buttonVisible ? 'fab-button-hidden' : 'fab-button-visible'}`} 
      style={{position: 'fixed', bottom: '20px', right: '15px'}}
    >
      <FontAwesomeIcon icon={faChevronUp} className='fab-icon'/>
    </div>
  )
}

export default FloatingActionButton