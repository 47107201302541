import React from 'react'
import { Popover, Typography, Grid, Button } from '@mui/material'
import { builderModalTypes } from '../../../utils'

// Confirmation popover for deleting a question or question option
// rendered in EditQuestionOrOptionModal.js
const DeleteQuestionOrOptionConfirm = (props) => {
  // all props generated in grand parent component BuilderQuestionTable.js
  const {
    openConfirmDelete, deleteAnchorEl, handleDeleteClose = () => {}, handleDeleteQuestionOrOption = () => {},
    modalType, lastQuestion = false
  } = props

  const isQuestionModal = Boolean(modalType && modalType === builderModalTypes?.QUESTION)
  const modalTitle = lastQuestion && isQuestionModal ? 'Warning: This is the last question left in this assessment.' : `Are you sure you want to delete this ${isQuestionModal ? 'question' : 'option'}?`
  const modalSubText = lastQuestion && isQuestionModal ? 'Removing this question will automatically inactivate this assessment.' : 'This will remove it from all future versions of this assessment.'

  return (
    <Popover
      id={openConfirmDelete ? 'confirm-popover' : undefined}
      open={openConfirmDelete}
      anchorEl={deleteAnchorEl}
      onClose={handleDeleteClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Grid container direction='column' style={{ padding: '1em' }}>
        <Typography
          variant='h6'
          gutterBottom
        >
          {modalTitle}
        </Typography>

        <Typography variant='body1' gutterBottom>{modalSubText}</Typography>

        <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
          <Button onClick={handleDeleteClose} variant='outlined' color='primary' style={{ marginRight: '.5em' }}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDeleteQuestionOrOption()}>Confirm</Button>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default DeleteQuestionOrOptionConfirm
