import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const QuestionAnswer = ({ questionOptions, userSelectedOption }) => {
  return (
    <div className='d-flex flex-wrap mt-1 mb-4 justify-content-start align-items-center'>
      {questionOptions
        .sort((x, y) => y.optionOrder - x.optionOrder)
        .map((option, index) => {
          const { questionOptionID, optionText } = option
          const active = userSelectedOption === questionOptionID
          return (
            <FormGroup check className='col-12 px-0 mt-2' key={index}>
              <Label check className={`${active ? 'text-primary text-underline' : ''} font-weight-300 form-check-label col-12 p-0`}>
                <div className='mx-0 d-flex align-items-center justify-content-start'>
                  <FontAwesomeIcon icon={faPlay} className={`text-${active ? 'primary' : 'white'}`} size='xs' style={{ marginTop: '1px' }} />
                  <span className={`ml-1 ${active ? 'font-italic font-600' : ''}`}>{optionText}</span>
                </div>
              </Label>
            </FormGroup>
          )
        })}
    </div>
  )
}

export default QuestionAnswer
