/* eslint camelcase: off */

import { GET_SECTIONS } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

export const getSections = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/sections`, options), loaderArea.ASSESSMENT_SECTOR_QUESTIONS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_SECTIONS,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get sections.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
