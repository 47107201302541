import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { updateUserAgreement } from '../../redux/actions'
import { NotificationToast } from '../../components/tools'

const ConfidentialityStatement = (props) => {
  const { currentAgreementData: { content = '', agreementID = '' } } = useSelector(state => state.agreement)
  const { lastAgreement = null } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const fireFailure = () => {
    NotificationToast(true, 'Unable to complete agreement. Please try again later.')
  }

  // Update the db and the redux auth obj to the new agreement for the user
  const handleAgreement = () => {
    dispatch(updateUserAgreement(agreementID, () => { }, fireFailure))
  }

  return (
    <div className='border rounded bg-light p-5 col-12 col-lg-12 col-xl-6 d-flex flex-column justify-content-center flex-wrap justify-self-center'>
      <div>
        <h3 className='mb-0'>Confidentiality Statement</h3>
        {/* If the user has previously agreed to a statement, be sure they know this one is new */}
        {lastAgreement && lastAgreement !== agreementID ? <p className='text-muted mb-0'>*Please review our updated statement.</p> : ''}
      </div>
      <p className='my-4 text-left'>
        {content}
      </p>
      <Button
        size='lg'
        onClick={() => handleAgreement()}
        color='success'
      >
        <FontAwesomeIcon icon={faCheckCircle} />
        <span className='ml-2'>Acknowledge & Continue</span>
      </Button>
    </div>
  )
}

export default ConfidentialityStatement
