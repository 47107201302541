import React from 'react'
import { Col, Button, Card, CardTitle, CardBody, Collapse } from 'reactstrap'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { utils } from '../../utils/utils'
import HowToImproveScore from './HowToImproveScore'
import QuestionAnswer from './QuestionAnswer'
import QuestionFeedbackBox from './QuestionFeedbackBox'
import _ from 'lodash'

const SectionResult = (props) => {
  const {
    reportMode = false, section, sectionScoreObj, index,
    toggleSectionID, toggleSectionOpen, onTalkToAnExpert = () => { }, informationModal = {},
    setInformationModal = () => { }
  } = props

  const { id, name, summary, shortName, callToAction, sectionQuestions = [], actionItems = [] } = section
  const { sectionScore } = sectionScoreObj

  return (
    <div id={`section-${id}`} style={{ pageBreakInside: 'avoid' }}>
      <Card className='border bg-light'>
        <CardTitle className='d-flex justify-content-between align-items-center bg-darkerblue text-white py-2 px-2'>
          <span className='px-3 py-1'>{`${index}. ${name}`}</span>
          <span className='d-flex justify-content-end align-items-center py-1'>
            <span className={`text-white rounded-pill px-4 py-1 text-center border border-${utils.getScoreBorderColor(sectionScore * 100)}`} style={{ backgroundColor: utils.getScoreBgColor(sectionScore * 100) }}>{`${Math.round(Math.max(sectionScore, 0) * 100)}%`}</span>
            <span className='px-4 py-1'>
              <FontAwesomeIcon onClick={() => toggleSectionOpen(id)} icon={faChevronDown} size='lg' style={{ cursor: 'pointer', fontSize: '1.5em', visibility: id === toggleSectionID ? 'hidden' : '' }} />
            </span>
          </span>
        </CardTitle>
        <Collapse isOpen={Boolean(toggleSectionID === id || reportMode)}>
          <CardBody className='py-4'>
            <div className='d-flex flex-column flex-sm-row flex-md-row'>
              <Col className='d-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-7 col-md-8'>
                <h5 className='font-600 mb-4'>Summary</h5>
                <span>{summary || 'No information to show'}</span>
                <HowToImproveScore actionItems={actionItems} />
              </Col>
              <Col className='d-flex flex-column justify-content-between col-xs-12 col-sm-5 col-md-4 my-2 p-3 border rounded bg-white' style={{ height: 'fit-content' }}>
                <div>
                  <span className='font-600'>{shortName}</span>
                  <p className='mt-2'>{callToAction}</p>
                </div>
                {!reportMode &&
                  <div className='d-flex justify-content-center'>
                    <Button hidden color='white' size='sm' className='col-6 border-primary rounded text-primary'>
                      Watch a Video
                    </Button>
                    <Button onClick={() => onTalkToAnExpert({ sectionID: section.id })} color='primary' size='sm' className='col-6 ml-2 rounded'>
                      Talk to an Expert
                    </Button>
                  </div>}
              </Col>
            </div>
            <div className='d-flex flex-column mt-2'>
              <div className='m-2 text-uppercase'>Your Responses</div>
              {
                _.orderBy(sectionQuestions, (q) => q.questionOrder)
                  .map(questionObj => {
                    const { question, questionID, userSelectedOption, questionOptions = [] } = questionObj
                    const selectedObj = questionOptions.find(x => x.questionOptionID === userSelectedOption)

                    return (
                      <div id={`question-card-${questionID}`} key={questionID} className='d-flex p-3 border rounded bg-white mb-3 flex-column flex-sm-row flex-md-row' style={{ pageBreakInside: 'avoid' }}>
                        <Col className='d-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-4 col-md-5'>
                          <h5 className='font-600 mt-1'>{question}</h5>
                          <QuestionAnswer questionOptions={questionOptions} userSelectedOption={userSelectedOption} />
                        </Col>
                        <Col className='d-flex flex-column justify-content-start align-items-start col-xs-12 col-sm-8 col-md-7'>
                          <QuestionFeedbackBox
                            selectedObj={selectedObj}
                            questionObj={questionObj}
                            informationModal={informationModal}
                            setInformationModal={setInformationModal}
                            onTalkToAnExpert={onTalkToAnExpert}
                          />
                        </Col>
                      </div>
                    )
                  })
              }
            </div>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  )
}

export default SectionResult
