import {
  GET_INDUSTRIES, LOGOUT_USER
} from '../types'

const initialState = {
  industryList: {
    industries: [],
    totalIndustries: 0
  }
}

const industryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDUSTRIES: return {
      ...state,
      industryList: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default industryReducer
