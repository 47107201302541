import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid, Typography, Divider, Button, DialogTitle, DialogContent, DialogActions, Dialog, IconButton, useTheme, Chip,
  RadioGroup, FormControlLabel, Radio
} from '@mui/material'
import { Add, EditRounded, CloseRounded } from '@mui/icons-material'
import { BuilderSectionsTable } from './builderTools'
import { NewQuestionModal } from './builderModals'
import { getSections, postAssessmentQuestion, getSelectedAssessment, duplicateAssessment } from '../../redux/actions'
import { usePromiseTracker } from 'react-promise-tracker'
import { NotificationToast } from '../tools'
import { ValidateUrl, checkValidInputs, loaderArea, progressComponentTypes } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'

const contentIndex = {
  NEW: 0,
  COPY: 1,
  OPTIONS: 2
}

const defaultNewQuestionForm = {
  assessmentID: '',
  text: '',
  sectionID: 0,
  explanationText: '',
  helpText: '',
  questionGroupID: '',
  education: '',
  educationType: 'READ',
  questionType: 'MULTIPLE_CHOICE',
  questionOptions: []
}

// Lists the questions of an assessment group by the section's they appear in
// Rendered in Builder.js
const AssessmentSections = (props) => {
  // all props passed in from Builder.js (AssessmentBuilder)
  const {
    classes, parsedProps, editMode = false, setEditMode = () => { }, setOpenLeaveEditConfirm = () => { },
    remainOnPage, currentMaxScore = 0, fetchNewAssessmentDetails = () => { }
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const { promiseInProgress: questionsLoading } = usePromiseTracker({ area: loaderArea.ASSESSMENT_SECTOR_QUESTIONS })
  const { promiseInProgress: duplicationLoading } = usePromiseTracker({ area: loaderArea.DUPLICATE_ASSESSMENTS })
  const { selectedAssessment = {} } = useSelector(state => state.assessment)

  // check if there is a draft of the origin located in the version history and isolate the ID if applicable
  const draftAvailable = Boolean(selectedAssessment?.versionHistory?.length && selectedAssessment?.versionHistory.findIndex(x => x.isDraft) !== -1)
  const draftID = draftAvailable ? selectedAssessment?.versionHistory.find(x => x.isDraft).assessmentID : null

  // Indices that decides the current viewed component
  const [viewContentIndex, setViewContentIndex] = useState(0)

  // Add new question state, and init new question form
  const [newQuestionOpen, setNewQuestionOpen] = useState(false)
  const [newQuestionForm, setNewQuestionForm] = useState({ ...defaultNewQuestionForm })

  const [confirmEditMode, setConfirmEditMode] = useState(false)

  // Edit option content state items
  const [editOptionView, setEditOptionView] = useState(false)

  // New Question Form Error Logic
  const defaultErrors = { invalidInput: false, requiredInformation: false, failedAction: null, noQuestionOptions: false }
  const [errors, setErrors] = useState({ ...defaultErrors })
  const errorCheck = Boolean(viewContentIndex === contentIndex?.OPTIONS) &&
    (Boolean((newQuestionForm && newQuestionForm === defaultNewQuestionForm) ||
      Boolean(!newQuestionForm?.text) ||
      Boolean(newQuestionForm?.sectionID !== 0 && !newQuestionForm?.sectionID)
    ))

  // Close new Question modal an reset local state
  const handleCloseNewQuestion = () => {
    setNewQuestionOpen(false)
    setEditOptionView(false)
    setNewQuestionForm({ ...defaultNewQuestionForm })
    setViewContentIndex(contentIndex.NEW)
    setErrors({ ...defaultErrors })
  }

  // Handle populating the new question form with the information from an existing question
  const handleCopyQuestion = (props) => {
    const { questionText = '', questionGroupID = '', explanationText = '', education = '', sectionID = '', helpText = '', questionOptions = [] } = props
    setNewQuestionForm({
      ...newQuestionForm,
      text: questionText,
      sectionID,
      explanationText,
      questionGroupID,
      helpText,
      education,
      questionOptions
    })
    setViewContentIndex(contentIndex.NEW)
  }

  const handleOpenNewQuestion = () => {
    dispatch(getSections())
    setNewQuestionOpen(true)
  }

  const fireSuccess = () => {
    dispatch(getSelectedAssessment(selectedAssessment?.assessmentID))
    handleCloseNewQuestion()
  }
  const fireFailure = () => {
    NotificationToast(true, 'Unable to add new question.')
  }

  const handleAddQuestion = () => {
    const { text, sectionID, explanationText, helpText, education } = newQuestionForm
    const { assessmentID = '' } = selectedAssessment
    if (!text || !sectionID) {
      return setErrors({ ...defaultErrors, requiredInformation: true })
    }

    const inputsToValidate = { text, explanationText, helpText }

    if (!checkValidInputs({ ...inputsToValidate })) {
      setErrors({ ...defaultErrors, invalidInput: true })
      return false
    }

    if (education && !ValidateUrl(education)) { setErrors({ ...defaultErrors, invalidLink: true }); return false }

    dispatch(postAssessmentQuestion({ ...newQuestionForm, assessmentID }, fireSuccess, fireFailure))
  }

  // discard draft confirmation control
  const [confirmDiscard, setConfirmDiscard] = useState(false)

  // close the edit confirm modal
  const closeEditConfirm = () => {
    setConfirmEditMode(false)
    setConfirmDiscard(false)
  }

  // handle clicking the edit mode button
  const handleConfirmEditMode = () => {
    // if currently in edit mode, perform confirm exit actions
    if (editMode) {
      // indicate we are not trying to navigate away from the page
      remainOnPage.current = true
      // fire the confirmation modal
      setOpenLeaveEditConfirm(true)
    } else {
      // if we are already viewing a draft, simply begin editing
      if (selectedAssessment?.isDraft) {
        setEditMode(true)
      } else {
        // otherwise fire the 'entering edit mode' confirmation modal
        setConfirmEditMode(true)
      }
    }
  }

  // close confirmation modal and duplicate assessment into new version
  const fireNewVersionSuccess = (newID) => {
    closeEditConfirm()
    props.history.replace({ pathname: '/assessments/builder', search: `?assessmentID=${newID}` })
    fetchNewAssessmentDetails(newID, true)
  }

  const fireVersionFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  // handle the confirmation of moving into edit mode
  const handleEditModeConfirmed = () => {
    // if there is no current draft or the user confirmed they wanted to discard the current one, duplicate the active version to start a new draft
    if (!draftID || confirmDiscard) {
      if (Object.keys(selectedAssessment).length) {
        const { assessmentID, industryID, sectorID, originID, assessmentType } = selectedAssessment

        const dispatchForm = {
          assessmentID,
          originID,
          sectorID,
          industry: industryID,
          assessmentType,
          ...(confirmDiscard && { discardDraft: true })
        }
        return dispatch(duplicateAssessment(dispatchForm, fireNewVersionSuccess, fireVersionFailure))
      }

      // indicate an error if no selected assessment was set into redux (should not happen)
      return fireVersionFailure()
    } else if (draftID) {
      // otherwise if we DO have a draft, simply fetch those details and move into edit mode
      return fireNewVersionSuccess(draftID)
    } else {
      // if neither of the above conditions were met fire a generic failure (should not happen)
      return fireVersionFailure()
    }
  }

  return (
    <>
      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'flex-end' }}>
          {/* Section Title */}
          <Grid item xs={9} container direction='column'>
            <Grid item>
              <Typography variant='h5' style={{ fontSize: '22px' }}>Questions by Section</Typography>
            </Grid>
            <Grid item container direction='row' justifyContent='flex-start' alignItems='center' style={{ marginTop: '1em' }} spacing={1} xs={12}>
              <Grid item>
                <Typography variant='h6' sx={{ fontSize: '1.2rem' }}>Max score: </Typography>
              </Grid>
              <Grid item>
                <Chip sx={{ ...classes.maxScoreChip }} label={questionsLoading ? '--' : currentMaxScore || 0} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} container direction='row'>
            {/* 'New' Button */}
            {editMode &&
              <Grid item xs={6} container justifyContent='flex-end'>
                <Button variant='contained' startIcon={<Add />} onClick={handleOpenNewQuestion}>Add Question</Button>
              </Grid>}
            {/* 'Edit Mode' Button */}
            <Grid item xs={editMode ? 6 : null} container justifyContent='flex-end'>
              <Button
                variant={editMode ? 'outlined' : 'contained'}
                onClick={() => handleConfirmEditMode()}
                style={{ fontWeight: editMode ? 600 : 400 }}
                {...!editMode && { startIcon: <EditRounded /> }}
              >
                {editMode ? 'Exit Edit Mode' : 'Edit Assessment'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ padding: '.5em 0' }} />

        {/* Display loader when specific promise is in progress */}
        {questionsLoading &&
          <StyledLoadingIndicator
            area={loaderArea.ASSESSMENT_SECTOR_QUESTIONS}
            loaderType={progressComponentTypes.LINEAR_PROGRESS}
            style={{ height: 3 }}
          />}

        {/* Section Content */}
        <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
          <BuilderSectionsTable
            classes={classes}
            parsedProps={parsedProps}
            editMode={editMode}
          />
        </Grid>
      </Grid>

      {newQuestionOpen &&
        <NewQuestionModal
          newQuestionOpen={newQuestionOpen}
          handleAddQuestion={handleAddQuestion}
          handleCloseNewQuestion={handleCloseNewQuestion}
          viewContentIndex={viewContentIndex}
          setViewContentIndex={setViewContentIndex}
          contentIndex={contentIndex}
          newQuestionForm={newQuestionForm}
          setNewQuestionForm={setNewQuestionForm}
          classes={classes}
          defaultNewQuestionForm={defaultNewQuestionForm}
          errorCheck={errorCheck}
          errors={errors}
          handleCopyQuestion={handleCopyQuestion}
          editOptionView={editOptionView}
          setEditOptionView={setEditOptionView}
        />}

      {/* Confirmation modal to enter edit mode */}
      <Dialog open={confirmEditMode} maxWidth='sm' fullWidth onClose={() => setConfirmEditMode(false)}>
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='h5' style={{ fontWeight: 600, color: theme.palette.blue.main }}>Heads up!</Typography>
            </Grid>
            <Grid item justifyContent='flex-end'>
              <IconButton style={{ padding: '0px' }} onClick={() => setConfirmEditMode(false)}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid item container direction='column'>
            <Grid item container direction='row' style={{ marginBottom: '1em' }}>
              <Typography gutterBottom style={{ fontWeight: 600 }}>You are about to enter 'Edit Mode' for this assessment, and will temporarily lock the console into this process while changes are being made.</Typography>
            </Grid>
            <Grid item container direction='row'>
              <Typography gutterBottom variant='body2'>
                {!draftAvailable ? 'Confirming this action will generate a new draft for this assessment.' : ' There is a draft version currently available to edit. Would you like to continue editing this version, or discard it and start a new one?'}
              </Typography>
            </Grid>
            {draftAvailable &&
              <Grid item container direction='row'>
                <RadioGroup>
                  <FormControlLabel label='Discard and Start New' control={<Radio checked={confirmDiscard} onChange={() => setConfirmDiscard(true)} />} />
                  <FormControlLabel label='Continue Editing Current Draft' control={<Radio checked={!confirmDiscard} onChange={() => setConfirmDiscard(false)} />} />
                </RadioGroup>
              </Grid>}
            <Grid item container direction='row'>
              <Typography gutterBottom variant='body2'>
                You can return to edit this draft further at any time until it is published.
              </Typography>
              <Typography variant='caption' style={{ fontStyle: 'italic', marginTop: '.5em' }}>
                Note: A draft is considered inactive, and will not be seen by users until after it is both published and activated.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '1em' }}>
          <Button variant='text' style={{ color: 'black' }} onClick={() => closeEditConfirm()}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={() => handleEditModeConfirmed()}>
            {duplicationLoading ? <StyledLoadingIndicator area={loaderArea.ADMIN_ADMINISTRATION} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} /> : 'Confirm and Begin Editing'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AssessmentSections
