import {
  GET_AGREEMENTS, GET_AGREEMENT, GET_CURRENT_AGREEMENT, LOGOUT_USER
} from '../types'

const initialState = {
  agreementsList: {
    agreements: [],
    totalAgreements: 0
  },
  agreementData: {},
  currentAgreementData: {}
}

const agreementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGREEMENTS: return {
      ...state,
      agreementsList: action.payload
    }
    case GET_AGREEMENT: return {
      ...state,
      agreementData: action.payload
    }
    case GET_CURRENT_AGREEMENT: return {
      ...state,
      currentAgreementData: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default agreementsReducer
