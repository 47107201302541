import React from 'react'
import {
  Grid, Typography, OutlinedInput, useTheme
} from '@mui/material'

import { builderModalTypes } from '../../../utils'

// Form for editing a question option
// rendered in EditQuestionOrOptionModal.js
const EditOptionContent = (props) => {
  // props generated in parent component EditQuestionOrOptionModal.js
  const { form, setForm, optionCount = 1, editModalType } = props
  const theme = useTheme()

  const handleChangeQuestionOptionOrder = (val) => {
    if (editModalType === builderModalTypes.NEW_OPTION) {
      if (Number(val) > optionCount + 1) val = optionCount + 1
      if (Number(val) < 1) val = 1
    }

    setForm({ ...form, requestedOptionOrder: val || 1 })
  }

  return (
    <>
      <Grid container item direction='row' spacing={2}>
        {/*  Question Option Text */}
        <Grid item xs={7}>
          <Typography gutterBottom variant='body1'>Option<span style={{ color: 'red' }}>*</span></Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            inputProps={{
              maxLength: 100
            }}
            value={form?.text || ''}
            onChange={(e) => setForm({ ...form, text: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.text?.length || 0}/100`}</Typography>
        </Grid>
        {/* Options's score impact */}
        <Grid item xs={5}>
          <Typography gutterBottom variant='body1'>Score Impact<span style={{ color: 'red' }}>*</span></Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            type='number'
            inputProps={{ min: -100, max: 100, step: 0.01 }}
            value={form?.scoreImpact || 0}
            onChange={(e) => {
              let val = e.target.value
              if (Number(val) > 100) val = 100
              if (Number(val) < -100) val = -100
              setForm({ ...form, scoreImpact: val || 0 })
            }}
          />
        </Grid>
      </Grid>
      {/* Option Order */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>
          {editModalType === builderModalTypes.NEW_OPTION ? 'Option Order' : 'New Option Order'}
          {editModalType === builderModalTypes.NEW_OPTION ? <span style={{ color: 'red' }}>*</span> : ''}
        </Typography>
        <OutlinedInput
          // fullWidth
          margin='dense'
          size='small'
          type='number'
          style={{ width: 'fit-content' }}
          inputProps={{ min: 1, max: optionCount + 1 }}
          value={form?.requestedOptionOrder || 1}
          onChange={(e) => handleChangeQuestionOptionOrder(e.target.value)}
        />
        {editModalType === builderModalTypes.OPTION &&
          <Typography variant='body1' sx={{ marginTop: '0.5em', fontSize: '0.9em', color: theme.palette.grey.darkGrey }}>
            Current Option Order: <span style={{ fontSize: '1em', fontWeight: 600, color: theme.palette.blue.main }}>{form?.optionOrder}</span>
          </Typography>}
      </Grid>
      {/* Options's conditional feedback text */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Feedback/Commentary</Typography>
        <OutlinedInput
          fullWidth
          margin='dense'
          size='small'
          multiline
          rows={3}
          inputProps={{
            maxLength: 750
          }}
          value={form?.conditionalLanguageFB || ''}
          onChange={(e) => setForm({ ...form, conditionalLanguageFB: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.conditionalLanguageFB?.length || 0}/100`}</Typography>
      </Grid>
      {/* Options conditional action text */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Action to Take</Typography>
        <OutlinedInput
          fullWidth
          multiline
          rows={3}
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 750
          }}
          value={form?.conditionalLanguageAT || ''}
          onChange={(e) => setForm({ ...form, conditionalLanguageAT: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${form?.conditionalLanguageAT?.length || 0}/100`}</Typography>
      </Grid>
    </>
  )
}

export default EditOptionContent
