import React from 'react'
import { Progress } from 'reactstrap'
import { utils } from '../../utils/utils'

const ProgressBar = (props) => {
  const { answered = 0, totalQuestions = 0 } = props

  const progress = Math.round(answered / totalQuestions * 100)

  return (
    <>
      <div className='bg-tertiary d-flex align-items-center col-12 progress-container'>
        <Progress color='success' className='col-10 offset-1 px-0 border' value={progress}>
          {utils.formatPercent(answered, totalQuestions)}
        </Progress>
      </div>
    </>
  )
}

export default ProgressBar
