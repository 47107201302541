import React, { useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import {
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert
} from 'reactstrap'

import Loader from '../../../components/Loader'

import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { resetPassword } from '../../../redux/actions'

const formFeedbackMsgs = {
  MISSING_REQS: {
    alertType: 'warning',
    msg: 'Please provide all information.'
  },
  MISSING_TOKEN: {
    alertType: 'warning',
    msg: 'Oh no! It looks like there is no token to complete this request. Please try clicking on the link in your email again.'
  },
  DO_NOT_MATCH: {
    alertType: 'warning',
    msg: 'The fields do not match. Please re-enter the information and try again.'
  },
  GEN_ERROR: {
    alertType: 'danger',
    msg: 'Oh no! Something went wrong with your request. Please try again later or contact FoundersIB for assistance.'
  },
  SUCCESS: {
    alertType: 'success',
    msg: 'Success! Your password has now been reset. You can now login with your new credentials.'
  }
}

const defaultForm = {
  newPassword: '',
  confirmPassword: ''
}

export const NewPasswordForm = (props) => {
  const { token } = props
  const { promiseInProgress } = usePromiseTracker()
  const dispatch = useDispatch()

  const [localData, setLocalData] = useState({ ...defaultForm })

  const [feedbackMsg, setFeedbackMsg] = useState(null)

  const fireFailure = (res) => {
    const { message } = res
    setFeedbackMsg({ ...formFeedbackMsgs.GEN_ERROR, msg: message || formFeedbackMsgs.GEN_ERROR.msg })
  }

  const fireSuccess = () => {
    setFeedbackMsg(formFeedbackMsgs.SUCCESS)
    setLocalData({ ...defaultForm })
  }

  const handleResetPassword = () => {
    const { newPassword = '', confirmPassword = '' } = { ...localData }

    if (!newPassword || !confirmPassword) { setFeedbackMsg(formFeedbackMsgs.MISSING_REQS); return false }
    if (!isEqual(newPassword, confirmPassword)) { setFeedbackMsg(formFeedbackMsgs.DO_NOT_MATCH); return false }
    if (!token) { setFeedbackMsg(formFeedbackMsgs.MISSING_TOKEN); return false }

    dispatch(resetPassword({ token, newPassword }, fireSuccess, fireFailure))

    setFeedbackMsg(null)
    return false
  }

  const disableSubmitButton = Boolean(!localData?.newPassword || !localData.confirmPassword || feedbackMsg?.alertType === 'success')

  return (
    <Col className='col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 px-xl-5 flex-grow-1 d-flex flex-column justify-content-center'>
      <div className='text-center'>
        <h3>Investment Grade Assessment</h3>
      </div>
      <Card>
        <CardBody className='px-0'>
          <Form>
            <FormGroup>
              <Label className='font-600'>Password</Label>
              <Input
                required
                maxLength='50'
                bsSize='lg'
                value={localData?.newPassword || ''}
                type='password'
                name='new-password'
                autoComplete='new-password'
                placeholder='Enter your password'
                onChange={(event) =>
                  setLocalData({
                    ...localData,
                    newPassword: event.currentTarget.value
                  })}
              />
            </FormGroup>
            <FormGroup>
              <Label className='font-600'>Confirm Password</Label>
              <Input
                required
                maxLength='50'
                bsSize='lg'
                type='password'
                name='confirm-password'
                autoComplete='new-password'
                placeholder='Enter your password'
                value={localData?.confirmPassword || ''}
                onChange={(event) =>
                  setLocalData({
                    ...localData,
                    confirmPassword: event.currentTarget.value
                  })}
              />
              <div className='d-flex justify-content-end'>
                <small>
                  <Link to='/auth/sign-in'>
                    Do you remember the password? Sign In!
                  </Link>
                </small>
              </div>
            </FormGroup>
            <div className='text-center mt-3'>
              <UncontrolledAlert
                isOpen={Boolean(feedbackMsg)}
                toggle={() => { setFeedbackMsg(null); setLocalData({ ...defaultForm }) }}
                color={feedbackMsg?.alertType || 'light'}
              >
                <div className='alert-icon'>
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className='alert-message text-left'>
                  <span className=''>{feedbackMsg?.msg || ''}</span>
                </div>
              </UncontrolledAlert>
              {promiseInProgress
                ? (
                  <Loader size='sm' />
                  )
                : (
                  <Button color='primary' disabled={disableSubmitButton} style={{ cursor: disableSubmitButton ? 'default' : 'pointer' }} onClick={handleResetPassword} size='lg'>
                    Change Password
                  </Button>
                  )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  )
}
