import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { DateTime } from 'luxon'
import {
  Grid, Typography, Divider, Button, useTheme, TableContainer, TableBody, TableRow,
  TableCell, Table, IconButton, Collapse, OutlinedInput,
  Pagination, Select, MenuItem, useMediaQuery
} from '@mui/material'
import { Add, KeyboardArrowUp, KeyboardArrowDown, EditRounded, SearchRounded } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { adminSideMenuTabs, validateEmail, loaderArea, progressComponentTypes } from '../../../utils'
import { StyledTableRow, StyledLoadingIndicator } from '../../../utils/custom'
import { getPartners, createPartner, updatePartner, getPartnerUsers, updatePartnerUser, createPartnerUser, getPartnerUserCompanies } from '../../../redux/actions'
import { PartnerUserTable, CompanyUsersModal, AddEditPartnerModal } from '../sectionTools'
import { DuplicateUserToast } from '../../tools'

// Mapping object used to determine which dialog content to render
const partnerModal = {
  PARTNER: 'partner',
  PARTNER_USER: 'partnerUser'
}

// Init the add partner form
const defaultPartnerForm = {
  partnerName: '',
  defaultDiscount: 0
}

// Init the add partner user form
const defaultPartnerUserForm = {
  firstName: '',
  lastName: '',
  email: ''
}

// Specific local classnames
const useStyles = (theme) => ({
  tableRowStyling: {
    backgroundColor: theme.palette.grey.lightestGrey
  },
  tableCellFontStyling: {
    fontSize: '19px'
  }
})

const PartnerAdministration = (props) => {
  const {
    partners = [], totalPartners = 0, filter = {}, setFilter = () => { }, defaultFilter = {},
    currentTab = '', classes = {}
  } = props

  const theme = useTheme()
  const localClasses = useStyles(theme)
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.PARTNER_ADMINISTRATION })
  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))

  // Redux State
  const {
    partnerUserList: { partnerUsers = [], totalPartnerUsers = 0 },
    partnerUserCompanies: { companyUsers = [], totalCompanyUsers = 0 }
  } = useSelector(state => state.partner)

  // ******************** Partner Data Creation **************** //
  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.PARTNERS.tab) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getPartners(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, currentTab])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (partners) {
      if (partners.length) {
        const newRows = []
        partners.forEach(admin => {
          const { partnerID, partnerName, defaultDiscount, createdAt, updatedAt } = admin
          newRows.push({ partnerID, partnerName, defaultDiscount, createdAt, updatedAt })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [partners])

  // ********************* Partner Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalPartners - page * rowsPerPage)

  // ********************** Add/Edit Partner/Partner User Modal ************* //
  // Add edit partner/partnerUser Modal controls
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [editPartnerID, setEditPartnerID] = useState(null)
  const [partnerForm, setPartnerForm] = useState(defaultPartnerForm)
  const [editPartnerUserID, setEditPartnerUserID] = useState(null)
  const [partnerUserForm, setPartnerUserForm] = useState(defaultPartnerUserForm)

  // Close the partner/user modal and reset state
  const handleAddEditModalClose = () => {
    setErrors({ ...defaultErrors })
    setModalContent(null)
    setPartnerForm(defaultPartnerForm)
    setPartnerUserForm(defaultPartnerUserForm)
    setEditPartnerID(null)
    setEditPartnerUserID(null)
    setModalOpen(false)
  }

  // Success of partner add/update: close modal and reset filter.
  const fireSuccess = () => {
    handleAddEditModalClose()
    setFilter({ ...defaultFilter })
    setPage(0)
  }

  // Error logic for partner/partneruser add/edit
  const defaultErrors = { failedAction: null, invalidEmail: false }
  const [errors, setErrors] = useState({ ...defaultErrors })

  // assign the correct action to send to the confirm-plus-address toast
  const triggerPlusAddressPop = (duplicateUser) => {
    // attach the duplicate user ID returned from the api to the information
    const info = { duplicateUser, ...partnerUserForm }
    const action = () => dispatch(createPartnerUser(expandPartner, info, firePuSuccess, fireFailure))
    DuplicateUserToast(action)
  }

  const fireFailure = (response) => {
    let res = 'Unable to perform action.'
    if (response) {
      const { message = '' } = response
      res = message || 'Unable to perform action.'

      // if we receive a specific 409 back from the api, trigger the confirmation to plus-address the original user.
      if (response.duplicateUser) {
        return triggerPlusAddressPop(response.duplicateUser)
      }
    }
    setErrors({ ...defaultErrors, failedAction: res })
  }

  // ****** add/edit partner logic
  // Basic Partner Open Modal Logic
  const handleAddPartnerOpen = () => {
    setModalContent(partnerModal.PARTNER)
    setModalOpen(true)
  }

  // Edit Partner Open Modal logic
  const handleEditPartnerOpen = (partnerInfo) => {
    const { partnerID, partnerName, defaultDiscount } = partnerInfo

    // Format the discount for display locally
    const formatDiscount = defaultDiscount * 100

    setPartnerForm({
      partnerName,
      defaultDiscount: formatDiscount
    })

    setEditPartnerID(partnerID)
    setModalContent(partnerModal.PARTNER)
    setModalOpen(true)
  }

  // Handle save action of adding or updating a partner
  const handleSavePartner = () => {
    const { partnerName = '', defaultDiscount = 0 } = partnerForm

    // Format the discount for the sql table
    const formatDiscount = defaultDiscount * 0.01

    const partnerInfo = { partnerName, defaultDiscount: formatDiscount }

    if (editPartnerID) {
      dispatch(updatePartner(editPartnerID, partnerInfo, fireSuccess, fireFailure))
    } else {
      dispatch(createPartner(partnerInfo, fireSuccess, fireFailure))
    }
  }

  // ******* add/edit partner user logic

  // Basic Partner User Open Modal Logic
  const handleAddPartnerUser = () => {
    setModalContent(partnerModal.PARTNER_USER)
    setModalOpen(true)
  }

  // Edit Partner User
  const handleEditPartnerUser = (partnerUserInfo) => {
    const { partnerUserID, firstName, lastName, email } = partnerUserInfo
    setPartnerUserForm({
      firstName,
      lastName,
      email
    })
    setEditPartnerUserID(partnerUserID)
    setModalContent(partnerModal.PARTNER_USER)
    setModalOpen(true)
  }

  // Fire success specific to partner users
  const firePuSuccess = () => {
    dispatch(getPartnerUsers(expandPartner, puFilter))
    handleAddEditModalClose()
  }

  // Save partneruser add/edit
  const handleSavePartnerUser = () => {
    const { email } = partnerUserForm

    if (!validateEmail(email)) {
      setErrors({ ...errors, invalidEmail: true })
      return false
    }

    if (editPartnerUserID) {
      dispatch(updatePartnerUser({ partnerID: expandPartner, userID: editPartnerUserID, ...partnerUserForm }, firePuSuccess, fireFailure))
    } else {
      dispatch(createPartnerUser(expandPartner, partnerUserForm, firePuSuccess, fireFailure))
    }
  }

  // ********************** Partner Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  // Handle Partner Search
  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        search: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ******************** PartnerUser Expansion Logic ************* //

  // The expanded partner row
  const [expandPartner, setExpandPartner] = useState(null)

  // Init specific pagination filter for Partner Users
  const defaultPartnerUserFilter = useMemo(() => ({
    page: 1,
    sortCount: 10,
    direction: '',
    sortBy: '',
    search: ''
  }), [])

  // Partner User State Items
  const [puFilter, setPuFilter] = useState({ ...defaultPartnerUserFilter })
  const [localPartnerUsers, setLocalPartnerUsers] = useState([])
  const [totalPu, setTotalPu] = useState(0)

  // Ref for partner user pagination filter to help control re-renders
  const tempPuFilter = useRef({ ...puFilter })

  const fetchParterUsers = useCallback(async () => {
    await dispatch(getPartnerUsers(expandPartner, puFilter))
  }, [dispatch, expandPartner, puFilter])

  useMemo(() => {
    if (puFilter && tempPuFilter.current) {
      if (!isEqual(puFilter, tempPuFilter.current)) {
        tempPuFilter.current = { ...puFilter }
        fetchParterUsers()
      }
    }
  }, [puFilter, fetchParterUsers])

  useMemo(() => {
    if (partnerUsers && !isEqual(localPartnerUsers, partnerUsers) && expandPartner) {
      setLocalPartnerUsers(partnerUsers)
      setTotalPu(totalPartnerUsers)
    }
  }, [partnerUsers, localPartnerUsers, totalPartnerUsers, expandPartner])

  // Reset when row closes
  const closeRow = () => {
    setLocalPartnerUsers([])
    setTotalPu(0)
    setPuFilter({ ...defaultPartnerUserFilter })
    tempPuFilter.current = { ...defaultPartnerUserFilter }
  }

  // Handle Partner row expansion
  const handlePartnerExpand = (partnerID) => {
    if (!expandPartner) {
      setExpandPartner(partnerID)
      dispatch(getPartnerUsers(partnerID, puFilter))
    } else {
      closeRow()
      setExpandPartner(null)
    }
  }

  // ********* PU's Company User logic *********

  // Init specific pagination filter for Companu Users
  const defaultCompanyUserFilter = useMemo(() => ({
    page: 1,
    sortCount: 10,
    direction: '',
    sortBy: '',
    search: ''
  }), [])

  // Company User and Company User Modal Logic
  const [userFilter, setUserFilter] = useState({ ...defaultCompanyUserFilter })
  const [usersModalOpen, setUsersModalOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)

  // Ref for the current partner user (helps to control re-renders)
  const partnerUserIDRef = useRef(null)
  // Ref for the company user pagination filter (helps to control re-renders)
  const tempCompUserFilter = useRef({ ...userFilter })

  const handleUserModalOpen = (id) => {
    partnerUserIDRef.current = id
    dispatch(getPartnerUserCompanies(expandPartner, id, {}, () => { setUsersModalOpen(true) }))
  }

  const fetchParterUserCompanies = useCallback(async () => {
    if (expandPartner && partnerUserIDRef.current) {
      await dispatch(getPartnerUserCompanies(expandPartner, partnerUserIDRef.current, userFilter))
    }
  }, [dispatch, expandPartner, partnerUserIDRef, userFilter])

  useEffect(() => {
    if (userFilter && tempCompUserFilter.current) {
      if (!isEqual(userFilter, tempCompUserFilter.current)) {
        tempCompUserFilter.current = { ...userFilter }
        fetchParterUserCompanies()
      }
    }
  }, [dispatch, userFilter, fetchParterUserCompanies, tempCompUserFilter])

  // Checks the returned array from the dispatch against the local storage and user companies if they do not match
  useMemo(() => {
    if (usersModalOpen && users && companyUsers && !isEqual(users, companyUsers)) {
      setUsers(companyUsers)
      setTotalUsers(totalCompanyUsers)
    }
  }, [usersModalOpen, users, companyUsers, totalCompanyUsers])

  const handleUsersModalClose = () => {
    setUsersModalOpen(false)
    partnerUserIDRef.current = null
    tempCompUserFilter.current = { ...defaultCompanyUserFilter }
    setUserFilter({ ...defaultCompanyUserFilter })
    setUsers([])
    setTotalUsers(0)
  }

  // Collapsible partner row component
  const PartnerRow = (props) => {
    const { row } = props
    const { partnerID, partnerName, createdAt, defaultDiscount } = row
    const formatDate = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)
    const formatDiscount = defaultDiscount * 100

    return (
      <>
        {/* Partner row */}
        <TableRow sx={{ ...localClasses.tableRowStyling }}>
          <TableCell style={{ border: 'none' }} component='th' scope='row' align='left'>
            <Typography variant='body2' color={theme.palette.grey.darkerGrey}>Partner Name:</Typography>
            <Typography variant='body1' fontWeight={400} sx={{ ...localClasses.tableCellFontStyling }} color={theme.palette.grey.darkestGrey}>{partnerName}</Typography>
          </TableCell>
          <TableCell style={{ border: 'none' }} align='left'>
            <Typography variant='body2' color={theme.palette.grey.darkerGrey}>Date Added:</Typography>
            <Typography variant='body1' fontWeight={400} sx={{ ...localClasses.tableCellFontStyling }} color={theme.palette.grey.darkestGrey}>{formatDate}</Typography>
          </TableCell>
          <TableCell style={{ border: 'none' }} align='left'>
            <Typography variant='body2' color={theme.palette.grey.darkerGrey}>Default Discount:</Typography>
            <Typography variant='body1' fontWeight={400} sx={{ ...localClasses.tableCellFontStyling }} color={theme.palette.grey.darkestGrey}>{`${formatDiscount}%`}</Typography>
          </TableCell>
          <TableCell style={{ border: 'none' }} align='right'>
            <Grid item container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <Button
                  style={{
                    minWidth: 0,
                    width: '18px',
                    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                    backgroundColor: editPartnerID === partnerID ? theme?.palette?.grey?.lighterGrey : 'white'
                  }}
                  onClick={() => handleEditPartnerOpen(row)}
                >
                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => handlePartnerExpand(partnerID)}
                >
                  {expandPartner === partnerID ? <KeyboardArrowUp fontSize='large' /> : <KeyboardArrowDown fontSize='large' />}
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        {/* Collapsible partner user row */}
        <TableRow sx={{ ...localClasses.tableRowStyling, borderBottom: 'solid white 12px' }}>
          <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
            <Collapse in={Boolean(expandPartner === partnerID)} timeout='auto' unmountOnExit>
              <PartnerUserTable
                classes={classes}
                puFilter={puFilter}
                setPuFilter={setPuFilter}
                handleAddPartnerUser={handleAddPartnerUser}
                handleEditPartnerUser={handleEditPartnerUser}
                totalPu={totalPu}
                localPartnerUsers={localPartnerUsers}
                handleUserModalOpen={handleUserModalOpen}
                partnerUserID={partnerUserIDRef}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  // MAIN PARTNER COMPONENT RETURN
  return (
    <>
      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' alignItems='center'>
          {/* Section Title */}
          <Grid item xs={6} container>
            <Typography variant='h5'>Partners</Typography>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={3} container justifyContent='flex-end'>
            <OutlinedInput
              style={{ width: '100%' }}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
              }
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>

          {/* 'New' Button */}
          <Grid item xs={3} container justifyContent='center'>
            <Button variant='contained' startIcon={<Add />} onClick={() => handleAddPartnerOpen()}>New Partner</Button>
          </Grid>
        </Grid>

        <Divider style={{ padding: '.5em 0' }} />

        {/* Section Content */}
        <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ paddingTop: '1em' }}>
          <TableContainer elevation={0}>
            <Table aria-label='collapsible table'>
              <TableBody>
                {/* Table Rows */}
                {promiseInProgress
                  ? (
                    <StyledTableRow style={{ height: '10em' }}>
                      <TableCell colSpan={6}>
                        <StyledLoadingIndicator area={loaderArea.PARTNER_ADMINISTRATION} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                      </TableCell>
                    </StyledTableRow>)
                  : (
                    <>
                      {rows.map((row) => (
                        <PartnerRow row={row} key={row.partnerID} />
                      ))}
                      {/* Data Array has reached it's length: */}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 10 }}>
                          <TableCell style={{ border: 'none', padding: '0.3rem 0' }} colSpan={6}>
                            <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                    )}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        {/* Pagination */}
        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalPartners ? Math.ceil(totalPartners / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Add/Edit Partner && Partner User */}
      <AddEditPartnerModal
        modalOpen={modalOpen}
        handleAddEditModalClose={handleAddEditModalClose}
        modalContent={modalContent}
        partnerModal={partnerModal}
        editPartnerID={editPartnerID}
        editPartnerUserID={editPartnerUserID}
        errors={errors}
        handleSavePartner={handleSavePartner}
        handleSavePartnerUser={handleSavePartnerUser}
        partnerUserForm={partnerUserForm}
        setPartnerUserForm={setPartnerUserForm}
        partnerForm={partnerForm}
        setPartnerForm={setPartnerForm}
        defaultPartnerUserForm={defaultPartnerUserForm}
      />

      {/* Company Users modal */}
      <CompanyUsersModal
        usersModalOpen={usersModalOpen}
        handleUsersModalClose={handleUsersModalClose}
        smScreenDown={smScreenDown}
        users={users}
        totalUsers={totalUsers}
        userFilter={userFilter}
        setUserFilter={setUserFilter}
        classes={classes}
      />
    </>
  )
}

export default PartnerAdministration
