import React from 'react'
import {
  DialogActions, Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton,
  Button, Tooltip, OutlinedInput
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

const AddEditPartnerModal = (props) => {
  const {
    modalOpen = false, handleAddEditModalClose = () => { }, modalContent = '', partnerModal = {},
    editPartnerID = null, editPartnerUserID = null, errors = {}, handleSavePartner = () => { },
    handleSavePartnerUser = () => { }, partnerUserForm = {}, setPartnerUserForm = () => { }, partnerForm = {}, setPartnerForm = () => { },
    defaultPartnerUserForm = {}
  } = props

  const { promiseInProgress: partnerPromiseInProgress } = usePromiseTracker({ area: loaderArea.PARTNER_ADMINISTRATION })
  const { promiseInProgress: partnerUserPromiseInProgress } = usePromiseTracker({ area: loaderArea.PARTNER_USER_ADMINISTRATION })

  const handleModalContent = (type) => {
    switch (type) {
      case partnerModal.PARTNER_USER: {
        return (
          <>
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>First Name</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={partnerUserForm?.firstName || ''}
                onChange={(e) => setPartnerUserForm({ ...partnerUserForm, firstName: e.target.value })}
              />
            </Grid>

            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Last Name</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={partnerUserForm?.lastName || ''}
                onChange={(e) => setPartnerUserForm({ ...partnerUserForm, lastName: e.target.value })}
              />
            </Grid>

            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Email</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={partnerUserForm?.email || ''}
                onChange={(e) => setPartnerUserForm({ ...partnerUserForm, email: e.target.value })}
              />
            </Grid>
          </>
        )
      }
      case partnerModal.PARTNER: {
        return (
          <>
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Partner Name</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={partnerForm?.partnerName || ''}
                onChange={(e) => setPartnerForm({ ...partnerForm, partnerName: e.target.value })}
              />
            </Grid>

            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Default Discount (%)</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                type='number'
                inputProps={{ min: 0, max: 100 }}
                size='small'
                value={partnerForm?.defaultDiscount || 0}
                onChange={(e) => setPartnerForm({ ...partnerForm, defaultDiscount: e.target.value })}
              />
            </Grid>
          </>
        )
      }
      default: {
        return <Typography>Unable to load information. Please close the modal and try again.</Typography>
      }
    }
  }

  const disableSaveButton = modalContent === partnerModal.PARTNER
    ? Boolean(partnerForm && !partnerForm?.partnerName)
    : Boolean((partnerUserForm && partnerUserForm === defaultPartnerUserForm) || (!partnerUserForm?.firstName || !partnerUserForm?.lastName || !partnerUserForm?.email))

  return (
    <Dialog
      open={modalOpen}
      onClose={() => handleAddEditModalClose()}
      fullWidth
      maxWidth='xs'
      style={{ zIndex: 1060 }}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h5'
            >
              {modalContent === partnerModal?.PARTNER ? `${!editPartnerID ? 'Add' : 'Edit'} Partner` : `${!editPartnerUserID ? 'Add' : 'Edit'} Partner User`}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => handleAddEditModalClose()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' spacing={2}>
          {handleModalContent(modalContent)}
        </Grid>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Grid container direction='column'>
          {errors?.failedAction &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>}
          {errors?.invalidEmail &&
            <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>'* Invalid email provided.</Typography>}
          <Grid container item direction='row' justifyContent='space-between'>
            <Button
              variant='outlined'
              color='primary'
              style={{
                margin: '.5em 1em',
                fontWeight: '600'
              }}
              onClick={() => handleAddEditModalClose()}
            >
              Cancel
            </Button>

            <Tooltip arrow placement='top' title={disableSaveButton ? 'Missing required information.' : ''}>
              <span>
                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600'
                  }}
                  disabled={disableSaveButton}
                  onClick={modalContent === partnerModal.PARTNER ? () => handleSavePartner() : () => handleSavePartnerUser()}
                >
                  {partnerPromiseInProgress || partnerUserPromiseInProgress
                    ? <StyledLoadingIndicator area={modalContent === partnerModal.PARTNER ? loaderArea.PARTNER_ADMINISTRATION : loaderArea.PARTNER_USER_ADMINISTRATION} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                    : 'Save'}
                </Button>
              </span>
            </Tooltip>

          </Grid>
        </Grid>
      </DialogActions>

    </Dialog>
  )
}

export default AddEditPartnerModal
