import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel,
  Button, Typography, useTheme, Tooltip, IconButton
} from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { StyledTableRow, assessmentTypeLabels } from '../../../utils'
import { NotificationToast } from '../../tools'
import { ActivateAssessmentModal } from '../builderModals'
import { updateActiveAssessmentStatus } from '../../../redux/actions'

const headCells = [
  { id: 'isActive', label: '' },
  { id: 'title', label: 'Title' },
  { id: 'assessmentType', label: 'Type' },
  { id: 'sector', label: 'Sector' },
  { id: 'industry', label: 'Industry' },
  { id: 'questionCount', label: 'Questions' }
]

// Table listing the assessment rows
// Rendered in AssessmentsList.js
const BuilderAssessmentsTable = (props) => {
  const {
    // Props from parent AssessmentList.js
    rows = [],
    emptyRows = 0,
    handleRequestSort = () => { },
    order,
    orderBy,
    // Props from top parent Builder.js
    setAssessmentListFilter = () => { },
    defaultAssessmentListFilter = {},
    handleViewQuestions = () => { }
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const isActiveID = useRef(null)
  // Confirm popper related elements --- //
  const [isActiveAnchorEl, setisActiveAnchorEl] = useState(null)

  const handleisActivePopover = (e, assID, activeStat, secID) => {
    setisActiveAnchorEl(e.currentTarget)
    isActiveID.current = { assessmentID: assID, isActive: activeStat, sectorID: secID }
  }

  const handlePopoverClose = () => {
    setisActiveAnchorEl(null)
    isActiveID.current = null
  }

  const openConfirmModal = Boolean(isActiveAnchorEl)

  const fireActivateSuccess = () => {
    setAssessmentListFilter({
      ...defaultAssessmentListFilter,
      page: 1,
      sortCount: 10
    })
  }

  const fireActivateFailure = () => {
    NotificationToast(true, 'Unable to update active status of assessment.')
  }

  const handleAssessmentActiveStatus = () => {
    const { isActive: currentStat } = isActiveID.current
    const dispatchInfo = { ...isActiveID.current, isActive: currentStat ? 0 : 1 }
    dispatch(updateActiveAssessmentStatus(dispatchInfo, fireActivateSuccess, fireActivateFailure))
    handlePopoverClose()
  }

  return (
    <>
      <TableContainer elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(header => {
                return (
                  <TableCell
                    key={header.id}
                    align='left'
                    sortDirection={orderBy === header?.id ? 'asc' : false}
                  >
                    <TableSortLabel
                      active={orderBy === header?.id}
                      direction={orderBy === header?.id ? order : 'asc'}
                      onClick={() => handleRequestSort(header?.id)}
                    >
                      <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                    </TableSortLabel>
                  </TableCell>
                )
              })}
              <TableCell align='left' style={{ padding: '16px' }} />
              <TableCell align='left' style={{ padding: '16px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const { isActive = 0, isDraft = 0, assessmentID = '', sectorID, sectorName = '', assessmentTitle = '', assessmentType = '', industryName = '', questionCount = 0, activeIndustry } = row

              return (
                <StyledTableRow key={`assessment-row-${assessmentID}`}>
                  <TableCell align='left'>
                    {!isActive
                      ? (
                        <Tooltip title={isDraft ? 'Draft' : 'Inactive'} arrow>
                          <span>
                            <IconButton size='small' disabled={Boolean(isDraft)} onClick={(e) => handleisActivePopover(e, assessmentID, isActive, sectorID)}>
                              <VisibilityOff style={{ fontSize: '16px' }} />
                            </IconButton>
                          </span>
                        </Tooltip>)
                      : (
                        <Tooltip title='Active' arrow>
                          <IconButton size='small' onClick={(e) => handleisActivePopover(e, assessmentID, isActive, sectorID)}>
                            <Visibility style={{ fontSize: '16px' }} />
                          </IconButton>
                        </Tooltip>)}
                  </TableCell>
                  <TableCell align='left'>{assessmentTitle || '-'}</TableCell>
                  <TableCell align='left'>{assessmentType ? assessmentTypeLabels[assessmentType] : '-'}</TableCell>
                  <TableCell align='left'>{sectorName || '-'}</TableCell>
                  <TableCell align='left'>{activeIndustry ? industryName : <span style={{ color: 'grey' }}>{industryName} (inactive)</span>}</TableCell>
                  <TableCell align='left'>{questionCount || '-'}</TableCell>
                  <TableCell align='center'>
                    <Button
                      variant='contained'
                      style={{ width: 'max-content' }}
                      onClick={() => { handleViewQuestions(row) }}
                    >
                      View Questions
                    </Button>
                  </TableCell>
                </StyledTableRow>
              )
            })}
            {/* Data Array has reached it's length: */}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 10 }}>
                <TableCell colSpan={6}>
                  <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ActivateAssessmentModal
        openConfirmModal={openConfirmModal}
        isActiveAnchorEl={isActiveAnchorEl}
        isActiveID={isActiveID}
        handlePopoverClose={handlePopoverClose}
        handleAssessmentActiveStatus={handleAssessmentActiveStatus}
      />
    </>
  )
}

export default BuilderAssessmentsTable
