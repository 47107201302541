import React from 'react'
import { NewPasswordForm } from '../newPassword/NewPasswordForm'
import { ResetPasswordForm } from '../resetPassword/ResetPasswordForm'

const ResetPassword = ({ match }) => {
  const { token = '' } = match?.params

  return token ? <NewPasswordForm token={token} /> : <ResetPasswordForm />
}

export default ResetPassword
