import React from 'react'
import Routes from './routes/Routes'
import LogRocket from 'logrocket'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from '../src/redux/store'

class App extends React.Component {
  render () {
    const { store, persistor } = configureStore()

    // Initialize LogRocket
    if (process.env.REACT_APP_LOGROCKET_ID) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
    }

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    )
  }
}

export default App
