import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid, Button, Typography, Divider, TextField,
  Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableSortLabel,
  Tabs, Tab, Select, Pagination, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions,
  IconButton, OutlinedInput, Collapse, Box,
  useTheme, InputAdornment, Tooltip
} from '@mui/material'
import { Add, EditRounded, EmailRounded, CloseRounded, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

import { StyledTableRow, adminSideMenuTabs, discountCodeTabTypes, loaderArea, progressComponentTypes, assessmentTypes, validateEmail } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { NotificationToast } from '../../../components/tools'

import {
  generateDiscountCode, getDiscountCodes, updateDiscountCode, emailDiscountCode, getPartners,
  getPartnerDiscountCodes, updatePartner, getPartnerUserDiscountCodes, getPartnerUsers, generateDiscountCodeLink, getActiveAssessmentList
} from '../../../redux/actions'

import { usePromiseTracker } from 'react-promise-tracker'

import { isEqual } from 'lodash'
import { DateTime } from 'luxon'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { responseTypes } from '../../../lib/response'

import moment from 'moment'

// Specific local classnames
const useStyles = (theme) => ({
  tabs: {
    color: '#5280AA !important'
  },
  tabIndicator: {
    backgroundColor: '#5280AA'
  },
  activeTabStyle: {
    fontWeight: 'bold',
    textTransform: 'none'
  },
  inactiveTabStyle: {
    textTransform: 'none'
  }
})

const codeTypes = {
  EMAIL: 'email',
  LINK: 'link'
}

const defaultDiscountErrors = {
  missingFormElements: false,
  generalError: false,
  errorMessage: ''
}

const activeAssessmentFilter = {
  groupType: 'grouped',
  industryID: '',
  sectorID: ''
}

const DiscountCodeAdministration = (props) => {
  const {
    classes, adminUser = false, currentTab = '',
    discountCodes = [], totalDiscountCodes = 0,
    filter = {}, setFilter = () => { }, defaultFilter = {}
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const localClasses = useStyles(theme)

  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ADMIN_DISCOUNTS })

  const { industryList: { industries = [] } } = useSelector(state => state.industry)

  // This is required for the tabs to properly set
  const [value, setValue] = useState(0)
  const [discountCodeTab, setDiscountCodeTab] = useState(discountCodeTabTypes.DISCOUNT_CODES)
  const [discountErrors, setDiscountErrors] = useState({ ...defaultDiscountErrors })

  const { partnerList: { partners: partnerData = [], totalPartners: partnerCount = 0 } } = useSelector(state => state.partner)

  const { userID = '' } = useSelector(state => state.auth)

  const [discountPartners, setDiscountPartners] = useState([])
  const [totalDiscountPartners, setTotalDiscountPartners] = useState(0)

  const partnerHeadCells = [
    {
      id: 'partner',
      label: 'Partner'
    },
    {
      id: 'defaultDiscount',
      label: 'Default Discount'
    }
  ]

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && currentTab === adminSideMenuTabs.CODES.tab && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES && adminUser) {
      dispatch(getPartners(filter))
    }
  }, [dispatch, currentTab, filter, defaultFilter, discountCodeTab, adminUser])

  // Checks the returned array from the dispatch against the local storage and resets agreements if they do not match
  useMemo(() => {
    if (discountPartners && partnerData && !isEqual(discountPartners, partnerData) && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES && adminUser) {
      setDiscountPartners(partnerData)
      setTotalDiscountPartners(partnerCount)
    }
  }, [discountPartners, partnerData, partnerCount, discountCodeTab, adminUser])

  const [partnerRows, setPartnerRows] = useState([])

  // ********************* Partner Pagination Logic (Start) **************** //
  const [rowsPerPagePartner, setRowsPerPagePartner] = useState(10)
  const [pagePartner, setPagePartner] = useState(0)

  const handleChangePagePartner = (event, value) => {
    if (value >= 0) {
      setPagePartner(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPagePartner,
        page: value
      })
    }
  }

  const handleChangeRowsPerPagePartner = (event) => {
    setRowsPerPagePartner(event.target.value)
    setPagePartner(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRowsPartner = rowsPerPagePartner - Math.min(rowsPerPagePartner, totalDiscountPartners - pagePartner * rowsPerPagePartner)
  // ********************* Partner Pagination Logic (End) **************** //

  // ******************** Partner Column Sort Logic (Start) **************** //
  const [orderByPartner, setOrderByPartner] = useState('')
  const [orderPartner, setOrderPartner] = useState('desc')

  const handleRequestSortPartner = (property) => {
    const isAsc = orderByPartner === property && orderPartner === 'asc'
    setOrderPartner(isAsc ? 'desc' : 'asc')
    setOrderByPartner(property)
    setPagePartner(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPagePartner,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }
  // ******************** Partner Column Sort Logic (End) **************** //

  const [partnerDiscountCodes, setPartnerDiscountCodes] = useState([])

  const handleOpenDiscountPartnerCodes = async (partnerID) => {
    if (discountPartnerExpanded !== partnerID) {
      const getCodes = await dispatch(getPartnerDiscountCodes(partnerID))

      if (getCodes) { setPartnerDiscountCodes(getCodes) }
    } else {
      setPartnerDiscountCodes([])
    }
    setDiscountPartnerExpanded(discountPartnerExpanded === partnerID ? null : partnerID)
  }

  useEffect(() => {
    if (discountPartners && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES && adminUser) {
      if (discountPartners.length) {
        const newRows = []
        discountPartners.forEach(partner => {
          const { partnerID, partnerName, defaultDiscount, createdAt, userCount = 0 } = partner
          newRows.push({ partnerID, partnerName, defaultDiscount, createdAt, userCount })
        })
        setPartnerRows(newRows)
      } else {
        setPartnerRows([])
      }
    }
  }, [discountPartners, discountCodeTab, adminUser])

  // ******************** Data Creation **************** //
  // If the filter was changed, fetch the discount codes with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.CODES.tab && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES && adminUser) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getPartners(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab, discountCodeTab, adminUser])

  // ******************** Data Creation **************** //
  // If the filter was changed, fetch the discount codes with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.CODES.tab && discountCodeTab === discountCodeTabTypes.DISCOUNT_CODES) {
      if (!isEqual(filter, defaultFilter)) {
        adminUser ? dispatch(getDiscountCodes(filter)) : dispatch(getPartnerUserDiscountCodes(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab, discountCodeTab, adminUser])

  const headCells = [
    {
      id: 'code',
      label: 'Code'
    },
    {
      id: 'discount',
      label: 'Discount'
    },
    {
      id: 'expiresAt',
      label: 'Expiration'
    }
  ]

  const [rows, setRows] = useState([])

  useEffect(() => {
    if (discountCodes) {
      if (discountCodes.length) {
        const newRows = []
        discountCodes.forEach(discountCode => {
          const { code, discount, description, expiresAt } = discountCode
          newRows.push({ id: discountCode.discountID ? discountCode.discountID : discountCode.id, code, discount, description, expiresAt })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [discountCodes])

  // ********************* Pagination Logic (Start) **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalDiscountCodes - page * rowsPerPage)
  // ********************* Pagination Logic (End) **************** //

  // ******************** Column Sort Logic (Start) **************** //
  const [orderBy, setOrderBy] = useState('code')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }
  // ******************** Column Sort Logic (End) **************** //

  // ******************** Discount Code State / Functions (Start) **************** //
  const defaultDiscountCodeForm = { code: '', discount: 0, description: '', expiresAt: null }
  const [discountCodeForm, setDiscountCodeForm] = useState(defaultDiscountCodeForm)
  const [discountCodeModalOpen, setDiscountCodeModalOpen] = useState(false)
  const [editDiscountCodeID, setEditDiscountCodeID] = useState('')

  // List of partner users to select from when generating code on partner as admin
  const [partnerUsersList, setPartnerUsersList] = useState([])

  const handleEditDiscountCode = (discountCodeInfo) => {
    const { id, discount, description, expiresAt } = discountCodeInfo
    setDiscountCodeForm({
      discount: Math.round(discount * 100),
      description,
      expiresAt
    })
    setEditDiscountCodeID(id)
    setDiscountCodeModalOpen(true)
  }

  const handleOpenDiscountCodeModal = async (partnerData) => {
    if (partnerData && adminUser && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES) {
      const { partnerID = '', defaultDiscount = 0 } = partnerData

      const partnerUsersListGet = await dispatch(getPartnerUsers(partnerID))

      if (partnerUsersListGet) {
        setPartnerUsersList(partnerUsersListGet.partnerUsers)

        setDiscountCodeForm({ ...discountCodeForm, discount: Math.round(defaultDiscount * 100) })

        setDiscountCodeModalOpen(true)
      }
    } else {
      setDiscountCodeModalOpen(true)
    }
  }

  const handleCloseDiscountCodeModal = () => {
    setDiscountCodeForm(defaultDiscountCodeForm)
    setEditDiscountCodeID('')
    setPartnerUsersList([])
    setDiscountCodeModalOpen(false)
    setDiscountErrors({ ...defaultDiscountErrors })
  }

  const handlePartnerUserSelect = (e) => {
    const partnerID = e.target.value

    if (partnerID) { setDiscountCodeForm({ ...discountCodeForm, partnerID }) }
  }

  const handleSaveDiscountCode = async () => {
    if ((!editDiscountCodeID && !discountCodeForm?.code) || !discountCodeForm?.discount) {
      setDiscountErrors({ ...defaultDiscountErrors, missingFormElements: true })
      return false
    }

    const discountCodeInfo = { ...discountCodeForm }

    discountCodeInfo.discount = discountCodeInfo.discount * 0.01

    if (editDiscountCodeID) {
      await dispatch(updateDiscountCode(editDiscountCodeID, discountCodeInfo, fireSuccess, fireFailure))
    } else {
      if (!adminUser && userID) discountCodeInfo.partnerUserID = userID

      await dispatch(generateDiscountCode(discountCodeInfo, fireSuccess, fireFailure))
    }
  }

  const fireSuccess = () => {
    handleCloseDiscountCodeModal()

    adminUser ? dispatch(getDiscountCodes(filter)) : dispatch(getPartnerUserDiscountCodes(filter))
  }

  const fireFailure = (info = {}) => {
    const { message, code } = info
    const errMsg = code && (code === 422 || code === 500) ? 'Something went wrong.' : message

    setDiscountErrors({ generalError: true, errorMessage: errMsg || 'Something went wrong.' })
  }
  // ******************** Discount Code State / Functions (End) **************** //

  // ******************** Email State / Functions (Start) **************** //
  const [emailTo, setEmailTo] = useState('')
  const [codeVal, setCodeVal] = useState({})
  const [codeModalOpen, setCodeModalOpen] = useState(false)
  const [currentCodeType, setCurrentCodeType] = useState('')

  const [selectedIndustry, setSelectedIndustry] = useState('')
  const [selectedSector, setSelectedSector] = useState('')
  const [selectedAssessment, setSelectedAssessment] = useState('')

  const [assessmentOpts, setAssessmentOpts] = useState([])

  // Set the sector list to the sectors of the selected industry
  const sectorOpts = useMemo(() => {
    if (industries && selectedIndustry) {
      /*
         - Uses a Map to filter out duplicate sectors (that occur because of alternate assessments) based on sector.id.
         - The entire sector obj is preserved as the Map value, ensuring all sector data is retained while eliminating unnecessary duplicates.
      */
      const indSectors = Array.from(new Map(selectedIndustry?.sectors?.map(sector => [sector.id, sector]) ?? []).values())
      return indSectors
    }
    return []
  }, [industries, selectedIndustry])

  const resetActiveAssessment = () => {
    setSelectedAssessment(null)
    setAssessmentOpts([])
  }

  // Handle the industry selection
  const handleIndustryChange = (id) => {
    // Reset the sector list
    setSelectedSector(null)
    resetActiveAssessment()

    // find the selected ID in the industry list
    const newIndustry = industries.find(x => x.id === parseInt(id))

    // If the industry was found
    if (newIndustry) {
      setSelectedIndustry(newIndustry)
    } else {
      // If no industry was found and the industry was not intentionally being unset, fire a notification
      if (id !== '') { NotificationToast(true, 'Unable to find selected industry.') }
      // either way, reset the selected indutry to null
      setSelectedIndustry(null)
    }
  }

  const handleSectorChange = (id) => {
    // find the id in the list of sector opts
    const newSector = sectorOpts.find(x => x.id === parseInt(id))
    // Reset assessments
    resetActiveAssessment()

    // If the sector was found
    if (newSector) {
      setSelectedSector(newSector)
    } else {
      // If no sector was found and the id was not intentionally being unset, fire a notification
      if (id !== '') { NotificationToast(true, 'Unable to find selected sector.') }
      setSelectedSector(null)
    }
  }

  const fireActiveAssessmentSuccess = (data) => {
    if (data) {
      const [{ sectors }] = data
      if (sectors) {
        const assessments = sectors[0]?.assessments
        setAssessmentOpts(assessments)
      }
    }
  }

  const fireActiveAssessmentFailure = () => { NotificationToast(true, 'Something went wrong.') }

  // When an industry and sector have been selected, fetch the active assessment list
  useMemo(() => {
    if (selectedIndustry && selectedSector) {
      dispatch(getActiveAssessmentList({ ...activeAssessmentFilter, industryID: selectedIndustry?.id, sectorID: selectedSector?.id }, fireActiveAssessmentSuccess, fireActiveAssessmentFailure))
    }
  }, [dispatch, selectedIndustry, selectedSector])

  const handleAssessmentChange = (id) => {
    // find the id in the list of assessment opts
    const newAssessment = assessmentOpts?.find(x => x?.assessmentID === id)

    // If the assessment was found
    if (newAssessment) {
      setSelectedAssessment(newAssessment)
    } else {
      // If no assessment was found and the id was not intentionally being unset, fire a notification
      if (id !== '') { NotificationToast(true, 'Unable to find selected assessment.') }
      setSelectedAssessment(null)
    }
  }

  const handleCopy = () => {
    const discountCodeInfo = { code: codeVal?.code, industryID: selectedIndustry?.id, sectorID: selectedSector?.id, assessmentID: selectedAssessment?.assessmentID }

    dispatch(generateDiscountCodeLink(discountCodeInfo, fireCodeLinkSuccess))
  }

  const fireCodeLinkSuccess = async (url) => {
    handleCloseCodeModal()

    navigator.clipboard.writeText(url)

    NotificationToast(false, 'Link copied successfully!')
  }

  const handleCloseCodeModal = () => {
    try {
      setCodeModalOpen(false)
      setEmailTo('')
      setCodeVal({})
      setCurrentCodeType('')
      setSelectedSector('')
      setSelectedIndustry('')
      setSelectedAssessment('')
      setAssessmentOpts([])
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenCodeModal = (codeData, codeType) => {
    setCurrentCodeType(codeType)

    const { id, code } = codeData

    setCodeVal({ id, code })

    setCodeModalOpen(true)
  }

  const handleSendEmail = async () => {
    if (emailTo && validateEmail(emailTo)) {
      const { id, code } = codeVal
      const assessmentID = selectedAssessment?.assessmentID || null
      const industryID = selectedIndustry?.id || null
      const sectorID = selectedSector?.id || null

      dispatch(emailDiscountCode(id, { email: emailTo, code, industryID, sectorID, assessmentID }, fireSendSuccess, fireSendEmailFailure))
    } else {
      NotificationToast(true, 'Please provide a valid email.')
    }
  }

  const fireSendEmailFailure = (responseData) => {
    let failureMessage = 'Unable to complete request.'
    if (responseData && responseData?.type === responseTypes.DUPLICATE_ENTRY) {
      failureMessage = 'Cannot send discount code to an existing account.'
    }

    return NotificationToast(true, failureMessage)
  }

  const fireSendSuccess = () => {
    handleCloseCodeModal()

    NotificationToast(false, 'Code emailed successfully!')
  }
  // ******************** Email State / Functions (End) **************** //

  // ******************** Partner Modal Stuff (Start) **************** //
  const [partnerModalOpen, setPartnerModalOpen] = useState(false)
  const [partnerName, setPartnerName] = useState('')
  const [partnerDefaultDiscount, setPartnerDefaultDiscount] = useState('')
  const [editPartnerID, setEditPartnerID] = useState('')

  const handleClosePartnerModal = () => {
    setPartnerName('')
    setPartnerDefaultDiscount('')
    setEditPartnerID('')
    setPartnerModalOpen(false)
  }

  const handleEditPartner = (partnerInfo) => {
    const { partnerID, partnerName, defaultDiscount } = partnerInfo

    setEditPartnerID(partnerID)
    setPartnerName(partnerName)
    setPartnerDefaultDiscount(Math.round(defaultDiscount * 100))

    setPartnerModalOpen(true)
  }

  const handleSavePartnerInfo = () => {
    const defaultDiscount = partnerDefaultDiscount * 0.01
    const partnerInfo = { partnerName, defaultDiscount }

    dispatch(updatePartner(editPartnerID, partnerInfo, savePartnerSuccess, savePartnerFailure))
  }

  const savePartnerSuccess = () => {
    handleClosePartnerModal()
    dispatch(getPartners(filter))
  }

  const savePartnerFailure = () => { }

  // ******************** Partner Modal Stuff (End) **************** //

  const handleDateChange = (value) => {
    const formattedDate = moment(value).startOf('day').format('yyyy-MM-DD h:mm:ss')

    setDiscountCodeForm({ ...discountCodeForm, expiresAt: formattedDate })
  }

  const [discountPartnerExpanded, setDiscountPartnerExpanded] = useState(null)

  // This handles the tab render and checks for the global state boolean
  const handleChangeTab = async (event, newValue) => {
    setValue(newValue)

    setFilter(defaultFilter)

    if (newValue === 0) {
      setRowsPerPagePartner(10)
      setPagePartner(0)
      setOrderByPartner('')
      setOrderPartner('desc')
      setDiscountPartnerExpanded(null)

      setDiscountCodeTab(discountCodeTabTypes.DISCOUNT_CODES)
    }

    if (newValue === 1) {
      setRowsPerPage(10)
      setPage(0)
      setOrderBy('')
      setOrder('desc')

      setDiscountCodeTab(discountCodeTabTypes.PARTNER_DISCOUNT_CODES)
    }
  }

  const renderEmailModal = () => {
    return (
      <>
        <Dialog
          open={codeModalOpen}
          onClose={(e) => { e.preventDefault(); handleCloseCodeModal() }}
          fullWidth
          maxWidth='xs'
        >
          <DialogTitle>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography
                  variant='h5'
                >
                  {`${currentCodeType === codeTypes.EMAIL ? 'Email' : 'Copy'} Discount Code ${currentCodeType === codeTypes.LINK ? 'Link' : ''}`}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleCloseCodeModal() }}>
                  <CloseRounded />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction='column' spacing={2} style={{ marginBottom: '.5rem' }}>
              <Grid item container direction='column'>
                <Typography>If you want to associate the code with an industry and sector, please select them below.</Typography>
              </Grid>
            </Grid>

            <Grid container direction='column' spacing={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>

              {/* Email */}
              {currentCodeType === codeTypes.EMAIL &&
                <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Email</Typography>
                  <OutlinedInput
                    fullWidth
                    margin='dense'
                    size='small'
                    value={emailTo}
                    onChange={(e) => setEmailTo(e.target.value)}
                  />
                </Grid>}

              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Industry</Typography>

                <Select
                  variant='outlined'
                  size='small'
                  defaultValue=''
                  value={selectedIndustry && selectedIndustry.id ? selectedIndustry.id : ''}
                  onChange={e => {
                    handleIndustryChange(e.target.value)
                  }}
                  sx={{ ...classes?.searchInput }}
                  style={{ padding: 0 }}
                  MenuProps={{
                    sx: { ...classes?.statusSelect }
                  }}
                >
                  <MenuItem value=''>None</MenuItem>

                  {industries && industries.length && industries.length > 0 && industries.map(industry =>
                    <MenuItem key={`industry-${industry.id}`} value={industry.id}>{industry.name}</MenuItem>
                  )}
                </Select>
              </Grid>

              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Sector</Typography>

                <Select
                  variant='outlined'
                  size='small'
                  defaultValue=''
                  value={selectedSector && selectedSector.id ? selectedSector.id : ''}
                  disabled={!sectorOpts?.length}
                  onChange={e => {
                    handleSectorChange(e.target.value)
                  }}
                  sx={{ ...classes?.searchInput }}
                  style={{ padding: 0 }}
                  MenuProps={{
                    sx: { ...classes?.statusSelect }
                  }}
                >
                  <MenuItem value=''>None</MenuItem>

                  {sectorOpts && sectorOpts.length && sectorOpts.length > 0 && sectorOpts.map(sector =>
                    <MenuItem key={`sector-${sector.id}`} value={sector.id}>{sector.name}</MenuItem>
                  )}
                </Select>
              </Grid>

              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Assessment</Typography>

                <Select
                  variant='outlined'
                  size='small'
                  defaultValue=''
                  value={selectedAssessment?.assessmentID ? selectedAssessment?.assessmentID : ''}
                  disabled={!assessmentOpts?.length}
                  onChange={e => {
                    handleAssessmentChange(e.target.value)
                  }}
                  sx={{ ...classes?.searchInput }}
                  style={{ padding: 0 }}
                  MenuProps={{
                    sx: { ...classes?.statusSelect }
                  }}
                >
                  <MenuItem value=''>None</MenuItem>

                  {assessmentOpts && assessmentOpts.length && assessmentOpts.length > 0 && assessmentOpts.map(assessment =>
                    <MenuItem key={`assessment-${assessment.assessmentID}`} value={assessment.assessmentID}>
                      <Grid item container direction='row' alignItems='center' spacing={1}>
                        <Grid item>
                          {assessment.title}
                        </Grid>
                        {assessment.assessmentType === assessmentTypes.PRIMARY
                          ? <Grid item> <Typography sx={{ fontWeight: 600, color: theme.palette.blue.main, fontSize: '14px' }}>(Primary)</Typography></Grid>
                          : ''}
                      </Grid>
                    </MenuItem>
                  )}
                </Select>
              </Grid>

            </Grid>
          </DialogContent>

          {/* Action Buttons */}
          <DialogActions>
            <Grid container direction='column'>
              {/* {errors?.invalidEmail &&
                <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid email address.</Typography>}
              {errors?.failedAction &&
                <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>} */}
              <Grid container item direction='row' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '400'
                  }}
                  onClick={() => handleCloseCodeModal()}
                >
                  Cancel
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600'
                  }}
                  // disabled={errorCheck}
                  onClick={() => currentCodeType === codeTypes.EMAIL ? handleSendEmail() : handleCopy()}
                  disabled={currentCodeType === codeTypes.EMAIL && !emailTo}
                >
                  {promiseInProgress
                    ? <StyledLoadingIndicator area={loaderArea.ADMIN_DISCOUNTS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                    : currentCodeType === codeTypes.EMAIL ? 'Send' : 'Copy'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const renderDiscountCodeModal = () => {
    return (
      <>
        <Dialog
          open={discountCodeModalOpen}
          onClose={() => handleCloseDiscountCodeModal()}
          fullWidth
          maxWidth='xs'
        >
          <DialogTitle>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography
                  variant='h5'
                >
                  {`${editDiscountCodeID ? 'Edit' : 'Generate'} Discount Code`}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton style={{ padding: '0px' }} onClick={() => handleCloseDiscountCodeModal()}>
                  <CloseRounded />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction='column' spacing={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
              {/* Discount Name */}
              {!editDiscountCodeID && adminUser &&
                <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Discount Name<span style={{ color: 'red' }}>*</span></Typography>
                  <OutlinedInput
                    fullWidth
                    margin='dense'
                    size='small'
                    value={discountCodeForm.code}
                    onChange={(e) => setDiscountCodeForm({ ...discountCodeForm, code: e.target.value })}
                  />
                </Grid>}

              {/* Discount Percentage */}
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Discount Percentage<span style={{ color: 'red' }}>*</span></Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  type='number'
                  inputProps={{ min: 0, max: 100 }}
                  endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                  value={discountCodeForm?.discount || 0}
                  onChange={(e) => setDiscountCodeForm({ ...discountCodeForm, discount: e.target.value })}
                />
              </Grid>

              {/* Description */}
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Description</Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  value={discountCodeForm.description}
                  onChange={(e) => setDiscountCodeForm({ ...discountCodeForm, description: e.target.value })}
                />
              </Grid>

              {/* Expiration */}
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Expiration</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat='yyyy/MM/dd'
                    disablePast
                    value={discountCodeForm.expiresAt}
                    onChange={(value) => handleDateChange(value)}
                    renderInput={(params) => <TextField variant='outlined' {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              {adminUser && discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES && Boolean(partnerUsersList?.length) &&
                <Grid item container direction='column'>
                  <Typography gutterBottom variant='body1'>Partner User</Typography>
                  <Select
                    value={discountCodeForm?.partnerID ? discountCodeForm.partnerID : ''}
                    onChange={handlePartnerUserSelect}
                  >
                    {partnerUsersList.map((partnerUser) => {
                      const { firstName = '', lastName = '', partnerUserID = '' } = partnerUser
                      return (
                        <MenuItem key={`partner-${partnerUserID}`} value={partnerUserID}>{`${firstName} ${lastName}`}</MenuItem>
                      )
                    })}
                  </Select>
                </Grid>}
            </Grid>
          </DialogContent>

          {/* Action Buttons */}
          <DialogActions>
            <Grid container direction='column'>
              {discountErrors?.missingFormElements &&
                <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please be sure you have filled out all required fields.</Typography>}
              {discountErrors?.generalError &&
                <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${discountErrors?.errorMessage || 'Unable to perform action.'}`}</Typography>}
              <Grid container item direction='row' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '400'
                  }}
                  onClick={() => handleCloseDiscountCodeModal()}
                >
                  Cancel
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600'
                  }}
                  // disabled={errorCheck}
                  onClick={(e) => { e.preventDefault(); handleSaveDiscountCode() }}
                >
                  {promiseInProgress
                    ? <StyledLoadingIndicator area={loaderArea.ADMIN_DISCOUNTS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                    : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const renderPartnerModal = () => {
    return (
      <>
        <Dialog
          open={partnerModalOpen}
          onClose={() => handleClosePartnerModal()}
          fullWidth
          maxWidth='xs'
        >
          <DialogTitle>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography
                  variant='h5'
                >
                  Edit Partner
                </Typography>
              </Grid>

              <Grid item>
                <IconButton style={{ padding: '0px' }} onClick={() => handleClosePartnerModal()}>
                  <CloseRounded />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction='column' spacing={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
              {/* Partner Name */}
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Partner Name</Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  value={partnerName}
                  onChange={(e) => setPartnerName(e.target.value)}
                />
              </Grid>

              {/* Default Discount */}
              <Grid item container direction='column'>
                <Typography gutterBottom variant='body1'>Default Discount</Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  type='number'
                  inputProps={{ min: 0, max: 100 }}
                  value={partnerDefaultDiscount}
                  onChange={(e) => setPartnerDefaultDiscount(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>

          {/* Action Buttons */}
          <DialogActions>
            <Grid container direction='column'>
              <Grid container item direction='row' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '400'
                  }}
                  onClick={(e) => { e.preventDefault(); handleClosePartnerModal() }}
                >
                  Cancel
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600'
                  }}
                  onClick={(e) => { e.preventDefault(); handleSavePartnerInfo() }}
                >
                  {promiseInProgress
                    ? <StyledLoadingIndicator area={loaderArea.ADMIN_DISCOUNTS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                    : 'Send'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const renderAdminSection = () => {
    if (discountCodeTab === discountCodeTabTypes.DISCOUNT_CODES) {
      return (
        <>
          {/* Section Content */}
          <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
            <TableContainer elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map(header => {
                      return (
                        <TableCell
                          key={header.id}
                          align='left'
                          sortDirection={orderBy === header?.id ? 'asc' : false}
                        >
                          <TableSortLabel
                            active={orderBy === header?.id}
                            direction={orderBy === header?.id ? order : 'asc'}
                            onClick={() => handleRequestSort(header?.id)}
                          >
                            <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    })}
                    <TableCell align='left' style={{ padding: '16px' }}>
                      <Typography variant='body1' style={{ fontSize: '18px' }}>Description</Typography>
                    </TableCell>
                    <TableCell align='left' style={{ padding: '16px' }} />
                    <TableCell align='left' style={{ padding: '16px' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Temporary frontend sorting */}
                  {promiseInProgress
                    ? (
                      <StyledTableRow style={{ height: '10em' }}>
                        <TableCell colSpan={6}>
                          <StyledLoadingIndicator area={loaderArea.ADMIN_DISCOUNTS} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                        </TableCell>
                      </StyledTableRow>)
                    : (
                      <>
                        {rows.map((row) => {
                          const { id = '', code = '', discount = '', expiresAt = '', description = '' } = row
                          const formatDate = expiresAt ? DateTime.fromISO(expiresAt).toLocaleString(DateTime.DATE_SHORT) : '-'
                          const formatDiscount = discount ? `${Math.round(discount * 100)}%` : '-'

                          return (
                            <StyledTableRow key={`discount-code-row-${id}`}>
                              <TableCell component='th' scope='row'>{code}</TableCell>
                              <TableCell align='left'>{formatDiscount}</TableCell>
                              <TableCell align='left'>{formatDate}</TableCell>
                              <TableCell align='left'>{!description ? '-' : description}</TableCell>
                              <TableCell align='center'>
                                <Button
                                  variant='contained'
                                  startIcon={<EmailRounded />}
                                  onClick={() => handleOpenCodeModal(row, 'email')}
                                >
                                  Email
                                </Button>
                              </TableCell>
                              <TableCell align='center'>
                                <Button
                                  variant='contained'
                                  onClick={() => handleOpenCodeModal(row, 'link')}
                                >
                                  Copy Link
                                </Button>
                              </TableCell>
                              <TableCell align='center'>
                                <Button
                                  style={{
                                    minWidth: 0,
                                    width: '18px',
                                    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                                    backgroundColor: editDiscountCodeID === id ? theme?.palette?.grey?.lighterGrey : 'white'
                                  }}
                                  onClick={() => handleEditDiscountCode(row)}
                                >
                                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                                </Button>
                              </TableCell>
                            </StyledTableRow>
                          )
                        })}
                        {/* Data Array has reached it's length: */}
                        {emptyRows > 0 && (
                          <StyledTableRow style={{ height: 10 }}>
                            <TableCell colSpan={6}>
                              <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Pagination */}
          <Grid item container direction='row' style={{ marginBottom: '4em', marginTop: '2em' }}>
            {/* Rows per Page Selection */}
            <Grid item container direction='row' xs={6} justifyContent='flex-start'>
              <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  variant='outlined'
                  size='small'
                  defaultValue={10}
                  value={rowsPerPage}
                  onChange={(e) => handleChangeRowsPerPage(e)}
                  sx={{ ...classes?.searchInput }}
                  style={{ padding: 0 }}
                  MenuProps={{
                    sx: { ...classes?.statusSelect }
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
              <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
              </Grid>
            </Grid>
            {/* Pagination/ Page Selection */}
            <Grid item container xs={6} justifyContent='flex-end'>
              <Pagination
                color='primary'
                sx={{ ...classes?.pagination }}
                count={totalDiscountCodes ? Math.ceil(totalDiscountCodes / rowsPerPage) : 0}
                page={page === 0 ? 1 : page + 1}
                onChange={handleChangePage}
                shape='rounded'
              />
            </Grid>
          </Grid>
        </>
      )
    }

    if (discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES) {
      return (
        <>
          {/* Section Content */}
          <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
            <TableContainer elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {partnerHeadCells.map(header => {
                      return (
                        <TableCell
                          key={header.id}
                          align='left'
                          sortDirection={orderBy === header?.id ? 'asc' : false}
                        >
                          <TableSortLabel
                            active={orderBy === header?.id}
                            direction={orderBy === header?.id ? order : 'asc'}
                            onClick={() => handleRequestSortPartner(header?.id)}
                          >
                            <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    })}
                    <TableCell align='left' style={{ padding: '16px' }} />
                    <TableCell align='left' style={{ padding: '16px' }} />
                  </TableRow>
                </TableHead>
                {/* Temporary frontend sorting */}
                {promiseInProgress
                  ? (
                    <StyledTableRow style={{ height: '10em' }}>
                      <TableCell colSpan={6}>
                        <StyledLoadingIndicator area={loaderArea.ADMIN_DISCOUNTS} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                      </TableCell>
                    </StyledTableRow>)
                  : (
                    <>
                      {partnerRows.map((row) => {
                        const { partnerID = '', partnerName = '', defaultDiscount = '', userCount = 0 } = row
                        const formatDiscount = defaultDiscount ? `${Math.round(defaultDiscount * 100)}%` : '-'

                        return (
                          <TableBody key={`discount-code-row-${partnerID}`}>
                            <StyledTableRow>
                              <TableCell>
                                <IconButton
                                  aria-label='expand row'
                                  size='small'
                                  onClick={(e) => { e.preventDefault(); handleOpenDiscountPartnerCodes(partnerID) }}
                                >
                                  {discountPartnerExpanded === partnerID ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                              </TableCell>
                              <TableCell component='th' scope='row'>{partnerName}</TableCell>
                              <TableCell align='left'>{formatDiscount}</TableCell>
                              <TableCell align='center'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  enterDelay={750}
                                  enterNextDelay={750}
                                  title={!userCount ? 'Partner must have users assigned to them in order to generate a discount code.' : ''}
                                >
                                  <span>
                                    <Button
                                      variant='contained'
                                      startIcon={<Add />}
                                      onClick={(e) => { e.preventDefault(); handleOpenDiscountCodeModal(row) }}
                                      disabled={!userCount}
                                    >
                                      Generate Code
                                    </Button>
                                  </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align='center'>
                                <Button
                                  style={{
                                    minWidth: 0,
                                    width: '18px',
                                    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                                    backgroundColor: editDiscountCodeID === partnerID ? theme?.palette?.grey?.lighterGrey : 'white'
                                  }}
                                  onClick={() => handleEditPartner(row)}
                                >
                                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                                </Button>
                              </TableCell>
                            </StyledTableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={discountPartnerExpanded === partnerID} timeout='auto' unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Typography variant='body2' style={{ textAlign: partnerDiscountCodes?.length ? 'right' : 'center' }} gutterBottom component='div'>
                                      {partnerDiscountCodes?.length ? 'Codes' : 'No associated codes.'}
                                    </Typography>
                                    {
                                      partnerDiscountCodes?.length
                                        ? (
                                          <>
                                            <Table size='small' aria-label='purchases'>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>Code</TableCell>
                                                  <TableCell>Discount</TableCell>
                                                  <TableCell>Partner User</TableCell>
                                                  <TableCell>Expires At</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {partnerDiscountCodes.map((partnerDiscountCode) => {
                                                  const formatDiscount = partnerDiscountCode.discount ? `${Math.round(partnerDiscountCode.discount * 100)}%` : '-'

                                                  return (
                                                    <TableRow key={`user-code-row-${partnerDiscountCode.partnerUserID}`}>
                                                      <TableCell>{partnerDiscountCode.code}</TableCell>
                                                      <TableCell>{formatDiscount}</TableCell>
                                                      <TableCell>{`${partnerDiscountCode.firstName} ${partnerDiscountCode.lastName}`}</TableCell>
                                                      <TableCell>{partnerDiscountCode.expiresAt ? DateTime.fromISO(partnerDiscountCode.expiresAt).toLocaleString(DateTime.DATE_SHORT) : '-'}</TableCell>
                                                    </TableRow>
                                                  )
                                                })}
                                              </TableBody>
                                            </Table>
                                          </>)
                                        : (
                                          <>
                                          </>)
                                    }
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </TableBody>
                        )
                      })}

                      {/* Data Array has reached it's length: */}
                      {emptyRowsPartner > 0 && (
                        <StyledTableRow style={{ height: 10 }}>
                          <TableCell colSpan={6}>
                            <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </>)}
              </Table>
            </TableContainer>
          </Grid>

          {/* Pagination */}
          <Grid item container direction='row' style={{ marginBottom: '4em', marginTop: '2em' }}>
            {/* Rows per Page Selection */}
            <Grid item container direction='row' xs={6} justifyContent='flex-start'>
              <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  variant='outlined'
                  size='small'
                  defaultValue={10}
                  value={rowsPerPagePartner}
                  onChange={(e) => handleChangeRowsPerPagePartner(e)}
                  sx={{ ...classes?.searchInput }}
                  style={{ padding: 0 }}
                  MenuProps={{
                    sx: { ...classes?.statusSelect }
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
              <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
              </Grid>
            </Grid>
            {/* Pagination/ Page Selection */}
            <Grid item container xs={6} justifyContent='flex-end'>
              <Pagination
                color='primary'
                sx={{ ...classes?.pagination }}
                count={totalDiscountPartners ? Math.ceil(totalDiscountPartners / rowsPerPagePartner) : 0}
                page={pagePartner === 0 ? 1 : pagePartner + 1}
                onChange={handleChangePagePartner}
                shape='rounded'
              />
            </Grid>
          </Grid>
        </>
      )
    }
  }

  const renderPartnerUserSection = () => {
    return (
      <>
        <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
          <TableContainer elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align='left'
                        sortDirection={orderBy === header?.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header?.id}
                          direction={orderBy === header?.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header?.id)}
                        >
                          <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                  <TableCell align='left' style={{ padding: '16px' }}>
                    <Typography variant='body1' style={{ fontSize: '18px' }}>Description</Typography>
                  </TableCell>
                  <TableCell align='left' style={{ padding: '16px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const { id = '', code = '', discount = '', expiresAt = '', description = '' } = row
                  const formatDate = expiresAt ? DateTime.fromISO(expiresAt).toLocaleString(DateTime.DATE_SHORT) : '-'
                  const formatDiscount = discount ? `${Math.round(discount * 100)}%` : '-'

                  return (
                    <StyledTableRow key={`discount-code-row-${id}`}>
                      <TableCell component='th' scope='row'>{code}</TableCell>
                      <TableCell align='left'>{formatDiscount}</TableCell>
                      <TableCell align='left'>{formatDate}</TableCell>
                      <TableCell align='left'>{!description ? '-' : description}</TableCell>
                      <TableCell align='center'>
                        <Button
                          variant='contained'
                          startIcon={<EmailRounded />}
                          onClick={() => handleOpenCodeModal(row, 'email')}
                        >
                          Email
                        </Button>
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          variant='contained'
                          startIcon={<EmailRounded />}
                          onClick={() => handleOpenCodeModal(row, 'link')}
                        >
                          Copy Link
                        </Button>
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          style={{
                            minWidth: 0,
                            width: '18px',
                            boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                            backgroundColor: editDiscountCodeID === id ? theme?.palette?.grey?.lighterGrey : 'white'
                          }}
                          onClick={() => handleEditDiscountCode(row)}
                        >
                          <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
                {/* Data Array has reached it's length: */}
                {emptyRows > 0 && (
                  <StyledTableRow style={{ height: 10 }}>
                    <TableCell colSpan={6}>
                      <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Pagination */}
        <Grid item container direction='row' style={{ marginBottom: '4em', marginTop: '2em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalDiscountCodes ? Math.ceil(totalDiscountCodes / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {/* Email Modal */}
      {renderEmailModal()}

      {/* Discount Code Modal */}
      {renderDiscountCodeModal()}

      {/* Partner Modal */}
      {renderPartnerModal()}

      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'center' }}>
          {/* Section Title */}
          <Grid item xs={8} container>
            <Grid item container direction='row'>
              {
                adminUser
                  ? (
                    <>
                      <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        classes={{ indicator: localClasses.tabIndicator }}
                      >
                        <Tab styles={discountCodeTab === discountCodeTabTypes.DISCOUNT_CODES ? localClasses.activeTabStyle : localClasses.inactiveTabStyle} label='Discount Codes' />
                        <Tab styles={discountCodeTab === discountCodeTabTypes.PARTNER_DISCOUNT_CODES ? localClasses.activeTabStyle : localClasses.inactiveTabStyle} label='Partner Discount Codes' />
                      </Tabs>
                    </>)
                  : (
                    <>
                      <Typography variant='h5'>Discount Codes</Typography>
                    </>)
              }
            </Grid>
          </Grid>

          {/* 'New' Button */}
          {discountCodeTab === discountCodeTabTypes.DISCOUNT_CODES &&
            <Grid item xs={4} container justifyContent='flex-end'>
              <Button
                variant='contained'
                startIcon={<Add />}
                onClick={(e) => { e.preventDefault(); handleOpenDiscountCodeModal() }}
              >
                Generate Code
              </Button>
            </Grid>}
        </Grid>

        <Divider style={{ padding: '.5em 0' }} />

        {
          adminUser
            ? (
              <>
                {renderAdminSection()}
              </>)
            : (
              <>
                {renderPartnerUserSection()}
              </>)
        }
      </Grid>
    </>
  )
}

export default DiscountCodeAdministration
