import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePromiseTracker } from 'react-promise-tracker'

import React from 'react'

import { Col, Card, CardBody, FormGroup, Label } from 'reactstrap'
import Loader from '../../components/Loader'

const AssessmentQuestionContent = (props) => {
  const {
    answer, currentQuestion, handleAnswerQuestion, lastQuestionCondition,
    handleCompleteAssessment
  } = props

  const { promiseInProgress } = usePromiseTracker()

  return (
    <Col className='mt-4 mt-lg-3 row-12 d-flex flex-column justify-content-center'>
      <div className='d-flex flex-column'>
        {currentQuestion?.sectionName &&
          <small className='text-primary text-center text-uppercase font-600'>{currentQuestion.sectionName}</small>}
        <h2 className='mt-3 text-center mb-1'>{currentQuestion?.question}</h2>
        {currentQuestion?.helpText &&
          <small className='text-center'><i>{currentQuestion.helpText}</i></small>}
      </div>
      <Card className='mt-4 w-100'>
        <CardBody className='p-0'>
          {promiseInProgress && <Loader size='md' />}
          {!promiseInProgress &&
            <>
              <div className='font-600 mb-3'>Select One</div>
              {currentQuestion.questionOptions
                .sort((x, y) => y.optionOrder - x.optionOrder)
                .map((option) => {
                  const { questionOptionID, optionText } = option
                  const active = answer?.questionOptionID === questionOptionID
                  return (
                    <FormGroup check className='col-12 px-0 mt-2' key={questionOptionID}>
                      <Label onClick={() => { handleAnswerQuestion(currentQuestion.questionID, questionOptionID, option) }} check className={`${active ? 'text-primary border-primary' : ''} cursor-pointer font-weight-300 p-2 border rounded form-check-label col-12`}>
                        <FontAwesomeIcon icon={faCheckCircle} className={`text-${active ? 'primary' : 'white'}`} size='lg' />
                        <span className='ml-2'>{optionText}</span>
                      </Label>
                    </FormGroup>
                  )
                })}
            </>}
          {!promiseInProgress && currentQuestion.explanationText &&
            <div className='bg-light border rounded px-4 py-3 mt-4'>
              <h5 className='font-600'>Term Explanation</h5>
              <p>{currentQuestion.explanationText}</p>
            </div>}

        </CardBody>
        <div
          className='d-flex justify-content-center mt-5'
        >
          {lastQuestionCondition &&
            <button
              type='button'
              onClick={handleCompleteAssessment}
              className='px-3 py-2 bg-primary border border-primary rounded text-white'
              color='primary'
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span className='ml-2'>Complete Assessment</span>
            </button>}
        </div>
      </Card>
    </Col>
  )
}

export default AssessmentQuestionContent
