import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'

import { updatePartnerUser, updateAdmin, updateUser, updateUserPassword } from '../redux/actions'
import { userTypes } from '../utils'

import InformationModal from './InformationModal'
import Loader from './Loader'

const ProfileModal = ({ onClose }) => {
  const authUser = useSelector(state => state.auth)
  const [user, setUser] = useState({ ...authUser })
  const dispatch = useDispatch()

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null
  })

  const [password, setPassword] = useState()
  const [loading, setLoading] = useState()

  const updateFunction = {
    [userTypes.COMPANY_USER]: updateUser,
    [userTypes.PARTNER_USER]: updatePartnerUser,
    [userTypes.ADMIN_USER]: updateAdmin
  }

  const doSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const { firstName, lastName, email, company, userType } = user

    const promises = []

    const updateInfo = { firstName, lastName, company, ...(userType === userTypes.COMPANY_USER || authUser?.email !== email ? { email } : {}) }
    const reduxUpdate = new Promise((resolve, reject) => {
      const updateFunc = updateFunction[userType]
      return dispatch(updateFunc({ ...updateInfo, userID: authUser.userID }, resolve, reject))
    })
    promises.push(
      reduxUpdate
    )

    if (password) {
      promises.push(
        dispatch(updateUserPassword({ password }))
      )
    }

    Promise.all(promises)
      .then(() => {
        setLoading(false)
        setInformationModal({
          isOpen: true,
          title: 'Update Profile',
          body: 'Profile updated successfully.',
          onClose
        })
      })
      .catch(err => {
        setLoading(false)
        return setInformationModal({
          isOpen: true,
          title: 'Update Profile',
          body: err?.response?.data[0].msg || err.msg || 'There was an error with your request.',
          onClose: null
        })
      })
  }

  const closeBtn = <Button className='close' color='none' onClick={onClose}>&times;</Button>

  return informationModal.isOpen
    ? (
      <InformationModal
        title={informationModal.title}
        body={informationModal.body}
        onClose={() => informationModal.onClose ? informationModal.onClose() : setInformationModal({ isOpen: false, title: '', body: '' })}
      />
      )
    : (
      <Modal isOpen size='sm' centered>
        <Form onSubmit={doSubmit}>
          <ModalHeader className='d-flex justify-content-between font-600' close={closeBtn}>
            Edit Profile
          </ModalHeader>
          <ModalBody>
            <Row>
              {loading
                ? (
                  <Loader size='sm' />
                  )
                : (
                  <Col className='col-12'>
                    <FormGroup>
                      <Label>
                        <span className='font-600'>First Name</span>
                        <span className='text-danger ml-1'>*</span>
                      </Label>
                      <Input
                        disabled={user.userId}
                        type='text'
                        name='firstName'
                        value={user.firstName || ''}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            firstName: e.target.value
                          })}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        <span className='font-600'>Last Name</span>
                        <span className='text-danger ml-1'>*</span>
                      </Label>
                      <Input
                        disabled={user.userId}
                        type='text'
                        name='lastName'
                        value={user.lastName || ''}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            lastName: e.target.value
                          })}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        <span className='font-600'>Email</span>
                        <span className='text-danger ml-1'>*</span>
                      </Label>
                      <Input
                        type='email'
                        name='email'
                        value={user.email}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            email: e.target.value
                          })}
                        required
                      />
                    </FormGroup>
                    {user.userType === userTypes.COMPANY_USER &&
                      <FormGroup>
                        <Label>
                          <span className='font-600'>Company</span>
                          <span className='text-danger ml-1'>*</span>
                        </Label>
                        <Input
                          type='text'
                          name='company'
                          value={user.company || ''}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              company: e.target.value
                            })}
                          required
                        />
                      </FormGroup>}
                    <FormGroup className='mb-0'>
                      <Label>
                        <span className='font-600'>Password</span>
                        <span className='text-danger ml-1'>*</span>
                      </Label>
                      <Input
                        type='password'
                        name='password'
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className='justify-content-between'>
                <Button color='secondary' onClick={onClose}>
                  Cancel
                </Button>
                <Button color='primary' type='submit'>
                  Save
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      </Modal>
      )
}

export default ProfileModal
