import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, IconButton, useTheme, Select, MenuItem,
  FormControl, Button, CircularProgress
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

import { NotificationToast } from '../../../components/tools'
import { loaderArea } from '../../../utils'

import { startExport } from '../../../redux/actions'

import { usePromiseTracker } from 'react-promise-tracker'

const ExportModal = (props) => {
  const {
    classes, exportModalOpen = false, setExportModalOpen = () => { }, localClasses, industryList = []
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()

  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ASSESSMENT_EXPORT })

  const [selectedIndustry, setSelectedIndustry] = useState('All')
  const [selectedSector, setSelectedSector] = useState('All')

  // Set the sector list to the sectors of the selected industry
  const sectorOpts = useMemo(() => {
    if (industryList && selectedIndustry) {
      const indSectors = selectedIndustry?.sectors
      return indSectors
    }
    return []
  }, [industryList, selectedIndustry])

  // Handle the industry selection
  const handleIndustryChange = (id) => {
    // Reset the sector list
    setSelectedSector('All')

    // find the selected ID in the industry list
    const newIndustry = industryList.find(x => x.industryID === parseInt(id))

    // If the industry was found
    if (newIndustry) {
      setSelectedIndustry(newIndustry)
    } else {
      // If no industry was found and the industry was not intentionally being unset, fire a notification
      if (id !== 'All') { NotificationToast(true, 'Unable to find selected industry.') }
      // either way, reset the selected indutry to null
      setSelectedIndustry('All')
    }
  }

  const handleSectorChange = (id) => {
    // find the id in the list of sector opts
    const newSector = sectorOpts.find(x => x.sectorID === parseInt(id))

    // If the sector was found
    if (newSector) {
      setSelectedSector(newSector)
    } else {
      // If no sector was found and the id was not intentionally being unset, fire a notification
      if (id !== 'All') { NotificationToast(true, 'Unable to find selected sector.') }
      setSelectedSector(null)
    }
  }

  const handleExportModalClose = () => {
    setExportModalOpen(false)
    setSelectedIndustry('All')
    setSelectedSector('All')
  }

  const handleStartExport = () => {
    const industryID = selectedIndustry?.industryID || null
    const sectorID = selectedSector?.sectorID || null

    dispatch(startExport(industryID, sectorID, fireExportSuccess, fireExportFailure))
  }

  const fireExportSuccess = () => {
    handleExportModalClose()
    NotificationToast(false, 'The export will be emailed to you shortly.')
  }

  const fireExportFailure = () => {
    handleExportModalClose()
    NotificationToast(true, 'Unable to start export.')
  }

  return (
    <Dialog
      open={exportModalOpen}
      onClose={() => handleExportModalClose()}
      fullWidth
      keepMounted
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h5'>Export</Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => handleExportModalClose()}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column'>
          <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
            <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Industry:</Typography>
            <FormControl fullWidth>
              <Select
                variant='outlined'
                size='small'
                value={selectedIndustry?.industryID || 'All'}
                onChange={e => {
                  handleIndustryChange(e.target.value)
                }}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value='All'>All</MenuItem>

                {industryList?.length && industryList?.map(industry =>
                  <MenuItem key={`industry-${industry.industryID}`} value={industry.industryID}>{industry.industryName}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sx={{ ...localClasses.selectFilterSpacing, marginTop: '0.7em' }}>
            <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Sector:</Typography>
            <FormControl fullWidth>
              <Select
                variant='outlined'
                size='small'
                value={selectedSector?.sectorID || 'All'}
                disabled={!sectorOpts?.length}
                onChange={e => {
                  handleSectorChange(e.target.value)
                }}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value='All'>All</MenuItem>

                {sectorOpts && sectorOpts.length && sectorOpts.length > 0 && sectorOpts.map(sector =>
                  <MenuItem key={`sector-${sector.sectorID}`} value={sector.sectorID}>{sector.sectorName}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <DialogActions>
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' sx={{ marginTop: '1em' }}>
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{
                    margin: '.5em 0.5em',
                    fontWeight: '600'
                  }}
                  onClick={() => handleExportModalClose()}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button
                  onClick={(e) => { e.preventDefault(); handleStartExport() }}
                  variant='contained'
                  color='primary'
                  style={{
                    margin: '.5em 0.5em',
                    fontWeight: '600'
                  }}
                >
                  {
                    promiseInProgress ? <CircularProgress style={{ color: 'white' }} size={25} /> : 'Start Export'
                  }
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ExportModal
