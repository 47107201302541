import React, { useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Button, Typography, useTheme, Avatar, Grid
} from '@mui/material'
import { EditRounded } from '@mui/icons-material'
import { StyledTableRow, builderModalTypes, ValidateUrl, checkValidInputs } from '../../../utils'
import { NotificationToast } from '../../tools'
import { QuestionOptionsModal, EditQuestionOrOptionModal } from '../builderModals'
import {
  getSectorQuestionOptions, deleteAssessmentQuestion, deleteAssessmentQuestionOption, getSelectedAssessment,
  updateAssessmentQuestion, updateAssessmentQuestionOption, postQuestionOption
} from '../../../redux/actions'

const headCells = [
  { id: 'text', label: 'Question' },
  { id: 'explanationText', label: 'Explanation' },
  { id: 'education', label: 'Resource' },
  { id: 'eductionType', label: 'Resource Type' },
  { id: 'maxScoreImpact', label: 'Max. Score' },
  { id: 'questionOptions', label: 'Option Count' }
]

// Default form for editing a question
const defaultQuestionForm = {
  questionID: '',
  text: '',
  explanationText: '',
  helpText: '',
  education: '',
  educationType: 'READ',
  questionOrder: null,
  requestedOrder: null,
  newSectionID: ''
}

// Default form for editing a question option
const defaultOptionForm = {
  questionOptionID: '',
  text: '',
  scoreImpact: 0,
  conditionalLanguageFB: '',
  conditionalLanguageAT: '',
  optionOrder: null,
  requestedOptionOrder: null
}

// Table listing the question rows
// rendered in BuilderSectionsTable.js
const BuilderQuestionTable = (props) => {
  const {
    // Generated in BuilderQuestionTable.js
    questionRows = [],
    currentSectionID,
    // generated in top most parent Builder.js
    classes,
    editMode = false
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const deleteID = useRef(null)
  const currentQuestionID = useRef(null)
  const currentOptionCount = useRef(1)

  const { assessmentSectionQuestions: sections = [], selectedAssessment = {} } = useSelector(state => state.assessment)

  // *** Question Option Modal/Table Controls **** //
  const [questionOptionsModalOpen, setQuestionOptionsModalOpen] = useState(false)
  const [questionOptions, setQuestionOptions] = useState([])

  const handleQuestionOptionsClose = () => {
    setQuestionOptionsModalOpen(false)
    currentQuestionID.current = null
    currentOptionCount.current = 1
    setQuestionOptions([])
  }

  // on successful fetch of question options, set the retrieved options to state
  const fireOpSuccess = (data) => {
    if (data?.length) {
      setQuestionOptions(data)
    } else {
      setQuestionOptions([])
    }
  }

  // When opening the question  options modal, fetch the selected question's options
  const handleOpenQuestionOptionsModal = (assID, qID, opCount = 1) => {
    dispatch(getSectorQuestionOptions(assID, qID, fireOpSuccess))
    currentQuestionID.current = qID
    currentOptionCount.current = opCount
    setQuestionOptionsModalOpen(true)
  }

  // *** Edit Question or Question Option Modal Control *** //
  const [editModalType, setEditModalType] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [questionForm, setQuestionForm] = useState({ ...defaultQuestionForm })
  const [optionForm, setOptionForm] = useState({ ...defaultOptionForm })

  // Used to provide the modal with correct form and set state function dependant on the modal type
  const form = editModalType && editModalType === builderModalTypes.QUESTION ? questionForm : optionForm
  const setForm = editModalType && editModalType === builderModalTypes.QUESTION ? setQuestionForm : setOptionForm

  const defaultErrors = { missingRequirements: false, invalidLink: false, invalidInput: false, failedAction: false, invalidRequestedOrder: false }
  const [errors, setErrors] = useState({ ...defaultErrors })

  // Close the edit modal
  const handleEditModalClose = () => {
    setEditModalOpen(false)
    setEditModalType(builderModalTypes.QUESTION)
    setQuestionForm({ ...defaultQuestionForm })
    setOptionForm({ ...defaultOptionForm })
    setErrors({ ...defaultErrors })
  }

  // open the edit modal
  const handleOpenEditModal = (type, info) => {
    setEditModalType(type)
    if (type === builderModalTypes.NEW_OPTION) { info = { ...defaultOptionForm, requestedOptionOrder: 1, assessmentID: selectedAssessment.assessmentID } }
    if (type === builderModalTypes.QUESTION) {
      const educationValue = (!info?.education || (info?.education && !ValidateUrl(info.education)) ? null : info.education)
      setQuestionForm({ ...info, education: educationValue, questionOrder: info?.questionOrder, requestedOrder: info?.questionOrder, newSectionID: currentSectionID })
    } else {
      setOptionForm({ ...info, optionOrder: info?.optionOrder, requestedOptionOrder: info?.optionOrder })
    }
    setEditModalOpen(true)
  }

  // Gets the selected section's details to obtain the max question order
  const newSectionDetails = useMemo(() => {
    if (form?.newSectionID) {
      const findCurrentMaxQuestionOrder = sections.find(section => section?.sectionID === form?.newSectionID)

      if (findCurrentMaxQuestionOrder && typeof findCurrentMaxQuestionOrder !== 'undefined') { return findCurrentMaxQuestionOrder?.questions?.length }
      return null
    }
  }, [form?.newSectionID, sections])

  const fireUpdateQuestionOrOptionSuccess = () => {
    dispatch(getSelectedAssessment(selectedAssessment?.assessmentID))
    handleEditModalClose()
  }

  const fireUpdateQuestionOrOptionFailure = () => {
    NotificationToast(true, 'Unable to perform update.')
  }

  // update either a question or an option based on the modal type
  const handleEditQuestionOrOption = () => {
    // boolean to decide subsequent actions and checks
    const updateQuestion = Boolean(editModalType && editModalType === builderModalTypes.QUESTION)
    const newOption = Boolean(editModalType && editModalType === builderModalTypes.NEW_OPTION)

    // decide the correct redux action
    const action = updateQuestion ? updateAssessmentQuestion : newOption ? postQuestionOption : updateAssessmentQuestionOption

    // extract the forms
    const { questionID, text: questionText, explanationText, helpText, education, questionOrder, newSectionID } = questionForm
    const { questionOptionID, text: optionText, optionOrder, scoreImpact, conditionalLanguageFB, conditionalLanguageAT } = optionForm

    // init an undefined form to go to the dispatch
    let form

    // Check for the appropriate ID
    if ((updateQuestion && !questionID) || (!updateQuestion && !newOption && !questionOptionID)) { setErrors({ ...errors, failedAction: true }); return false }
    // Check for the appropriate required text
    if ((updateQuestion && !questionText) || (!updateQuestion && !optionText)) { setErrors({ ...errors, missingRequirements: true }); return false }

    // based on the form, decide which inputs need to be validated
    const validateInputs = updateQuestion ? { questionText, explanationText, helpText } : { optionText, conditionalLanguageFB, conditionalLanguageAT }

    // check for valid inputs
    if (!checkValidInputs({ ...validateInputs })) {
      setErrors({ ...errors, invalidInput: true })
      return false
    }

    // If updating a question, validate url of a resource and build the expected form
    if (updateQuestion) {
      if (education && !ValidateUrl(education)) { setErrors({ ...errors, invalidLink: true }); return false }
      if (newSectionID) {
        if ((questionForm?.requestedOrder && newSectionDetails && Number(questionForm?.requestedOrder) > Number(newSectionDetails))) {
          setErrors({ ...errors, invalidRequestedOrder: true })
          return false
        }
      }

      form = {
        questionID,
        text: questionForm.text?.trim() || '',
        explanationText: questionForm.explanationText?.trim() || '',
        helpText: questionForm.helpText?.trim() || '',
        education: questionForm?.education?.trim() || '',
        educationType: 'READ',
        questionOrder: Number(questionOrder) || null,
        requestedOrder: Number(questionForm?.requestedOrder) || null,
        newSectionID: questionForm?.newSectionID !== currentSectionID ? questionForm?.newSectionID : null
      }
    } else {
      // else we are updating/adding an option
      if (scoreImpact !== 0 && !scoreImpact) { setErrors({ ...errors, missingRequirements: true }); return false }

      form = {
        ...(!newOption ? { questionOptionID } : { questionID: currentQuestionID.current }),
        optionOrder: newOption ? (Number(optionForm?.requestedOptionOrder) || 1) : (Number(optionOrder) || null),
        requestedOrder: !newOption ? Number(optionForm?.requestedOptionOrder) : null,
        text: optionForm.text?.trim() || '',
        scoreImpact: optionForm.scoreImpact || 0,
        conditionalLanguageFB: optionForm.conditionalLanguageFB?.trim() || '',
        conditionalLanguageAT: optionForm?.conditionalLanguageAT?.trim() || ''
      }
    }

    // once we have a form, dispatch it with the appropriate action
    if (form) {
      dispatch(action({ ...form, assessmentID: selectedAssessment?.assessmentID }, fireUpdateQuestionOrOptionSuccess, fireUpdateQuestionOrOptionFailure))
    }
  }

  // Confirm popper related elements --- //
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null)

  // open the delete confirm poover
  const handleDeletePopover = (e, value) => {
    setDeleteAnchorEl(e.currentTarget)
    deleteID.current = value
  }

  // close the delete popover
  const handleDeleteClose = () => {
    setDeleteAnchorEl(null)
    deleteID.current = null
  }

  const openConfirmDelete = Boolean(deleteAnchorEl)

  // On successfully option or question delete, reset the correct state before closing the delete and edit modals
  const fireDeleteSuccess = () => {
    // if a question was removed, fetch the sections/questions again
    if (editModalType && editModalType === builderModalTypes.QUESTION) {
      dispatch(getSelectedAssessment(selectedAssessment?.assessmentID))
    } else {
      // Get the information needed from the deleted option to update the list of options
      const deletedOpInfo = questionOptions.find(x => x.questionOptionID === deleteID.current)
      if (deletedOpInfo && typeof deletedOpInfo !== 'undefined') {
        dispatch(getSectorQuestionOptions(deletedOpInfo?.assessmentID, deletedOpInfo?.questionID, fireOpSuccess))
      }
    }
    handleDeleteClose()
    handleEditModalClose()
  }

  const fireDeleteFailure = () => {
    handleDeleteClose()
    NotificationToast(true, 'Unable to perform delete action.')
  }
  // handle deletion of either question or option
  const handleDeleteQuestionOrOption = () => {
    const action = editModalType && editModalType === builderModalTypes.QUESTION ? deleteAssessmentQuestion : deleteAssessmentQuestionOption
    dispatch(action(selectedAssessment?.assessmentID, deleteID.current, fireDeleteSuccess, fireDeleteFailure))
  }

  const noQuestionsRow = () => {
    return (
      <StyledTableRow key='question-row-none'>
        <TableCell align='center' colSpan={7} sx={classes.smallTableCellContent} style={{ padding: '8px 16px 8px 0', color: theme.palette.grey.darkGrey }}>No Questions</TableCell>
      </StyledTableRow>
    )
  }

  return (
    <>
      <TableContainer elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(header => {
                return (
                  <TableCell
                    key={header.id}
                    align='left'
                    style={{ padding: '4px 16px 4px 0' }}
                  >
                    <Typography variant='body2' style={{ fontSize: '14px' }}>{header?.label}</Typography>
                  </TableCell>
                )
              })}
              <TableCell align='left' />
              {editMode && <TableCell align='left' />}
            </TableRow>
          </TableHead>
          <TableBody>
            {!questionRows.length && noQuestionsRow()}
            {Boolean(questionRows.length) && questionRows.map((row) => {
              const { assessmentID = '', questionID = 0, text = '', explanationText = '', education = '', educationType = 0, maxScoreImpact = 0, questionOrder, optionCount } = row
              return (
                <StyledTableRow key={`question-row-${questionID}`}>
                  <TableCell align='left' sx={classes.smallTableCellContent}>
                    <Grid container direction='row' alignItems='center'>
                      <Grid item xs={2}>
                        <Avatar sx={{ height: 14, width: 14, marginRight: '12px', fontSize: 10, backgroundColor: theme.palette.blue.main }}>{questionOrder}</Avatar>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography noWrap style={{ fontSize: 12 }}>{text || '-'}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='left' sx={classes.smallTableCellContent}>{explanationText || '-'}</TableCell>
                  <TableCell align='left' sx={classes.smallTableCellContent}>{!education || (education && !ValidateUrl(education)) ? '-' : education}</TableCell>
                  <TableCell align='left' sx={classes.smallTableCellContent}>{educationType || '-'}</TableCell>
                  <TableCell align='left' sx={classes.smallTableCellContent}>{maxScoreImpact || 0}</TableCell>
                  <TableCell align='left' sx={classes.smallTableCellContent}>{optionCount || 0}</TableCell>
                  <TableCell align='right' sx={classes.smallTableCellContent}>
                    <Button
                      variant='contained'
                      style={{ width: 'max-content', fontSize: 12, lineHeight: '1.5' }}
                      onClick={() => { handleOpenQuestionOptionsModal(assessmentID, questionID, optionCount) }}
                    >
                      View Question Options
                    </Button>
                  </TableCell>
                  {editMode &&
                    <TableCell align='center' sx={classes.smallTableCellContent}>
                      <Button sx={classes.editButtonBasicStyles} style={{ backgroundColor: 'white' }} onClick={() => handleOpenEditModal(builderModalTypes.QUESTION, row)}>
                        <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '12px' }} />
                      </Button>
                    </TableCell>}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {editModalOpen &&
        <EditQuestionOrOptionModal
          editModalOpen={editModalOpen}
          handleEditModalClose={handleEditModalClose}
          form={form}
          setForm={setForm}
          modalType={editModalType}
          classes={classes}
          handleDeletePopover={handleDeletePopover}
          handleDeleteClose={handleDeleteClose}
          openConfirmDelete={openConfirmDelete}
          deleteAnchorEl={deleteAnchorEl}
          handleDeleteQuestionOrOption={handleDeleteQuestionOrOption}
          handleEditQuestionOrOption={handleEditQuestionOrOption}
          errors={errors}
          optionCount={currentOptionCount.current || 1}
          newSectionDetails={newSectionDetails}
          currentSectionID={currentSectionID}
          editModalType={editModalType}
        />}

      {questionOptionsModalOpen &&
        <QuestionOptionsModal
          questionOptionsModalOpen={questionOptionsModalOpen}
          handleQuestionOptionsClose={handleQuestionOptionsClose}
          classes={classes}
          handleOpenEditModal={handleOpenEditModal}
          form={form}
          setForm={setForm}
          optionRows={questionOptions}
          editMode={editMode}
        />}
    </>
  )
}

export default BuilderQuestionTable
