import { LOGIN, LOGOUT_USER, VALIDATE_USER, UPDATE_AUTH, REMOVE_ERROR, UPDATE_USER_AGREEMENT, REFRESH_TOKEN } from '../types'

const initialState = {
  token: '',
  userID: '',
  userType: '',
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  partnerID: '',
  discountCode: '',
  receiptUrl: '',
  createdAt: '',
  preferredSector: null,
  preferredIndustry: null,
  lastAgreement: null,
  exp: 0,
  isValidated: false,
  error: false
}
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: return {
      ...state,
      token: action.payload.token,
      userID: action.payload.userID,
      userType: action.payload.userType,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      company: action.payload.company,
      partnerID: action.payload.partnerID,
      discountCode: action.payload.discountCode,
      receiptUrl: action.payload.receiptUrl,
      createdAt: action.payload.createdAt,
      exp: action.payload.exp,
      lastAgreement: action.payload.lastAgreement,
      builderAccess: action.payload.builderAccess,
      preferredSector: action.payload.preferredSector,
      preferredIndustry: action.payload.preferredIndustry,
      isValidated: true
    }
    case UPDATE_AUTH: return {
      ...state,
      firstName: action.payload.firstName || state.firstName,
      lastName: action.payload.lastName || state.lastName,
      email: action.payload.email || state.email
    }
    case LOGOUT_USER: return {
      ...initialState,
      error: action.payload.error || false
    }
    case REMOVE_ERROR: return {
      ...state,
      error: false
    }
    case VALIDATE_USER: return {
      ...state,
      isValidated: true
    }
    case UPDATE_USER_AGREEMENT: return {
      ...state,
      lastAgreement: action.payload
    }
    case REFRESH_TOKEN: return {
      ...state,
      token: action.payload.token,
      exp: action.payload.exp
    }
    default: return state
  }
}

export default authReducer
