import { TableRow, styled } from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey.lightestGrey
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))
