import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link, useHistory } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { faBuilding, faSignOutAlt, faUser, faFile, faCubes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProfileModal from './ProfileModal'

import { logout, getUserAssessments } from '../redux/actions'
import { userTypes } from '../utils'

const ProfileDropdown = (props) => {
  const dispatch = useDispatch()
  const { userID = '', userType = '', company = '', builderAccess = false } = useSelector(state => state.auth)

  const history = useHistory()

  const [profileModal, setProfileModal] = useState()
  const [dropdownOpen, setDropdownOpen] = useState()

  const logoutUser = () => {
    dispatch(logout())
    history.push('/auth/sign-in')
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle
        data-toggle='dropdown'
        tag='button'
        className='dropdown-toggle mx-0 border-0 bg-white'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className='mx-2'>{company || 'Menu'}</span>
      </DropdownToggle>
      <DropdownMenu right className='dropdown-menu-animated' style={{ marginTop: '1.75rem' }}>
        <div onClick={() => setDropdownOpen(!dropdownOpen)}>
          <button
            type='button'
            onClick={() => setProfileModal(true)}
            className='dropdown-item notify-item'
            style={{ outline: 'none' }}
          >
            <FontAwesomeIcon className='text-primary' icon={faUser} />
            <span className='ml-2'>Profile</span>
          </button>

          {userType && userType === userTypes?.COMPANY_USER &&
            <button
              type='button'
              onClick={() => { dispatch(getUserAssessments(userID, () => { props.history.replace({ pathname: '/assessments', search: '' }) })) }}
              className='dropdown-item notify-item'
              style={{ outline: 'none' }}
            >
              <FontAwesomeIcon className='text-primary' icon={faFile} />
              <span className='ml-2'>Assessments</span>
            </button>}

          {userType && userType !== userTypes?.COMPANY_USER &&
            <Link
              to={{ pathname: '/administration', search: `?${userType}=${userID}` }}
              className='dropdown-item notify-item'
            >
              <FontAwesomeIcon className='text-primary' icon={faBuilding} />
              <span className='ml-2'>Administration</span>
            </Link>}

          {userType && userType === userTypes?.ADMIN_USER && Boolean(builderAccess) &&
            <Link
              to={{ pathname: '/assessments/builder' }}
              className='dropdown-item notify-item'
            >
              <FontAwesomeIcon className='text-primary' icon={faCubes} />
              <span className='ml-2'>Assessment Builder</span>
            </Link>}

          <Link
            to='#'
            onClick={logoutUser}
            className='dropdown-item notify-item'
          >
            <FontAwesomeIcon className='text-primary' icon={faSignOutAlt} />
            <span className='ml-2'>Logout</span>
          </Link>
        </div>
      </DropdownMenu>
      {profileModal ? <ProfileModal onClose={() => setProfileModal(false)} /> : null}
    </Dropdown>
  )
}

export default withRouter(ProfileDropdown)
