import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Typography, Divider, Button, useTheme, TableContainer, TableBody, TableRow,
  TableCell, Table, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Pagination, Select, MenuItem,
  styled, TextField, useMediaQuery
} from '@mui/material'
import { Add, CloseRounded } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { isEqual } from 'lodash'
import { adminSideMenuTabs, loaderArea, progressComponentTypes } from '../../../utils'
import { StyledTableRow, StyledLoadingIndicator } from '../../../utils/custom'
import { getAgreements, createAgreement } from '../../../redux/actions'
import { DateTime } from 'luxon'

// Adds specific styling to the table row element to remove the last border and to alternate the bg color fo the rows
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  fontSize: '16px',
  backgroundColor: theme.palette.grey.lightestGrey
}))

// Specific local classnames
const useStyles = (theme) => ({
  currentAgreement: {
    backgroundColor: '#5280AA',
    color: '#ffff',
    padding: '0.4rem 0.6rem',
    borderRadius: '5px'
  },
  agreementDateSpacing: {
    marginRight: '1rem',
    marginLeft: '1rem',
    fontSize: '1.6rem'
  },
  tableSpacing: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px'
  }
})

const AgreementAdministration = (props) => {
  const {
    agreements = [], totalAgreements = 0, filter = {}, setFilter = () => { }, defaultFilter = {},
    currentTab = '', classes = {}
  } = props

  const theme = useTheme()
  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))
  const localClasses = useStyles(theme)
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ADMIN_AGREEMENTS })

  // Init the add agreement form
  const defaultAgreementForm = {
    content: '',
    createdAt: '',
    isCurrent: 0
  }

  const [agreementModalOpen, setAgreementModalOpen] = useState(false)

  const [dateCreated, setDateCreated] = useState('')

  const [editAgreementID, setEditAgreementID] = useState(null)
  const [agreementForm, setAgreementForm] = useState(defaultAgreementForm)

  // ******************** Data Creation **************** //
  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.AGREEMENTS.tab) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getAgreements(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (agreements) {
      if (agreements.length) {
        const newRows = []
        agreements.forEach(admin => {
          const { agreementID, content, createdAt, isCurrent } = admin
          newRows.push({ agreementID, content, createdAt, isCurrent })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [agreements])

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAgreements - page * rowsPerPage)

  // Basic Open Modal Logic
  const handleAddAgreement = () => {
    setAgreementModalOpen(true)
  }

  const fireSuccess = () => {
    handleAgreementModalClose()
    setFilter({ ...defaultFilter })
    setPage(0)
  }

  // Error logic
  const defaultErrors = { failedAction: null }
  const [errors, setErrors] = useState({ ...defaultErrors })

  const fireFailure = (response) => {
    let res = 'Unable to perform action.'
    if (response) {
      const { msg = '' } = response
      res = msg || 'Unable to perform action.'
    }
    setErrors({ ...defaultErrors, failedAction: res })
  }

  const handleSaveNewAgreement = () => {
    const { content = '' } = agreementForm
    dispatch(createAgreement({ content }, fireSuccess, fireFailure))
  }

  // View current agreement (cannot edit)
  const handleViewAgreement = (agreementInfo) => {
    const { agreementID, content, createdAt } = agreementInfo
    const formatDate = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)

    setAgreementForm({
      content
    })
    setEditAgreementID(agreementID)
    setDateCreated(formatDate)
    setAgreementModalOpen(true)
  }

  // Close the modal/reset state
  const handleAgreementModalClose = () => {
    setAgreementModalOpen(false)
    setErrors({ ...defaultErrors })
    setDateCreated('')
    setEditAgreementID(null)
    setAgreementForm(defaultAgreementForm)
  }

  // Disable the save button in the modal when required information has not been provided
  const disableSaveButton = Boolean(agreementForm && !agreementForm?.content)
  return (
    <>
      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'center' }}>
          {/* Section Title */}
          <Grid item xs={6} container>
            <Typography variant='h5'>Confidentiality Agreements</Typography>
          </Grid>

          {/* 'New' Button */}
          <Grid item xs={6} container justifyContent='flex-end'>
            <Button variant='contained' startIcon={<Add />} onClick={() => handleAddAgreement()}>New Agreement</Button>
          </Grid>
        </Grid>

        <Divider style={{ padding: '.5em 0' }} />

        {/* Section Content */}
        <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ paddingTop: '1em' }}>
          <TableContainer elevation={0}>
            <Table sx={{ ...localClasses.tableSpacing }}>
              <TableBody>
                {/* Table Rows */}
                {promiseInProgress
                  ? (
                    <StyledTableRow style={{ height: '10em' }}>
                      <TableCell colSpan={6}>
                        <StyledLoadingIndicator area={loaderArea.ADMIN_AGREEMENTS} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                      </TableCell>
                    </StyledTableRow>)
                  : (
                    <>
                      {rows.map((row) => {
                        const { agreementID, isCurrent, createdAt } = row
                        const formatDate = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)
                        return (
                          <TableRow
                            key={`agreement-row-${agreementID}`}
                          >
                            <StyledTableCell component='th' scope='row' align='left'>
                              <Grid item container direction='row' alignItems='center'>
                                <Grid xs={smScreenDown ? 12 : null} item sx={isCurrent ? { ...localClasses.currentAgreement } : null} fontWeight={500}>{`${isCurrent ? 'Current ' : ''} Agreement`}</Grid>
                                <Grid display={smScreenDown ? 'none' : 'flex'} item sx={{ ...localClasses.agreementDateSpacing }}>•</Grid>
                                <Grid xs={smScreenDown ? 12 : null} item>
                                  Date created:
                                  <span style={{ marginLeft: '0.3rem' }}>{formatDate}</span>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              <Grid item container alignItems='center'>
                                <Grid item xs={12}>
                                  <Button
                                    style={{
                                      minWidth: 0,
                                      boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                                      backgroundColor: theme?.palette?.grey?.darkGrey,
                                      color: 'white'
                                    }}
                                    onClick={() => handleViewAgreement(row)}
                                  >
                                    View Agreement
                                  </Button>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          </TableRow>
                        )
                      })}
                      {/* Data Array has reached it's length: */}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 10 }}>
                          <TableCell style={{ border: 'none', padding: '0.3rem 0' }} colSpan={6}>
                            <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>)}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalAgreements ? Math.ceil(totalAgreements / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Add/Edit Agreement */}
      <Dialog
        open={agreementModalOpen}
        onClose={() => handleAgreementModalClose()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h5'
              >
                {`${!editAgreementID ? 'Add Agreement' : dateCreated ? `Agreement Created On ${dateCreated}` : 'Agreement'}`}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => handleAgreementModalClose()}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='column' spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontSize={15}
                fontWeight={500}
                color={theme.palette.grey.darkestGrey}
                style={{ marginBottom: '.3rem', marginTop: '.6rem' }}
              >
                Agreement Content
              </Typography>
              <TextField
                variant='outlined'
                margin='dense'
                fullWidth
                disabled={Boolean(editAgreementID)}
                multiline
                rows={10}
                value={agreementForm?.content || ''}
                onChange={(e) => setAgreementForm({ ...agreementForm, content: e.target.value })}
                style={{ marginBottom: editAgreementID ? '1.5rem' : '1rem' }}
                sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: theme.palette.grey.darkGrey } }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        {/* Action Buttons */}
        {!editAgreementID &&
          <DialogActions>
            {errors?.failedAction &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>}
            <Grid container direction='column'>
              <Grid container item direction='row' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600'
                  }}
                  onClick={() => handleAgreementModalClose()}
                >
                  Cancel
                </Button>

                <Tooltip arrow placement='top' title={disableSaveButton ? 'Missing required information.' : ''}>
                  <span>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{
                        margin: '.5em 1em',
                        fontWeight: '600'
                      }}
                      disabled={disableSaveButton}
                      onClick={() => handleSaveNewAgreement()}
                    >
                      {promiseInProgress
                        ? <StyledLoadingIndicator area={loaderArea.ADMIN_AGREEMENTS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                        : 'Save'}
                    </Button>
                  </span>
                </Tooltip>

              </Grid>
            </Grid>
          </DialogActions>}
      </Dialog>
    </>
  )
}

export default AgreementAdministration
