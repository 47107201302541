import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  Grid, Typography, IconButton, Button
} from '@mui/material'

import { CloseRounded } from '@mui/icons-material'
import { EditOptionContent, EditQuestionContent } from '../builderTools'
import { DeleteQuestionOrOptionConfirm } from '../builderModals'
import { builderModalTypes, loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

import { usePromiseTracker } from 'react-promise-tracker'

// Modal to edit a form for either a question or question option
// rendered in BuilderQuestionTable.js
const EditQuestionOrOptionModal = (props) => {
  const {
    // generated in parent component BuilderQuestionTable.js
    editModalOpen = false, handleEditModalClose = () => { }, form = {}, setForm = () => { },
    modalType, handleDeletePopover = () => { }, handleDeleteClose = () => { }, currentSectionID,
    openConfirmDelete, deleteAnchorEl, handleDeleteQuestionOrOption = () => { }, handleEditQuestionOrOption = () => { },
    errors = {},
    optionCount = 1,
    editModalType,
    newSectionDetails,
    // generated in top parent component Builder.js
    classes = {}
  } = props

  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.EDIT_QUESTION_OPTIONS })
  const { assessmentSectionQuestions: sections = [] } = useSelector(state => state.assessment)

  // calculate how many questions are in the assessment currently
  const assessmentTotalQuestions = useMemo(() => {
    const questionCount = sections.reduce((count, section) => {
      if (section?.questions.length) {
        count += section.questions.length
      }
      return count
    }, 0)

    return questionCount ?? 0
  }, [sections])

  // boolean to determine we only have one question left
  const lastQuestion = Boolean(assessmentTotalQuestions === 1)

  // Decided the correct ID from the form to provide to the delete popover
  const id = modalType && modalType === builderModalTypes?.QUESTION ? form?.questionID : form?.questionOptionID

  // decide if we are looking at an 'option' modal type
  const optionModal = Boolean(modalType && (modalType === builderModalTypes.NEW_OPTION || modalType === builderModalTypes.OPTION))
  // build the modal title
  const modalTitle = `${modalType && modalType === builderModalTypes.NEW_OPTION ? 'New' : 'Edit'} ${optionModal ? 'Option' : 'Question'}`

  return (
    <>
      <Dialog
        open={editModalOpen}
        onClose={() => handleEditModalClose()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='h5'>{modalTitle}</Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => handleEditModalClose()}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {/* Content of modal: decided by provided modal type */}
        <DialogContent dividers>
          <Grid container item direction='column' spacing={2}>
            {optionModal
              ? (
                <EditOptionContent classes={classes} form={form} setForm={setForm} optionCount={optionCount} editModalType={editModalType} />)
              : (
                <EditQuestionContent
                  classes={classes}
                  form={form}
                  setForm={setForm}
                  newSectionDetails={newSectionDetails}
                  currentSectionID={currentSectionID}
                />)}
          </Grid>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Grid container direction='column'>
            {errors?.missingRequirements &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter all required information.</Typography>}
            {errors?.invalidLink &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Invalid Resource Link.</Typography>}
            {errors?.invalidRequestedOrder &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Invalid question order.</Typography>}
            {errors?.invalidInput &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Invalid input provided. Cannot contian only special characters or only blank spaces.</Typography>}
            {errors?.failedAction &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Unable to perform action.</Typography>}
            <Grid container item direction='row' justifyContent='space-between'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '400'
                }}
                onClick={() => handleEditModalClose()}
              >
                Cancel
              </Button>

              {modalType && modalType !== builderModalTypes.NEW_OPTION &&
                <Button
                  variant='contained'
                  color='error'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '400'
                  }}
                  onClick={(e) => handleDeletePopover(e, id)}
                >
                  Delete
                </Button>}

              <Button
                variant='contained'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={() => handleEditQuestionOrOption()}
              >
                {promiseInProgress
                  ? <StyledLoadingIndicator area={loaderArea.EDIT_QUESTION_OPTIONS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                  : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Delete Popover */}
      <DeleteQuestionOrOptionConfirm
        openConfirmDelete={openConfirmDelete}
        deleteAnchorEl={deleteAnchorEl}
        handleDeleteQuestionOrOption={handleDeleteQuestionOrOption}
        handleDeleteClose={handleDeleteClose}
        modalType={modalType}
        lastQuestion={lastQuestion}
      />
    </>
  )
}

export default EditQuestionOrOptionModal
