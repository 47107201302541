import React, { useState } from 'react'
import {
  Box, Table, TableHead, TableCell, TableRow, TableSortLabel, Typography,
  Button, TableBody, Grid, useTheme, Select, MenuItem,
  Pagination
} from '@mui/material'
import { EditRounded } from '@mui/icons-material'
import { StyledTableRow, loaderArea, progressComponentTypes } from '../../../utils'
import { usePromiseTracker } from 'react-promise-tracker'
import { StyledLoadingIndicator } from '../../../utils/custom'

const headPuCells = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'Email' }
]

const PartnerUserTable = (props) => {
  const {
    classes = {}, puFilter = {}, setPuFilter = () => { },
    handleAddPartnerUser = () => { }, handleEditPartnerUser = () => { }, totalPu = 0, localPartnerUsers = [], handleUserModalOpen = () => { },
    partnerUserID = null
  } = props

  const theme = useTheme()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.PARTNER_USERS })

  // ********************* Partner User Pagination Logic: **************** //
  const [puPage, setPuPage] = useState(0)

  const handleChangePuPage = (event, value) => {
    if (value >= 0) {
      setPuPage(value - 1)
      setPuFilter({
        ...puFilter,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setPuFilter({
      ...puFilter,
      page: 1,
      sortCount: event.target.value
    })
  }

  // ******************** Partner User Sort Logic **************** //

  const handlePuRequestSort = (property) => {
    const { sortBy, direction } = puFilter
    const isAsc = sortBy === property && direction === 'asc'

    setPuFilter({
      ...puFilter,
      page: 1,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  const emptyPuRows = puFilter?.sortCount - Math.min(puFilter?.sortCount, totalPu - puPage * puFilter?.sortCount)

  return (
    <Box sx={{ margin: 1, paddingBottom: '1em' }}>
      <Table size='small' aria-label='partner users'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} align='left'>
              <Typography variant='body1' fontWeight={500} style={{ fontSize: '17px' }} color={theme.palette.grey.darkestGrey}>Partner Users</Typography>
            </TableCell>
            <TableCell align='right'>
              <Button variant='contained' onClick={() => handleAddPartnerUser()}>Add</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            {headPuCells.map(header => {
              return (
                <TableCell
                  key={header.id}
                  align='left'
                  sortDirection={puFilter?.sortBy === header?.id ? 'asc' : false}
                  colSpan={header.id === 'email' ? 6 : 'auto'}
                >
                  <TableSortLabel
                    active={puFilter?.sortBy === header?.id}
                    direction={puFilter?.sortBy === header?.id ? puFilter?.direction : 'asc'}
                    onClick={() => handlePuRequestSort(header?.id)}
                    sx={{ '.MuiTableSortLabel-icon': { fontSize: '14px' } }}
                  >
                    <Typography variant='body1' style={{ fontSize: '14px' }}>{header?.label}</Typography>
                  </TableSortLabel>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {promiseInProgress
            ? (
              <TableRow style={{ height: 80 }}>
                <TableCell colSpan={12}>
                  <Grid item container direction='row' justifyContent='center' alignItems='center'>
                    <StyledLoadingIndicator
                      area={loaderArea.PARTNER_USERS}
                      loaderType={progressComponentTypes.CIRCULAR_PROGRESS}
                      color='primary'
                    />
                  </Grid>
                </TableCell>
              </TableRow>)
            : (
              <>
                {localPartnerUsers.map((partnerUserRow) => (
                  <TableRow key={partnerUserRow.partnerUserID}>
                    <TableCell component='th' scope='row'>
                      {partnerUserRow.firstName}
                    </TableCell>
                    <TableCell>{partnerUserRow.lastName}</TableCell>
                    <TableCell>{partnerUserRow.email}</TableCell>
                    <TableCell colSpan={6} align='right'>
                      <Grid item container direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                        <Grid item>
                          <Button
                            style={{
                              minWidth: 0,
                              width: '18px',
                              boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                              backgroundColor: partnerUserID.current === partnerUserRow.partnerUserID ? theme?.palette?.grey?.lighterGrey : 'white'
                            }}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleEditPartnerUser(partnerUserRow) }}
                          >
                            <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '16px' }} />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant='contained' onClick={(e) => handleUserModalOpen(partnerUserRow.partnerUserID)}>View Users</Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
                {/* Data Array has reached it's length: */}
                {emptyPuRows > 0 && (
                  <StyledTableRow style={{ height: 10 }}>
                    <TableCell colSpan={7}>
                      <Typography variant='subtitle2' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>{!localPartnerUsers?.length ? 'No partner users assigned.' : 'End of List'}</Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
              </>)}
        </TableBody>
      </Table>
      {/* Pagination */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle2' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={puFilter?.sortCount}
              onChange={(e) => handleChangeRowsPerPage(e)}
              sx={{ ...classes?.searchInput }}
              style={{ padding: 0 }}
              MenuProps={{
                sx: { ...classes?.statusSelect }
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle2' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            sx={{ ...classes?.pagination }}
            count={totalPu ? Math.ceil(totalPu / puFilter?.sortCount) : 0}
            page={puFilter?.page || 1}
            onChange={handleChangePuPage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PartnerUserTable
