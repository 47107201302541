import React from 'react'
import {
  Grid, Typography, Button, useTheme, Tooltip,
  Table, TableContainer, TableHead, TableBody, TableRow, TableCell
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { usePromiseTracker } from 'react-promise-tracker'
import { progressComponentTypes, loaderArea } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

const defaultHeaderPadding = '8px 16px 8px 0'
const headCells = [
  { id: 'optionOrder', label: '', padding: defaultHeaderPadding },
  { id: 'text', label: 'Option', padding: defaultHeaderPadding },
  { id: 'scoreImpact', label: 'Score Impact', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageFB', label: 'Feedback', padding: defaultHeaderPadding },
  { id: 'conditionalLanguageAT', label: 'Action to Take', padding: defaultHeaderPadding }
]

// Renders the details and options currently associated with the selected question
// rendered in CopyQuestionContent.js
const CopyQuestionDetails = (props) => {
  const {
    // generated in parent CopyQuestionContent.js
    row = {},
    expandedQuestionOp = [],
    // Generated in AssessmentSections.js
    handleCopyQuestion = () => { },
    // generated in top most parent Builder.js
    classes = {}
  } = props
  const { questionText = '', explanationText = '', education = '', sectionName = '', helpText = '' } = row
  const theme = useTheme()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.FETCH_QUESTION_OPTIONS })

  return (
    <Grid container direction='column' spacing={1} style={{ margin: '.5em .5em 1em .5em' }}>
      <Grid item container direction='row' spacing={2}>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Question</Typography>
          <Typography variant='subtitle2' gutterBottom>{questionText}</Typography>
        </Grid>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Explanation</Typography>
          <Typography variant='subtitle2' gutterBottom>{explanationText || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Help Text</Typography>
          <Typography variant='subtitle2' gutterBottom>{helpText || 'N/A'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction='row' spacing={2} alignItems='flex-start'>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Section</Typography>
          <Typography variant='subtitle2' gutterBottom>{sectionName || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Resource</Typography>
          <Typography variant='subtitle2' gutterBottom>{education || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={4} container direction='column'>
          <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Resource Type</Typography>
          <Typography variant='subtitle2' gutterBottom>Read</Typography>
        </Grid>
      </Grid>
      <Grid item container direction='column' style={{ margin: '.5em 0' }}>
        <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }} gutterBottom>Question Options</Typography>
        <TableContainer elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      sx={header?.id !== 'scoreImpact' ? { ...classes.smallTableCellContent, maxWidth: '1em' } : classes.smallTableCellContent}
                    >
                      <Typography style={{ fontSize: '14px' }}>{header?.label}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {promiseInProgress ? (
                <TableRow style={{ height: 80 }}>
                  <TableCell colSpan={6}>
                    <Grid item container direction='row' justifyContent='center'>
                      <StyledLoadingIndicator
                        area={loaderArea.FETCH_QUESTION_OPTIONS}
                        loaderType={progressComponentTypes.CIRCULAR_PROGRESS}
                        color='primary'
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {expandedQuestionOp?.map((row, i) => {
                    const { questionOptionID, optionOrder = 0, text = '', scoreImpact = '', conditionalLanguageFB = '', conditionalLanguageAT = 0 } = row
                    return (
                      <TableRow key={`question-options-${questionOptionID}`}>
                        <TableCell align='center' sx={{ ...classes.smallTableCellContent, maxWidth: '1em' }} style={{ color: theme.palette.blue.main, fontWeight: 600 }}>{optionOrder}</TableCell>
                        <Tooltip enterDelay={1000} enterNextDelay={1000} arrow placement='top' title={text || ''}>
                          <TableCell sx={{ ...classes.smallTableCellContent, maxWidth: '10em' }} align='left'>{text}</TableCell>
                        </Tooltip>
                        <TableCell sx={{ ...classes.smallTableCellContent, maxWidth: '1em !important' }} align='center'>{scoreImpact}</TableCell>
                        <Tooltip enterDelay={1000} enterNextDelay={1000} arrow placement='top' title={conditionalLanguageFB || ''}>
                          <TableCell sx={{ ...classes.smallTableCellContent, maxWidth: '8em' }} align='left'>{conditionalLanguageFB || 'N/A'}</TableCell>
                        </Tooltip>
                        <Tooltip enterDelay={1000} enterNextDelay={1000} arrow placement='top' title={conditionalLanguageAT || ''}>
                          <TableCell sx={{ ...classes.smallTableCellContent, maxWidth: '8em' }} align='left'>{conditionalLanguageAT || 'N/A'}</TableCell>
                        </Tooltip>
                      </TableRow>
                    )
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container direction='column' alignItems='center'>
        <Button variant='contained' startIcon={<Add />} onClick={() => handleCopyQuestion({ ...row, questionOptions: [...expandedQuestionOp] })}>Copy Question</Button>
        <Typography
          variant='caption'
          style={{ color: theme.palette.grey.darkGrey, marginTop: '.2em' }}
          gutterBottom
        >
          * You will be able to edit both the question and its options after it is copied.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CopyQuestionDetails
