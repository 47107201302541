import React from 'react'
import { Grid, Divider, List, ListItemText, ListItemButton, styled, Box, useMediaQuery } from '@mui/material'
import theme from '../../assets/styles/MuiTheme'
import { adminSideMenuTabs, partnerUserSideMenuTabs } from '../../utils'
import { getIndustries } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const AdminSideMenuBtn = styled(ListItemButton)(() => ({
  width: '100%',
  padding: '1rem 0.8rem',
  color: theme.palette.grey.darkest,
  '&.Mui-selected': {
    width: '100%',
    backgroundColor: theme.palette.blue.main,
    transition: '0.2s linear',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.blue.main
    }
  }
}))

const AdministrationSideMenu = (props) => {
  const { setCurrentTab, currentTab, adminUser = false, setFilter, defaultFilter } = props

  const dispatch = useDispatch()

  const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'))
  const useStyles = {
    listItemText: {
      color: theme.palette.grey.darkest,
      fontWeight: '500',
      fontSize: '16px'
    },
    dividerStyles: {
      borderBottom: '1px solid #FFFFFF',
      width: '100%'
    },

    boxStyles: {
      width: mdScreenDown ? '100%' : '95%',
      backgroundColor: theme.palette.grey.lighterGrey,
      borderRadius: '5px'
    }
  }

  const handleMenuClick = (tab) => {
    if (tab === adminSideMenuTabs.CODES.tab) { dispatch(getIndustries()) }

    setFilter({ ...defaultFilter })
    setCurrentTab(tab)
  }

  const renderList = () => {
    const menuTabs = adminUser ? adminSideMenuTabs : partnerUserSideMenuTabs
    const arr = Object.keys(menuTabs)

    if (arr && Boolean(arr.length)) {
      return arr.map((item, i) => {
        let borderStyle = {}
        if (i === 0) { borderStyle = { borderRadius: '5px 5px 0px 0px' } }
        if (i === arr.length) { borderStyle = { borderRadius: '0px 0px 5px 5px' } }

        const { label = '', tab = 'N/A' } = menuTabs[item]
        return (
          <div key={`list-item-${tab}-${i}`}>
            <AdminSideMenuBtn
              disableRipple
              style={{ ...borderStyle }}
              selected={currentTab === tab}
              onClick={(e) => { e.preventDefault(); handleMenuClick(tab) }}
            >
              <ListItemText disableTypography sx={useStyles.listItemText} primary={label} />
            </AdminSideMenuBtn>
            {i < arr.length - 1 &&
              <Divider sx={useStyles.dividerStyles} />}
          </div>
        )
      })
    }
    return ''
  }

  return (
    <Box sx={{ boxShadow: 2, ...useStyles.boxStyles }}>
      <Grid container direction='column'>
        <List component='nav' style={{ padding: 0 }}>
          {renderList()}
        </List>
      </Grid>
    </Box>
  )
}

export default AdministrationSideMenu
