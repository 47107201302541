import React, { useEffect, useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { faCheckCircle, faFileDownload, faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Container, Col, Button, FormGroup, Label, Input } from 'reactstrap'
import GradingChart from '../../components/Report/GradingChart'
import SectionResult from '../../components/Report/SectionResult'
import FloatingActionButton from '../../components/Report/FloatingActionButton'
import Loader from '../../components/Loader'
import ImproveActionOne from '../../components/Report/improveActions/ImproveActionOne'
import ImproveActionTwo from '../../components/Report/improveActions/ImproveActionTwo'
import ImproveActionThree from '../../components/Report/improveActions/ImproveActionThree'
import ResultFeedback from '../../components/Report/ResultFeedback'
import FarLeftBox from '../../components/Report/FarLeftBox'
import ReportFooter from '../../components/Report/ReportFooter'
import ConfirmationModalWithInput from '../../components/ConfirmationModalWithInput'
import { utils } from '../../utils/utils'
import { getAssessmentReportPDF, getUserAssessmentResults, shareAssessmentReportPDF, contactExpertAboutAssessment } from '../../redux/actions'
import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import InformationModal from '../../components/InformationModal'

const AssessmentReport = (props) => {
  const { id = '', userID = '', token = '' } = useParams()

  const { company = '' } = useSelector(state => state.auth)
  const { userAssessmentResults } = useSelector(state => state.userAssessment)

  // Used to prevent certain elements from rendering on pdf report
  const pdfReportMode = !!token

  const dispatch = useDispatch()

  const { createdAt = '', firstName = '', lastName = '', sectorName = '', userCompany = '' } = userAssessmentResults
  const formatCreatedAt = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)


  const [pdfLoading, setPdfLoading] = useState(false)
  const [shareResultsLoading, setShareResultsLoading] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)

  // Toggle individual summary sections open or closed based on the id of the section that was clicked (set to open first section by default)
  const [toggleSectionID, setToggleSectionID] = useState(1)

  const toggleSectionOpen = (id) => {
    setToggleSectionID(id)
  }

  // Local state for user assessment results from redux on normal report page, and pdf results
  // State is set on useEffect bellow if params are present from pdf endpoint to allow them to load properly
  const [pageAssessment, setPageAssessment] = useState(null)

  // If the page assessment state is null, and there are userAssessmentResults from redux, and not in report mode, then set the page assessment local state
  useMemo(() => {
    if (pageAssessment === null && userAssessmentResults && !pdfReportMode) {
      setPageAssessment(userAssessmentResults)
    }
  }, [pageAssessment, pdfReportMode, userAssessmentResults])

  // Check if id and token exist from params to get report data for pdf screen grab
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentAssessment = await dispatch(getUserAssessmentResults(userID, id, () => { }, () => { }, token))

        setPageAssessment(currentAssessment)
      } catch (error) {
        console.error(error)
      }
    }

    if (token && id) fetchData()
  }, [dispatch, token, id, userID])

  const onDownloadPdf = async () => {
    if (pdfLoading) { return }
    setPdfLoading(true)

    try {
      const pdfResult = await dispatch(getAssessmentReportPDF(id))

      const sanitizedCompany = company.replace(/[,.\s]/g, '_')

      if (pdfResult) {
        const link = document.createElement('a')
        link.href = pdfResult
        link.download = `AssessmentReport-${sanitizedCompany}`
        document.body.append(link)
        link.click()
        link.remove()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setPdfLoading(false)
    }
  }

  const onShareResults = async (email) => {
    if (shareResultsLoading) return
    setShareResultsLoading(true)


    await dispatch(shareAssessmentReportPDF(id, email, fireShareSuccess, fireShareFailure))
  }

  const fireShareSuccess = () => {
    setShareResultsLoading(false)
    setShowShareModal(false)
  }

  const fireShareFailure = () => {
    setShareResultsLoading(false)
  }

  const defaultModalSettings = {
    isOpen: false,
    title: '',
    body: '',
    onClose: null
  }

  const [informationModal, setInformationModal] = useState({ ...defaultModalSettings })

  const resetInfoModal = () => {
    setInformationModal({ ...defaultModalSettings })
  }

  const fireContactSuccess = () => {
    setInformationModal({
      isOpen: true,
      title: 'Talk to an Expert',
      body: 'Thank you! An expert will be reaching out to you.',
      onClose: resetInfoModal()
    })
  }

  const fireContactFailure = () => {
    setInformationModal({
      isOpen: true,
      title: 'Talk to an Expert',
      body: 'Sorry, we were unable to send the email!',
      onClose: resetInfoModal()
    })
  }

  const onTalkToAnExpert = (info) => {
    dispatch(contactExpertAboutAssessment(info, fireContactSuccess, fireContactFailure))
  }

  // Open a section and then scroll to it. The timeout is there to prevent scrolling issues that occur when a collapsed section is opened.
  const scrollToAndOpenSection = (sectionID) => {
    toggleSectionOpen(sectionID)
    const sectionElement = document.getElementById(`section-${sectionID}`)

    setTimeout(() => {
      if (sectionElement) {
        sectionElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 250)
  };

  return (
    <>
      <Container fluid>
        {pageAssessment &&
          <>
            <Col className={!pdfReportMode ? 'd-flex flex-column' : ''}>
              <div className='d-flex flex-column justify-content-center align-items-center border-bottom pb-5'>
                <h1 className={`text-${utils.getScoreBorderColor(pageAssessment?.assessmentScore)} text-xl`}>{`${pageAssessment?.assessmentScore || 0}%`}</h1>
                <h3>Your Score</h3>
                <ResultFeedback assessmentScore={pageAssessment?.assessmentScore} />
                {!pdfReportMode &&
                  <div className='d-flex justify-content-between align-items-center'>
                    <Button size='lg' color='primary' className='rounded d-flex justify-content-between align-items-center' onClick={onDownloadPdf}>
                      <FontAwesomeIcon icon={faFileDownload} size='lg' />
                      {pdfLoading
                        ? (
                          <div className='min-width-50'>
                            <Loader size='sm' color='white' className='mr-2' />
                          </div>
                        )
                        : (
                          <small className='ml-2'>Download PDF</small>
                        )}
                    </Button>
                    <Button size='lg' color='white' className='ml-2 border-primary rounded text-primary d-flex justify-content-between align-items-center' onClick={() => setShowShareModal(true)}>
                      <FontAwesomeIcon icon={faShareSquare} size='lg' />
                      {
                        shareResultsLoading
                          ? (
                            <div className='min-width-50'>
                              <Loader size='sm' color='white' className='mr-2' />
                            </div>
                          )
                          : (
                            <small className='ml-2'>Share Results</small>
                          )
                      }
                    </Button>
                  </div>}
                {/* At flex sm and up it will be a row, otherwise it's a column */}
                <div className='d-flex flex-column flex-sm-row mt-4 justify-content-around col-12 col-lg-5'>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Typography variant='caption'>Name</Typography>
                    <Typography variant='body1'>{firstName} {lastName}</Typography>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Typography variant='caption'>Company</Typography>
                    <Typography variant='body1'>{userCompany}</Typography>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Typography variant='caption'>Sector</Typography>
                    <Typography variant='body1'>{sectorName}</Typography>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Typography variant='caption'>Date</Typography>
                    <Typography variant='body1'>{formatCreatedAt}</Typography>
                  </div>
                </div>
              </div>
              <div hidden className='pt-5 px-2 mb-4 col-12 col-md-12 col-xl-10 offset-xl-1 px-0'>
                <h3>Top Actions to Improve Score</h3>
                <small>These questions are defined as the top items impacting your performance</small>
                <Row className='mt-4'>
                  <ImproveActionOne reportMode={pdfReportMode} />
                  <ImproveActionTwo reportMode={pdfReportMode} />
                  <ImproveActionThree reportMode={pdfReportMode} />
                </Row>
              </div>
              <div className='border-top pt-3 mb-4'>
                <h4 className='text-center'>Grading by Section</h4>
                <GradingChart />
              </div>
              <div className='border-top pt-5 px-2 mb-2' id='sections-container'>
                <Row>
                  {!pdfReportMode &&
                    <div className='col-12 col-lg-3'>
                      <div className='sticky-top pb-2' style={{ top: '20px', overflowY: 'auto' }}>
                        <h4>Sections</h4>
                        {pageAssessment?.sectionScores?.filter(obj => Boolean(obj.sectionScore || obj.sectionScore === 0))
                          .map(section => {
                            const { sectionID, shortName, sectionScore } = section
                            const active = toggleSectionID === sectionID
                            return (
                              <FormGroup check className='col-12 px-0 cursor-pointer' key={`report-section-${sectionID}-${shortName}`} style={{ marginTop: '.2em' }}>
                                <Label check className={`${active ? 'text-primary border-primary' : ''} cursor-pointer font-weight-300 p-2 border rounded form-check-label col-12 d-flex align-items-center justify-content-between`}>
                                  <div onClick={() => scrollToAndOpenSection(sectionID)}>
                                    <Input
                                      required
                                      className='ml-0'
                                      type='radio'
                                      name='sectionMC'
                                      defaultChecked={active}
                                    />
                                    <FontAwesomeIcon icon={faCheckCircle} className={`text-${active ? 'primary' : 'white'}`} size='lg' />
                                    <span className='ml-2'>{shortName}</span>
                                  </div>
                                  <small className={`text-center min-width-50 rounded-pill px-2 border border-${utils.getScoreBorderColor(sectionScore * 100)} text-white`} style={{ backgroundColor: utils.getScoreBgColor(sectionScore * 100) }}>{`${Math.round(Math.max(sectionScore, 0) * 100)}%`}</small>
                                </Label>
                              </FormGroup>
                            )
                          })}
                        <FarLeftBox
                          reportMode={pdfReportMode}
                          assessmentScore={pageAssessment?.assessmentScore}
                          onTalkToAnExpert={onTalkToAnExpert}
                        />
                        <FloatingActionButton />
                      </div>
                    </div>}
                  <Col className='flex-grow-1'>
                    {pageAssessment?.sections
                      .map((section, index) =>
                        <SectionResult
                          toggleSectionOpen={toggleSectionOpen}
                          toggleSectionID={toggleSectionID}
                          key={section.id}
                          index={index + 1}
                          reportMode={pdfReportMode}
                          section={section}
                          sectionScoreObj={pageAssessment?.sectionScores.find(x => x.sectionID === section.id)}
                          onTalkToAnExpert={onTalkToAnExpert}
                          informationModal={informationModal}
                          setInformationModal={setInformationModal}
                        />
                      )}
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        }

        <ConfirmationModalWithInput
          confirmTextPlaceholder='Enter Email...'
          isOpen={showShareModal}
          loading={shareResultsLoading}
          onClose={() => setShowShareModal(false)}
          setLoading={setShareResultsLoading}
          title='Share Results'
          onSubmit={onShareResults}
        />
        <InformationModal
          isOpen={informationModal.isOpen}
          rawBody
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => informationModal.onClose ? informationModal.onClose() : resetInfoModal()}
        />
      </Container>
      <ReportFooter
        onTalkToAnExpert={onTalkToAnExpert}
        setShowShareModal={setShowShareModal}
      />
    </>
  )
}

export default withRouter(AssessmentReport)
