import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid, Typography, Divider, Button, OutlinedInput, useTheme, TableContainer, TableBody, TableRow,
  TableCell, TableHead, Table, TableSortLabel, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Pagination, Select, MenuItem
  // Collapse, List, ListItemButton, ListItemText, Popover,
} from '@mui/material'
// import { ExpandMore } from '@mui/icons-material'
import { SearchRounded, EditRounded, CloseRounded } from '@mui/icons-material'
import { adminSideMenuTabs, validateEmail, StyledTableRow, loaderArea, progressComponentTypes } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { isEqual } from 'lodash'
import { usePromiseTracker } from 'react-promise-tracker'

import {
  getUsers, updateUser, getPartnerUserCompanies
  // adminCreatedUser, deleteUser, getPartners, getPartnerUsers, updatePartnerUserCompany
} from '../../../redux/actions'

// Init the add/edit form for new or modified users
const defaultUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  company: ''
  // partnerUserID: '',
  // currentPartnerUserID: '',
  // partnerUser: ''
}

const UserAdministration = (props) => {
  const {
    users = [], totalUsers = 0, filter = {}, setFilter = () => { }, defaultFilter = {}, currentTab = '',
    adminUser = false, classes = {}
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.USER_ADMINISTRATION })

  // Table Headers
  const tableHeadCells = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'company', label: 'Company' },
    ...(adminUser ? [{ id: 'edit', label: '' }] : [])
    // { id: 'partnerUser', label: 'Partner User' },
  ]

  const { partnerID = '', userID = '' } = useSelector(state => state.auth)
  // const { partnerList: { partners = [] }, partnerUserList: { partnerUsers = [] } } = useSelector(state => state.partner)

  // Modal/Dialog and form controls
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [editUserID, setEditUserID] = useState(null)
  const [userForm, setUserForm] = useState({ ...defaultUserForm })

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.USERS.tab) {
      if (!isEqual(filter, defaultFilter)) {
        if (adminUser) {
          dispatch(getUsers(filter))
        } else {
          dispatch(getPartnerUserCompanies(partnerID, userID, filter))
        }
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab, partnerID, userID, adminUser])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (users) {
      if (users.length) {
        const newRows = []
        users.forEach(user => {
          const { id, firstName, lastName, email, company, partnerUserID, partnerUser } = user
          newRows.push({ id, firstName, lastName, email, company, partnerUserID, partnerUser })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [users])

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalUsers - page * rowsPerPage)

  // Sort/Filter Logic
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        search: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const defaultErrors = { invalidEmail: false, requiredInformation: false, failedAction: null }
  const [errors, setErrors] = useState({ ...defaultErrors })

  // --- Commenting out full success function until editing a user's PU assignment reimplmented
  // const fireUserSuccess = async () => {
  //   const { partnerUserID, currentPartnerUserID, partnerID } = userForm

  //   if (partnerUserID) {
  //     if (currentPartnerUserID && currentPartnerUserID !== partnerUserID) {
  //       await dispatch(updatePartnerUserCompany(partnerID, currentPartnerUserID, 'remove'))
  //     }

  //     await dispatch(updatePartnerUserCompany(partnerID, partnerUserID, 'add'))
  //   }

  //   handleUserModalClose()
  //   setFilter({ ...defaultFilter })
  //   setPage(0)
  // }

  const fireUserSuccess = async () => {
    handleUserModalClose()
    setFilter({ ...defaultFilter })
    setPage(0)
  }

  const fireFailure = (response) => {
    let res = 'Unable to perform action.'
    if (response) {
      const { msg = '' } = response
      res = msg || 'Unable to perform action.'
    }
    setErrors({ ...defaultErrors, failedAction: res })
  }

  const handleSaveUser = () => {
    const { email, firstName, lastName, company } = userForm

    if (!email || !firstName || !lastName || !company) {
      setErrors({ ...errors, requiredInformation: true })
      return false
    }

    if (!validateEmail(email)) {
      setErrors({ ...errors, invalidEmail: true })
      return false
    }

    const userInfo = {
      email,
      firstName,
      lastName,
      company
    }

    dispatch(updateUser({ userID: editUserID, ...userInfo }, fireUserSuccess, fireFailure))

    // ------- Commenting out until create user is implemented -------
    // if (editUserID) {
    //   dispatch(updateUser(editUserID, userInfo, fireUserSuccess, fireFailure))
    // }
    // else {
    //   dispatch(createUser(userInfo, fireUserSuccess, fireFailure))
    // }
  }

  // Edit a current user
  const handleEditUser = (userInfo) => {
    const { id, firstName, lastName, email, company } = userInfo
    setUserForm({
      id,
      firstName,
      lastName,
      email,
      company
      // currentPartnerUserID: partnerUserID,
      // partnerUser
    })
    setEditUserID(id)
    setUserModalOpen(true)
  }

  // Close the modal/reset state
  const handleUserModalClose = () => {
    setErrors({ ...defaultErrors })
    setUserModalOpen(false)
    setEditUserID(null)
    setUserForm({ ...defaultUserForm })
    // setConfirmAnchorEl(null)
  }

  // Disable the save button in the modal when required information has not been provided
  const disableSaveButton = Boolean(userForm && userForm === defaultUserForm) || !userForm?.firstName || !userForm?.lastName || !userForm?.company || !userForm?.email/* || partnerListOpen */

  // ---- COMMENTING OUT UNTIL DELETE FUNCTIONALITY REIMPLEMENTED ----
  // Confirm popper related elements --- //
  // const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  // const handleConfirmPopover = (e) => {
  //   setConfirmAnchorEl(e.currentTarget)
  // }
  // const handleConfirmPopClose = () => {
  //   setConfirmAnchorEl(null)
  // }
  // const openConfirmPopover = Boolean(confirmAnchorEl)

  // Commenting out until re-implemented
  // const handleDeleteUser = () => {
  //   dispatch(deleteUser(editUserID, fireUserSuccess, fireFailure))
  // }

  //  ---- Commenting out until changing a user's assigned partner user is implmented again in this tab -------
  // const [partnerListOpen, setPartnerListOpen] = useState(false)
  // const [localPartners, setLocalPartners] = useState([])
  // const partnerListLoading = useRef(false)
  // const partnerUsersLoading = useRef(false)
  // const [localPartnerUsers, setLocalPartnerUsers] = useState([])
  // const [selectedPartner, setSelectedPartner] = useState(null)
  // const [selectedPartnerUser, setSelectedPartnerUser] = useState(null)

  // const handleEditPartnerUser = async (partnerID) => {
  //   partnerListLoading.current = true
  //   setPartnerListOpen(true)
  //   const fetch = await dispatch(getPartners())
  //   if (fetch) {
  //     partnerListLoading.current = false
  //     setLocalPartners(partners)
  //   }
  // }

  // const handlePartnerChange = async (id) => {
  //   const setTo = selectedPartner === id ? null : id
  //   setSelectedPartner(setTo)

  //   if (setTo) {
  //     partnerUsersLoading.current = true
  //     const fetchUsers = await dispatch(getPartnerUsers(id))

  //     if (fetchUsers) {
  //       partnerUsersLoading.current = false
  //       setLocalPartnerUsers(partnerUsers)
  //     }
  //   }
  // }

  // const handlePartnerEditClose = () => {
  //   setPartnerListOpen(false)
  //   setSelectedPartner(null)
  //   setLocalPartners([])
  //   setLocalPartnerUsers([])
  //   setSelectedPartnerUser(null)
  // }

  // const handleSelectPartnerUser = (puID, puName) => {
  //   const setPU = selectedPartnerUser === puID ? null : puID
  //   setSelectedPartnerUser(setPU)

  //   if (setPU) {
  //     setUserForm({ ...userForm, partnerUser: puName, partnerUserID: puID, partnerID: selectedPartner })
  //     handlePartnerEditClose()
  //   }
  // }

  return (
    <>
      <Grid container direction='column' style={{}}>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'center' }}>
          {/* Section Title */}
          <Grid item xs={8} container>
            <Typography variant='h5'>Users</Typography>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={4} container justifyContent='flex-end' style={{ paddingRight: '1.5em' }}>
            <OutlinedInput
              style={{ width: '100%' }}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
              }
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>

          {/* 'New' Button */}
          {/* <Grid item xs={2} container justifyContent='center'> */}
          {/* Commenting out till functionality decided around how to handle an admin generated user */}
          {/* <Button variant='contained' startIcon={<Add />} onClick={() => handleAddUser()}>New User</Button> */}
          {/* </Grid> */}
        </Grid>

        <Divider style={{ padding: '.5em 0' }} />

        {/* Section Content */}
        <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
          <TableContainer elevation={0}>
            <Table>
              {/* Table Headers */}
              <TableHead>
                <TableRow>
                  {tableHeadCells.map(header => {
                    return (
                      <TableCell
                        key={header?.id}
                        align='left'
                        sortDirection={orderBy === header?.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header?.id}
                          direction={orderBy === header?.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header?.id)}
                        >
                          <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {/* Table Rows */}
                {promiseInProgress
                  ? (
                    <StyledTableRow style={{ height: '10em' }}>
                      <TableCell colSpan={6}>
                        <StyledLoadingIndicator area={loaderArea.USER_ADMINISTRATION} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                      </TableCell>
                    </StyledTableRow>)
                  : (
                    <>
                      {rows.map((row) => {
                        const { id = '', lastName = '', firstName = '', email = '', company = 'N/A' } = row
                        return (
                          <StyledTableRow
                            key={`user-row-${id}`}
                          >
                            <TableCell component='th' scope='row'>
                              {firstName}
                            </TableCell>
                            <TableCell align='left'>{lastName}</TableCell>
                            <TableCell align='left'>{email}</TableCell>
                            <TableCell align='left'>{company}</TableCell>
                            {/* <TableCell align='left'>{partnerUser || 'N/A'}</TableCell> */}
                            {adminUser &&
                              <TableCell align='center'>
                                <Button
                                  style={{
                                    minWidth: 0,
                                    width: '18px',
                                    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                                    backgroundColor: editUserID === id ? theme?.palette?.grey?.lighterGrey : 'white'
                                  }}
                                  onClick={() => handleEditUser(row)}
                                >
                                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                                </Button>
                              </TableCell>}
                          </StyledTableRow>
                        )
                      })}
                      {/* Data Array has reached it's length: */}
                      {emptyRows > 0 && (
                        <StyledTableRow style={{ height: 10 }}>
                          <TableCell colSpan={6}>
                            <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </>)}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalUsers ? Math.ceil(totalUsers / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Add/Edit User */}
      <Dialog
        open={userModalOpen}
        onClose={() => handleUserModalClose()}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h5'
              >
                {`${!editUserID ? 'Add' : 'Edit'} User`}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => handleUserModalClose()}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='column' spacing={2}>
            <Grid item container direction='row' style={{ marginTop: 0 }}>
              {/* First/Last Name */}
              <Grid item xs={6} style={{ paddingRight: '1em' }}>
                <Typography gutterBottom variant='body1'>First Name<span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  value={userForm?.firstName || ''}
                  onChange={(e) => setUserForm({ ...userForm, firstName: e.target.value })}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '1em' }}>
                <Typography gutterBottom variant='body1'>Last Name<span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  value={userForm?.lastName || ''}
                  onChange={(e) => setUserForm({ ...userForm, lastName: e.target.value })}
                />
              </Grid>
            </Grid>

            {/* Email */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Email<span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={userForm?.email || ''}
                onChange={(e) => setUserForm({ ...userForm, email: e.target.value })}
              />
            </Grid>

            {/* Company NOTE: currently disabled until we hear from client */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Company<span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                disabled
                size='small'
                value={userForm?.company || ''}
                onChange={(e) => setUserForm({ ...userForm, company: e.target.value })}
              />
            </Grid>

            {/* Partner User (Not required) COMMENTING OUT UNTIL IMPLMENTED AGAIN IN THIS TAB */}
            {/* <Grid item container direction='column'>
              <Typography variant='body1'>Partner User</Typography>
              <Grid container item direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <Typography variant='subtitle1' style={{ color: Boolean(!userForm?.partnerUserID || partnerListOpen) ? theme.palette.grey.darkGrey : theme.palette.grey.darkestGrey }}>{userForm?.partnerUserID ? `${userForm?.partnerUser}` : 'None Assigned'}</Typography>
                </Grid>
                <Grid item>
                  <Button variant='text' onClick={() => handleEditPartnerUser()} disabled={partnerListOpen}>Edit</Button>
                  {partnerListOpen &&
                    <Button variant='text' onClick={() => handlePartnerEditClose()}>Cancel</Button>}
                </Grid>
              </Grid>
              <Collapse in={partnerListOpen} unmountOnExit>
                <Divider style={{ borderColor: theme.palette.grey.lighterGrey }} />
                <Grid container style={{ marginTop: '1em' }}>
                  <Typography variant='subtitle2' style={{ color: theme.palette.grey.darkGrey }}>Choose a Partner</Typography>
                  {partnerListLoading.current
                    ? (
                      <Grid item container justifyContent='center' style={{ margin: '.3em' }}>
                        <CircularProgress size={20} />
                      </Grid>
                    )
                    : (
                      localPartners && Boolean(localPartners.length)
                        ? (
                          <Grid item container direction='column' style={{ margin: '.3em' }}>
                            <List style={{ padding: 0 }}>
                              {partners.map((partner) => {
                                const { partnerName = '', partnerID = '' } = partner
                                return (
                                  <>
                                    <ListItemButton onClick={() => handlePartnerChange(partnerID)} dense disableGutters key={partnerID} selected={Boolean(selectedPartner === partnerID)}>
                                      <ListItemText disableTypography primary={<Typography variant='subtitle2'>{partnerName}</Typography>} />
                                      <ExpandMore style={{ color: theme.palette.grey.darkGrey, fontSize: 16 }} />
                                    </ListItemButton>
                                    <Collapse in={Boolean(selectedPartner === partnerID)}>
                                      <List disablePadding>
                                        {partnerUsersLoading.current
                                          ? (
                                            <Grid item container justifyContent='center' style={{ margin: '.3em' }}>
                                              <CircularProgress size={20} />
                                            </Grid>
                                          )
                                          : (
                                            <List disablePadding>
                                              {localPartnerUsers && Boolean(localPartnerUsers.length)
                                                ? (
                                                  localPartnerUsers.map((user) => {
                                                    const { partnerUserID, firstName, lastName } = user
                                                    const concatName = `${firstName} ${lastName}`

                                                    return (
                                                      <ListItemButton key={`partner-${partnerID}-partneruser-${partnerUserID}`} dense onClick={() => handleSelectPartnerUser(partnerUserID, concatName)}>
                                                        <ListItemText disableTypography primary={<Typography variant='subtitle2'>{concatName}</Typography>} />
                                                      </ListItemButton>
                                                    )
                                                  })
                                                )
                                                : (
                                                  <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '.3em' }}>
                                                    <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>No Partners Users Available for this Partner.</Typography>
                                                  </Grid>
                                                )}
                                            </List>
                                          )}
                                      </List>
                                    </Collapse>
                                  </>
                                )
                              })}
                            </List>
                          </Grid>
                        )
                        : (
                          <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '.3em' }}>
                            <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>No Partners Available.</Typography>
                          </Grid>
                        )
                    )}

                </Grid>
              </Collapse>
            </Grid> */}
          </Grid>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Grid container direction='column'>
            {errors?.invalidEmail &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid email address.</Typography>}
            {errors?.requiredInformation &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter all required information.</Typography>}
            {errors?.failedAction &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>}
            <Grid container item direction='row' justifyContent='space-between'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={() => handleUserModalClose()}
              >
                Cancel
              </Button>

              {/* Delete User (hidden when creating new user) COMMENTING OUT UNTIL FURTHER NOTICE */}
              {/* {editUserID &&
                <Button
                  variant='contained'
                  color='error'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600',
                    backgroundColor: confirmAnchorEl ? '#ab2424' : theme?.components?.MuiButton?.styleOverrides?.containedError?.backgroundColor
                  }}
                  onClick={(e) => handleConfirmPopover(e)}
                >
                  Delete User
                </Button>
              } */}

              <Tooltip arrow placement='top' title={disableSaveButton ? 'Missing required information.' : ''}>
                <span>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      margin: '.5em 1em',
                      fontWeight: '600'
                    }}
                    disabled={disableSaveButton}
                    onClick={() => handleSaveUser()}
                  >
                    {promiseInProgress
                      ? <StyledLoadingIndicator area={loaderArea.USER_ADMINISTRATION} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} />
                      : 'Save'}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Popover COMMENTING OUT UNTIL FURTHER NOTICE */}
      {/* <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h6'
            gutterBottom
          >
            Are you sure you want to delete this user?
          </Typography>

          <Typography variant='body1' gutterBottom>This cannot be undone.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} variant='outlined' color='primary' style={{ marginRight: '.5em' }}>Cancel</Button>
            <Button variant='contained' color='error' onClick={() => handleDeleteUser()}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover> */}
    </>
  )
}

export default UserAdministration
