import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { isEqual } from 'lodash'
import {
  Grid, Typography, OutlinedInput, Divider, useTheme, Pagination,
  Select, MenuItem, Button, useMediaQuery
} from '@mui/material'
import { SearchRounded } from '@mui/icons-material'
import { getIndustries, getAssessmentList, createNewAssessment, duplicateAssessment, getSelectedAssessment } from '../../redux/actions'
import { BuilderAssessmentsTable } from './builderTools'
import { usePromiseTracker } from 'react-promise-tracker'
import { progressComponentTypes, loaderArea, newAssessmentOptions, validInputCheck, assessmentTypes, assessmentTypeLabels } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'
import { AddAssessmentModal } from './builderModals'

import { NotificationToast } from '../tools'

const defaultAddAssessmentForm = {
  assessment: {
    assessmentID: '',
    display: '',
    versionNumber: '',
    sectorName: '',
    industryName: '',
    questionCount: 0,
    isActive: 0
  },
  newSectorName: '',
  existingIndustry: {
    industryID: '',
    display: ''
  },
  existingSector: {
    sectorID: '',
    display: ''
  }
}
// Lists the individual assessments per sectorID
// Rendered in Builder.js
const AssessmentList = (props) => {
  // all props passed in from Builder.js (AssessmentBuilder)
  const {
    setViewComponent, viewTypes, classes, assessmentListFilter = {}, setAssessmentListFilter = () => { },
    defaultAssessmentListFilter = {}, currentMaxScore, setCurrentMaxScore = () => { }, parsedProps, setEditMode = () => { }
  } = props
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'))
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.BUILDER_ASSESSMENT_LIST })

  const { industryList: { industries = [] } } = useSelector(state => state.industry)
  const { assessmentList: { assessments = [], totalAssessments = 0 } } = useSelector(state => state.assessment)

  const [localAssessmentList, setLocalAssessmentList] = useState([])

  // If we have not yet fetched industries, do so on load in
  useEffect(() => {
    if (!industries?.length) {
      dispatch(getIndustries())
    }
  }, [industries, dispatch])

  // Reset the local assessmentList if redux information does not match
  useMemo(() => {
    if (!isEqual(assessments, localAssessmentList)) {
      setLocalAssessmentList(assessments)
    }
  }, [localAssessmentList, assessments])

  // listen for the filter to change and pull a new assessment list based on the filter
  useMemo(() => {
    if (assessmentListFilter && defaultAssessmentListFilter) {
      if (!isEqual(assessmentListFilter, defaultAssessmentListFilter)) {
        dispatch(getAssessmentList(assessmentListFilter))
      }
    }
  }, [assessmentListFilter, dispatch, defaultAssessmentListFilter])

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)

      setAssessmentListFilter({
        ...assessmentListFilter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setAssessmentListFilter({
      ...assessmentListFilter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAssessments - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //
  const [orderBy, setOrderBy] = useState('firstName')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setAssessmentListFilter({
      ...assessmentListFilter,
      page: 1,
      sortCount: rowsPerPage,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  const [searchInput, setSearchInput] = useState('')

  // Handle the filter being changed, based on the chosen filter key
  const handleFilterList = (key, val) => {
    if (key) {
      const tempFilter = {
        ...assessmentListFilter,
        [key]: val || '',
        page: 1,
        sortCount: rowsPerPage
      }

      if (key === 'search' && !val) {
        setSearchInput('')
      }

      setPage(0)
      setAssessmentListFilter({ ...tempFilter })
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      handleFilterList('search', e.target.value)
    }

    if (e.target.value === '') {
      setPage(0)
      setAssessmentListFilter({
        ...defaultAssessmentListFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // reset local state on unmount
  useEffect(() => {
    return () => {
      setLocalAssessmentList([])
      setSearchInput('')
      setAssessmentListFilter({})
    }
  }, [setAssessmentListFilter])

  // Props used in the select filter
  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    size: 'small',
    defaultValue: '',
    style: { padding: 0 },
    sx: { ...classes?.searchInput },
    MenuProps: {
      sx: { ...classes?.statusSelect }
    },
    inputProps: {
      sx: { width: 'auto', maxWidth: 100, whitespace: 'normal', [theme.breakpoints.between('md', 'lg')]: { maxWidth: 45 } }
    }
  }

  // --- New/Duplicate Assessment state and handlers --- //
  const [addAssessmentModalOpen, setAddAssessmentModalOpen] = useState(null)
  const [addAssessmentView, setAddAssessmentView] = useState('')

  const [addAssessmentForm, setAddAssessmentForm] = useState({ ...defaultAddAssessmentForm })

  const defaultErrors = {
    invalidSectorName: false, requiredInformation: false, failedAction: null, existingSectorName: false
  }

  const [errors, setErrors] = useState({ ...defaultErrors })

  const handleCloseAddAssessmentModal = () => {
    setAddAssessmentModalOpen(false)
    setAddAssessmentView('')
    setAddAssessmentForm({ ...defaultAddAssessmentForm })
    setErrors({ ...defaultErrors })
  }

  const fireSuccess = (newID) => {
    NotificationToast(false, `Successfully ${addAssessmentView === newAssessmentOptions.CREATE_NEW_ASSESSMENT ? 'created' : 'duplicated'} assessment. Navigating to edit mode...`)
    handleCloseAddAssessmentModal()

    // if we successfully retrieved a new assessmentID, move into the edit mode for the v1 of the new assessment
    if (newID) {
      history.push({
        pathname: '/assessments/builder',
        search: `?assessmentID=${newID}`
      })
      dispatch(getSelectedAssessment(newID, () => { setEditMode(true) }))
    }
  }

  const fireFailure = (info) => {
    if (info.duplicateErr) {
      setErrors({ ...errors, existingSectorName: true })
    }

    NotificationToast(true, `Unable to ${addAssessmentView === newAssessmentOptions.CREATE_NEW_ASSESSMENT ? 'create' : 'duplicate'} assessment.`)
    return false
  }

  const handleAddAssessment = async (view) => {
    let dispatchForm
    const action = view === newAssessmentOptions.CREATE_NEW_ASSESSMENT ? createNewAssessment : duplicateAssessment

    if (view && view !== '') {
      if (view === newAssessmentOptions.CREATE_NEW_ASSESSMENT) {
        const { newSectorName, existingIndustry: { industryID }, existingSector: { sectorID } } = addAssessmentForm

        if ((!newSectorName && !sectorID) || !industryID) { return setErrors({ ...errors, requiredInformation: true }) }

        if (newSectorName && ((newSectorName.length > 255) || (validInputCheck?.onlyBlankSpaces.test(newSectorName) || validInputCheck?.onlySpecialChars.test(newSectorName)))) {
          return setErrors({ ...errors, invalidSectorName: true })
        }

        dispatchForm = {
          industry: industryID,
          assessmentType: sectorID ? assessmentTypes.ALTERNATE : assessmentTypes.PRIMARY // NOTE: this will need to be removed once we allow for multiple primaries
        }

        if (newSectorName) { dispatchForm.sector = newSectorName.trim() }
        if (sectorID) { dispatchForm.sectorID = sectorID }
      }

      if (view === newAssessmentOptions.DUPLICATE_ASSESSMENT) {
        const { assessment: { assessmentID }, existingIndustry: { industryID }, newSectorName, existingSector: { sectorID } } = addAssessmentForm

        if (!assessmentID || assessmentID.length === 0 || (!newSectorName && !sectorID) || (newSectorName.length === 0 && sectorID.length === 0) || !industryID || industryID.length === 0) {
          return setErrors({ ...errors, requiredInformation: true })
        }

        if (newSectorName && ((newSectorName.length > 255) || (validInputCheck?.onlyBlankSpaces.test(newSectorName) || validInputCheck?.onlySpecialChars.test(newSectorName)))) {
          return setErrors({ ...errors, invalidSectorName: true })
        }

        dispatchForm = {
          assessmentID: assessmentID,
          newOrigin: true,
          assessmentType: sectorID ? assessmentTypes.ALTERNATE : assessmentTypes.PRIMARY // NOTE: this will need to be removed once we allow for multiple primaries
        }

        if (newSectorName) { dispatchForm.sector = newSectorName.trim() }
        if (sectorID) { dispatchForm.sectorID = sectorID }
        if (industryID) { dispatchForm.industry = industryID }
      }

      if (dispatchForm && Object.keys(dispatchForm).length) {
        dispatch(action(dispatchForm, fireSuccess, fireFailure))
      }
    }
  }

  const handleViewQuestions = (assessment) => {
    setCurrentMaxScore(assessment.maxScore)
    dispatch(getSelectedAssessment(assessment.assessmentID))
    window.scrollTo({ top: 0 })
    setViewComponent(viewTypes.SECTIONS)
    history.push({
      pathname: '/assessments/builder',
      search: `?assessmentID=${assessment.assessmentID}`
    })
  }

  return (
    <>
      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'center' }}>
          {/* Section Title */}
          <Grid item xs={4} container sx={{ marginBottom: mdScreenDown ? '0.5em' : 0 }}>
            <Typography variant='h5' style={{ fontSize: '22px' }}>Assessments</Typography>
          </Grid>

          {/* Industry Filter  */}
          <Grid item container direction='row' alignItems='center' justifyContent={mdScreenDown ? 'flex-start' : 'center'} xs={12} lg={5} spacing={2}>
            <Grid item>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Industry:</Typography>
              <Select
                {...commonSelectProps}
                disabled={Boolean(!industries || !industries.length)}
                value={assessmentListFilter?.industryID || ''}
                onChange={(e) => handleFilterList('industryID', e.target.value)}
              >
                <MenuItem value=''>All</MenuItem>
                {industries?.map(industry => {
                  return (
                    <MenuItem key={`industry-${industry.id}`} value={industry.id}>{industry.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Type:</Typography>
              <Select
                {...commonSelectProps}
                value={assessmentListFilter?.assessmentType || ''}
                onChange={(e) => handleFilterList('assessmentType', e.target.value)}
              >
                <MenuItem value=''>All</MenuItem>
                <MenuItem value={assessmentTypes.PRIMARY}>{assessmentTypeLabels.P}</MenuItem>
                <MenuItem value={assessmentTypes.ALTERNATE}>{assessmentTypeLabels.A}</MenuItem>
              </Select>
            </Grid>

            <Grid item>
              <Button variant='contained' onClick={() => setAddAssessmentModalOpen(true)}>Add Assessment</Button>
            </Grid>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={12} lg={3} container justifyContent='flex-end' sx={{ marginTop: mdScreenDown ? '0.5em' : 0 }}>
            <OutlinedInput
              style={{ width: '100%' }}
              value={searchInput}
              onChange={(e) => handleSearch(e)}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
              }
            />
          </Grid>
        </Grid>
        <Divider style={{ padding: '.5em 0' }} />

        {promiseInProgress &&
          <StyledLoadingIndicator
            area={loaderArea.BUILDER_ASSESSMENT_LIST}
            loaderType={progressComponentTypes.LINEAR_PROGRESS}
            style={{ height: 3 }}
          />}

        {/* Assessment List Table */}
        <Grid item container direction='column' style={{ paddingTop: '.5em' }}>
          <BuilderAssessmentsTable
            emptyRows={emptyRows}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            rows={localAssessmentList}
            setAssessmentListFilter={setAssessmentListFilter}
            defaultAssessmentListFilter={defaultAssessmentListFilter}
            parsedProps={parsedProps}
            currentMaxScore={currentMaxScore}
            handleViewQuestions={handleViewQuestions}
          />
        </Grid>

        {/* Pagination */}
        <Grid item container direction='row' style={{ margin: '1em 0' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalAssessments ? Math.ceil(totalAssessments / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Modal that allows user to either create a new assessment or duplicate an existing one */}
      {addAssessmentModalOpen &&
        <AddAssessmentModal
          addAssessmentModalOpen={addAssessmentModalOpen}
          handleCloseAddAssessmentModal={handleCloseAddAssessmentModal}
          addAssessmentForm={addAssessmentForm}
          setAddAssessmentForm={setAddAssessmentForm}
          addAssessmentView={addAssessmentView}
          setAddAssessmentView={setAddAssessmentView}
          errors={errors}
          setErrors={setErrors}
          handleAddAssessment={handleAddAssessment}
          defaultErrors={defaultErrors}
          defaultAddAssessmentForm={defaultAddAssessmentForm}
        />}
    </>
  )
}

export default AssessmentList
