import axios from 'axios'
import config from '../config/config'

export const authApi = {
  captchaVerify: (token) =>
    axios('/api/auth/captcha-verify', {
      baseURL: config.apiURL,
      method: 'post',
      data: { token }
    }).then((result) => result.data),
  validateDiscountCode: (data) =>
    axios(`/api/discount-codes/${data.code}`, {
      baseURL: config.apiURL,
      method: 'get'
    }).then((result) => result.data)
}
