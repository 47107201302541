import {
  GET_SECTORS, LOGOUT_USER
} from '../types'

const initialState = {
  sectorList: {
    sectors: [],
    totalSectors: 0
  }
}

const sectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTORS: return {
      ...state,
      sectorList: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default sectorReducer
