import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { login, userSignup } from '../../../redux/actions'
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  FormFeedback
} from 'reactstrap'

import { ValidatePhone } from '../../../utils'

import { authApi } from '../../../services/authServices'
import Loader from '../../../components/Loader'
import { faBell, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { usePromiseTracker } from 'react-promise-tracker'
import queryString from 'query-string'

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import InformationModal from '../../../components/InformationModal'

const PRICE = 299

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#6c757d',
      color: '#6c757d',
      fontWeight: 500,
      fontFamily: 'Lato',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#6c757d' },
      '::placeholder': { color: '#6c757d' }
    },
    invalid: {
      iconColor: '#dc3545',
      color: '#dc3545'
    }
  }
}

export const SignupForm = (props) => {
  const { location } = props

  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker()

  const parsedProps = queryString.parse(location?.search)
  const industry = parsedProps?.industry || null
  const sector = parsedProps?.sector || null
  const assessment = parsedProps?.assessment || null

  const [discountCodeLoading, setDiscountCodeLoading] = useState(false)
  const [appliedCode, setAppliedCode] = useState(null)
  const [invalidCodeError, setInvalidCodeError] = useState()
  const [appliedCodeAlert, setAppliedCodeAlert] = useState()
  const [existingEmailError, setExistingEmailError] = useState(false)
  const [removedCodeAlert, setRemovedCodeAlert] = useState()
  // Need if auto applying code
  const [discountRemoved, setDiscountRemoved] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)

  const [catpchaError, setCatpchaError] = useState()

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    company: '',
    companyURL: '',
    discountCode: ''
  })

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null
  })

  const [error, setError] = useState(false)

  const fireFailure = (response) => {
    if (response) {
      const { duplicateErr = false } = response
      if (duplicateErr) {
        setError(false)
        setExistingEmailError(true)
      } else {
        setError('There was an error with your request, please try again.')
        return null
      }
    }
  }

  const fireLoginFailure = (response) => {
    if (response) {
      setError('There was an error with your request, please try again.')
      return null
    }
  }

  const fireSuccess = () => {
    dispatch(login({ email: userData.email, password: userData.password }, fireLoginFailure))
  }

  const removeDiscountCode = async () => {
    setAppliedCodeAlert(false)
    setRemovedCodeAlert(true)
    setDiscountRemoved(true)
    setAppliedCode(null)
    setUserData({ ...userData, discountCode: '' })
  }

  const applyDiscountCode = useCallback(async () => {
    setDiscountCodeLoading(true)
    setDiscountRemoved(false)
    setRemovedCodeAlert(false)

    try {
      const codeRes = await authApi.validateDiscountCode({ code: userData.discountCode })

      if (codeRes?.code && codeRes?.discount) {
        setAppliedCodeAlert(true)
        setAppliedCode(codeRes)
        setInvalidCodeError(false)
        if (discountRemoved) { setDiscountRemoved(false); setRemovedCodeAlert(false) }
      } else {
        setInvalidCodeError(true)
      }
      setDiscountCodeLoading(false)
    } catch (err) {
      setAppliedCodeAlert(false)
      setAppliedCode(null)
      setInvalidCodeError(true)
      setDiscountCodeLoading(false)
    }
  }, [userData, discountRemoved])

  // NOTES: the following useMemo and useEffect are slightly redundant in their logic to fire. We have decided to keep it this way to avoid 1000 loops when a user loads in, removes, applies and manually types a code
  // if you find a way to reduce the redundancy and still not freak it out, feel free to do so.

  // if a code is in the url, and there is not one in the user form BUT it has not been previously applied or removed, set it to the user form
  useMemo(() => {
    if (parsedProps?.code && !appliedCode && !discountRemoved && !userData.discountCode) {
      setUserData({ ...userData, discountCode: parsedProps.code })
    }
  }, [parsedProps?.code, appliedCode, userData, discountRemoved])

  // if there is a code in the params, it has not been removed or applied and the code in the form matches the code in the params, automatically apply discount code from params
  useEffect(() => {
    if (parsedProps?.code && !appliedCode && !discountRemoved && userData.discountCode === parsedProps?.code) {
      applyDiscountCode()
    }
  }, [parsedProps?.code, userData.discountCode, applyDiscountCode, appliedCode, discountRemoved])

  const handleInvalidPhoneCheck = (e) => {
    const delayDebounceFn = setTimeout(() => {
      const value = e.target.value

      if (value && !ValidatePhone(value)) {
        setError('Invalid phone number.')
        setInvalidPhone(true)
      } else {
        setError(false)
        setInvalidPhone(false)
      }
      return () => clearTimeout(delayDebounceFn)
    }, 1000)
  }

  const onDismiss = () => {
    setError(false)
    setExistingEmailError(false)
  }

  const doSignup = async (e) => {
    e.preventDefault()
    let error = false
    let paymentMethod = null

    if (!appliedCode || appliedCode.discount < 1) {
      if (!stripe) {
        setError('Missing or invalid payment method.')
        return false
      }
      const stripePayment = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      })

      error = stripePayment.error
      paymentMethod = stripePayment.paymentMethod
    }

    const tempUserData = { ...userData }

    if (tempUserData?.companyURL) {
      tempUserData.companyURL = tempUserData.companyURL.toLocaleLowerCase()
    }

    if (tempUserData?.phoneNumber) {
      if (!ValidatePhone(tempUserData.phoneNumber)) {
        setError('Invalid phone number.')
        setInvalidPhone(true)
        return false
      } else {
        tempUserData.phoneNumber = tempUserData.phoneNumber.replace(/[^0-9.]+/g, '')
      }
    }

    if (!error) {
      try {
        dispatch(userSignup({
          ...tempUserData,
          preferredSector: parsedProps?.sector ? Number(parsedProps.sector) : null,
          preferredIndustry: parsedProps?.industry ? Number(parsedProps.industry) : null,
          paymentMethodId: paymentMethod?.id || null
        }, fireSuccess, fireFailure))
      } catch (err) {
        console.log(err)
        setError('There was an error, please try again.')
      }
    } else {
      setError('There was an error with your payment, please try again.')
    }
  }

  const handleVerifyCaptcha = token =>
    authApi.captchaVerify(token)
      .then(result => {
        if (!result?.success) {
          setCatpchaError('It seems you are a robot, please try again refreshing the page.')
        }
      })

  const getPrice = () => `$${appliedCode ? PRICE * (1 - appliedCode.discount) : PRICE}`

  return (
    <Col className='col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3'>
      <div className='text-left'>
        <small className='text-primary text-capitalize'>Purchase Our Assessment</small>
        <h3>Investment Grade Assessment</h3>
        <div className='my-3'>
          <i className='text-primary'>Price:
            <span className={`ml-1 ${appliedCode ? 'text-strikethrough text-danger' : ''}`}>${PRICE}</span>
            {appliedCode ? <span className='ml-1 text-success'>{getPrice()}</span> : null}
          </i>
        </div>
      </div>
      <Card>
        <CardBody className='px-0'>
          <div>
            <Form onSubmit={doSignup}>
              <FormGroup>
                <Label>
                  <span className='font-600'>Name</span>
                  <span className='text-danger ml-1'>*</span>
                </Label>
                <Row className='d-flex justify-content-between'>
                  <div className='col-12 col-lg-6'>
                    <Input
                      maxLength='99'
                      required
                      bsSize='lg'
                      name='firstName'
                      placeholder='First'
                      onChange={event => setUserData({
                        ...userData,
                        firstName: event.currentTarget.value.replace(/[\u2018\u2019]/g, "'")
                      })}
                    />
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Input
                      maxLength='99'
                      required
                      bsSize='lg'
                      name='lastName'
                      placeholder='Last'
                      onChange={event => setUserData({
                        ...userData,
                        lastName: event.currentTarget.value.replace(/[\u2018\u2019]/g, "'")

                      })}
                    />
                  </div>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Email</span>
                  <span className='text-danger ml-1'>*</span>
                </Label>
                <Input
                  maxLength='99'
                  required
                  bsSize='lg'
                  autoComplete='off'
                  name='email'
                  type='email'
                  className='mb-1'
                  placeholder='Email'
                  onChange={event => setUserData({
                    ...userData,
                    email: event.currentTarget.value
                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Phone Number</span>
                  <span className='text-secondary ml-1'>(optional)</span>
                </Label>
                <Input
                  type='tel'
                  bsSize='lg'
                  name='phone-number'
                  className='mb-1'
                  placeholder='Phone Number'
                  invalid={invalidPhone}
                  onKeyDown={(event) => handleInvalidPhoneCheck(event)}
                  onChange={event => setUserData({
                    ...userData,
                    phoneNumber: event.currentTarget.value
                  })}
                />
                <FormFeedback>Must contain 10 numbers - No letters are allowed</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Create a Password</span>
                  <span className='text-danger ml-1'>*</span>
                </Label>
                <Input
                  required
                  maxLength='99'
                  bsSize='lg'
                  type='password'
                  name='password'
                  autoComplete='new-password'
                  placeholder='Password'
                  onChange={event => setUserData({
                    ...userData,
                    password: event.currentTarget.value
                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Company</span>
                  <span className='text-danger ml-1'>*</span>
                </Label>
                <Input
                  maxLength='99'
                  required
                  bsSize='lg'
                  name='company'
                  className='mb-1'
                  placeholder='Company'
                  onChange={event => setUserData({
                    ...userData,
                    company: event.currentTarget.value.replace(/[\u2018\u2019]/g, "'")

                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Website</span>
                  <span className='text-secondary ml-1'>(optional)</span>
                </Label>
                <Input
                  maxLength='99'
                  bsSize='lg'
                  name='website'
                  className='mb-1'
                  placeholder='Website'
                  autoComplete='off'
                  onChange={event => setUserData({
                    ...userData,
                    companyURL: event.currentTarget.value
                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span className='font-600'>Discount Code</span>
                  <span className='text-danger ml-1'>*</span>
                </Label>
                <div className='d-flex align-items-center'>
                  <Input
                    maxLength='99'
                    required
                    bsSize='lg'
                    name='discountCode'
                    placeholder='Discount Code..'
                    value={userData.discountCode}
                    onChange={event => setUserData({
                      ...userData,
                      discountCode: event.currentTarget.value
                    })}
                  />
                  {discountCodeLoading
                    ? (
                      <div className='min-width-50 ml-4'><Loader size='sm' /></div>)
                    : appliedCode
                      ? (
                        <Button
                          className='ml-4'
                          color='primary'
                          onClick={removeDiscountCode}
                          size='lg'
                        >
                          Remove
                        </Button>)
                      : (
                        <Button
                          className='ml-4'
                          color='primary'
                          onClick={applyDiscountCode}
                          size='lg'
                          disabled={Boolean(!userData?.discountCode)}
                        >
                          Apply
                        </Button>)}
                </div>
              </FormGroup>
              {appliedCode?.discount !== 1 &&
                <div>
                  <Label>
                    <span className='font-600'>Credit Card</span>
                    <span className='text-danger ml-1'>*</span>
                  </Label>
                  <FormGroup className='payment-container border' data-private='lipsum'>
                    <CardElement options={CARD_OPTIONS} />
                  </FormGroup>
                </div>}
              <div className='d-flex justify-content-end'>
                <small>
                  <Link
                    to={
                      `/auth/sign-in${parsedProps ? '?' : ''}${industry ? `industry=${industry}` : ''}${industry ? '&' : ''}${sector ? `sector=${sector}` : ''}${industry || sector ? '&' : ''}${assessment ? `assessment=${assessment}` : ''}`
                    }
                  >
                    Already have an account? Log In!
                  </Link>
                </small>
              </div>
              <div className='text-center mt-3'>
                <GoogleReCaptchaProvider reCaptchaKey='6LfEUyshAAAAAFM3ylGBfmM5HPvzOQ6yKTOBAk-h'>
                  <GoogleReCaptcha onVerify={handleVerifyCaptcha} />
                </GoogleReCaptchaProvider>
                <UncontrolledAlert
                  isOpen={!!invalidCodeError}
                  toggle={() => {
                    setInvalidCodeError(false)
                    setUserData({
                      ...userData,
                      discountCode: ''
                    })
                  }}
                  color='warning'
                >
                  <div className='alert-icon'>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className='font-600 alert-message text-left d-flex align-items-center'>
                    <span className='ml-2'>Invalid discount code</span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert isOpen={!!appliedCodeAlert} color='success' toggle={() => setAppliedCodeAlert()}>
                  <div className='alert-icon'>
                    <FontAwesomeIcon icon={faCheck} fixedWidth />
                  </div>
                  <div className='font-600 alert-message text-left d-flex align-items-center'>
                    <span className='ml-2'>Discount code applied!</span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert isOpen={!!removedCodeAlert} color='warning' toggle={() => setRemovedCodeAlert()}>
                  <div className='alert-icon'>
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </div>
                  <div className='font-600 alert-message text-left d-flex align-items-center'>
                    <span className='ml-2'>Discount code removed</span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert className='alert-outline' isOpen={!!existingEmailError} toggle={onDismiss} color='danger'>
                  <div className='alert-icon d-flex align-items-center'>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className='alert-message text-left'>
                    <div>
                      <small className='font-600'>This email is already in use.</small>
                    </div>
                    <div className='mt-2'>
                      <span>
                        Please return to <Link to='/auth/sign-in' className='font-600'>log in</Link> with this email,
                        or use <Link to='/auth/reset-password' className='font-600'>forgot password</Link> to reset the password.
                      </span>
                    </div>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert isOpen={!!error && !existingEmailError} toggle={onDismiss} color='warning'>
                  <div className='alert-icon'>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className='font-600 alert-message text-left d-flex align-items-center'>
                    <span className='ml-2'>{error}</span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert className='alert-outline' isOpen={!!catpchaError} color='danger'>
                  <div className='alert-icon d-flex align-items-center'>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className='alert-message text-left pr-4'>
                    <small>{catpchaError}</small>
                  </div>
                </UncontrolledAlert>
                {promiseInProgress
                  ? (
                    <Loader size='sm' />)
                  : (
                    <Button
                      color='primary'
                      type='submit'
                      size='lg'
                      disabled={error && error.length !== 0}
                      style={{ cursor: error ? 'not-allowed' : '' }}
                    >
                      Sign Up
                    </Button>)}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
      {informationModal.isOpen &&
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => informationModal.onClose ? informationModal.onClose() : setInformationModal({ isOpen: false, title: '', body: '', onClose: null })}
        />}
    </Col>
  )
}
