import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Typography, Divider, Button, OutlinedInput, useTheme, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  Popover, Tooltip, Select, Pagination, MenuItem
} from '@mui/material'
import { isEqual } from 'lodash'
import { Add, SearchRounded, EditRounded, CloseRounded } from '@mui/icons-material'
import { StyledTableRow, adminSideMenuTabs, validateEmail, progressComponentTypes, loaderArea } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { getAdmins, updateAdmin, createAdmin, deleteAdmin } from '../../../redux/actions'
import { usePromiseTracker } from 'react-promise-tracker'
import { DuplicateUserToast } from '../../tools'

const defaultAdminForm = {
  firstName: '',
  lastName: '',
  email: ''
}

const AdminAdministration = (props) => {
  const {
    admins = [], totalAdmins = 0, filter = {}, setFilter = () => { }, defaultFilter = {},
    currentTab = '', classes = {}
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ADMIN_ADMINISTRATION })

  // ********************* New/Edit Admin logic: ******************** //
  const [adminForm, setAdminForm] = useState({ ...defaultAdminForm })
  const errorCheck = Boolean((adminForm && adminForm === defaultAdminForm) || !adminForm?.firstName || !adminForm?.lastName || !adminForm?.email)

  // Edit State
  const [editAdminID, setEditAdminID] = useState(null)
  const [adminModalOpen, setAdminModalOpen] = useState(false)

  // Error logic
  const defaultErrors = { invalidEmail: false, requiredInformation: false, failedAction: null }
  const [errors, setErrors] = useState({ ...defaultErrors })

  // Add a new admin modal
  const handleAddAdmin = () => {
    setAdminModalOpen(true)
  }

  // Open the modal to edit a current admin
  const handleEditAdmin = (adminInfo) => {
    const { adminID, firstName, lastName, email } = adminInfo
    setAdminForm({
      firstName,
      lastName,
      email
    })
    setEditAdminID(adminID)
    setAdminModalOpen(true)
  }

  // Assign the correct dispatch action to send to the confirm-plus-address toast
  const triggerPlusAddressPop = async (duplicateUser) => {
    // assign in the duplicate userID that returned from the initial api call to the body
    const info = { ...adminForm, duplicateUser }
    const action = () => dispatch(createAdmin(info, fireAdminSuccess, fireFailure))
    DuplicateUserToast(action)
  }

  // On success, reset and close the modal
  const fireAdminSuccess = async () => {
    handleCloseAdminModal()
    setFilter({ ...defaultFilter })
    setPage(0)
  }

  const fireFailure = (response) => {
    let res = 'Unable to perform action.'
    if (response) {
      const { message = '' } = response
      res = message || 'Unable to perform action.'

      // if we got a specific 409 status returned, trigger the toast to request confirmation to plus address the original user email
      if (response.duplicateUser) {
        return triggerPlusAddressPop(response.duplicateUser)
      }
    }
    setErrors({ ...defaultErrors, failedAction: res })
  }

  // Save the form
  const handleSaveAdmin = () => {
    const { email, firstName, lastName } = adminForm

    // Check for errors
    if (!email || !firstName || !lastName) {
      setErrors({ ...errors, requiredInformation: true })
      return false
    }

    // check for invalid email
    if (!validateEmail(email)) {
      setErrors({ ...errors, invalidEmail: true })
      return false
    }

    const adminInfo = {
      email,
      firstName,
      lastName
    }

    // if editing, update the admin, otherwise create a new admin
    if (editAdminID) {
      dispatch(updateAdmin({ userID: editAdminID, ...adminInfo }, fireAdminSuccess, fireFailure))
    } else {
      dispatch(createAdmin(adminInfo, fireAdminSuccess, fireFailure))
    }
  }

  const handleDeleteAdmin = () => {
    dispatch(deleteAdmin(editAdminID, fireAdminSuccess, fireFailure))
  }

  // Confirm popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  const handleConfirmPopover = (e) => {
    setConfirmAnchorEl(e.currentTarget)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)
  // ----- *** ----- //

  const handleCloseAdminModal = () => {
    setConfirmAnchorEl(null)
    setAdminModalOpen(false)
    setErrors({ ...defaultErrors })
    setEditAdminID(null)
    setAdminForm({ ...defaultAdminForm })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        search: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ******************** Data Creation **************** //
  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.ADMINS.tab) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getAdmins(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (admins) {
      if (admins.length) {
        const newRows = []
        admins.forEach(admin => {
          const { adminID, firstName, lastName, email } = admin
          newRows.push({ adminID, firstName, lastName, email })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [admins])

  const headCells = [
    {
      id: 'firstName',
      label: 'First Name'
    },
    {
      id: 'lastName',
      label: 'Last Name'
    },
    {
      id: 'email',
      label: 'Email'
    }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAdmins - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //
  const [orderBy, setOrderBy] = useState('firstName')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  return (
    <>
      <Grid container direction='column'>
        {/* Section Header */}
        <Grid item container direction='row' style={{ alignItems: 'center' }}>
          {/* Section Title */}
          <Grid item xs={6} container>
            <Typography variant='h5'>Admins</Typography>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={4} container justifyContent='flex-end' style={{ paddingRight: '1.5em' }}>
            <OutlinedInput
              style={{ width: '100%' }}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
              }
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>

          {/* 'New' Button */}
          <Grid item xs={2} container justifyContent='center'>
            <Button variant='contained' startIcon={<Add />} onClick={() => handleAddAdmin()}>New Admin</Button>
          </Grid>
        </Grid>
        <Divider style={{ padding: '.5em 0' }} />
        {/* Section Content */}
        <Grid item container direction='column' style={{ paddingTop: '1.5em' }}>
          <TableContainer elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align='left'
                        sortDirection={orderBy === header?.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header?.id}
                          direction={orderBy === header?.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header?.id)}
                        >
                          <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                  <TableCell align='left' style={{ padding: '16px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Temporary frontend sorting */}
                {
                  promiseInProgress
                    ? (
                      <StyledTableRow style={{ height: '10em' }}>
                        <TableCell colSpan={6}>
                          <StyledLoadingIndicator area={loaderArea.ADMIN_ADMINISTRATION} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                        </TableCell>
                      </StyledTableRow>)
                    : (
                      <>
                        {rows.map((row) => {
                          const { adminID = '', lastName = '', firstName = '', email = '' } = row
                          return (
                            <StyledTableRow key={`admin-row-${adminID}`}>
                              <TableCell component='th' scope='row'>{firstName}</TableCell>
                              <TableCell align='left'>{lastName}</TableCell>
                              <TableCell align='left'>{email}</TableCell>
                              <TableCell align='center'>
                                <Button
                                  style={{
                                    minWidth: 0,
                                    width: '18px',
                                    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`,
                                    backgroundColor: editAdminID === adminID ? theme?.palette?.grey?.lighterGrey : 'white'
                                  }}
                                  onClick={() => handleEditAdmin(row)}
                                >
                                  <EditRounded style={{ color: theme?.palette?.blue?.main, fontSize: '18px' }} />
                                </Button>
                              </TableCell>
                            </StyledTableRow>
                          )
                        })}
                        {/* Data Array has reached it's length: */}
                        {emptyRows > 0 && (
                          <StyledTableRow style={{ height: 10 }}>
                            <TableCell colSpan={6}>
                              <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>End of List</Typography>
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Pagination */}
        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalAdmins ? Math.ceil(totalAdmins / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Add/Edit User */}
      <Dialog
        open={adminModalOpen}
        onClose={() => handleCloseAdminModal()}
        style={{ zIndex: 1060 }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h5'
              >
                {`${!editAdminID ? 'Add' : 'Edit'} Admin`}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => handleCloseAdminModal()}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='column' spacing={2}>
            {/* First Name */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>First Name</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={adminForm?.firstName || ''}
                onChange={(e) => setAdminForm({ ...adminForm, firstName: e.target.value })}
              />
            </Grid>

            {/* Last Name */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Last Name</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={adminForm?.lastName || ''}
                onChange={(e) => setAdminForm({ ...adminForm, lastName: e.target.value })}
              />
            </Grid>

            {/* Email */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1'>Email</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={adminForm?.email || ''}
                onChange={(e) => setAdminForm({ ...adminForm, email: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Grid container direction='column'>
            {errors?.invalidEmail &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter a valid email address.</Typography>}
            {errors?.requiredInformation &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>* Please enter all required information.</Typography>}
            {errors?.failedAction &&
              <Typography variant='caption' style={{ color: 'red', textAlign: 'right' }}>{`* ${errors?.failedAction || 'Unable to perform action.'}`}</Typography>}
            <Grid container item direction='row' justifyContent='space-between'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '400'
                }}
                onClick={() => handleCloseAdminModal()}
              >
                Cancel
              </Button>

              {/* Delete User (hidden when creating new user) */}
              {editAdminID &&
                <Button
                  variant='contained'
                  color='error'
                  style={{
                    margin: '.5em 1em',
                    fontWeight: '600',
                    backgroundColor: confirmAnchorEl ? '#ab2424' : theme?.components?.MuiButton?.styleOverrides?.containedError?.backgroundColor

                  }}
                  onClick={(e) => handleConfirmPopover(e)}
                >
                  Delete
                </Button>}

              <Tooltip arrow placement='top' title={errorCheck ? 'Missing required information.' : ''}>
                <span>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      margin: '.5em 1em',
                      fontWeight: '600'
                    }}
                    disabled={errorCheck}
                    onClick={() => handleSaveAdmin()}
                  >
                    {promiseInProgress ? <StyledLoadingIndicator area={loaderArea.ADMIN_ADMINISTRATION} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} /> : 'Save'}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Popover */}
      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h6'
            gutterBottom
          >
            Are you sure you want to delete this admin?
          </Typography>

          <Typography variant='body1' gutterBottom>This cannot be undone.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} variant='outlined' color='primary' style={{ marginRight: '.5em' }}>Cancel</Button>
            <Button variant='contained' color='error' onClick={() => handleDeleteAdmin()}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default AdminAdministration
