/* eslint camelcase: off */

import { GET_AGREEMENTS, GET_AGREEMENT, GET_CURRENT_AGREEMENT } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Return a list of agreements
export const getAgreements = (filter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/agreements?${queryParams}`, options), loaderArea.ADMIN_AGREEMENTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_AGREEMENTS,
          payload: data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get agreements.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Returns details for a single agreement
export const getAgreement = (agreementID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/agreements/single/${agreementID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const agreementResponse = await response.json()

        dispatch({
          type: GET_AGREEMENT,
          payload: agreementResponse.data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get agreement.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Returns details for the currently active agreement
export const getCurrentAgreement = (fireSuccess = () => {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/agreements/current`, options), 'fetching-agreement')

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_CURRENT_AGREEMENT,
          payload: data[0]
        })

        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get agreement.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new agreement (this will become the new current agreement)
export const createAgreement = (agreementInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { content = '' } = agreementInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(agreementInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/agreements`, options), loaderArea.ADMIN_AGREEMENTS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create agreement.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
