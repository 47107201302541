/* eslint camelcase: off */

import {
  GET_PARTNERS, GET_PARTNER, GET_PARTNER_DISCOUNT_CODES,
  GET_PARTNER_USERS, GET_PARTNER_USER,
  GET_PARTNER_USER_ASSESSMENTS,
  GET_PARTNER_USER_DISCOUNT_CODES,
  GET_PARTNER_USER_COMPANIES,
  UPDATE_AUTH
} from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

/* ******************************************************* PARTNER ENDPOINTS (START) *****************************************************************  */

// Return a list of partners
export const getPartners = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'asc' : tempFilter.direction

      // const { offset = 0, sortCount = 10, direction = 'desc', sortBy = 'partnerName', search = '' } = filter

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner?${queryParams}`, options), loaderArea.PARTNER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerResponse = await response.json()

        dispatch({
          type: GET_PARTNERS,
          payload: partnerResponse.data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partners.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return details for a single partner
export const getPartner = (partnerID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerResponse = await response.json()

        dispatch({
          type: GET_PARTNER,
          payload: partnerResponse.data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return associated codes / partner users for a single partner
export const getPartnerDiscountCodes = (partnerID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/discount`, options), loaderArea.PARTNER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerResponse = await response.json()

        dispatch({
          type: GET_PARTNER_DISCOUNT_CODES,
          payload: partnerResponse.data
        })

        return partnerResponse.data
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner discount codes.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new partner
export const createPartner = (partnerInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { partnerName, defaultDiscount } = partnerInfo

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner`, options), loaderArea.PARTNER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create partner.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing partner
export const updatePartner = (partnerID = '', partnerInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { partnerName, defaultDiscount } = partnerInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}`, options), loaderArea.PARTNER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update partner.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an existing partner
export const deletePartner = (partnerID = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to delete partner.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

/* ******************************************************* PARTNER ENDPOINTS (END) *****************************************************************  */

/* ******************************************************* PARTNER USER ENDPOINTS (START) *****************************************************************  */

// Return a list of partner users
export const getPartnerUsers = (partnerID = '', filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      // const { offset = 0, sortCount = 10, direction = 'desc', sortBy = 'lastName', search = '' } = filter

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser?${queryParams}`, options), loaderArea.PARTNER_USERS)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerUsersResponse = await response.json()

        dispatch({
          type: GET_PARTNER_USERS,
          payload: partnerUsersResponse.data
        })

        return partnerUsersResponse.data
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner users.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return details for a single partner user
export const getPartnerUser = (partnerID = '', partnerUserID = '', fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerUserResponse = await response.json()

        dispatch({
          type: GET_PARTNER_USER,
          payload: partnerUserResponse.data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Create a new partner user
export const createPartnerUser = (partnerID = '', partnerUserInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // if the user has confirmed they wish to plus-address the original user's email, add in the query param that bypasses the duplicate email check
      const query = partnerUserInfo?.duplicateUser ? '?confirm_duplicate_update=true' : ''

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerUserInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser${query}`, options), loaderArea.PARTNER_USER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const res = await response.json()
        const fullRes = { ...res }
        // if we get a 409, check for a userID returned in the res
        if (parsedResponse.type === responseTypes.DUPLICATE_ENTRY) {
          const { data } = res
          if (data) {
            if (!isNaN(parseInt(data))) {
              fullRes.duplicateUser = data
            }
          }
        }
        fireFailure(fullRes)

        log.info('Failed to create partner user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update an existing partner user
export const updatePartnerUser = (partnerUserInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID: authUser = '' } = getState().auth

      const { partnerID = '', userID = '' } = partnerUserInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerUserInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${userID}`, options), loaderArea.PARTNER_USER_ADMINISTRATION)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        if (authUser === userID) {
          dispatch({
            type: UPDATE_AUTH,
            payload: partnerUserInfo
          })
        }

        return true
      } else {
        const data = await response.json()
        fireFailure(data)

        log.info('Failed to update partner user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Delete an existing partner user
export const deletePartnerUser = (partnerID = '', partnerUserID = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to delete partner user.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Return all assessments related to a partner user
export const getPartnerUserAssessments = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', partnerID = '', userID = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'asc' : tempFilter.direction
      tempFilter.sortBy = !tempFilter.sortBy ? 'createdAt' : tempFilter.sortBy
      // const { offset = 0, sortCount = 10, direction = 'DESC', sortBy = 'createdAt', search = '', subFilter = { company, discountCode, partnerID, partnerUserID }  } = filter

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...tempFilter })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${userID}/assessments`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_PARTNER_USER_ASSESSMENTS,
          payload: data
        })

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get partner user assessments.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

/* ******************************************************* PARTNER USER ENDPOINTS (END) *****************************************************************  */

/* ******************************************************* PARTNER USER DISCOUNT ENDPOINTS (START) *****************************************************************  */

// Return a list of partner user discount codes
export const getPartnerUserDiscountCodes = (filter = {}, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', partnerID = '', userID: partnerUserID = '' } = getState().auth

      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'asc' : tempFilter.direction
      tempFilter.sortBy = !tempFilter.sortBy ? 'expiresAt' : tempFilter.sortBy

      // const { offset = 0, sortCount = 10, direction = 'desc', sortBy = 'discountCode', search = '' } = filter

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}/discount?${queryParams}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const partnerUserDiscountCodeResponse = await response.json()

        dispatch({
          type: GET_PARTNER_USER_DISCOUNT_CODES,
          payload: partnerUserDiscountCodeResponse.data
        })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner user discount codes.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update to connect or remove a discount code from a partner user
export const updatePartnerUserDiscountCode = (partnerID = '', partnerUserID = '', partnerUserInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { actionType = 'remove' / 'add', discountID = '' } = partnerUserInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerUserInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}/discount`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to update partner user discount code.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

/* ******************************************************* PARTNER USER DISCOUNT ENDPOINTS (END) *****************************************************************  */

/* ******************************************************* PARTNER USER COMPANY ENDPOINTS (START) *****************************************************************  */

// Return a list of partner user clients/company users
export const getPartnerUserCompanies = (partnerID = '', partnerUserID = '', filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const tempFilter = { ...filter }
      const { page = 1, sortCount = 10 } = tempFilter
      tempFilter.offset = (page - 1) * sortCount
      tempFilter.direction = !tempFilter.direction ? 'desc' : tempFilter.direction

      // const { offset = 0, sortCount = 10, direction = 'desc', sortBy = 'discountCode', search = '' } = filter

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // Translate filter object into query params
      const queryParams = Object.keys(tempFilter).filter(f => tempFilter[f]).map(f => `${f}=${tempFilter[f]}`).join('&')

      const response = await window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}/company?${queryParams}`, options)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_PARTNER_USER_COMPANIES,
          payload: data
        })

        fireSuccess()
        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to get partner user companies.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Update to connect or remove a client/company user from a partner user
export const updatePartnerUserCompany = (partnerID = '', partnerUserID = '', partnerUserInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // const { actionType = 'remove' / 'add', user = '' } = partnerUserInfo

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(partnerUserInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/partner/${partnerID}/partneruser/${partnerUserID}/company`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to update partner user company.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

/* ******************************************************* PARTNER USER COMPANY ENDPOINTS (END) *****************************************************************  */
