import React from 'react'
import { Button } from 'reactstrap'

const ReportFooter = (props) => {
  const { onTalkToAnExpert = () => {}, setShowShareModal = () => {} } = props
  return (
    <div className='report-footer bg-secondary d-flex'>
      <div className='d-flex flex-column col-12 align-items-center justify-content-center'>
        <h1 className='mb-4 text-white'>Next Steps</h1>
        <div className='d-flex flex-column flex-md-row align-items-center justify-content-center'>
          <Button size='lg' color='primary' className='py-3 py-md-4 px-5 rounded-0' onClick={() => setShowShareModal(true)}>
            Share Results
          </Button>
          <Button size='lg' className='mx-4 my-3 border-primary py-3 py-md-4 px-3 px-lg-5 rounded-0' onClick={() => onTalkToAnExpert()}>
            Talk With an Advisor
          </Button>
          {/* temporarily removing until further discussion with tom can decide how this should work */}
          {/* <Button size='lg' color='primary' className='py-3 py-md-4 px-5 rounded-0'>
            Refer a Friend
          </Button> */}
        </div>
      </div>
    </div>
  )
}

export default ReportFooter
