import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from './auth'
import userReducer from './user'
import adminReducer from './admin'
import partnerReducer from './partner'
import agreementReducer from './agreement'
import discountReducer from './discount'
import industryReducer from './industries'
import sectorReducer from './sectors'
import userAssessmentReducer from './userAssessment'
import assessmentReducer from './assessment'
import sectionReducer from './sections'

export const reduxReducer = () => ({
  auth: {}
})

// Specific auth config persist reducer (per documentation) to handle auth related events
const authPersistConfig = {
  key: 'auth',
  storage
}
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer)

export default combineReducers({
  auth: persistedAuthReducer,
  assessment: assessmentReducer,
  user: userReducer,
  admin: adminReducer,
  partner: partnerReducer,
  agreement: agreementReducer,
  discount: discountReducer,
  industry: industryReducer,
  sectors: sectorReducer,
  userAssessment: userAssessmentReducer,
  sections: sectionReducer
})
