import React, { useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid, Typography, Select, MenuItem, useTheme, OutlinedInput, Pagination, Button,
  Table, TableBody, TableContainer, TableRow, TableCell, TableHead,
  IconButton, Collapse
} from '@mui/material'
import { SearchRounded, KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '@mui/icons-material'
import CopyQuestionDetails from './CopyQuestionDetails'
import { getAllAssessmentQuestions, getSectorQuestionOptions } from '../../../redux/actions'
import { isEqual } from 'lodash'
import { usePromiseTracker } from 'react-promise-tracker'
import { progressComponentTypes, loaderArea } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'

const headCells = [
  { id: 'toggle', label: '' },
  { id: 'question', label: 'Question' },
  { id: 'section', label: 'Section' },
  { id: 'explanation', label: 'Explanation' }
]

const defaultCopyFilter = {
  industryID: null,
  sectorID: null,
  sectionID: null,
  search: '',
  page: 1,
  sortCount: 10
}

// Specific local classnames
const useStyles = (theme) => ({
  currentFilter: {
    backgroundColor: '#5280AA',
    color: '#ffff',
    padding: '0.4rem 0.6rem',
    borderRadius: '5px',
    marginLeft: '0.5em',
    marginBottom: '0.5em'
  },
  selectFilterSpacing: {
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em'
    }
  },
  selectFilter: {
    cursor: 'pointer'
  }
})

let searchTimer

// Renders the search options and results for copying a question
// rendered in NewQuestionModal.js
const CopyQuestionContent = (props) => {
  const {
    // generated in AssessmentSections.js
    handleCopyQuestion = () => { },
    // generated in top most parent Builder.js
    classes
  } = props
  const theme = useTheme()
  const localClasses = useStyles(theme)
  const { industryList: { industries = [] } } = useSelector(state => state.industry)
  const { sections = [] } = useSelector(state => state.sections)
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ALL_QUESTIONS_FETCH })

  const dispatch = useDispatch()

  const [viewQuestion, setViewQuestion] = useState(null)

  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)

  const [copyFilter, setCopyFilter] = useState({ ...defaultCopyFilter })
  const [copyQuestionRows, setCopyQuestionRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [expandedQuestionOp, setExpandedQuestionOp] = useState([])

  // ref to keep track when the filter changes
  const filterRef = useRef(null)

  // When successfully fetching data, set it to local state
  const fireFetchQSuccess = (data) => {
    const { questions = [], totalQuestions = 0 } = data
    setCopyQuestionRows(questions)
    setTotalRows(totalQuestions)
  }

  // listen for inital component mount where filter ref is null and fetch results
  useMemo(() => {
    if (!filterRef.current && copyFilter) {
      // only fetch results if we have both a industry and sector
      if ((copyFilter.industryID && copyFilter.sectorID) || (searchInput && searchInput.length >= 3)) {
        // set the ref to the current filter
        filterRef.current = copyFilter
        dispatch(getAllAssessmentQuestions({ ...copyFilter, page: 1, sortCount: 10 }, fireFetchQSuccess))
      }
    }
  }, [dispatch, copyFilter, searchInput])

  // listen for the filter ref to change
  useMemo(() => {
    if (filterRef.current && copyFilter && !isEqual(filterRef.current, copyFilter)) {
      filterRef.current = copyFilter
      if ((copyFilter.industryID && copyFilter.sectorID) || (searchInput && searchInput.length >= 3)) {
        dispatch(getAllAssessmentQuestions({ ...copyFilter }, fireFetchQSuccess))
      } else {
        // be sure to reset the local questions rows if we do not have the correct filters set
        if (copyQuestionRows?.length) {
          setCopyQuestionRows([])
          setTotalRows(0)
        }
      }
    }
  }, [dispatch, copyFilter, copyQuestionRows, searchInput])

  // Set the sector list to the sectors of the selected industry
  const sectorOpts = useMemo(() => {
    if (industries && selectedIndustry) {
      /*
         - Uses a Map to filter out duplicate sectors (that occur because of alternate assessments) based on sector.id.
         - The entire sector obj is preserved as the Map value, ensuring all sector data is retained while eliminating unnecessary duplicates.
      */
      const indSectors = Array.from(new Map(selectedIndustry?.sectors?.map(sector => [sector.id, sector]) ?? []).values())
      return indSectors
    }
    return []
  }, [industries, selectedIndustry])

  // Handle the industry selection
  const handleIndustryChange = (id) => {
    // Reset the sector list
    setSelectedSector(null)

    // find the selected ID in the industry list
    const newIndustry = industries.find(x => x.id === parseInt(id))

    // If the industry was found
    if (newIndustry) {
      setSelectedIndustry(newIndustry)
      setCopyFilter({ ...copyFilter, industryID: id })
    } else {
      setSelectedIndustry(null)
      setCopyFilter({ ...copyFilter, industryID: null, sectorID: null })
    }
  }

  // Handle the industry selection
  const handleSectionChange = (id) => {
    // find the selected ID in the industry list
    const newSection = sections.find(x => x.id === parseInt(id))

    // If the industry was found
    if (newSection) {
      setSelectedSection(newSection)
      setCopyFilter({ ...copyFilter, sectionID: id })
    } else {
      setSelectedSection(null)
      setCopyFilter({ ...copyFilter, sectionID: null })
    }
  }

  // Handle the sector change
  const handleSectorChange = (id) => {
    // find the id in the list of sector opts
    const newSector = sectorOpts.find(x => x.id === parseInt(id))

    // If the sector was found
    if (newSector) {
      setSelectedSector(newSector)
      setCopyFilter({ ...copyFilter, sectorID: id })
    } else {
      setSelectedSector(null)
      setCopyFilter({ ...copyFilter, sectorID: null })
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  // debounce the user styping in the search bar
  const handleKeyUp = (e) => {
    clearTimeout(searchTimer)

    if (e.target.value === '' || e.target.value.length >= 3) {
      searchTimer = setTimeout(() => {
        const input = e.target.value === '' ? '' : searchInput
        setPage(0)
        setCopyFilter({ ...copyFilter, search: input, page: 1 })
      }, 250)
    }
  }

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)

      setCopyFilter({
        ...copyFilter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setCopyFilter({
      ...copyFilter,
      sortCount: event.target.value,
      page: 1
    })
  }

  const fireOpSuccess = (data) => {
    if (data?.length) {
      setExpandedQuestionOp(data)
    } else {
      setExpandedQuestionOp([])
    }
  }

  // upon expanding the questions, fetch it's options
  const handleOpenExpandQuestion = (assID, qID) => {
    setViewQuestion(qID)
    dispatch(getSectorQuestionOptions(assID, qID, fireOpSuccess))
  }

  // reset the options when closing the question row
  const handleCloseExpandQuestion = () => {
    setViewQuestion(null)
    setExpandedQuestionOp([])
  }

  // Condition and handler for clearing filters
  const disableClearFilter = Boolean(!copyFilter?.industryID && !copyFilter?.sectorID && !copyFilter?.sectionID && !copyFilter?.search)
  const handleClearFilter = () => {
    setCopyFilter({ ...defaultCopyFilter })
    setSelectedIndustry(null)
    setSelectedSector(null)
    setSelectedSection(null)
    setSearchInput('')
  }

  // The section row with collapsable questions section
  const CollapsingRow = (props) => {
    const { assessmentID, questionID, questionText = '', explanationText = '', sectionShortName = '' } = props
    const questionIsOpen = Boolean(viewQuestion && viewQuestion === questionID)

    return (
      <>
        <TableRow
          hover
          sx={{ ...classes.copyQuestionCollapseRow, backgroundColor: questionIsOpen ? theme.palette.grey.lightestGrey : 'white' }}
          onClick={() => questionIsOpen ? handleCloseExpandQuestion() : handleOpenExpandQuestion(assessmentID, questionID)}
        >
          <TableCell align='center' sx={classes.tableCellContent}>
            <IconButton size='small' style={{ backgroundColor: questionIsOpen ? `${theme.palette.blue.main}17` : 'auto' }}>
              {questionIsOpen ? <KeyboardArrowUpRounded style={{ fontSize: '18px' }} /> : <KeyboardArrowDownRounded style={{ fontSize: '18px' }} />}
            </IconButton>
          </TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{questionText}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{sectionShortName}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{explanationText || 'N/A'}</TableCell>
        </TableRow>
        {/* Collapsing questions table */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={questionIsOpen} onExited={() => setExpandedQuestionOp([])} unmountOnExit>
              <CopyQuestionDetails
                classes={classes}
                row={{ ...props }}
                handleCopyQuestion={handleCopyQuestion}
                expandedQuestionOp={expandedQuestionOp}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    size: 'small',
    defaultValue: '',
    style: { padding: 0 },
    sx: { ...classes?.searchInput },
    MenuProps: {
      sx: { ...classes?.statusSelect }
    },
    inputProps: {
      sx: { width: 'auto', maxWidth: 100, whitespace: 'normal', [theme.breakpoints.between('md', 'lg')]: { maxWidth: 45 } }
    }
  }

  return (
    <>
      <Grid container item direction='row' alignItems='flex-start'>
        {/* Select Filters */}
        <Grid item xs={2} container direction='row'>
          <Button
            variant='text'
            disabled={disableClearFilter}
            onClick={handleClearFilter}
            sx={{ color: theme?.palette?.blue?.main, textTransform: 'none', fontWeight: 600, fontSize: 12 }}
          >
            Clear Filters
          </Button>
        </Grid>
        <Grid
          item
          xs={7}
          container
          direction='row'
          alignItems='center'
          style={{ marginBottom: '0.5rem' }}
        >
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Industry:</Typography>
              <Select
                {...commonSelectProps}
                disabled={Boolean(!industries || !industries.length)}
                value={selectedIndustry?.id || ''}
                onChange={(e) => {
                  handleIndustryChange(e.target.value)
                }}
              >
                <MenuItem value=''>All</MenuItem>
                {industries?.map(industry => {
                  return (
                    <MenuItem key={industry.id} value={industry.id}>{industry.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Sector:</Typography>
              <Select
                {...commonSelectProps}
                value={selectedSector?.id || ''}
                disabled={!sectorOpts.length}
                onChange={(e) => handleSectorChange(e.target.value)}
              >
                <MenuItem value=''>All</MenuItem>
                {sectorOpts && sectorOpts.length && sectorOpts.map(sector => {
                  return (
                    <MenuItem key={sector.id} value={sector.id}>{sector.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Section:</Typography>
              <Select
                {...commonSelectProps}
                disabled={Boolean(!sections || !sections.length)}
                value={selectedSection?.id || ''}
                onChange={(e) => {
                  handleSectionChange(e.target.value)
                }}
              >
                <MenuItem value=''>All</MenuItem>
                {sections?.map(section => {
                  return (
                    <MenuItem key={section.id} value={section.id}>{section.shortName}</MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        {/* Search Bar */}
        <Grid item xs={3} container justifyContent='flex-end'>
          <OutlinedInput
            style={{ width: '100%' }}
            value={searchInput}
            onChange={(e) => handleSearch(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
            }
          />
        </Grid>
      </Grid>
      <Grid container item direction='column'>
        {promiseInProgress &&
          <StyledLoadingIndicator
            area={loaderArea.ALL_QUESTIONS_FETCH}
            loaderType={progressComponentTypes.LINEAR_PROGRESS}
            style={{ height: 3 }}
          />}
        <TableContainer elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      sx={classes.smallTableCellContent}
                    >
                      <Typography style={{ fontSize: '14px', fontWeight: 600, color: theme.palette.blue.main }}>{header?.label}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!copyQuestionRows?.length &&
                <TableRow style={{ height: 80 }}>
                  <TableCell colSpan={6}>
                    <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey, textAlign: 'center' }}>Please select both an industry and sector, or use the search bar to view the available questions.</Typography>
                  </TableCell>
                </TableRow>}
              {copyQuestionRows.map((row) => {
                const { questionID = '' } = row
                return (
                  <CollapsingRow key={`section-row-${questionID}`} {...row} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item direction='row' style={{ marginTop: '1em' }}>
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              sx={{ ...classes?.searchInput }}
              style={{ padding: 0 }}
              MenuProps={{
                sx: { ...classes?.statusSelect }
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            sx={{ ...classes?.pagination }}
            count={totalRows ? Math.ceil(totalRows / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
            size='small'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CopyQuestionContent
