import React from 'react'
import { Link } from 'react-scroll'

const HowToImproveScore = ({ actionItems }) => {
  return (
    <>
      <h5 className='font-600 my-4'>How to improve your score</h5>
      <p>The following action steps are places we recommend you focus to improve your score in this section.</p>
      <ul style={{ paddingLeft: '1.2rem' }}>
        {actionItems?.length
          ? actionItems.map(option => {
              const { actionItem = '', questionID = '' } = option
              return (
                <li key={`action-item-option-${questionID}`}>
                  <Link className='text-primary text-underline cursor-pointer font-600' to={`question-card-${questionID}`} spy smooth duration={500}>
                    {actionItem}
                  </Link>
                </li>
              )
            })
          : (
            <li className='font-600'>No actions recommended</li>
            )}
      </ul>
    </>
  )
}

export default HowToImproveScore
