import React from 'react'
import Content from '../components/Content'

import Main from '../components/Main'
import MainHeader from '../components/MainHeader'

const Auth = ({ children, marginTop }) => (
  <>
    <Main className='d-flex flex-column justify-content-between'>
      <MainHeader />
      <Content marginTop={marginTop}>{children}</Content>
    </Main>
  </>
)

export default Auth
