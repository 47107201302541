const ENV_PRODUCTION = 'production'

export const utils = {
  formatPercent: (progress, total) => `${total ? Math.round((progress / total) * 100) : 0}%`,
  isProduction: () => process.env.REACT_APP_ENV === ENV_PRODUCTION,
  getScoreBgColor: score => {
    if (score < 50) {
      return '#EF6E68'
    } else if (score < 75) {
      return '#FF975B'
    }
    return '#9FC25F'
  },
  getScoreBorderColor: (score = 0) => {
    if (score < 50) {
      return 'danger'
    } else if (score < 75) {
      return 'warning'
    }
    return 'success'
  }
}
