import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import { withRouter, Prompt } from 'react-router-dom'
import {
  Grid, Paper, Button, Typography, useTheme, Avatar, Alert, Collapse,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  TextField, Popover, InputAdornment, Chip, Tooltip
} from '@mui/material'
import { CancelRounded, ChevronLeft, CloseRounded, EditRounded, SaveAs } from '@mui/icons-material'
import { AssessmentList, AssessmentSections } from '../../components/builder'
import { getAssessmentList, getIndustries, getSelectedAssessment, modifyAssessmentDetails, resetAssessment, updateActiveAssessmentStatus } from '../../redux/actions'
import { loaderArea, progressComponentTypes, validInputCheck, assessmentTypes, assessmentTypeLabels } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'
import { DateTime } from 'luxon'

import queryString from 'query-string'
import { NotificationToast } from '../../components/tools'
import { VersionHistoryPopover } from '../../components/builder/builderTools'

// The two table views in the builder NOTE: this may be worth moving to a util
const viewTypes = {
  ASSESSMENTS: 'ASSESSMENTS',
  SECTIONS: 'SECTIONS'
}

const defaultAssessmentListFilter = {
  industry: null,
  sector: null,
  search: '',
  page: null,
  sortCount: null
}
// edit Mode: assessment detail modification actions
const detailActionTypes = {
  RENAME: 'RENAME'
}

// edit mode: assessment detail modification areas
const detailActionFields = {
  INDUSTRY: 'INDUSTRY',
  SECTOR: 'SECTOR',
  ASSESSMENT: 'ASSESSMENT'
}

// Local class stylings
const useStyles = (theme) => ({
  currentFilter: {
    backgroundColor: '#5280AA',
    color: '#ffff',
    padding: '0.4rem 0.6rem',
    borderRadius: '5px',
    marginLeft: '0.5em',
    marginBottom: '0.5em'
  },
  selectFilterSpacing: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em'
    }
  },
  selectFilter: {
    cursor: 'pointer'
  },
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme?.palette.grey.darkGrey
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  },
  tableCellContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '20em',
    padding: '4px 16px 4px 0'
  },
  smallTableCellContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '20em',
    padding: '4px 16px 4px 0',
    fontSize: 12,
    height: 'min-content'
  },
  editButtonBasicStyles: {
    minWidth: 0,
    width: '18px',
    boxShadow: `0px 1px 1px ${theme?.palette?.grey?.darkGrey}91`
  },
  pagination: {
    '& .MuiPaginationItem-textPrimary': {
      '&.Mui-selected': {
        backgroundColor: theme?.palette.blue.main
      },
      '&:focus': {
        outline: 'none'
      }
    }
  },
  copyQuestionCollapseRow: {
    '& > *': { borderBottom: 'unset !important' },
    cursor: 'pointer'
  },

  maxScoreChip: {
    backgroundColor: theme?.palette.blue.main,
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    height: 20
  }
})

// MAIN RENDER (see ./BUILDER_TREE.js for full component render tree)
const AssessmentBuilder = (props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const firstFetch = useRef(false)

  const { assessmentList: { assessments = [] }, assessmentSectionQuestions: sections = [], selectedAssessment = {} } = useSelector(state => state.assessment)
  const parsedProps = queryString.parse(props?.location?.search)
  const { promiseInProgress: questionsLoading } = usePromiseTracker({ area: loaderArea.ASSESSMENT_SECTOR_QUESTIONS })

  // Edit mode: assessment detail modification loading promise
  const { promiseInProgress: detailModsLoading } = usePromiseTracker({ area: loaderArea.MODIFY_ASSESSMENT_DETAILS })

  // determine if a selected assessment is eligible to be published. (must have at least one question with two options)
  const eligibleForPublishing = Boolean(sections.length && sections.some((section) => {
    if (section?.questions?.length) {
      const questionsCheck = section.questions.some(q => q.optionCount && q.optionCount >= 2)
      return questionsCheck
    }
    return false
  }))

  // calculate the origin's correct assessmentID for when not viewing edit mode
  const originDefaultID = useMemo(() => {
    if (selectedAssessment) {
      const { versionHistory = [] } = selectedAssessment

      // first look for an active obj, if found, that is automatically our return ID
      const activeObj = versionHistory.find(x => x.isActive)
      if (activeObj?.assessmentID) { return activeObj.assessmentID }

      // look for a draft Obj
      const draftObj = versionHistory.find(x => x.isDraft)

      // NOTE: This may need to change to most recently published or reactivated
      // reduce the versionHistory into an array of only assessmentIDs that have been deactivated, and find the most recently deactivated ID
      const deactivatedObjs = versionHistory.reduce((arr, versionObj) => {
        // look at the current versions actions to see if it has ever bene deactivated
        const currPublished = versionObj?.actions?.filter(currAction => currAction?.actionType === 'DEACTIVATED')

        // if so, reduce that sub array to find the most recent history and return just the assessmentID and the most recent date as a unix timestamp
        if (currPublished.length) {
          const maxDate = currPublished.reduce((prev, curr) => {
            return (prev?.historyCreated && DateTime.fromISO(prev.historyCreated).toSeconds() > DateTime.fromISO(curr.historyCreated).toSeconds()) ? DateTime.fromISO(prev.historyCreated).toSeconds() : DateTime.fromISO(curr.historyCreated).toSeconds()
          })
          arr.push({ assessmentID: versionObj.assessmentID, maxDate })
        }

        return arr
      }, [])

      // use the deactivated objs (if any were found) to determine the assessmentID that was most recently deactivated
      const mostRecentDeactivatedID = deactivatedObjs.length ? deactivatedObjs.reduce((prev, curr) => prev?.maxDate && prev?.assessmentID && prev.maxDate > curr.maxDate ? prev.assessmentID : curr.assessmentID) : null

      // return the most recently deactivated, the draft, or null (should not happen)
      return mostRecentDeactivatedID || draftObj?.assessmentID || null
    }

    // should not happen
    return null
  }, [selectedAssessment])

  // The current table being viewed
  const [viewComponent, setViewComponent] = useState(viewTypes.ASSESSMENTS)
  const [assessmentListFilter, setAssessmentListFilter] = useState({ ...defaultAssessmentListFilter })
  const [editMode, setEditMode] = useState(false)

  // EditMode: edit assessment details modal control
  const [editSectorTitleOpen, setEditSectorTitleOpen] = useState(false)
  const [editAssessmentTitleOpen, setEditAssessmentTitleOpen] = useState(false)

  // after initial dispatch success set the firstFetch to true so dispatch will not happen twice
  const fireFirstFetchSuccess = () => {
    firstFetch.current = true
  }

  // fetch the assessment list on initial load in, and subsequent fetch calls are handled with filter changes in child components
  useMemo(() => {
    if (!firstFetch.current) {
      dispatch(getAssessmentList({}, fireFirstFetchSuccess))
    }
  }, [dispatch])

  // reset the first fetch on unmount
  useEffect(() => {
    return () => {
      firstFetch.current = false
      setAssessmentListFilter({ ...defaultAssessmentListFilter })
    }
  }, [])

  const [currentMaxScore, setCurrentMaxScore] = useState(null)

  useEffect(() => {
    if (viewComponent === viewTypes.SECTIONS && !parsedProps.assessmentID) {
      setViewComponent(viewTypes.ASSESSMENTS)
      dispatch(resetAssessment())
      dispatch(getAssessmentList({}))
      setCurrentMaxScore(null)
    }
  }, [dispatch, parsedProps.assessmentID, viewComponent])

  // Update max scores in real time
  useMemo(() => {
    if (sections && sections.length && parsedProps.assessmentID) {
      const totalSectionScore = sections.map(o => o.maxSectionImpact).reduce((a, c) => { return a + c })

      const roundedTotalSectionScore = Math.round(totalSectionScore * 100) / 100

      if ((roundedTotalSectionScore || roundedTotalSectionScore === 0) && !isNaN(roundedTotalSectionScore) && currentMaxScore !== roundedTotalSectionScore) {
        setCurrentMaxScore(roundedTotalSectionScore)
      }
    }
  }, [currentMaxScore, sections, setCurrentMaxScore, parsedProps.assessmentID])

  // navigation confirmation control states (when in edit mode)
  const [openLeaveEditConfirm, setOpenLeaveEditConfirm] = useState(false)
  const [confirmNavigate, setConfirmNavigate] = useState(false)
  const lastLocation = useRef(null)
  const remainOnPage = useRef(false)

  // handle resets and transition of states when navigating back to the assessment list
  const resetToAssessmentList = () => {
    setViewComponent(viewTypes.ASSESSMENTS)
    dispatch(resetAssessment())
    dispatch(getAssessmentList({}))
    setCurrentMaxScore(null)
    setEditAssessmentTitleOpen(false)
    setEditSectorTitleOpen(false)
    if (editMode) {
      setEditMode(false)
    }
  }

  // handle successful navigation confirmation
  const fireSuccessReset = () => {
    setConfirmNavigate(true)
    resetToAssessmentList()
  }

  // handle the back to assessment list button
  const handleBackClick = () => {
    resetToAssessmentList()

    const path = { pathname: '/assessments/builder', search: '' }
    props.history.replace(path)
  }

  // listener use effect to catch when a user has confirmed navigation and we have a location set
  useEffect(() => {
    if (confirmNavigate && lastLocation.current) {
      const { pathname, search } = lastLocation.current

      props.history.replace({
        pathname,
        search
      })

      // once navigation has been acted on, reset the local states triggering the use effect
      lastLocation.current = null
      setConfirmNavigate(false)

      // if we are navigating away from the individual view, reset the local and redux state items and set the vew back to the assessment list
      if (!search) {
        setViewComponent(viewTypes.ASSESSMENTS)
        dispatch(resetAssessment())
        setCurrentMaxScore(null)
      }
    }
  }, [confirmNavigate, props.history, dispatch])

  // Listener for react-router Prompt that fires when a user attempts to exit the Pursuer process
  const navigationBlock = (nextLocation) => {
    // if the confirmNavigate state has not been set
    if (!confirmNavigate) {
      // set the requested location in a ref
      lastLocation.current = nextLocation
      // open the confirm modal
      setOpenLeaveEditConfirm(true)

      // block navigation until user has confirmed navigation
      return false
    }

    // otherwise allow the navigation
    return true
  }

  // fetch new assessment details and navigate as indicated in or out of edit mode
  const fetchNewAssessmentDetails = (assessmentID, editBool = true) => {
    dispatch(getSelectedAssessment(assessmentID))
    setEditMode(editBool)
  }

  // handler for when the user has selected an option in the confirm navigation modal
  const handleLeaveExitMode = async (publishBool) => {
    let search = ''
    if (publishBool) {
      // TO DO: determine if the client would like published drafts to be auto activated, activate based on the previous published version's activation status, or if they would like to manually select the activation status at the time of publishing
      // current action will activate the assessment on publish but can be changed at any time
      await dispatch(updateActiveAssessmentStatus({ assessmentID: selectedAssessment.assessmentID, isActive: true, publishDraft: true }))
      // once published, fetch the industries again so the redux list is locally up to date
      dispatch(getIndustries())
    }

    // if the user chose to remain on the section list page
    if (remainOnPage.current) {
      // find the ID of the correct assessment for the origin (either active, most recently deactivated, or draft, in that order)
      const returnID = publishBool ? selectedAssessment.assessmentID : originDefaultID || null
      search = `?assessmentID=${returnID}`
      // fetch the new details
      fetchNewAssessmentDetails(returnID, false)
      remainOnPage.current = false
    }

    // trigger the navigation
    if (!lastLocation.current) {
      lastLocation.current = { pathname: '/assessments/builder', search }
    }
    fireSuccessReset()
  }

  // Common props shared between child components
  const commonComponentProps = {
    ...props,
    setViewComponent,
    viewTypes,
    classes,
    setAssessmentListFilter,
    assessmentListFilter,
    defaultAssessmentListFilter,
    currentMaxScore,
    setCurrentMaxScore,
    parsedProps,
    editMode,
    setEditMode,
    setOpenLeaveEditConfirm,
    remainOnPage,
    fetchNewAssessmentDetails
  }

  // Fetches the correct component for the chosen view type
  const getView = (type) => {
    switch (type) {
      case viewTypes.ASSESSMENTS: {
        return <AssessmentList {...commonComponentProps} />
      }
      case viewTypes.SECTIONS: {
        return <AssessmentSections {...commonComponentProps} />
      }
      default: return <AssessmentList {...commonComponentProps} />
    }
  }

  // Prevents leaving the current assessment view after refreshing the page
  useEffect(() => {
    if (parsedProps?.assessmentID && viewComponent === viewTypes.ASSESSMENTS) {
      setViewComponent(viewTypes.SECTIONS)
      window.scrollTo({ top: 0 })
    }
  }, [assessments, dispatch, parsedProps?.assessmentID, selectedAssessment, viewComponent])

  // *** EDIT MODE ASSESSMENT DETAIL MODIFICATION CONTROLS **** //

  // industry rename confirmation popper control
  const [confirmRenameAnchorEl, setConfirmRenameAnchorEl] = useState(null)
  const confirmOpen = Boolean(confirmRenameAnchorEl)

  const [detailModForm, setDetailModForm] = useState({})

  // handle specific action requests based on the provided, field and action types
  const handleDetailModActionRequest = (e, actionField, actionType, renameConfirmed = false) => {
    // if requesting to rename a sector without confirmation, fire the warning popover element to get confirmation
    if (actionType === detailActionTypes.RENAME && actionField !== detailActionFields.ASSESSMENT && !renameConfirmed) {
      setConfirmRenameAnchorEl(e?.currentTarget)
    } else {
      // otherwise set the modification form based on the area and actions requested
      const newForm = {
        ...detailModForm,
        ...(actionType === detailActionTypes.RENAME && { industryID: selectedAssessment?.industryID }),
        ...(actionField === detailActionFields.SECTOR && { sectorName: selectedAssessment?.sectorName }),
        ...(actionField === detailActionFields.ASSESSMENT && { newAssessmentTitle: selectedAssessment?.assessmentTitle })
      }

      setDetailModForm(newForm)

      if (actionField === detailActionFields.SECTOR) { setEditSectorTitleOpen(true) }
      if (actionField === detailActionFields.ASSESSMENT) { setEditAssessmentTitleOpen(true) }
    }
  }

  // reset the local form and state inside the modal
  const resetDetailMod = () => {
    setDetailModForm({})
  }

  // close the modal and reset all detail modification state values
  const handleDetailModalClose = () => {
    setConfirmRenameAnchorEl(null)
    setEditSectorTitleOpen(false)
    setEditAssessmentTitleOpen(false)
    resetDetailMod()
  }

  // success function for the assessment detail modification
  const fireUpdateDetailSuccess = (updatedAssessmentID) => {
    handleDetailModalClose()
    NotificationToast(false, 'Successfully updated assessment details!')

    dispatch(getSelectedAssessment(updatedAssessmentID))
  }

  // failure function for the detail modification call
  const fireUpdateDetailFailure = (data = 500) => {
    const message = data && data === 409 ? 'Sector Name Already Exists' : 'Something Went Wrong. Please Try Again Later.'
    NotificationToast(true, message)
  }

  // handle saving the new modification details
  const handleSaveNewAssessmentDetails = (detailActionField) => {
    // if the save button did  not properly disable, and we have an empty object, fail the call
    if (!Object.keys(detailModForm).length) {
      NotificationToast(true, 'Name must include at least one character.')
      return false
    }

    // shallow clone the state form
    const finalDetailForm = { ...detailModForm }

    // add the assessmentID
    finalDetailForm.assessmentID = selectedAssessment.assessmentID

    // be sure we did not end up with any keys with blank values
    Object.keys(finalDetailForm).forEach(key => {
      if (typeof finalDetailForm[key] === 'string' && !finalDetailForm[key].length) {
        delete finalDetailForm[key]
      }
    })

    const { sectorName, newAssessmentTitle } = finalDetailForm

    // validate the sector name value
    if (sectorName && (validInputCheck?.onlyBlankSpaces.test(sectorName) || validInputCheck?.onlySpecialChars.test(sectorName))) {
      NotificationToast(true, 'Invalid Sector Name.')
      return false
    }

    if (newAssessmentTitle && (validInputCheck?.onlyBlankSpaces.test(newAssessmentTitle) || validInputCheck?.onlySpecialChars.test(newAssessmentTitle))) {
      NotificationToast(true, 'Invalid Assessment Name.')
      return false
    }

    dispatch(modifyAssessmentDetails(finalDetailForm, fireUpdateDetailSuccess, fireUpdateDetailFailure))
  }

  // handle clicking the button next to the sector/title
  const handleEditDetailsClick = (e, detailActionField) => {
    // if we are not already editing the sector, fire the popover to get confirmation
    if ((detailActionField === detailActionFields.SECTOR && !editSectorTitleOpen) || (detailActionField === detailActionFields.ASSESSMENT && !editAssessmentTitleOpen)) {
      handleDetailModActionRequest(e, detailActionField, detailActionTypes.RENAME)
    } else {
      // otherwise we are saving
      handleSaveNewAssessmentDetails(detailActionField)
    }
  }

  // View version history popper related elements --- //
  const [versionHistoryAnchorEl, setVersionHistoryAnchorEl] = useState(null)

  const handleVersionHistoryPopover = (e) => {
    setVersionHistoryAnchorEl(e.currentTarget)
  }
  const handleVersionHistoryPopClose = () => {
    setVersionHistoryAnchorEl(null)
  }
  const openVersionHistoryPopover = Boolean(versionHistoryAnchorEl)

  return (
    <>
      {/* Prompt listener that triggers when a user attempts to exit the page while in edit mode */}
      <Prompt when={editMode && viewComponent === viewTypes.SECTIONS} message={navigationBlock} />
      <Grid
        container
        style={{ margin: '1.5rem 0', backgroundColor: editMode ? theme.palette.grey.lightGrey : 'white' }}
      >
        <Grid container direction='column'>
          <Collapse in={editMode}>
            <Alert severity='info' variant='filled' style={{ backgroundColor: theme.palette.blue.main }}>
              <Typography variant='subtitle2' style={{ color: 'white' }}>You are now in edit mode. Be sure you make all desired changes before exiting.</Typography>
            </Alert>
          </Collapse>
        </Grid>
        <Grid container direction='column' style={{ margin: '1em 3em' }}>
          {/* Back Button and Sector Name when viewing the 'sections' view */}
          {viewComponent && viewComponent === viewTypes.SECTIONS &&
            <Grid container item direction='row' style={{ marginBottom: '1em', padding: '0 1em' }}>
              <Grid item container xs={2}>
                <Button
                  variant='contained'
                  startIcon={<ChevronLeft style={{ marginRight: 0 }} />}
                  style={{ margin: 'auto 0', paddingLeft: 7 }}
                  onClick={() => { handleBackClick() }}
                >
                  Back
                </Button>
              </Grid>

              <Grid item container direction='column' alignItems='flex-end' xs={10}>
                <Grid item container direction='row' justifyContent='flex-end' alignItems={editAssessmentTitleOpen ? 'flex-start' : 'baseline'}>

                  {questionsLoading
                    ? (
                      <>
                        <Grid item container direction='row' justifyContent='flex-end' style={{ marginBottom: '1em' }}>
                          <StyledLoadingIndicator area={loaderArea.MODIFY_ASSESSMENT_DETAILS} loaderType={progressComponentTypes.LINEAR_PROGRESS} style={{ height: 10, width: 200 }} color='primary' />
                        </Grid>

                        <Grid item container direction='row' justifyContent='flex-end' style={{ marginBottom: '1em' }}>
                          <StyledLoadingIndicator area={loaderArea.MODIFY_ASSESSMENT_DETAILS} loaderType={progressComponentTypes.LINEAR_PROGRESS} style={{ height: 10, width: 200 }} color='primary' />
                        </Grid>

                        <Grid item container direction='row' justifyContent='flex-end'>
                          <StyledLoadingIndicator area={loaderArea.MODIFY_ASSESSMENT_DETAILS} loaderType={progressComponentTypes.LINEAR_PROGRESS} style={{ height: 10, width: 200 }} color='primary' />
                        </Grid>
                      </>)
                    : (
                      <>
                        <Grid item container direction='row' justifyContent='flex-end' alignItems='baseline'>
                          <Typography
                            variant='caption'
                            style={{ fontSize: 14, fontWeight: 600, marginRight: '.2em' }}
                          >
                            Assessment:
                          </Typography>

                          {!editAssessmentTitleOpen
                            ? (
                              <Typography variant='h5'>{selectedAssessment?.assessmentTitle || ''}</Typography>)
                            : (
                              <TextField
                                style={{ minWidth: '25%', width: 'fit-content' }}
                                inputProps={{
                                  maxLength: 255
                                }}
                                value={detailModForm?.newAssessmentTitle || ''}
                                placeholder='Enter the new name for the assessment...'
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Tooltip title='Cancel' arrow placement='right'>
                                        <IconButton size='small' onClick={() => { setEditAssessmentTitleOpen(false); resetDetailMod() }}>
                                          <CancelRounded fontSize='inherit' />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  )
                                }}
                                onChange={(e) => { setDetailModForm({ ...detailModForm, newAssessmentTitle: e.target.value }) }}
                                helperText={<Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${detailModForm?.newAssessmentTitle?.length || 0}/255`}</Typography>}
                                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                              />)}

                          {editMode && !editSectorTitleOpen &&
                            <Avatar
                              style={{
                                height: 26,
                                width: 26,
                                marginLeft: '1em',
                                marginTop: '2px',
                                top: editAssessmentTitleOpen && detailModForm?.newAssessmentTitle ? '.2em' : '',
                                backgroundColor: editAssessmentTitleOpen ? !detailModForm?.newAssessmentTitle?.length ? theme.palette.grey.lightGrey : theme.palette.blue.main : 'white',
                                border: editAssessmentTitleOpen ? 'none' : `solid 1px ${theme.palette.grey.lightGrey}`,
                                cursor: editAssessmentTitleOpen && !detailModForm?.newAssessmentTitle?.length ? 'default' : 'pointer',
                                boxShadow: editAssessmentTitleOpen && !detailModForm?.newAssessmentTitle?.length ? theme.shadows[0] : theme.shadows[2]
                              }}
                              onClick={(e) => { handleEditDetailsClick(e, detailActionFields.ASSESSMENT) }}
                              variant='rounded'
                              disabled={editSectorTitleOpen}
                            >
                              {editAssessmentTitleOpen && detailModForm?.newAssessmentTitle
                                ? detailModsLoading ? <StyledLoadingIndicator area={loaderArea.MODIFY_ASSESSMENT_DETAILS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} size={16} style={{ color: 'white' }} /> : <Tooltip title='Save' arrow placement='right'><SaveAs style={{ fontSize: 18, color: !detailModForm.newAssessmentTitle ? theme.palette.grey.darkGrey : 'white' }} /></Tooltip>
                                : <Tooltip title='Edit' arrow placement='right'><EditRounded style={{ fontSize: 18, color: theme.palette.blue.main }} /></Tooltip>}
                            </Avatar>}
                        </Grid>

                        <Grid item container direction='row' justifyContent='flex-end' alignItems='baseline'>
                          <Typography
                            variant='caption'
                            style={{ fontSize: 14, fontWeight: 600, marginRight: '.2em' }}
                          >
                            Sector:
                          </Typography>

                          {!editSectorTitleOpen
                            ? (
                              <Typography variant='h5'>{selectedAssessment?.sectorName || ''}</Typography>)
                            : (
                              <TextField
                                style={{ minWidth: '25%', width: 'fit-content', marginTop: '1.5em' }}
                                inputProps={{
                                  maxLength: 255
                                }}
                                value={detailModForm?.sectorName || ''}
                                placeholder='Enter the new name for the sector...'
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Tooltip title='Cancel' arrow placement='right'>
                                        <IconButton size='small' onClick={() => { setEditSectorTitleOpen(false); resetDetailMod() }}>
                                          <CancelRounded fontSize='inherit' />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  )
                                }}
                                onChange={(e) => { setDetailModForm({ ...detailModForm, sectorName: e.target.value }) }}
                                helperText={<Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${detailModForm?.sectorName?.length || 0}/255`}</Typography>}
                                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                              />)}

                          {editMode && !editAssessmentTitleOpen &&
                            <Avatar
                              style={{
                                height: 26,
                                width: 26,
                                marginLeft: '1em',
                                marginTop: '2px',
                                top: editSectorTitleOpen && detailModForm?.sectorName ? '.2em' : '',
                                backgroundColor: editSectorTitleOpen ? !detailModForm?.sectorName?.length ? theme.palette.grey.lightGrey : theme.palette.blue.main : 'white',
                                border: editSectorTitleOpen ? 'none' : `solid 1px ${theme.palette.grey.lightGrey}`,
                                cursor: editSectorTitleOpen && !detailModForm?.sectorName?.length ? 'default' : 'pointer',
                                boxShadow: editSectorTitleOpen && !detailModForm?.sectorName?.length ? theme.shadows[0] : theme.shadows[2]
                              }}
                              onClick={(e) => { handleEditDetailsClick(e, detailActionFields.SECTOR) }}
                              variant='rounded'
                              disabled={editAssessmentTitleOpen}
                            >
                              {editSectorTitleOpen && detailModForm?.sectorName
                                ? detailModsLoading ? <StyledLoadingIndicator area={loaderArea.MODIFY_ASSESSMENT_DETAILS} loaderType={progressComponentTypes.CIRCULAR_PROGRESS} size={16} style={{ color: 'white' }} /> : <Tooltip title='Save' arrow placement='right'><SaveAs style={{ fontSize: 18, color: !detailModForm.sectorName ? theme.palette.grey.darkGrey : 'white' }} /></Tooltip>
                                : <Tooltip title='Edit' arrow placement='right'><EditRounded style={{ fontSize: 18, color: theme.palette.blue.main }} /></Tooltip>}
                            </Avatar>}
                        </Grid>

                        <Grid item container direction='row' justifyContent='flex-end' alignItems='center'>
                          <Typography variant='caption' style={{ fontSize: 14 }}>
                            <span style={{ fontWeight: 600 }}>{'Assessment Type: '}</span>
                            {selectedAssessment?.assessmentType === assessmentTypes.PRIMARY ? assessmentTypeLabels.P : assessmentTypeLabels.F}
                          </Typography>
                        </Grid>

                        <Grid item container direction='row' spacing={1} justifyContent='flex-end' alignItems='center' style={{ marginBottom: '.5em' }}>
                          <Grid item>
                            <Typography variant='caption' style={{ fontSize: 14 }}>
                              <span style={{ fontWeight: 600 }}>{'Version: '}</span>
                              {selectedAssessment?.versionNumber || 0}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Chip size='small' label={selectedAssessment?.isActive ? 'Active' : 'Inactive'} variant={selectedAssessment?.isActive ? 'contained' : 'outlined'} color='primary' />
                          </Grid>
                        </Grid>
                      </>)}

                </Grid>

                {/* TO DO: Handle version history */}
                {!questionsLoading && selectedAssessment?.versionNumber && selectedAssessment?.versionNumber > 1 &&
                  <Button onClick={handleVersionHistoryPopover} color='primary' size='small' style={{ fontWeight: 600, color: theme.palette.blue.main, textTransform: 'none', textDecoration: 'underline', paddingRight: 0 }}>View Version History</Button>}
              </Grid>
            </Grid>}

          {/* Render the selected view's component */}
          <Paper style={{ width: '100%', padding: '1em' }}>
            {getView(viewComponent)}
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openLeaveEditConfirm}
        fullWidth
        maxWidth='sm'
        onClose={() => { remainOnPage.current = false; setOpenLeaveEditConfirm(false) }}
      >
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.blue.main, fontSize: '1.3rem' }}
              >
                Are you ready to exit Edit Mode?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); remainOnPage.current = false; setOpenLeaveEditConfirm(false) }}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, marginBottom: '2em' }}>
          <Grid container item direction='column'>
            {eligibleForPublishing &&
              <Typography gutterBottom>If you are ready to publish your changes, please select the option below. Otherwise, select 'Exit Only' to return to the current published version of the assessment.</Typography>}
            {!eligibleForPublishing &&
              <Typography gutterBottom>You will have the option to publish your draft once the assessment contains at least one question with two assigned answer options.</Typography>}
            <Typography>Your current changes have been saved, and you may choose to continue editing at any time.</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container direction='row'>
            <Grid item container direction='row' xs={6} justifyContent='flex-start'>
              <Button
                variant='text'
                style={{ color: theme.palette.grey.darkGrey, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
                onClick={(e) => { e.preventDefault(); remainOnPage.current = false; setOpenLeaveEditConfirm(false) }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item container direction='row' xs={6} justifyContent='flex-end'>
              <Button
                variant='text'
                style={{ color: theme.palette.blue.main, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
                onClick={() => { setOpenLeaveEditConfirm(false); handleLeaveExitMode(false) }}
              >
                {eligibleForPublishing ? 'Exit Only' : 'Leave Edit Mode'}
              </Button>
              {eligibleForPublishing &&
                <Button
                  variant='contained'
                  size='small'
                  style={{ backgroundColor: theme.palette.blue.main, color: 'white', fontWeight: 600, textTransform: 'none' }}
                  onClick={() => { setOpenLeaveEditConfirm(false); handleLeaveExitMode(true) }}
                >
                  Publish Draft
                </Button>}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* RENAME SECTOR CONFIRMATION POPOVER */}
      <Popover
        open={confirmOpen}
        onClose={() => { setConfirmRenameAnchorEl(null) }}
        anchorEl={confirmRenameAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{ style: { padding: '1em', maxWidth: '25%' } }}
      >
        <Grid container direction='column'>
          <Grid item>
            <Typography gutterBottom style={{ color: 'red', fontWeight: 600, fontSize: 18 }}>Warning!</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: 16 }}>Renaming the sector is permanent and will reflect across all published versions. Are you sure you want to proceed?</Typography>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-end'>
            <Button style={{ textTransform: 'none', color: 'black' }} onClick={() => { setConfirmRenameAnchorEl(null) }}>Cancel</Button>
            <Button variant='contained' size='small' onClick={(e) => { setConfirmRenameAnchorEl(null); handleDetailModActionRequest(e, detailActionFields.SECTOR, detailActionTypes.RENAME, true) }} style={{ padding: 0, backgroundColor: 'red' }}>Continue</Button>
          </Grid>
        </Grid>
      </Popover>

      <VersionHistoryPopover
        openVersionHistoryPopover={openVersionHistoryPopover}
        versionHistoryAnchorEl={versionHistoryAnchorEl}
        handleVersionHistoryPopClose={handleVersionHistoryPopClose}
      />
    </>
  )
}

export default withRouter(AssessmentBuilder)
