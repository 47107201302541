import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Typography, useTheme, IconButton, Collapse, Grid
} from '@mui/material'
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import { StyledTableRow, loaderArea } from '../../../utils'
import BuilderQuestionTable from './BuilderQuestionTable'

const headCells = [
  { id: 'toggle', label: '' },
  { id: 'sectionShortName', label: 'Section' },
  { id: 'sectionSummary', label: 'Summary' },
  { id: 'maxSectionImpact', label: 'Section Impact' },
  { id: 'questionCount', label: 'Question Count' },
  { id: 'callToAction', label: 'Call to Action' }
]

// Table listing the assessment rows
// rendered in AssessmentSections.js
const BuilderSectionsTable = (props) => {
  // props generated and passed in from top most parent, Builder.js
  const {
    classes, editMode = false
  } = props

  const theme = useTheme()
  const { assessmentSectionQuestions: sections = [] } = useSelector(state => state.assessment)
  const { promiseInProgress: questionsLoading } = usePromiseTracker({ area: loaderArea.ASSESSMENT_SECTOR_QUESTIONS })

  const [viewSection, setViewSection] = useState(null)

  // The section row with collapsable questions section
  const CollapsingRow = (props) => {
    const { sectionID = 0, sectionName = '', sectionShortName = '', sectionSummary = '', callToAction = '', maxSectionImpact = 0, questions = [] } = props
    const sectionIsOpen = Boolean(viewSection && viewSection === sectionID)
    return (
      <>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
          <TableCell align='center'>
            <IconButton onClick={() => sectionIsOpen ? setViewSection(null) : setViewSection(sectionID)} style={{ backgroundColor: sectionIsOpen ? `${theme.palette.blue.main}17` : 'auto' }}>
              {sectionIsOpen ? <KeyboardArrowUpRounded style={{ fontSize: '18px' }} /> : <KeyboardArrowDownRounded style={{ fontSize: '18px' }} />}
            </IconButton>
          </TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{sectionShortName}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{sectionSummary}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{questionsLoading ? '--' : maxSectionImpact}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{questionsLoading ? '--' : questions.length || 0}</TableCell>
          <TableCell align='left' sx={classes.tableCellContent}>{callToAction}</TableCell>
        </StyledTableRow>
        {/* Collapsing questions table */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: theme.palette.grey.lightestGrey }} colSpan={6}>
            <Collapse in={sectionIsOpen} unmountOnExit>
              <Grid container spacing={1}>
                <Grid item container direction='column'>
                  <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }}>Section Name</Typography>
                  <Typography variant='subtitle2'>{sectionName}</Typography>
                </Grid>
                <Grid item container direction='row' spacing={2}>
                  <Grid item xs={6} container direction='column'>
                    <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }}>Summary</Typography>
                    <Typography variant='subtitle2'>{sectionSummary}</Typography>
                  </Grid>
                  <Grid item xs={6} container direction='column'>
                    <Typography variant='subtitle1' style={{ color: theme.palette.blue.main, fontWeight: 600 }}>Call to Action</Typography>
                    <Typography variant='subtitle2'>{callToAction}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction='column' style={{ marginBottom: '3em' }}>
                  <BuilderQuestionTable classes={classes} questionRows={questions} editMode={editMode} currentSectionID={sectionID} />
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  // Main table
  return (
    <TableContainer elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map(header => {
              return (
                <TableCell
                  key={header.id}
                  align='left'
                  style={{ paddingLeft: 0 }}
                >
                  <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                </TableCell>
              )
            })}
            <TableCell align='left' style={{ padding: '16px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sections?.map((row) => {
            const { sectionID = 0 } = row
            return (
              <CollapsingRow key={`section-row-${sectionID}`} {...row} />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BuilderSectionsTable
