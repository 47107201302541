import {
  GET_SECTIONS, LOGOUT_USER
} from '../types'

const initialState = {
  sections: []
}

const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTIONS: return {
      ...state,
      sections: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default sectionReducer
