import React from "react";
import Content from "../components/Content";

import Main from "../components/Main";
import MainHeader from "../components/MainHeader";

const ReportApi = ({ children, width = 12 }) => {

  return (
    <React.Fragment>
      <Main className="d-flex flex-column justify-content-between">
        <MainHeader/>
        <Content width={width} paddingx={0}>{children}</Content>
      </Main>
    </React.Fragment>
  );
};

export default ReportApi;
