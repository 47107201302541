import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'reactstrap'
import queryString from 'query-string'
import AssessmentQuestion from './AssessmentQuestion'
import ConfidentialityStatement from './ConfidentialityStatement'
import IndustrySector from './IndustrySector'
import ProgressBar from './ProgressBar'
import { getCurrentAgreement } from '../../redux/actions'
import { usePromiseTracker } from 'react-promise-tracker'
import Loader from '../../components/Loader'

const AssessmentFlow = (props) => {
  const { width } = props
  const parsedProps = queryString.parse(props?.location?.search)
  const userAssessmentID = parsedProps?.userAssessment || null
  // tracker for determining if we are fetching the current agreement
  const { promiseInProgress: agreementPromise } = usePromiseTracker({ area: 'fetching-agreement' })
  const { promiseInProgress: newUserAssessmentPromise } = usePromiseTracker({ area: 'post-user-assessment' })

  const { assessmentFlowQuestions = [] } = useSelector(state => state.assessment)
  const { currentAgreementData: { agreementID = '' } } = useSelector(state => state.agreement)
  const { lastAgreement = null } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  // bool to determine if the user has agreed to the most recent agreement in the system
  const statementAgreed = Boolean(lastAgreement && lastAgreement === agreementID)

  // if we do not have an agreement yet in redux, fetch it from the api
  useEffect(() => {
    if (!agreementID) {
      dispatch(getCurrentAgreement())
    }
  }, [dispatch, agreementID])

  // local state used to visually manipulate questions answered without making new fetch calls
  const [localQuestions, setLocalQuestions] = useState([])

  // controls the question being viewed
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null)

  useEffect(() => {
    return () => {
      setLocalQuestions([])
      setCurrentQuestionIndex(null)
    }
  }, [])

  // load in the questions from the api as the local questions (only fires when there are no local questions already set)
  useMemo(() => {
    if (assessmentFlowQuestions?.length && !localQuestions?.length) {
      setLocalQuestions(assessmentFlowQuestions)
    }
  }, [assessmentFlowQuestions, localQuestions])

  // Find the index of the first question to display
  useMemo(() => {
    // if the current index is not 0 or falsy but we have a userAssessmentID
    if (currentQuestionIndex !== 0 && !currentQuestionIndex && userAssessmentID) {
      // if our local questions array has content
      if (localQuestions?.length) {
        let index = 0

        // look for the first question that has NOT been answered
        const firstUnansweredQuestionIndex = localQuestions.findIndex(x => !x.userSelectedOption)

        // fallback to find the last question answered
        // (used when the user is on the last question but has not hit 'complete assessment')
        const lastQuestionAnswered = localQuestions.findLastIndex(x => x.userSelectedOption)

        // If available set the index to the first unanswered question, otherwise set it to the last answered question.
        // if neither is found, start at the 0 index
        index = firstUnansweredQuestionIndex && firstUnansweredQuestionIndex !== -1
          ? firstUnansweredQuestionIndex
          : lastQuestionAnswered && lastQuestionAnswered !== -1
            ? lastQuestionAnswered
            : 0

        setCurrentQuestionIndex(index)
      }
    }
  }, [currentQuestionIndex, userAssessmentID, localQuestions])

  // Object containing the question information at the selected point in the question options
  const currentQuestion = useMemo(() => { return localQuestions[currentQuestionIndex] }, [localQuestions, currentQuestionIndex])

  // fetch the correct component to display based on the user's process
  const getComponent = () => {
    // If we are not actively fetching the agreement, determine which component to render
    if (!agreementPromise && !newUserAssessmentPromise) {
      // if the user has not agreed to the current statement, have them agree to the new one
      if (!statementAgreed) {
        return <ConfidentialityStatement />
      }

      // if the statement has been agreed to, but we have no userAssessmentID yet
      // (used when creating a new user assessment)
      if (statementAgreed && !userAssessmentID) {
        return <IndustrySector />
      }

      // Otherwise start the questions
      if (statementAgreed && userAssessmentID && localQuestions.length) {
        return (
          <AssessmentQuestion
            currentQuestion={currentQuestion}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            currentQuestionIndex={currentQuestionIndex}
            userAssessmentID={userAssessmentID}
            localQuestions={localQuestions}
            setLocalQuestions={setLocalQuestions}
          />
        )
      }
    } else {
      // while fetching the agreement, show the loader
      return <div className='min-width-50 d-flex align-items-center'><Loader size='sm' /></div>
    }
  }

  // Totals needed for progress bar
  const answeredTotal = localQuestions?.filter(x => x.userSelectedOption).length
  const totalQuestions = localQuestions?.length

  return (
    <>
      <div
        className={`col-sm-12 col-lg-${width} offset-lg-${(12 - width) / 2} mt-4 content flex-grow-1 d-flex flex-column`}
        style={{ marginBottom: '1.5rem' }}
      >
        <Container fluid className='flex-grow-1 d-flex flex-column justify-content-center justify-content-md-start align-items-center'>
          {getComponent()}
        </Container>
      </div>
      <div className='d-flex progress-position'>
        <ProgressBar answered={answeredTotal} totalQuestions={totalQuestions} />
      </div>
    </>
  )
}

export default AssessmentFlow
