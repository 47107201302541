import {
  GET_DISCOUNT_CODES, GET_DISCOUNT_CODE, LOGOUT_USER
} from '../types'

const initialState = {
  discountList: [],
  discountData: {}
}

const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCOUNT_CODES: return {
      ...state,
      discountList: action.payload
    }
    case GET_DISCOUNT_CODE: return {
      ...state,
      discountData: action.payload
    }
    case LOGOUT_USER: return {
      ...initialState
    }
    default: return state
  }
}

export default discountReducer
