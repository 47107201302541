import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Typography, Divider, OutlinedInput, useTheme, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TableSortLabel,
  Select, Pagination, MenuItem, useMediaQuery, Chip, Button, CircularProgress
} from '@mui/material'
import { SearchRounded, CloseRounded, IosShare } from '@mui/icons-material'
import { StyledTableRow, adminSideMenuTabs, progressComponentTypes, loaderArea, assessmentTypes, assessmentTypeLabels } from '../../../utils'
import { StyledLoadingIndicator } from '../../../utils/custom'
import { getAdminAssessments, getPartnerUserAssessments, getAssessmentReportPDF, getActiveAssessmentList, generateAssessmentLink } from '../../../redux/actions'
import { isEqual } from 'lodash'
import { usePromiseTracker } from 'react-promise-tracker'
import { NotificationToast } from '../../tools'

import { ExportModal, AssessmentLinkModal } from '../sectionTools'
import { DateTime } from 'luxon'

// The various db values for assessment progress status'
const assessmentStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
}

// User friendly labels for db values of assessment status'
const statusLabels = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
}

const defaultAssessmentLinkInfo = {
  emailAddress: '',
  userID: '',
  assessmentID: ''
}

const activeAssessmentFilter = {
  sectorID: '',
  assessmentType: assessmentTypes.ALTERNATE
}

// Local object to help manipulate values to the api vs values to the display chip
const defaultLocalSub = {
  user: { id: '', display: '' },
  company: { id: '', display: '' },
  email: { id: '', display: '' },
  discountCode: { id: '', display: '' },
  industry: { id: '', display: '' },
  sector: { id: '', display: '' },
  status: { id: '', display: '' },
  assessmentType: { id: '', display: '' },
  search: { id: '', display: '' }
}

// Keys to translate between local display and IDs needed for api call
const translateKeys = {
  user: 'userID',
  company: 'company',
  email: 'email',
  discountCode: 'discountCode',
  industry: 'industryID',
  sector: 'sectorID',
  status: 'status',
  search: 'search',
  assessmentType: 'assessmentType'
}

// Table Headers
const tableHeadCells = [
  { id: 'status', label: 'Status' },
  { id: 'completedAt', label: 'Date' },
  { id: 'userLastName', label: 'User' },
  { id: 'companyName', label: 'Company' },
  { id: 'email', label: 'Email' },
  { id: 'industryName', label: 'Industry' },
  { id: 'sectorName', label: 'Sector' },
  { id: 'discountCode', label: 'Discount Code' },
  { id: 'versionNumber', label: 'Version Number' },
  { id: 'assessmentType', label: 'Type' },
  { id: 'alternate', label: '' }
]

// Specific local classnames
const useStyles = (theme) => ({
  currentFilter: {
    backgroundColor: '#5280AA',
    color: '#ffff',
    padding: '0.4rem 0.6rem',
    borderRadius: '5px',
    marginLeft: '0.5em',
    marginBottom: '0.5em'
  },
  selectFilterSpacing: {
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em'
    }
  },
  selectFilter: {
    cursor: 'pointer'
  },
  exportButton: {
    padding: '0.4em 1.5em',
    fontWeight: 500,
    color: '#5280AA',
    borderColor: '#5280AA',
    '&:hover': {
      color: '#5280AACF',
      borderColor: '#5280AA'
    }
  }
})

const AssessmentAdministration = (props) => {
  const {
    classes = {}, assessments = [], totalAssessments = 0, filter = {}, setFilter = () => { }, defaultFilter = {},
    currentTab = '', adminUser = false
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()
  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'))
  const betweenMdLgScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const localClasses = useStyles(theme)
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ADMIN_ASSESSMENTS })

  // default the local industry options to an empty array
  const [industryList, setIndustryList] = useState([])

  // the local filter
  const [localSub, setLocalSub] = useState({ ...defaultLocalSub })

  // build the sector options array from the industry list and/or the locally selected industryID
  const sectorOpts = useMemo(() => {
    // if the industry list has been set return the correct sub sectors array
    if (industryList.length) {
      // if a local industry ID has been chosen, return only that industry's sectors
      if (localSub?.industry?.id) { return industryList.find((ind) => ind.industryID === localSub?.industry?.id)?.sectors || [] }

      // otherwise compile an array of all the sectors to return as the default 'All' option
      const fullList = industryList.reduce((arr, industry) => {
        if (industry?.sectors) { arr.push(...industry.sectors) }
        return arr
      }, [])

      return fullList
    }

    // return an empty array if the industry list has not been set
    return []
  }, [industryList, localSub?.industry?.id])

  // fetch the list of industry and sector options with active assessments
  useMemo(async () => {
    if (!industryList?.length) {
      const assessmentData = await dispatch(getActiveAssessmentList({ groupType: 'grouped' })).then(res => { return res })
      if (assessmentData) {
        // set the data to the local list
        setIndustryList(assessmentData)
      }
    }
  }, [dispatch, industryList])

  // Industry select filter
  const [industryOpen, setIndustryOpen] = useState(false)

  // Sector select filter
  const [sectorOpen, setSectorOpen] = useState(false)

  // Status select filter
  const [statusOpen, setStatusOpen] = useState(false)

  // Assessment Type select filter
  const [typeOpen, setTypeOpen] = useState(false)

  // Alternate Link State Items / Handlers
  const [assessmentLinkModalOpen, setAssessmentLinkModal] = useState(false)
  const [assessmentLinkInfo, setAssessmentLinkInfo] = useState({ ...defaultAssessmentLinkInfo })
  const [alternateAssessments, setAlternateAssessments] = useState([])

  const defaultAssessmentLinkErrors = { invalidEmail: false, failedAction: false, requiredInformation: false }
  const [assessmentLinkErrs, setAssessmentLinkErrs] = useState({ ...defaultAssessmentLinkErrors })

  const fireActiveAssessmentSuccess = (data) => { if (data) { setAlternateAssessments(data) } }

  const fireActiveAssessmentFailure = () => { NotificationToast(true, 'Something went wrong.') }

  const handleOpenAssessmentLinkModal = async (emailAddress, sectorID, userID) => {
    if (sectorID && userID) {
      setAssessmentLinkInfo({ ...assessmentLinkInfo, emailAddress, userID })
      dispatch(getActiveAssessmentList({ ...activeAssessmentFilter, sectorID }, fireActiveAssessmentSuccess, fireActiveAssessmentFailure))
      setAssessmentLinkModal(true)
    }
  }

  const handleCloseAssessmentLinkModal = () => {
    setAssessmentLinkInfo({ ...defaultAssessmentLinkInfo })
    setAlternateAssessments([])
    setAssessmentLinkModal(false)
    setAssessmentLinkErrs({ ...defaultAssessmentLinkErrors })
  }

  const fireGetAssessmentLinkSuccess = async (url) => {
    handleCloseAssessmentLinkModal()
    navigator.clipboard.writeText(url)

    NotificationToast(false, 'Link copied successfully!')
  }

  const fireGetAssessmentLinkFailure = () => { setAssessmentLinkErrs({ ...assessmentLinkErrs, failedAction: true }) }

  const handleGetAssessmentLink = () => {
    if (!assessmentLinkInfo?.assessmentID) {
      setAssessmentLinkErrs({ ...assessmentLinkErrs, requiredInformation: true })
      return false
    }

    dispatch(generateAssessmentLink(assessmentLinkInfo, fireGetAssessmentLinkSuccess, fireGetAssessmentLinkFailure))
  }

  // ******************** Data Creation **************** //
  // If the filter was changed, fetch the assessments with the new filter
  useEffect(() => {
    if (filter && defaultFilter && currentTab === adminSideMenuTabs.ASSESSMENTS.tab) {
      if (!isEqual(filter, defaultFilter)) {
        const action = adminUser ? getAdminAssessments : getPartnerUserAssessments
        dispatch(action(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, currentTab, adminUser])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (assessments) {
      if (assessments.length) {
        const newRows = []
        assessments.forEach(admin => {
          newRows.push({ ...admin })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [assessments])

  // Renders the list of sub filters applied based on the local subfilter object
  const handleRenderSubfilters = () => {
    // Check if the the local subfilter has any truthy values for any of its sub 'display' keys
    if (localSub && !Object.values(localSub).every(value => !value?.display)) {
      // If so return a 'chip' for each display key that has a turthy value
      return Object.keys(localSub).map(subKey => {
        const { id = null, display = '' } = localSub[subKey]

        if (display) {
          // If dealing with a 'status' or 'assessmentType' filter, be sure to apply the user friendly label.
          const displayName = Object.keys(statusLabels).includes(display) ? statusLabels[display] : Object.keys(assessmentTypeLabels).includes(display) ? assessmentTypeLabels[display] : display

          return (
            <Chip
              key={`local-filter-${subKey}-${id || display}`}
              label={<Typography variant='caption1' style={{ color: 'white', overflow: 'hidden', textOverflow: 'ellipsis' }}>{displayName}</Typography>}
              sx={{ ...localClasses.currentFilter }}
              onDelete={(e) => handleSetSubFilterForm(subKey, null, '')}
              deleteIcon={<CloseRounded style={{ color: 'white', fontSize: '15px' }} />}
            />
          )
        }
        return false
      })
    } else {
      // If no viable filters are selected, display a 'none' message
      return <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>None</Typography>
    }
  }

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAssessments - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //
  const [orderBy, setOrderBy] = useState('user')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      direction: isAsc ? 'desc' : 'asc',
      sortBy: property
    })
  }

  // Set the subfilter form (both the local version for display, and the official version to send to the api)
  const handleSetSubFilterForm = (key, id, val) => {
    if (key) {
      if (localSub && localSub !== defaultLocalSub && Object.keys(defaultLocalSub).includes(key)) {
        // Sets the appropriate value for the local filter
        const tempLocal = { ...localSub, [key]: { id: id || '', display: val } }

        // Sets the appropriate value for the api filter
        const tempCopy = {
          ...filter,
          subFilter: { ...filter.subFilter, [translateKeys[key]]: id || val || '' },
          page: 1,
          sortCount: rowsPerPage
        }

        // Be sure if we have clicked the 'chip' for a search related filter, then clear the input as well as the chip/filter
        if (key === 'search' && !val) {
          setSearchInput('')
        }

        // be sure to reset sector if the industry key was changed
        if (key === 'industry') { tempLocal.sector = defaultLocalSub.sector; tempCopy.subFilter = { ...tempCopy.subFilter, sectorID: '' } }

        setPage(0)
        setLocalSub(tempLocal)
        setFilter(tempCopy)
      }
    }
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      handleSetSubFilterForm('search', null, e.target.value)
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setLocalSub({ ...localSub, search: { id: null, display: '' } })
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // Placeholder span for when the values returned from the api call are falsy
  const defaultSpan = <div style={{ fontSize: '12px', color: theme.palette.grey.lightGrey }}>None</div>

  // Reused props applied to the select filter components
  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    size: 'small',
    defaultValue: '',
    style: { padding: 0 },
    sx: { ...classes?.searchInput },
    MenuProps: {
      sx: { ...classes?.statusSelect }
    },
    inputProps: {
      sx: { width: 'auto', maxWidth: 100, whitespace: 'normal', [theme.breakpoints.between('md', 'lg')]: { maxWidth: 45 } }
    }
  }

  const [currentlyDownloading, setCurrentlyDownloading] = useState(null)

  const handleDownloadPDF = async (assessmentInfo) => {
    const { userAssessmentID, companyName } = assessmentInfo

    if (currentlyDownloading) { return }
    setCurrentlyDownloading(userAssessmentID)

    try {
      const pdfResult = await dispatch(getAssessmentReportPDF(userAssessmentID))

      const sanitizedCompany = companyName.replace(/[,.\s]/g, '_')

      if (pdfResult) {
        const link = document.createElement('a')
        link.href = pdfResult
        link.download = `AssessmentReport-${sanitizedCompany}`
        document.body.append(link)
        link.click()
        link.remove()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentlyDownloading(null)
    }
  }

  // Export state/handlers
  const [exportModalOpen, setExportModalOpen] = useState(false)

  const handleExportModalOpen = () => {
    setExportModalOpen(true)
  }

  return (
    <>
      <ExportModal
        classes={classes}
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
        localClasses={localClasses}
        commonSelectProps={commonSelectProps}
        industryList={industryList}
      />
      <AssessmentLinkModal
        assessmentLinkModalOpen={assessmentLinkModalOpen}
        assessmentLinkInfo={assessmentLinkInfo}
        handleCloseAssessmentLinkModal={handleCloseAssessmentLinkModal}
        alternateAssessments={alternateAssessments}
        setAlternateAssessments={setAlternateAssessments}
        setAssessmentLinkInfo={setAssessmentLinkInfo}
        classes={classes}
        handleGetAssessmentLink={handleGetAssessmentLink}
        assessmentLinkErrs={assessmentLinkErrs}
      />
      <Grid container direction='column'>
        <Grid item container direction={smScreenDown ? 'column' : 'row'} xs={12} justifyContent='flex-end' alignItems='center' style={{ marginBottom: '0.5em' }} spacing={2}>
          <Grid item>
            <Button variant='outlined' sx={{ ...localClasses.exportButton }} startIcon={<IosShare fontSize='large' />} onClick={handleExportModalOpen}>Export</Button>
          </Grid>
        </Grid>
        {/* Section Header */}
        <Grid item container direction='column' style={{ alignItems: 'center', marginTop: '0.5em' }}>
          <Grid item container direction={betweenMdLgScreen ? 'column' : 'row'}>
            {/* Section Title */}
            <Grid item xs={betweenMdLgScreen ? null : 2} style={{ marginBottom: mdScreenDown ? '0.5rem' : 0 }} container>
              <Typography variant='h5'>Assessments</Typography>
            </Grid>
            <Grid item container direction='row' xs={betweenMdLgScreen || mdScreenDown ? null : 10}>
              {/* Select Filters */}
              <Grid
                item
                xs={smScreenDown ? null : 8}
                container
                direction='row'
                alignItems='center'
                style={{ marginBottom: mdScreenDown ? '0.5rem' : 0 }}
              >
                <Grid
                  item
                  container
                  direction={smScreenDown ? 'column' : 'row'}
                  alignItems={mdScreenDown ? 'flex-start' : 'center'}
                  justifyContent={mdScreenDown ? 'flex-start' : 'flex-end'}
                >
                  <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
                    <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Industry:</Typography>
                    <Select
                      {...commonSelectProps}
                      disabled={Boolean(!industryList || !industryList.length)}
                      value={localSub?.industry?.display || ''}
                      open={industryOpen}
                      onOpen={() => setIndustryOpen(true)}
                      onClose={() => setIndustryOpen(false)}
                      onChange={(e, child) => handleSetSubFilterForm('industry', child.props.id, e.target.value)}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {industryList?.map(industry => {
                        const { industryID = '', industryName = '' } = industry
                        return (
                          <MenuItem key={industryID} id={industryID} value={industryName}>{industryName}</MenuItem>
                        )
                      })}
                    </Select>
                  </Grid>

                  <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
                    <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Sector:</Typography>
                    <Select
                      {...commonSelectProps}
                      value={localSub?.sector?.display || ''}
                      disabled={Boolean(!sectorOpts || !sectorOpts.length)}
                      open={sectorOpen}
                      onOpen={() => setSectorOpen(true)}
                      onClose={() => setSectorOpen(false)}
                      onChange={(e, child) => { handleSetSubFilterForm('sector', child.props.id, e.target.value) }}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {sectorOpts?.sort((a, b) => a?.sectorName?.localeCompare(b?.sectorName)).map(sector => {
                        return (
                          <MenuItem key={sector.sectorID} id={sector.sectorID} value={sector.sectorName}>{sector.sectorName}</MenuItem>
                        )
                      })}
                    </Select>
                  </Grid>

                  <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
                    <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Status:</Typography>
                    <Select
                      {...commonSelectProps}
                      open={statusOpen}
                      onOpen={() => setStatusOpen(true)}
                      onClose={() => setStatusOpen(false)}
                      value={localSub?.status?.display || ''}
                      onChange={(e) => handleSetSubFilterForm('status', null, e.target.value)}
                    >
                      <MenuItem value=''>All</MenuItem>
                      <MenuItem value={assessmentStatus.COMPLETED}>{statusLabels.COMPLETED}</MenuItem>
                      <MenuItem value={assessmentStatus.IN_PROGRESS}>{statusLabels.IN_PROGRESS}</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item sx={{ ...localClasses.selectFilterSpacing }}>
                    <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Type:</Typography>
                    <Select
                      {...commonSelectProps}
                      open={typeOpen}
                      onOpen={() => setTypeOpen(true)}
                      onClose={() => setTypeOpen(false)}
                      value={localSub?.assessmentType?.display || ''}
                      onChange={(e) => handleSetSubFilterForm('assessmentType', null, e.target.value)}
                    >
                      <MenuItem value=''>All</MenuItem>
                      <MenuItem value={assessmentTypes.PRIMARY}>{assessmentTypeLabels.P}</MenuItem>
                      <MenuItem value={assessmentTypes.ALTERNATE}>{assessmentTypeLabels.A}</MenuItem>
                    </Select>
                  </Grid>

                </Grid>
              </Grid>

              {/* Search Bar */}
              <Grid item xs={smScreenDown ? null : 4} container justifyContent='flex-end'>
                <OutlinedInput
                  style={{ width: '100%' }}
                  startAdornment={
                    <SearchRounded style={{ color: theme.palette.grey.darkGrey, fontSize: '20px' }} />
                  }
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                />
              </Grid>
            </Grid>

          </Grid>

          {/* Export Button / Current Filters */}
          <Grid item container direction={smScreenDown ? 'column' : 'row'} xs={12} justifyContent='flex-end' alignItems='center' style={{ marginTop: '0.5em' }} spacing={2}>
            <Grid item>
              <Typography variant='caption1' style={{ color: theme?.palette?.grey?.darkGrey, marginRight: '0.4em' }}>Current filter(s):</Typography>
              {handleRenderSubfilters()}
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ padding: '.5em 0' }} />

        {/* Section Content */}
        <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ paddingTop: '1em' }}>
          <TableContainer elevation={0}>
            <Table sx={{ ...localClasses.tableSpacing }}>
              {/* Table Headers */}
              <TableHead>
                <TableRow>
                  <TableCell />
                  {tableHeadCells.map(header => {
                    return (
                      <TableCell
                        key={header?.id}
                        align={(header?.id === 'discountCode' || header?.id === 'versionNumber') ? 'center' : 'left'}
                        colSpan={header?.id === 'alternate' ? 2 : 0}
                        sortDirection={orderBy === header?.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header?.id}
                          direction={orderBy === header?.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header?.id)}
                        >
                          <Typography variant='body1' style={{ fontSize: '18px' }}>{header?.label}</Typography>
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Table Rows */}
                {promiseInProgress
                  ? (
                    <StyledTableRow style={{ height: '10em' }}>
                      <TableCell colSpan={12}>
                        <StyledLoadingIndicator area={loaderArea.ADMIN_ASSESSMENTS} loaderType={progressComponentTypes.LINEAR_PROGRESS} />
                      </TableCell>
                    </StyledTableRow>)
                  : (
                    <>
                      {rows.map((row) => {
                        const {
                          userAssessmentID = '', userID = '', userFirstName = '', userLastName = '', email = '', sectorID = '', industryID = '',
                          companyName = '', industryName = '', sectorName = '', status = '', discountCode = '', responseCount = '', totalQuestions = '',
                          versionNumber = '', completedAt = '', assessmentType = ''
                        } = row
                        // Formatted completedAt date
                        const formattedDate = completedAt ? DateTime.fromISO(completedAt).toLocaleString(DateTime.DATE_SHORT) : 'N/A'

                        // Format assessment type display
                        const assessmentTypeDisplay = assessmentTypeLabels[assessmentType] || defaultSpan

                        return (
                          <StyledTableRow
                            key={`assessment-row-${userAssessmentID}`}
                          >
                            <TableCell
                              align='center'
                              colSpan={2}
                            >
                              {status === assessmentStatus.COMPLETED
                                ? (
                                  <Button variant='contained' onClick={(e) => { e.preventDefault(); if (currentlyDownloading === null) handleDownloadPDF(row) }}>
                                    {
                                      currentlyDownloading && currentlyDownloading === userAssessmentID ? <CircularProgress style={{ color: 'white' }} size={25} /> : 'PDF'
                                    }
                                  </Button>)
                                : (
                                  <>
                                    <Grid item container justifyContent='center' alignItems='center'>
                                      <Typography fontWeight={600} textAlign='center'>
                                        {`${responseCount}/${totalQuestions}`}
                                      </Typography>
                                    </Grid>
                                  </>)}
                            </TableCell>
                            <TableCell
                              align='left'
                              style={{ cursor: completedAt ? 'pointer' : 'default' }}
                            >
                              {formattedDate}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (userLastName) handleSetSubFilterForm('user', userID, userLastName) }}
                              align='left'
                              style={{ cursor: userLastName ? 'pointer' : 'default' }}
                            >
                              {`${userFirstName} ${userLastName}`}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (companyName) handleSetSubFilterForm('company', null, companyName) }}
                              align='left'
                              style={{ cursor: companyName ? 'pointer' : 'default' }}
                            >
                              {companyName}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (email) handleSetSubFilterForm('email', null, email) }}
                              align='left'
                              style={{ cursor: email ? 'pointer' : 'default' }}
                            >
                              {email}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (industryName) handleSetSubFilterForm('industry', industryID, industryName) }}
                              align='left'
                              style={{ cursor: industryName ? 'pointer' : 'default' }}
                            >
                              {industryName || defaultSpan}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (sectorName) handleSetSubFilterForm('sector', sectorID, sectorName) }}
                              align='left'
                              style={{ cursor: sectorName ? 'pointer' : 'default' }}
                            >
                              {sectorName || defaultSpan}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (discountCode) handleSetSubFilterForm('discountCode', null, discountCode) }}
                              align='center'
                              style={{ cursor: discountCode ? 'pointer' : 'default' }}
                            >
                              {discountCode || defaultSpan}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (versionNumber) handleSetSubFilterForm('versionNumber', null, versionNumber) }}
                              align='center'
                              style={{ cursor: versionNumber ? 'pointer' : 'default' }}
                            >
                              {versionNumber || defaultSpan}
                            </TableCell>
                            <TableCell
                              onClick={(e) => { e.preventDefault(); if (assessmentType) handleSetSubFilterForm('assessmentType', null, assessmentType) }}
                              align='left'
                              style={{ cursor: assessmentType ? 'pointer' : 'default' }}
                              colSpan={2}
                            >
                              {assessmentTypeDisplay}
                            </TableCell>
                            <TableCell
                              align='right'
                            >
                              <Button variant='outlined' sx={{ width: '10rem' }} disabled={status !== assessmentStatus.COMPLETED} onClick={() => handleOpenAssessmentLinkModal(email, sectorID, userID)}>
                                <Typography>Get Assessment Link</Typography>
                              </Button>
                            </TableCell>
                          </StyledTableRow>
                        )
                      })}
                      {/* Data Array has reached it's length: */}
                      {emptyRows > 0 && (
                        <StyledTableRow style={{ height: 10 }}>
                          <TableCell colSpan={12}>
                            <Typography variant='body1' style={{ color: theme?.palette?.grey?.lightGrey, textAlign: 'center' }}>{!assessments?.length ? 'No Results Found' : 'End of List'}</Typography>
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </>)}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        <Grid item container direction='row' style={{ marginBottom: '4em', marginTop: '1em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                sx={{ ...classes?.searchInput }}
                style={{ padding: 0 }}
                MenuProps={{
                  sx: { ...classes?.statusSelect }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ color: theme?.palette?.grey?.darkGrey }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              sx={{ ...classes?.pagination }}
              count={totalAssessments ? Math.ceil(totalAssessments / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default AssessmentAdministration
