import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import { Grid } from '@mui/material'
import { adminSideMenuTabs, userTypes } from '../../utils'

import AdministrationSideMenu from '../../components/administration/AdministrationSideMenu'
import AdministrationMainContent from '../../components/administration/AdministrationMainContent'

const subDefaultFilter = {
  userID: '',
  company: '',
  discountCode: '',
  industryID: '',
  sectorID: '',
  status: '',
  search: ''
}

const Administration = (props) => {
  const { width } = props
  const { userType, userID } = useSelector(state => state.auth)
  const parsedProps = queryString.parse(props?.location?.search)
  const [currentTab, setCurrentTab] = useState(adminSideMenuTabs?.USERS?.tab)

  // Base Filter needed for pagination
  const defaultFilter = useMemo(() => ({
    page: 1,
    sortCount: 10,
    direction: '',
    sortBy: '',
    search: '',
    subFilter: { ...subDefaultFilter }
  }), [])

  // Set the filter initially
  const [filter, setFilter] = useState({ ...defaultFilter })

  useEffect(() => {
    if (!parsedProps || !Object.keys(parsedProps).length) {
      if (userType && userType !== userTypes.COMPANY_USER) {
        props.history.push({
          pathname: '/administration',
          search: `?${userType}=${userID}`
        })
      }
    }
  }, [parsedProps, userType, userID, props.history])

  const adminUser = Boolean(parsedProps.admin)
  const defaultProps = { filter, setFilter, defaultFilter, currentTab, setCurrentTab, adminUser }

  return (
    <div
      className={`col-sm-12 col-lg-${width} offset-lg-${(12 - width) / 2} mt-4 content flex-grow-1 d-flex flex-column}`}
      style={{ marginBottom: '1.5rem' }}
    >
      <Grid container direction='column' style={{ marginTop: '1.3rem' }}>
        {parsedProps && Boolean(parsedProps.admin || parsedProps.partnerUser) &&
          <Grid container item direction='row'>
            <Grid item sm={12} md={3} container direction='column' style={{ padding: '1em', position: 'relative' }}>
              <AdministrationSideMenu
                {...defaultProps}
              />
            </Grid>
            <Grid item sm={12} md={9} container direction='column' style={{ padding: '1em', height: '100vh' }}>
              <AdministrationMainContent
                {...defaultProps}
              />
            </Grid>
          </Grid>}
      </Grid>
    </div>
  )
}

export default withRouter(Administration)
