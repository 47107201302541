import React from 'react'

import Main from '../components/Main'
import MainHeader from '../components/MainHeader'

const Default = ({ children, width }) => {
  return (
    <>
      <Main className='d-flex flex-column justify-content-between position-relative'>
        <MainHeader />
        {children}
      </Main>
    </>
  )
}

export default Default
