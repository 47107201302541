import Swal from 'sweetalert2'
const loginToastConfig = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000
})
export const LoginToast = () => {
  loginToastConfig.fire({
    icon: 'error',
    title: 'Session expired, please log in again.'
  })
}

const notificationToastConfig = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export const NotificationToast = (error, message, question = false) => {
  if (question !== true) {
    notificationToastConfig.fire({
      icon: error ? 'error' : 'success',
      title: message,
      iconColor: error ? undefined : '#4C5788'
    })
  } else {
    notificationToastConfig.fire({
      icon: 'warning',
      title: message
    })
  }
}

const duplicateUserToastConfig = Swal.mixin({
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#5280AA',
  confirmButtonText: 'Change current email and proceed.',
  cancelButtonColor: '#e6e6e6',
  cancelButtonText: '<span style="color: #404040">Cancel</span>',
  reverseButtons: true
})

export const DuplicateUserToast = (action) => {
  duplicateUserToastConfig.fire({
    title: 'This email already exists on a company level account.',
    html: '<p style="color: #777777">If you wish to proceed in creating a new user with this email, you can elect to have the current account modified to add \'+1\' to their stored email address. (e.g john.smith+1@website.com)</p><p style="color: #777777">If you do <span style="font-weight: bold; text-decoration: underline">not</span> wish to change the current user\'s email address, cancel this request and provide an alternate email for the new user.</p>'
  })
    .then((result) => {
      if (result.isConfirmed) {
        action()
      }
    })
}
