import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, OutlinedInput, Select, MenuItem, useTheme
} from '@mui/material'

// Renders the form for creating a new question
// rendered in NewQuestionModal.js
const NewQuestionContent = (props) => {
  const {
    // generated in AssessmentSections.js
    newQuestionForm = {}, setNewQuestionForm = () => {},
    // generated in top most parent Builder.js
    classes = {}
  } = props
  const theme = useTheme()
  const { sections = [] } = useSelector(state => state.sections)

  const commonSelectProps = {
    displayEmpty: true,
    variant: 'outlined',
    fullWidth: true,
    defaultValue: '',
    style: { padding: 0 },
    sx: { ...classes?.searchInput },
    MenuProps: {
      sx: { ...classes?.statusSelect }
    }
  }

  return (
    <>
      {/* Edit the question text */}
      <Grid container item direction='row' spacing={2}>
        <Grid container item xs={8} direction='column'>
          <Typography gutterBottom variant='body1'>Question<span style={{ color: 'red' }}>*</span></Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            inputProps={{
              maxLength: 200
            }}
            value={newQuestionForm?.text || ''}
            placeholder='Type the content of the question here...'
            onChange={(e) => setNewQuestionForm({ ...newQuestionForm, text: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${newQuestionForm?.text?.length || 0}/200`}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom variant='body1'>Section<span style={{ color: 'red' }}>*</span></Typography>
          <Select
            {...commonSelectProps}
            disabled={Boolean(!sections)}
            value={newQuestionForm.sectionID || ''}
            onChange={(e) => setNewQuestionForm({ ...newQuestionForm, sectionID: e.target.value })}
          >
            <MenuItem value='' disabled>Select a Section...</MenuItem>
            {sections?.map((section) => {
              const { id: sectionID, shortName } = section
              return (
                <MenuItem key={`assessment-section-option-${sectionID}`} value={sectionID}>{shortName}</MenuItem>
              )
            })}
          </Select>
        </Grid>
      </Grid>
      {/* Edit the explanation for a question */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Explanation</Typography>
        <OutlinedInput
          fullWidth
          multiline
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 1000
          }}
          value={newQuestionForm?.explanationText || ''}
          placeholder='Type an explanation of the question here...'
          onChange={(e) => setNewQuestionForm({ ...newQuestionForm, explanationText: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${newQuestionForm?.explanationText?.length || 0}/1000`}</Typography>
      </Grid>
      {/* Edit the help text for a question */}
      <Grid container item direction='column'>
        <Typography gutterBottom variant='body1'>Help Text</Typography>
        <OutlinedInput
          fullWidth
          multiline
          margin='dense'
          size='small'
          inputProps={{
            maxLength: 1000
          }}
          value={newQuestionForm?.helpText || ''}
          placeholder='Type helper text for the question here...'
          onChange={(e) => setNewQuestionForm({ ...newQuestionForm, helpText: e.target.value })}
        />
        <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${newQuestionForm?.helpText?.length || 0}/1000`}</Typography>
      </Grid>
      {/* resource link for a question */}
      <Grid container item direction='row' spacing={2}>
        <Grid item xs={7}>
          <Typography gutterBottom variant='body1'>Resource</Typography>
          <OutlinedInput
            fullWidth
            margin='dense'
            size='small'
            inputProps={{
              maxLength: 255
            }}
            value={newQuestionForm?.education || ''}
            onChange={(e) => setNewQuestionForm({ ...newQuestionForm, education: e.target.value })}
          />
          <Typography variant='caption' style={{ color: theme.palette.grey.darkGrey }}>{`${newQuestionForm?.education?.length || 0}/250`}</Typography>
        </Grid>
        {/* resource type (currently only has 'read' as available option) */}
        <Grid item xs={5}>
          <Typography gutterBottom variant='body1'>Resource Type</Typography>
          <Select
            {...commonSelectProps}
            disabled
          >
            <MenuItem value='' disabled>Read</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  )
}

export default NewQuestionContent
